import React from 'react'
import { translate, checkTextTranslation } from '../utils/useful'
import TLink from './TLink'
// import { Helmet } from 'react-helmet';
import { siteConfig } from '../variables/config';
import { Helmet } from 'react-helmet-async';

class FastHelmet extends React.Component {
    state = {
        key: ''
    }




    render() {
        return (
            <Helmet>
                <meta charSet="utf-8" />
                <title>{siteConfig.siteName} | {String(this.props.title)}</title>
                <meta property="title" content={this.props.title} />
                <meta property="description" content={this.props.description} />
                {this.props.image && (
                    <meta property="image" content={this.props.image} />
                )}
                <meta property="url" content={window.location} />

                <meta property="og:title" content={this.props.title} />
                <meta property="og:url" content={window.location} />
                {this.props.image && (
                    <meta property="og:image" content={this.props.image} />
                )}
                <meta property="og:description" content={this.props.description} />
                {this.props.image && (
                    <meta property="twitter:card" content={this.props.image} />
                )}
                <meta property="twitter:title" content={this.props.title} />
                <meta property="twitter:description" content={this.props.description} />

                <link rel="canonical" href="http://mysite.com/example" />
            </Helmet>
        )
    }
}

export default FastHelmet