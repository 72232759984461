import React, { Component } from "react";
import * as XLSX from "xlsx";
import { v4 as uuid } from "uuid";
import { fillRemainingDataGaps, paginateData } from "../../utils/functions";
import Modal from "../Modal";
import ExcelTable from "./ExcelTable";
import HttpServices from "../../utils/Http.services";
import Loader from "react-loader-spinner";

class ExcelDefinedNames extends Component {
    state = {
        toggleInfoBoxClickSign: false,
        allAvailableWBNames: {},
        tempSelectedWBNames: {},
        selectedWBNames: {},
        isExcelFileLoading: false,
    };
    displayImportGuidance = () => {
        const informationtag = document.getElementById(
            "choose_file_section_text_guidance"
        );
        if (informationtag) {
            informationtag.style.display = "block";
        }
    };
    hideImportGuidance = () => {
        const informationtag = document.getElementById(
            "choose_file_section_text_guidance"
        );
        if (informationtag) {
            informationtag.style.display = "none";
        }
    };
    toggleInfoBoxClick = () => {
        this.setState({
            toggleInfoBoxClickSign: !this.state.toggleInfoBoxClickSign,
        });
    };
    getCheckedBox = (wbName) => {
        const { nameId } = wbName;
        const tempSelectedWBNames = { ...this.state.tempSelectedWBNames };
        if (tempSelectedWBNames[nameId]) {
            delete tempSelectedWBNames[nameId];
        } else {
            tempSelectedWBNames[nameId] = wbName;
        }
        this.setState({ tempSelectedWBNames });
    };

    deleteDisplayedName = (nameId) => {
        const tempSelectedWBNames = { ...this.state.tempSelectedWBNames };
        const selectedWBNames = { ...this.state.selectedWBNames };
        delete tempSelectedWBNames[nameId];
        delete selectedWBNames[nameId];
        this.setState({
            tempSelectedWBNames,
            selectedWBNames,
        });
    };
    removeAllSelectedWBNames = () => {
        const tempSelectedWBNames = { ...this.state.tempSelectedWBNames };
        const selectedWBNames = { ...this.state.selectedWBNames };
        for (const wbName in selectedWBNames) {
            delete tempSelectedWBNames[wbName];
        }
        this.setState({
            tempSelectedWBNames,
            selectedWBNames: {},
        });
    };

    getSelectedWBNames = () => {
        return { ...this.state.selectedWBNames };
    };
    setSelectedWBNames = (selectedWBNames) =>
        this.setState({ selectedWBNames });
    readExcel = (event) => {
        const file = event.target.files[0];
        event.target.value = null;
        if (!file) {
            return;
        }
        this.setState(
            {
                selectedWBNames: {},
                tempSelectedWBNames: {},
                allAvailableWBNames: {},
                isExcelFileLoading: true,
            },
            () => {
                
                const fileProps = {
                    file,
                    name: file?.name,
                    type: file?.type,
                    size: file?.size,
                    lastModified: file?.lastModified,
                    lastModifiedDate: file?.lastModifiedDate,
                    address: "/or/readExcelDefinedName",
                    field: "field",
                    applet: null,
                };
                HttpServices.fileUpload({ file, fileProps })
                    .then((result) => {
                        const excelFileDefinedNames =
                            result.excelFileDefinedNames;
                        this.setState({
                            allAvailableWBNames: excelFileDefinedNames,
                        });
                    })
                    .catch(alert).finally(() => this.setState({ isExcelFileLoading: false }));
            }
        );
    };
    render() {
        return (
            <div
                className="choose_file_section_container col-12 col-md-7 h-100"
                style={{ flex: "1" }}
            >
                <div className="choose_file_section px-3">
                    <div className="choose_file_section_text">
                        <div className="choose_file_section_text_ImportData">
                            <span style={{ fontSize: "14px" }}>
                                Import Data{" "}
                            </span>
                            <img
                                onMouseEnter={() =>
                                    this.displayImportGuidance()
                                }
                                onMouseLeave={() => this.hideImportGuidance()}
                                onClick={() => this.toggleInfoBoxClick()}
                                src="../images/icons/information.png"
                                className="information_icon_excel"
                                alt=""
                            />
                            <span
                                style={{
                                    display: this.state.toggleInfoBoxClickSign
                                        ? "block"
                                        : "none",
                                }}
                                className="choose_file_section_text_guidance"
                                id="choose_file_section_text_guidance"
                            >
                                How to Import Data From Excel File :
                                <ol>
                                    <li>Define data range in an excel file</li>
                                    <li>Upload the excel file</li>
                                    <li>Select the defined variable(s)</li>
                                    <li>Click "Submit"</li>
                                    <li>Check the values of variables</li>
                                    <li>Confirm the data</li>
                                </ol>
                            </span>
                        </div>
                    </div>

                    <div className="choose_file_section_icon ">
                        <label
                            htmlFor="selectExcelFile"
                            style={{ cursor: "pointer" }}
                        >
                            Upload File
                        </label>
                        <input
                            type="file"
                            id="selectExcelFile"
                            className="selectExcelFileInput"
                            accept=".xlsx"
                            onChange={(e) => {
                                this.readExcel(e);
                            }}
                        />
                    </div>
                </div>
                <div className=" " style={{ backgroundColor: "#ffff" }}>
                    <div className="excel_disaply_data_section col-12">
                        <div className="excel_disaply_data_section_all_variables ">
                            <table style={{ width: "70%" }}>
                                <tbody>
                                    <tr
                                        style={{
                                            position: "relative",
                                        }}
                                    >
                                        <th
                                            style={{
                                                borderBottom: "1px solid black",
                                                fontSize: "14px",
                                            }}
                                        >
                                            All Variables
                                        </th>
                                    </tr>
                                    {this.state.isExcelFileLoading ? (
                                        <div
                                            style={{
                                                width: "100%",
                                                height: "100%",
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                marginTop: 30,
                                            }}
                                        >
                                            <Loader
                                                type={"Oval"}
                                                color={"rgb(57,111,180)"}
                                                height={30}
                                                width={30}
                                            />
                                        </div>
                                    ) : (
                                        Object.values(
                                            this.state.allAvailableWBNames
                                        ).map((wbName, index) => {
                                            // map the inputnameWithValues to display the names to be checked
                                            const {
                                                name,
                                                // comment,
                                                // sheet,
                                                // nameRangeValues,
                                                nameId,
                                            } = wbName;
                                            if (
                                                !this.state.selectedWBNames[
                                                    nameId
                                                ]
                                            ) {
                                                return (
                                                    <tr
                                                        className="single_variable_name"
                                                        key={nameId}
                                                    >
                                                        <td className="displayedValues_Checkbox_td">
                                                            <span className="excel_variables_checkmark"></span>
                                                            <input
                                                                onChange={() =>
                                                                    this.getCheckedBox(
                                                                        wbName
                                                                    )
                                                                }
                                                                type="checkbox"
                                                                className="displayedValues_Checkbox"
                                                                checked={
                                                                    this.state
                                                                        .tempSelectedWBNames[
                                                                        nameId
                                                                    ]
                                                                }
                                                            />
                                                            <label className="single_item_name">
                                                                {name}
                                                            </label>
                                                        </td>
                                                    </tr>
                                                );
                                            }
                                        })
                                    )}
                                </tbody>
                            </table>
                        </div>
                        <div className="transfer_data_arrow_holder">
                            <img
                                onClick={() =>
                                    this.setState({
                                        selectedWBNames: this.state
                                            .tempSelectedWBNames,
                                    })
                                }
                                className="transfer_data_arrow transfer_data_arrowOne"
                                src="../images/icons/fast-forward.png"
                                alt=""
                            />
                        </div>
                        <div className="excel_disaply_data_section_selected_variables ">
                            <table style={{ width: "70%" }}>
                                <tbody>
                                    <tr>
                                        <th
                                            style={{
                                                borderBottom: "1px solid black",
                                                fontSize: "14px",
                                            }}
                                        >
                                            Selected Variables
                                        </th>
                                    </tr>

                                    {Object.values(
                                        this.state.selectedWBNames
                                    ).map((wbName) => {
                                        const {
                                            name,
                                            // comment,
                                            // sheet,
                                            // nameRangeValues,
                                            nameId,
                                        } = wbName;
                                        return (
                                            <tr
                                                className="single_displayed_variable"
                                                key={nameId}
                                            >
                                                <td>
                                                    <img
                                                        onClick={() =>
                                                            this.deleteDisplayedName(
                                                                nameId
                                                            )
                                                        }
                                                        src="../images/icons/delete.png"
                                                        alt=""
                                                    />
                                                    <span className="single_item_name">
                                                        {name}
                                                    </span>
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </div>
                        <div className="selectAll_deleteAll_btns_div">
                            <div
                                onClick={() =>
                                    this.setState({
                                        tempSelectedWBNames: {
                                            ...this.state.allAvailableWBNames,
                                        },
                                    })
                                }
                            >
                                Select All
                            </div>

                            <div onClick={this.removeAllSelectedWBNames}>
                                Remove All
                            </div>
                        </div>
                    </div>
                    <div className="choose_file_section_submit_icon_holder col-12">
                        <div className="choose_file_section_submit_icon ">
                            <label
                                onClick={() =>
                                    Object.values(this.state.selectedWBNames)
                                        .length > 0 &&
                                    this.excelWBNamesModal.showModal()
                                }
                                htmlFor="submitExcelFile"
                                style={{ cursor: "pointer" }}
                            >
                                Show Data
                            </label>
                            <input
                                type="submit"
                                id="submitExcelFile"
                                className="submitExcelFile"
                            />
                        </div>
                    </div>
                </div>
                <Modal
                    ref={(el) => (this.excelWBNamesModal = el)}
                    maxWidth={900}
                >
                    <ExcelTable
                        hideModal={() => this.excelWBNamesModal.hideModal()}
                        selectedWBNames={{ ...this.state.selectedWBNames }}
                        parseExcelDefinedNames={
                            this.props.parseExcelDefinedNames
                        }
                    />
                </Modal>
            </div>
        );
    }
}

export default ExcelDefinedNames;
