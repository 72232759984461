import React from 'react'
import { checkTranslation } from '../../utils/useful'
import TLink from '../TLink'

class AdminModule extends React.Component {

    state = {
        open: false
    }

    

    render() {

        let data = this.props.data
        let link = '/admin/'+data.key
        return (
            <div className="w-100 service-box h-100">
                    <div className="w-100 cover-img-container m-0 p-0 flexcc">
                        <TLink className="m-0 p-0 w-100 h-100 flexcc" to={link}>
                            <img className="cover-img m-0 p-0" src={data.image} alt={data.title}  />
                        </TLink>
                    </div>
                


                <div className="mx-3 pt-3  pb-4 service-box-content text-center">
                    <TLink to={link}><h3 className="font-bold text-normal m-0">{checkTranslation(data.title).toUpperCase()}</h3></TLink>
                    <TLink to={link}><p className="service-box-desc text-smaller">{data.description}</p></TLink>
                </div>
{/* 
                <div className="flexcb service-box-actions  mx-3 pb-3 pt-3">
                    <TLink to={link} className="py-1 px-3 preview-but">
                        <p className="text-smaller">Open</p>
                    </TLink>
                    
                </div> */}

            </div>
        )
    }
}

export default AdminModule
