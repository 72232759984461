import React from "react";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actions from '../../stores/actionsList';
import Configurer from "../../components/Configurer";

import DataGrid from "../../components/DataGrid";

import AdminModule from "../../components/boxes/AdminModule";
import { adminPages } from "../../variables/adminPages";


class AdminDashboard extends React.Component {


    state = {
        modules: []
    }


    
    


    render() {
        return (

            <Configurer
                settingsInfo={{ showFooter: true, showTabBar: true, showHeader: true, headerTitle: "Dashboard", button: {} }}
                title={"Dashboard"}
                description={"AdminDashboard Description"}
                className=""
            >

                <div className="row m-0">

                    <div className="col-12 pt-0 pb-4 ">


                        <div className="px-0 mt-0 mb-4">
                            <p className="text-ultra-big font-bold">Dashboard</p>
                            <p className="text-small" style={{ color: '#456' }}>Admin Section - Choose a module to work with</p>
                        </div>



                        <DataGrid isLoading={this.state.isLoading} data={this.props.modules} component={AdminModule} col="col-12 col-md-6 col-lg-3" />
                    </div>
                </div>
            </Configurer>
        )
    }


}



const mapStateToProps = state => ({ settings: state.settings, user: state.user })
const mapDispatchToProps = dispatch => ({ actions: bindActionCreators(actions, dispatch) })

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AdminDashboard);
