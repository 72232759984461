import React, { Component } from "react";
class WordSuggestion extends Component {
    state = {
        suggestionWords: [],
        display: false,
        activeWordIndex: ""
    };

    updateSuggestionWords = (newSuggestionWords, cb) => {
        this.setState({ suggestionWords: newSuggestionWords }, () => {
            cb && cb();
        })
    }
    onKeyDown = (e) => {
        
        if(e.key.includes("Arrow") && this.WordSuggestion.getDisplay()){
            e.preventDefault();
            if(e.key.includes("Up")){
                this.setState({
                    activeWordIndex: this.state.activeWordIndex + 1
                })
            }
            else if(e.key.includes("Down")){
                this.setState({
                    activeWordIndex: this.state.activeWordIndex - 1
                })
            }

        }
    }
    showWrapper = () => this.setState({ display: true })
    hideWrapper = () => this.setState({ display: false })
    getDisplay = () => this.state.display;
    changePosition = (left, top) => {
        const WordSuggestionWrapperStyle = this.wrapperRef.style;
        WordSuggestionWrapperStyle.left = `${left + 10}px`;
        WordSuggestionWrapperStyle.top = `${top +25}px`;
    }
    render() {
        return (
            <div className={
                this.props?.className
            }
            onKeyDown={this.onKeyDown}
                style={{
                    display: this.state.display ? "block" : "none",
                    position: "fixed",
                    ...this.props.styles,

                }}
                ref={el => this.wrapperRef = el}
            >
                {
                    this.state.suggestionWords?.map((suggestionWord, index) => {
                        const isActiveWord = index === this.state.activeWordIndex;
                        const boxStyle = {
                            ...this.props.wordBoxStyle,
                            ...(isActiveWord ? this.props?.activeWordStyle || {} : {}),
                        };
                        return (
                            <div style={{
                                cursor: "pointer",
                                ...boxStyle
                            }}
                                onClick={() => this.props.selectSuggestionWord(suggestionWord)}
                                onMouseOver={() => this.setState({activeWordIndex: index})}
                                onMouseLeave={() => this.setState({activeWordIndex: null})}
                            >
                                {suggestionWord}
                            </div>
                        )
                    })
                }
            </div>
        );
    }
}

export default WordSuggestion;