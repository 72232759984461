import React from "react";
import TLink from "../components/TLink";
import { checkTextTranslation, pathMaker } from "../utils/useful";
import { siteTheme } from "../variables/config";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actions from '../stores/actionsList';
import TabBarItem from "../components/boxes/TabBarItem";
import { withRouter } from "react-router-dom";

class TabBar extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            tabs: [

                { name: '{{lang}}Home', href: '/', key: 'Home', icon: '/images/home2.svg', page: 'search' },

                // { name: '{{lang}}Thing', href: '/things', key: 'devices', icon: 'https://www.flaticon.com/svg/static/icons/svg/2628/2628401.svg', page: 'Tower', notif: 5 },


                // { name: '{{lang}}services', key: 'services', icon: 'https://www.flaticon.com/svg/static/icons/svg/2055/2055895.svg', page: 'Tower' },
                { name: '{{lang}}library', href: '/library', key: 'library', icon: '/images/library2.svg', page: 'Tower' },

                { name: '{{lang}}history', href: '/history',key: 'history', icon: '', page: 'Tower' },
                // { name: '{{lang}}history', href: '/history',key: 'history', icon: 'https://image.flaticon.com/icons/png/512/1827/1827379.png', page: 'Tower' },

                // { name: '{{lang}}Edit Thing', href: '/thing/id', key: 'thing', icon: '/images/settings-3.svg', page: 'Tower' },
                { name: '{{lang}}profile',href: '/profile', key: 'profile', icon: '/images/profile2.svg', page: 'Tower' },


                // { name: '{{lang}}addnew', key: 'newService', icon: 'https://www.flaticon.com/svg/static/icons/svg/3465/3465222.svg', page: 'Tower', parent: 'services' },
                // { name: '{{lang}}editService', key: 'editService', icon: 'https://www.flaticon.com/svg/static/icons/svg/3465/3465222.svg', page: 'Tower', parent: 'services' },
                // { name: '{{lang}}removeService', key: 'removeService', icon: 'https://www.flaticon.com/svg/static/icons/svg/3465/3465222.svg', page: 'Tower', parent: 'services' },
                // { name: '{{lang}}addVeryNew', key: 'addVeryNew', icon: 'https://www.flaticon.com/svg/static/icons/svg/3465/3465222.svg', page: 'Tower', parent: 'newService' },



            ],
            tabTree: [],
            selected: 0
        }

    }

    componentDidMount() {
        let tabs = this.state.tabs
        let tabTree = []

        tabs.forEach(tab => {
            if (!tab.children) {
                tab.children = []
            }
            if (!tab.parent) {
                tabTree.push(tab)
            } else {
                this.findParent(tabs, tab)
            }
        });

        this.setState({ tabTree })
    }

    findParent(tabs, tab) {
        tabs.forEach(oneTab => {
            if (oneTab.key == tab.parent) {
                if (!oneTab.children) {
                    oneTab.children = []
                }
                oneTab.children.push(tab)
            }
        });
    }

    changePage = (prop) => {
        this.setState({ selected: prop.key })
        if (prop.href) {
            this.props.history.push(pathMaker(prop.href))
        }
    }
    render() {
        return (
            // <div style={{ overflow: 'auto', height: '100vh', position: 'sticky', top: 0, width: "100%" }}>
            <div className={" tabBar " + (this.props.settings.tabBarOpen ? 'tabBarOpen ' : '') + (this.props.settings.isMobile ? 'mobile' : 'desktop')} style={{}}>
                {/* <div className="w-100" style={{ overflow: 'visible' }}> */}
                <div className="w-100" style={{ position: 'relative', overflow: 'auto', height: '100%', paddingBottom: 0 }}>
                    {/* <div style={{ position: 'relative', }}> */}
                    {/* <div > */}
                    <div className="flexcc tabBarLogo" style={{ width: '100%' }}>
                        <TLink className="" to={'/'} style={{ width: '100%', height: '100%', textAlign: 'center', justifyContent: 'center' }}>
                            <img style={{ width: '80%', maxHeight: '95%', maxWidth: 120, objectFit: 'contain' }} src={siteTheme.logo} />
                        </TLink>
                    </div>

                    <div className={'tabsList '} style={{}}>
                        {/* <div> */}
                        {this.state.tabTree.map((prop, index) => {
                            return (
                                <TabBarItem tabBarOpen={this.props.settings.tabBarOpen} isMobile={this.props.settings.isMobile} data={prop} key={index} index={index} selected={this.state.selected === index} changePage={this.changePage} padding={0} />
                            )
                        })}
                        {/* </div> */}

                    </div>


                    {/* <div style={{ height: 80 }}>
                    </div> */}



                </div>

                <button className="tabBarOpenButton flexcc" onClick={() => this.props.actions.toggleSetting('tabBarOpen')} >
                    {/* <img src={"https://www.flaticon.com/svg/static/icons/svg/570/570221.svg"} width="23px" alt="" /> */}
                    <img src={''} width="23px" alt="" />
                </button>
            </div>
            // </div>
            // </div>
        )
    }
}

const mapStateToProps = state => ({ settings: state.settings, user: state.user, resource: state.resource })
const mapDispatchToProps = dispatch => ({ actions: bindActionCreators(actions, dispatch) })

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(TabBar));

