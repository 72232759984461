import React from "react";
import HttpService from '../utils/Http.services';
import { siteConfig, siteTheme } from "../variables/config";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actions from '../stores/actionsList';
// import TabBar from "../views/TabBar";
import Configurer from "../components/Configurer";
import ProfileImage from "../components/partials/ProfileImage";
import TableViewer from "../components/TableViewer";
import { v4 as uuidv4 } from 'uuid';
import DataGrid from "../components/DataGrid";
import ServiceBox from "../components/boxes/ServiceBox";
import SearchBar from "../components/SearchBar";
import FilterViewer from "../components/FilterViewer";


class Explore extends React.Component {


    state = {
        categories: [
            { name: 'Sensors', key: '', dontShowInDashboard: true },
            { name: 'Temprature', key: 'edit' },
            { name: 'Humidity', key: 'type' },
            { name: 'Actions', key: 'password' },
        ],
        sortList: [
            { key: 'newest', label: 'Newest', filter: '', active: true },
            { key: 'bestSeller', label: 'Best Seller', filter: '' },
            { key: 'price', label: 'Price', filter: '' },
        ],

        filtersList: {
            select: { key: 'select', type: 'SelectListInput', information: { label: 'Compatible with', type: 'local', items: [{ title: 'test 1', value: 'test1' }, { title: 'test 2', value: 'test2' }], searchable: false, placeholder: '{{lang}}insertName', required: true } },
            radio: { key: 'radio', type: 'RadioInput', information: { label: 'radio', required: true } },
            checkbox: { key: 'checkbox', type: 'CheckboxInput', information: { label: 'On Sale', required: true } },
            checkbox1: { key: 'checkbox1', type: 'CheckboxInput', information: { label: 'Compatible with', required: true } },
            checkbox2: { key: 'checkbox2', type: 'CheckboxInput', information: { label: 'Rating', required: true } },

            fromTo: { key: 'fromTo', type: 'FromToInput', information: { label: 'fromTo', inputType: 'PriceInput', searchable: true, placeholder: '{{lang}}insertName', required: true } },
            slider: { key: 'slider', type: 'SliderInput', information: { label: 'Slider', inputType: 'PriceInput', min: 0, max: 1000, step: 1, searchable: true, placeholder: '{{lang}}insertName', required: true } },

        },

        filters: {},

        data: [1, 1, 1, 1, 1]
    }


    componentDidMount() {
        this.queryToFilter()
    }

    changeSorting = (prop) => {
        let sortList = this.state.sortList
        sortList.forEach(element => {
            element.active = false
            if (prop.key == element.key) {
                element.active = true
            }
        });

        this.setState({ sortList })

    }

    changeCategory = () => {

    }

    changeFilter = (key, value) => {
        
        let filters = this.state.filters
        if (!filters) filters = {}
        if (value == false || value == null) {
            delete filters[key]
        } else {
            let label = this.getFilterLabel(key, value).label
            let labelRef = this.getFilterLabel(key, value)
            if (labelRef.mergeValues) {
                value = String(value)
            }
            filters[key] = { key, value, label }
        }
        this.setState({ filters }, () => {
            this.filterToQuery()
        })
    }

    filterToQuery() {
        let query = '?'
        Object.values(this.state.filters).forEach((filter, index) => {
            if (index > 0) {
                query = query + '&'
            }
            query = query + filter.key + '=' + (filter.value)
        });

        if (query === '?') {
            query = ''
        }

        window.history.pushState(null, null, window.location.pathname + query);
    }



    queryToFilter() {

        let search = window.location.search
        search = search.replace('?', '')
        search = search.split('&')
        
        let filters = {}
        if (Array.isArray(search)) {
            search.forEach(element => {
                let keyValue = element.split('=')
                let key = keyValue[0]
                let value = keyValue[1]
                if (key && value) {
                    if (value.includes(',')) {
                        value = value.split(',')
                    }
                    //    
                    let labelRef = this.getFilterLabel(key, value)
                    if (labelRef.shouldBeArray) {
                        value = [value]
                    }
                    if (labelRef.mergeValues) {
                        value = String(value)
                    }
                    let filter = { key, value, label: labelRef.label }
                    filters[key] = filter
                }

            });
        }
        this.setState({ filters })
    }


    getFilterLabel(key, value) {
        let label = ''
        let shouldBeArray = false
        let mergeValues = false
        if (this.state.filtersList[key].type == 'CheckboxInput') {
            label = this.state.filtersList[key]?.information?.label
        }

        if (this.state.filtersList[key].type == 'FromToInput' || this.state.filtersList[key].type == 'SliderInput') {
            mergeValues = true
            let newValue = value
            if (!Array.isArray(value)) {
                newValue = value.split(',')

            }
            // 
            if (newValue[0] && newValue[0] != '') {
                label = "From " + newValue[0] + ' '
            }
            if (newValue[1] && newValue[1] != '') {
                label = label + "To " + newValue[1]
            }
            if (this.state.filtersList[key].information.inputType == "PriceInput") {
                label = "$ " + label
            }
        }

        if (this.state.filtersList[key].type == 'SelectListInput') {
            let items = this.state.filtersList[key]?.information?.items
            label = []
            if (!Array.isArray(value)) {
                shouldBeArray = true
                value = [value]
            }
            value.forEach(oneValue => {
                items.forEach(item => {
                    if (oneValue === item.value) {
                        label.push(item.title)
                    }
                })
            });


        }

        if (this.state.filtersList[key].type == 'CheckboxInput') {
            label = this.state.filtersList[key]?.information?.label
        }

        return { label, shouldBeArray, mergeValues }
    }



    removeFilter = (key, index) => {
        let filters = this.state.filters
        if (index === null) {
            delete filters[key]
        } else {
            filters[key].value.splice(index, 1)
            filters[key].label.splice(index, 1)
            if (filters[key].value.length === 0) {
                delete filters[key]
            }
        }

        this.setState({ filters }, () => {
            this.filterToQuery()
        })
    }



    render() {
        return (

            <Configurer
                settingsInfo={{ showFooter: true, showTabBar: true, showHeader: true, headerTitle: "Explore", button: {} }}
                title={"Explore"}
                description={"Explore Description"}
                className=""
            >


                <div className="row m-0">
                    <div className="col-12 col-sm-12 col-md-3 col-lg-3 pageSide pr-4 pl-5 d-md-block d-none" style={{ backgroundColor: '#fff' }}>
                        <div style={{ position: 'sticky', top: 60 }}>

                            <div className="text-center pt-2 pb-3" style={{ borderBottom: '1px solid #eee' }}>
                                <SearchBar />
                            </div>


                            {Object.values(this.state.filters)?.length > 0 && (
                                <div>
                                    <p className="text-small mb-1 mt-2">Active Filters</p>
                                    <div className="flexc px-2 pb-1 pt-2 w-100" style={{ flexWrap: 'wrap', backgroundColor: '#f2f6f8', borderRadius: 4, }}>
                                        {Object.values(this.state.filters).map((prop, index) => {
                                            if (Array.isArray(prop.value)) {
                                                return prop.value.map((onOfArray, cindex) => {
                                                    return (
                                                        <div key={onOfArray} className="flexcc mrd-2 mb-1" style={{ backgroundColor: '#fff', borderRadius: 4, padding: '4px 10px' }}>
                                                            <button onClick={() => this.removeFilter(prop.key, cindex)} className="p-0 m-0 flexcc">
                                                                <img className="mrd-2 opacity-7" src="/images/close.svg" alt="" width="10px" />
                                                            </button>
                                                            <p className="text-smaller">{prop?.label[cindex]}</p>
                                                        </div>
                                                    )
                                                })
                                            } else {
                                                return (
                                                    <div className="flexcc mrd-2 mb-1" key={prop.key} style={{ backgroundColor: '#fff', borderRadius: 4, padding: '4px 10px' }}>
                                                        <button onClick={() => this.removeFilter(prop.key, null)} className="p-0 m-0 flexcc">
                                                            <img className="mrd-2 opacity-7" src="/images/close.svg" alt="" width="10px" />
                                                        </button>
                                                        <p className="text-smaller">{prop.label}</p>
                                                    </div>
                                                )
                                            }
                                        })}
                                    </div>
                                </div>
                            )}



                            <div className=" " >
                                {/* style={{ backgroundColor: '#fff', borderRadius: 4, boxShadow: '0px 0px 15px 0px rgba(85, 85, 85, 0.1)' }} */}
                                <div className="py-3 " style={{ borderBottom: '1px solid #eee' }} >
                                    <div className={"flexcb"}>
                                        <p className="text-small text-bold">Categories</p>
                                    </div>

                                    <div className="d-flex text-start flex-column align-items-start px-0 mt-1">
                                        {this.state.categories.map((prop, index) => {
                                            return (
                                                <button onClick={() => this.changeCategory(prop.key)} key={index} className={"px-0 mt-1 flexc"}>
                                                    <img src="/images/nexts.png" width="12px" className="mrd-2 opacity-2" />
                                                    <p className="text-smaller">{prop.name}</p>
                                                </button>
                                            )
                                        })}
                                    </div>
                                </div>

                                <div className="">
                                    <FilterViewer changeFilter={this.changeFilter} ref={el => this.form = el} headers={Object.values(this.state.filtersList)} data={this.state.filters} errors={this.state.errors} inputClass={''} />
                                </div>

                            </div>

                        </div>
                    </div>



                    <div className="col-12 col-md-9 col-lg-9 pt-4 pb-4 px-5">

                        <div className="flexcb ">
                            <div>
                                <h1 className="text-semibig mb-0" style={{ fontWeight: 'normal' }}>Title of this page</h1>
                                <p className="text-smaller" style={{ color: '#789' }}>13 items in category</p>
                            </div>

                            <div>
                                <div className="flexc">
                                    {this.state.sortList.map((prop, index) => {
                                        return (
                                            <button onClick={() => this.changeSorting(prop)} className={"mx-1 explore-filter-but " + (prop.active ? 'active-filter' : '')} key={index}>
                                                <span className="text-smaller">{prop.label}</span>
                                            </button>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>

                        <div className="flexc  w-100 mb-4 mt-2" style={{ flexWrap: 'wrap' }}>
                            {Object.values(this.state.filters).map((prop, index) => {
                                if (Array.isArray(prop.value)) {
                                    return prop.value.map((onOfArray, cindex) => {
                                        return (
                                            <div key={onOfArray} className="flexcc mrd-2 mb-1" style={{ backgroundColor: '#f2f6f8', borderRadius: 4, padding: '4px 10px' }}>
                                                <button onClick={() => this.removeFilter(prop.key, cindex)} className="p-0 m-0 flexcc">
                                                    <img className="mrd-2 opacity-7" src="/images/close.svg" alt="" width="10px" />
                                                </button>
                                                <p className="text-smaller">{prop.label[cindex]}</p>
                                            </div>
                                        )
                                    })
                                } else {
                                    return (
                                        <div className="flexcc mrd-2 mb-1" key={prop.key} style={{ backgroundColor: '#f2f6f8', borderRadius: 4, padding: '4px 10px' }}>
                                            <button onClick={() => this.removeFilter(prop.key, null)} className="p-0 m-0 flexcc">
                                                <img className="mrd-2 opacity-7" src="/images/close.svg" alt="" width="10px" />
                                            </button>
                                            <p className="text-smaller">{prop.label}</p>
                                        </div>
                                    )
                                }
                            })}
                        </div>

                        <DataGrid isLoading={this.state.isLoading} data={this.state.data} component={ServiceBox} col="col-12 col-md-6 col-lg-4" />
                    </div>
                </div>
            </Configurer>
        )
    }


}



const mapStateToProps = state => ({ settings: state.settings, user: state.user })
const mapDispatchToProps = dispatch => ({ actions: bindActionCreators(actions, dispatch) })

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Explore);
