const adminPages = {

    categories: {
        key: 'categories',
        title: 'categories',
        description: 'Categories List',
        fetchUrl: 'getValuesWithData',
        addUrl: 'postValue',
        removeUrl: 'removeValue',
        helperKey: 'values',
        pageType: 'form',
        options: {},
        sort: { 'cDate': -1 },
        image: 'https://images.unsplash.com/photo-1597092451116-27787c07901d?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1567&q=80',
        headers: [
            { key: 'values.name', type: 'TextInput', col: '6', information: { label: '{{lang}}name', placeholder: '{{lang}}insertName', required: true } },
            { key: 'values.parent', type: 'SelectInput', col: '6', information: { label: '{{lang}}parent', address: 'getValuesWithData', filter: { page: 'categories' }, fields: { title: 'values.name', value: '_id' }, type: 'api', placeholder: '{{lang}}insert', required: false }, },
        ]
    },



    brands: {
        key: 'brands',
        title: 'brands',
        description: 'Brands of things',
        fetchUrl: 'getValuesWithData',
        addUrl: 'postValue',
        removeUrl: 'removeValue',
        helperKey: 'values',
        pageType: 'form',
        options: {},
        sort: { 'cDate': -1 },
        image: 'https://images.unsplash.com/photo-1520982399404-d4cc203fdc5a?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1651&q=80',
        headers: [
            { key: 'values.name', type: 'TextInput', col: '6', information: { label: '{{lang}}name', placeholder: '{{lang}}insertName', required: true } },
            { key: 'values.description', type: 'TextInput', col: '6', information: { label: '{{lang}}description', placeholder: '{{lang}}insert', required: true } },

        ]
    },




    Wallpapers: {
        key: 'Wallpapers',
        title: 'Wallpapers',
        description: 'Wallpapers list',
        fetchUrl: 'getValuesWithData',
        addUrl: 'postValue',
        removeUrl: 'removeValue',
        helperKey: 'values',
        pageType: 'form',
        options: {},
        sort: { 'cDate': -1 },
        image: 'https://images.unsplash.com/photo-1520982399404-d4cc203fdc5a?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1651&q=80',
        headers: [
            { key: 'values.image', type: 'ImageInput', col: '12', information: { label: '{{lang}}image', single: true, cropper: true, placeholder: '{{lang}}chooseImage', required: true } },
            { key: 'values.page', type: 'SelectInput', col: '12', information: { label: '{{lang}}page', placeholder: '{{lang}}insert', type: 'local', items: [{ title: "login", value: 'login' }, { title: 'addWiki', value: 'addWiki' }, { title: 'addThing', value: 'addThing' }] } },

        ]
    },



}


export { adminPages }