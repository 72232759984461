import { io } from "socket.io-client";
import { SOCKET_ROOT } from "../../variables/envVariables";
import { APP_ERROR, GENERATED_MODELS, INIT_SOCKET, initSocket } from "../actions/socket.actions";
import { getToken } from "../../utils/useful";
import store from "../storeConfig";
export const socketConnection = (state = {}, { type }) => {
    switch (type) {
        case INIT_SOCKET:
            const socket = io(SOCKET_ROOT, {
                auth: {
                    token: getToken()
                },
                reconnectionDelayMax: 10000,
                path: '/socket',
                reconnection: true,
                reconnectionAttempts: 10,
                reconnectionDelay: 2000,
                retries: 3
            });
            socket.on('connect', async () => {
            })
            socket.on('disconnect', async (err) => {
                console.log(err)
                console.log("DISCONNECTED")
                store.dispatch(initSocket())
            })
            socket.on(APP_ERROR, (err, cb) => {
                const errorMessage = err.message;
                alert(errorMessage || "Error in Connection to the server");
            });
            return socket;
        default:
            return state;
    }

}