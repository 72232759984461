import React from "react";
import Collapsible from "react-collapsible";
import { diagramComponents, diagramGroups } from "../../variables/diagrmaFunctions";


class DiagramSidebar extends React.Component {

    state = {

        closedGroups: {},
        showChildsSide: false
    }


    toggleOpen = (key) => {
        let closedGroups = this.state.closedGroups
        closedGroups[key] = closedGroups[key] == null ? true : !closedGroups[key]
        this.setState({ closedGroups })
    }

    toggleSideOpen = () => {
        let sideClose = this.state.sideClose == null ? true : !this.state.sideClose
        this.setState({ sideClose })
        if (sideClose) {
            this.setState({ showChildsSide: false })
        }
        if (this.state.selectedParent && !sideClose) {
            this.setState({ showChildsSide: true })
        }
    }


    showChildsList = (parent) => {
        this.setState({ showChildsSide: !this.state.showChildsSide })
        if (parent) {
            this.setState({ selectedParent: parent })
        } else {
            setTimeout(() => {
                this.setState({ selectedParent: parent })
            }, 500);
        }
    }


    render() {

        return (
            <>
                <div className={"blur-back digramSidePanel " + (this.state.sideClose ? 'sideClose' : '')}>
                    <div className="digramSidePanelList pt-3">
                        {Object.values(diagramGroups).map((prop, index) => {
                            return (
                                <div className="mb-3 pb-2" style={{ borderBottom: '1px solid #eee' }} key={prop.key}>
                                    <button className="p-0 px-2 mb-2 flexcb w-100" onClick={() => this.toggleOpen(prop.key)} >
                                        <div className="text-start">
                                            <p className="text-normal " style={{ color: '#456' }}>{prop.title}</p>
                                            {/* <p className="text-smallest " style={{color:'#9ab'}}>This is the description of this section</p> */}
                                        </div>
                                        <img className={"mld-2 transition opacity-2 " + (!this.state.closedGroups[prop.key] ? 'rotate-90' : '')} src={'/images/nexts.png'} width="13px" alt="" />
                                    </button>

                                    <Collapsible open={!this.state.closedGroups[prop.key]} transitionTime={100}>
                                        <div className="d-flex mb-2" style={{ flexWrap: 'wrap', alignItems: "stretch" }}>
                                            {Object.values(diagramComponents).map((cprop, index) => {
                                                if (!cprop.parent && cprop.group === prop.key)
                                                    return (
                                                        <div className=" p-1 mb-0" style={{ width: '50%' }} key={cprop.key}>
                                                            <div onClick={() => { if (cprop.isParent) this.showChildsList(cprop.key) }} onDragStart={(e) => this.props.onDragStart(e, cprop, 'function')} draggable={!cprop.isParent} className=" h-100 text-center " style={{ position: 'relative', padding: '10px 5px', cursor: !cprop.isParent ? 'move' : 'pointer', backgroundImage: 'linear-gradient(#f2f6f8cc,#eaecef)', borderRadius: 2, border: '1px solid #e2e6e8' }}>
                                                                <img draggable={false} src={cprop.icon} width="32px" alt="" style={{ userSelect: 'none', WebkitUserSelect: 'none' }} />
                                                                <p className="" style={{ fontSize: 11, color: '#567', marginTop: 3 }}>{cprop.title}</p>

                                                                {cprop.isParent && (
                                                                    <img draggable={false} src={'/images/cd-2.svg'} width="15px" alt="" style={{ position: 'absolute', top: 8, right: 8, userSelect: 'none', WebkitUserSelect: 'none', opacity: 0.3 }} />
                                                                )}
                                                            </div>
                                                        </div>
                                                    )
                                            })}
                                        </div>
                                    </Collapsible>
                                </div>
                            )
                        })}
                    </div>


                    <div className={"blur-back digramSidePanel " + (this.state.childsSideClose ? 'sideClose' : '')}>

                    </div>


                    <button className={"diagramSideHandler blur-back flexcc "} onClick={this.toggleSideOpen}>
                        <img src="/images/next-2.png" width="20px" alt="" />
                    </button>

                </div>


                <div className={"blur-back digramSidePanel " + (!this.state.showChildsSide ? 'sideClose' : '')}>
                    <div className="digramSidePanelList pt-3">
                        <button onClick={() => this.showChildsList()} className="flexc p-0 mx-0 mb-2">
                            <img className="reverse mrd-2" src="/images/nexts.png" width="14px" alt="" />
                            <span className="text-normal">Back</span>
                        </button>

                        <div className="d-flex mb-2" style={{ flexWrap: 'wrap', alignItems: "stretch" }}>
                            {Object.values(diagramComponents).map((cprop, index) => {
                                if (this.state.selectedParent && cprop.parent === this.state.selectedParent)
                                    return (
                                        <div className=" p-1 mb-0" style={{ width: '50%' }} key={cprop.key}>
                                            <div onClick={() => { if (cprop.isParent) this.showChildsList(cprop.key) }} onDragStart={(e) => this.props.onDragStart(e, cprop, 'function')} draggable={!cprop.isParent} className=" h-100 text-center " style={{ position: 'relative', padding: '10px 5px', cursor: !cprop.isParent ? 'move' : 'pointer', backgroundImage: 'linear-gradient(#f2f6f8cc,#eaecef)', borderRadius: 2, border: '1px solid #e2e6e8' }}>
                                                <img draggable={false} src={cprop.icon} width="32px" alt="" style={{ userSelect: 'none', WebkitUserSelect: 'none' }} />
                                                <p className="" style={{ fontSize: 11, color: '#567', marginTop: 3 }}>{cprop.title}</p>

                                                {cprop.isParent && (
                                                    <img draggable={false} src={'/images/cd-2.svg'} width="15px" alt="" style={{ position: 'absolute', top: 8, right: 8, userSelect: 'none', WebkitUserSelect: 'none', opacity: 0.3 }} />
                                                )}
                                            </div>
                                        </div>
                                    )
                            })}
                        </div>

                    </div>
                </div>





            </>

        )
    }
}



export default DiagramSidebar;
