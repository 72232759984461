import React from 'react'
import Draggable from './Draggable';
import { v4 as uuidv4 } from 'uuid';
import { getBoundingClientRect } from '../../utils/functions'

class DraggableList extends React.Component {
    constructor() {
        super()
        this.state = {
            shadowTop: null,
            shadowLeft: null
        }
    }


    componentDidMount() {
       
    }

    dragStarted = (id) => {
        this.setState({ dragId: id })
    }

    changeShadowPosition = (e) => {
        // 
        if(this.shadowChild){
            this.shadowChild.style.top = (e.clientY - 10)+'px' 
            this.shadowChild.style.left = e.clientX + 2 +'px'

        }
    }

    dragOver = (id) => {
    
        if (this.state.dragId !== id && this.dragOverId != id) {
            this.dragOverId = id
            this.animateSorting(this.state.dragId, id)
        }
    }



    animateSorting = (dragId, overId) => {
        let dragIndex = null
        let overIndex = null
        // 
        this.props.data.forEach((element, index) => {
            // 
            let id = element.id??element._id
            if (id === dragId) {
                dragIndex = index
            }
            if (id === overId) {
                overIndex = index
            }
        });

        // 
        // 
        if (dragIndex !== null && overIndex !== null) {

            this.bounds = this.getElementsBounds(this.props.data)

            // 

            let data = this.props.data
            let temp = data[dragIndex]
            data.splice(dragIndex, 1)
            data.splice(overIndex, 0, temp)

            this.props.updateData(data, () => {
                let newBounds = this.getElementsBounds(this.props.data)

                requestAnimationFrame(() => {
                    Object.keys(this.bounds).forEach(key => {

                        const deltaX = this.bounds[key].left - newBounds[key].left;
                        const deltaY = this.bounds[key].top - newBounds[key].top;

                        if (deltaX !== 0 || deltaY !== 0) {

                        let domNode = this[key].draggableItem
                        domNode.style.transform = `translate(${deltaX}px, ${deltaY}px)`;
                        domNode.style.transition = 'transform 0s';
                        requestAnimationFrame(() => {
                            // this.dragOverId = null
                            // In order to get the animation to play, we'll need to wait for
                            // the 'invert' animation frame to finish, so that its inverted
                            // position has propagated to the DOM.
                            //
                            // Then, we just remove the transform, reverting it to its natural
                            // state, and apply a transition so it does so smoothly.
                            domNode.style.transform = '';
                            domNode.style.transition = 'transform 300ms';

                        });
                        }
                    });
                    let dragOverId = this.dragOverId
                    setTimeout(() => {
                        if (dragOverId === this.dragOverId) {
                            this.dragOverId = null
                        }
                    }, 350);
                })
            })

        }
    }

    getElementsBounds(data) {
        let bounds = {}
        data.forEach(element => {
            let id = element.id??element._id

            if (this[id]) {
                // 
                bounds[id] = getBoundingClientRect(this[id].draggableItem)
            }
        });
        return bounds
    }

    onDrop = (id) => {
        // this.setState({ dragId: null })
        // this.dragOverId = null
        // 
        // window.removeEventListener('drag', this.changeShadowPosition)
        // window.removeEventListener('mouseup', this.onDrop)

    }

    onDrag = (e) => {
        // 
        // 
        // this.setState({ shadowLeft: e.clientX+2, shadowTop: e.clientY })

        this.changeShadowPosition(e)
    }

    dragEnd = (e) => {
        this.setState({ dragId: null })
        this.dragOverId = null
        // 
    }


    render() {
        let shadowChild
        if (this.props.children) {
            var children = []
            if (Array.isArray(this.props.children)) {
                var children = this.props.children.map( (child, i) => {
                    if (child.key === this.state.dragId) {
                        shadowChild = React.cloneElement(child)
                    }
                    return (
                        <Draggable ref={el => this[child.key] = el} id={child.key} key={'draggable' + child.key} onDrag={this.onDrag} dragStarted={this.dragStarted} dragOver={this.dragOver} onDrop={this.onDrop} dragEnd={this.dragEnd}>
                            {React.cloneElement(child,
                                { key: child.key }
                            )}

                            {child.key === this.state.dragId && (
                                <div className="drop-ghost" style={{ zIndex: 2, position: 'absolute', top: 0, left: 0, height: '100%', width: '100%', backgroundColor: 'rgba(230,240,245,0.9)', border: '1px dotted #007aff40' }}></div>
                            )}
                        </Draggable>
                    )

                }, this);
            } else {
                var children = React.cloneElement(this.props.children,
                    { lastStage: this.lastStage, nextStage: this.nextStage, changeStage: this.changeStage }
                )

            }

            return (
                <div ref={el=>this.draggableList=el} className={"w-100 draggableList " + (this.state.dragId ? "isDragging" : '')} >

                    {children}

                    {shadowChild  && (
                        <div className=" blur-back" ref={el=>this.shadowChild=el} style={{width:this.props.width,overflow:'hidden', backgroundColor: '#ffffffcc', boxShadow: '0px 0px 15px #00000010', zIndex: 6, position: 'fixed',  transform: 'rotate(0.5deg)' }}>
                            {shadowChild}
                        </div>
                    )}

                </div>
            )
        }
        else {
            return <div></div>
        }
    }

}

export default DraggableList
