import { siteConfig } from '../variables/config'
import HttpService from '../utils/Http.services';
import i18n from 'i18next';
import Cookies from 'universal-cookie';
const cookies = new Cookies();

function translate(key, params) {
    return (
        i18n.t(key, params)
    )
}


function checkTextTranslation(text) {
    if (text) {
        if (text.includes("{{lang}}")) {
            let newText = text
            newText = newText.replace('{{lang}}', '')
            newText = i18n.t(newText)
            return newText

        } else {
            return text
        }
    }
}


function checkTranslation(text) {
    if (text) {
        if (String(text).includes("{{lang}}")) {
            let newText = String(text)
            newText = newText.replace('{{lang}}', '')
            newText = i18n.t(newText)
            return newText

        } else {
            return text
        }
    }
}



function changeBodyDirection(language) {

    if (language === 'fa') {
        document.body.classList.remove('ltr')
        document.body.classList.add('rtl')
    } else {
        document.body.classList.remove('rtl')
        document.body.classList.add('ltr')
    }
}


function currentLng() {
    return i18n.language
}


function changeUrlWithLng(lng) {
    let pathname = window.location.pathname.split('/')
    for (let index = pathname.length - 1; index >= 0; index--) {
        if (pathname[index] === "") {
            pathname.splice(index, 1)
        }
    }


    if (lng === siteConfig.defaultLng && siteConfig.dontShowUrlForDefaultLng) {
        if (siteConfig.languages.indexOf(pathname[0]) !== -1) {
            // 
            pathname.splice(0, 1)
            pathname = pathname.join('/')
            window.location = '/' + pathname

        }
    } else {

        if (lng !== pathname[0]) {

            if (!pathname[0] || siteConfig.languages.indexOf(pathname[0]) !== -1) {
                pathname[0] = lng
            } else {
                pathname.splice(0, 0, lng)
            }
            window.location = "/" + pathname.join('/')
        }
    }
}


function defaultPathMaker() {
    return siteConfig.dontShowUrlForDefaultLng ? (i18n.language === siteConfig.defaultLng ? '' : '/' + i18n.language) : '/' + i18n.language
}

function pathMaker(path) {
    return defaultPathMaker() + path
}

function imageAddress(image, type, prefered) {
    // 
    let imageInfo = {
        profile: { address: 'images/', defaultImg: '/images/male.png' },
        product: { address: 'images/', defaultImg: '/images/male.png' },
        resource: { address: 'images/', defaultImg: '/images/male.png' },
        estate: { address: 'images/', defaultImg: '/images/no-image.jpg' },

    }

    let prefixAddress = 'images/'
    let defaultImg = ''
    if (imageInfo[type]) {
        prefixAddress = imageInfo[type].address
    }
    if (imageInfo[type] && imageInfo[type].defaultImg) {
        defaultImg = imageInfo[type].defaultImg
    }

    if (image && image != '') {

        let imageName = ''
        let myImage = Array.isArray(image) ? image[0] : image

        if (prefered && myImage.sizes && myImage.sizes[prefered]) {
            // 
            imageName = myImage.sizes[prefered].address

        } else {
            if (typeof myImage === "object" && !Array.isArray(myImage)) {
                // 
                imageName = myImage.address
            } else {
                imageName = myImage
            }
        }


        let final = siteConfig.assetsDomain + prefixAddress + imageName
            // 

        return final
    } else {
        return defaultImg
    }

}


function getToken() {
    let token = cookies.get('token')
    if (typeof token === "undefined") {
        token = null
    }
    return token
}

function getUserInfo(cb) {
    HttpService.request("getUserInfo", null, (fetchResult, fetchError, status) => {
        // 
        if (fetchError) { if (cb) { cb(null, fetchError.message, status) }; return }
        if (cb) { cb(fetchResult, null, status) }
    })
}

function phoneStandardView(number) {
    let phone = number
    if (number) {
        if (number.includes('-')) {
            phone = number.substring(4, number.length)
        }
        let counter = 0
        while (number[counter] === "0") {
            counter++
        }
        phone = phone.substring(counter, number.length)
        return phone
    }
}



let numbers = { '0': '۰', '1': '۱', '2': '۲', '3': '۳', '4': '۴', '5': '۵', '6': '۶', '7': '۷', '8': '۸', '9': '۹' }

function converNumberToPersian(number) {
    if (number != null) {
        let numberArray = String(number).split('')
        numberArray.forEach((element, index) => {
            if (numbers[element]) {
                numberArray[index] = numbers[element]
            }
        });
        return numberArray.join('')

    }
}


function priceStandardView(priceRef) {
    if (priceRef) {

        let priceArray = String(priceRef).split('')
        let price = ''
            // let j = 0
        for (let i = 0; i < priceArray.length; i++) {
            let element = priceArray[priceArray.length - 1 - i];
            // j++
            price = element + price
                // 
            if ((i + 1) % 3 === 0 && i !== priceArray.length - 1) {
                price = ',' + price
            }
            // 

        }

        return price

    }
}




function setObject(data, key, value, append) {

    if (!data) data = {}
    if (key && key.includes('.')) {
        let pList = key.split('.');
        let newKey = [...pList]
        newKey.splice(0, 1)
        newKey = newKey.join('.')
        data[pList[0]] = setObject(data[pList[0]], newKey, value)
        return data

    } else {
        if (append) {
            data[key] = data[key] ? data[key] : []
            if (Array.isArray(value)) {
                data[key] = [...data[key], ...value]
            } else {
                data[key] = [...data[key], value]
            }
        } else {
            data[key] = value
        }
        return data
    }

}

function getObject(data, key, splitLevel, level) {


    if (data && key) {

        if (key.includes('.') && (splitLevel == null || level == null || splitLevel > (level ? level : 0))) {
            let pList = key.split('.');
            let newKey = [...pList]
            newKey.splice(0, 1).join('.')
            newKey = newKey.join('.')
            return getObject(data[pList[0]], newKey, splitLevel, level ? level + 1 : 1)

        } else {

            return data[key]
        }
    } else {
        return null
    }
}


function capitalLetter(text) {
    if (text) {
        let words = text.split(' ')
        let newWords = []
        words.forEach((word, index) => {
            let newWord = word
            newWord = newWord.substr(0, 1).toUpperCase() + newWord.substr(1)
            newWords.push(newWord)
        });
        return newWords.join(' ')
    }
}

function chooseWallpaper(data) {
    // 
    if ((data && data[0] && data[0].values && data[0].values.image)) {
        let image = Array.isArray(data[0].values.image) ? data[0].values.image[0] : data[0].values.image
        return siteConfig.assetsDomain + 'images/' + image.address
    }
    // return "/images/cover2.jpeg"
    // return "/images/cover.jpg"

}
function isAlphabetLetter(str) {
    const regex = /^[a-zA-Z]$/;
    return regex.test(str);
}
function replaceParenthesisWithItsLetter(inputString = "") {
    if(!inputString.includes("(")){
        return inputString;
    }
    let isInsideParenthesis = false;
    let newInputString = ""
    for(let i=0; i < inputString.length; i++){
        const currentIndex = inputString[i];
        if (currentIndex === "("){
            isInsideParenthesis = true;
            continue;
        }
        if (currentIndex === ")"){
            isInsideParenthesis = false;
            continue;
        }
        if(!isInsideParenthesis){
            newInputString += currentIndex;
        }
        if (isInsideParenthesis){
            if (isAlphabetLetter(currentIndex)){
                newInputString += currentIndex;
            }
        }

    }
    return newInputString;
}


function detectBrowser() {
    // Opera 8.0+
    if ((navigator.userAgent.indexOf("Opera") || navigator.userAgent.indexOf('OPR')) != -1) {
        return 'Opera'
    } else if (navigator.userAgent.indexOf("Chrome") != -1) {
        return 'Chrome'
    } else if (navigator.userAgent.indexOf("Safari") != -1) {
        return 'Safari'
    } else if (navigator.userAgent.indexOf("Firefox") != -1) {
        return 'Firefox'
    } else if ((navigator.userAgent.indexOf("MSIE") != -1) || (!!document.documentMode == true)) //IF IE > 10
    {
        return 'IE'
    } else {
        return ''
    }

}
function removeSpaces(inputString) {
    return inputString.replace(/\s/g, '');
}

const renderConditions = (conditionsString = '') => {
    conditionsString = removeSpaces(conditionsString);
    const conditions = conditionsString.split(";")?.filter(con => con !== "");
    const conditionsArray = conditions.map((condition) => {
        const conditionObjects = condition.split("&").filter(con => con?.trim() !== "").map(con => {
            const conObject = parseCondition(con);
            return conObject;
        });
        return conditionObjects

    });
    return conditionsArray;
}



function parseCondition(conditionString) {
    const regex = /([a-zA-Z]+)([<>=]+)(\d+)/;
    const matches = conditionString.match(regex);

    if (matches && matches.length === 4) {
        const [, letter, equalitySign, number] = matches;
        return {
            letter: letter,
            equalitySign: equalitySign,
            number: parseInt(number)
        };
    }
}

function splitByOperators(inputString) {
    // Splitting the string by '*' and '+' using regular expression
    let splitted = inputString.split(/[*+-/]/);
    return splitted.filter(item => item !== ''); // Filter out empty elements
}
function splitByOperatorsAndEqualitySigns(inputString) {
    // Splitting the string by '*' and '+' using regular expression
    let splitted = inputString.split(/[*+\-\/=<>]+/);
    return splitted.filter(item => item !== ''); // Filter out empty elements
}





export { translate, detectBrowser, changeBodyDirection, changeUrlWithLng, currentLng, checkTextTranslation, checkTranslation, pathMaker, imageAddress, getToken, getUserInfo, phoneStandardView, getObject, setObject, capitalLetter, chooseWallpaper, priceStandardView, converNumberToPersian, removeSpaces, splitByOperators, renderConditions, splitByOperatorsAndEqualitySigns, replaceParenthesisWithItsLetter }