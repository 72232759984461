import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { fillRemainingDataGaps, paginateData } from '../../utils/functions';
import Pagination from '../../utils/Pagination';
import PositiveNegativeButtons from '../../utils/PositiveNegativeButtons';

// TODO: THE LOGIC MUST BE IMPLEMENTED IN THE BACKEND
const ExcelTable = ({ selectedWBNames, hideModal }) => {
    const didMountRef = useRef(false);
    const [pageNumber, setPageNumber] = useState(0);
    const [maximumLengthOfWBNames, setMaximumLengthOfWBNames] = useState(0);
    const [paginationSlice, setPaginationSlice] = useState({
        sliceStart: 0,
        sliceEnd: 5,
    });

    useEffect(() => {
        const maximumLengthOfWBNames = Math.max(
            ...Object.values(selectedWBNames).map(
                (wbName) => wbName.nameRangeValues.length
            )
        );
        setMaximumLengthOfWBNames(maximumLengthOfWBNames);
    }, []);
    useLayoutEffect(() => {
        const { sliceEnd, sliceStart } = paginationSlice;
        if (didMountRef.current) {
            setPaginationSlice((prev) => {
                if (
                    pageNumber + 1 === sliceEnd &&
                    pageNumber + 5 <= paginationRange.length
                ) {
                    
                    return {
                        ...prev,
                        sliceStart: prev.sliceStart + 4,
                        sliceEnd: prev.sliceEnd + 4,
                    };
                }
                if (
                    pageNumber + 1 === sliceEnd &&
                    pageNumber + 5 >= paginationRange.length
                ) {
                    
                    return {
                        ...prev,
                        sliceStart: paginationRange.length - 5,
                        sliceEnd: paginationRange.length,
                    };
                }
                if (pageNumber === sliceStart && pageNumber - 5 <= 0) {
                    
                    return {
                        ...prev,
                        sliceStart: 0,
                        sliceEnd: 5,
                    };
                }
                if (pageNumber === sliceStart && pageNumber - 5 > 0) {
                    
                    return {
                        ...prev,
                        sliceStart: prev.sliceStart - 4,
                        sliceEnd: prev.sliceEnd - 4,
                    };
                }
                return prev;
            });
        }
        didMountRef.current = true;
    }, [pageNumber]);

    const PER_PAGE_DATA_LENGTH = 10;

    useEffect(() => {}, [pageNumber]);
    const rowsPerPage = 10;
    const rowsLength = maximumLengthOfWBNames;
    const paginationRange = new Array(
        Math.ceil(
            (rowsLength < rowsPerPage ? rowsPerPage : rowsLength) / rowsPerPage
        )
    )
        .fill()
        .map((x, i) => i);
    return (
        <div className="editor__excelWBNameModal">
            <div className="header">Header</div>
            <div className="body">
                {Object.values(selectedWBNames).map((wbName) => {
                    const { name, nameRangeValues, nameId } = wbName;
                    const emptyValue = { t: "-", v: "-", w: "-" };
                    const DATA_PERPAGE = 10;

                    const nameRangeValuesWithFilledGaps = fillRemainingDataGaps(
                        nameRangeValues,
                        DATA_PERPAGE,
                        emptyValue
                    );
                    const paginatedRangeValues = paginateData(
                        nameRangeValuesWithFilledGaps,
                        pageNumber,
                        PER_PAGE_DATA_LENGTH
                    );
                    if (paginatedRangeValues.length > 0) {
                        return (
                            <div className="name--row" key={nameId}>
                                <div className="name--title">{name}</div>
                                {paginatedRangeValues.map((nameVal) => {
                                    return (
                                        <div className="name--value">
                                            {nameVal.v}
                                        </div>
                                    );
                                })}
                            </div>
                        );
                    }
                })}
            </div>
            <div className="footer">
                <Pagination
                    page={pageNumber}
                    range={paginationRange.slice(
                        paginationSlice.sliceStart,
                        paginationSlice.sliceEnd
                    )}
                    setPage={setPageNumber}
                    props={{
                        parentDiv: {
                            className: "pagination",
                            style: {
                                visibility:
                                    rowsLength > 0 ? "visible" : "hidden",
                            },
                        },
                        btn: {
                            className: "button",
                            style: {},
                        },
                        prev: {
                            className: "",
                            style: {
                                cursor: "pointer",
                                fontSize: 25,
                            },
                            content: (
                                <div
                                // className={`${
                                //     isDarkMode
                                //         ? 'text-[#fff]'
                                //         : 'text-[#000]'
                                // }`}
                                >
                                    &laquo;
                                </div>
                            ),
                        },
                        next: {
                            className: "",
                            style: {
                                cursor: "pointer",
                                fontSize: 25,
                            },

                            content: (
                                <div
                                // className={`${
                                //     isDarkMode
                                //         ? 'text-[#fff]'
                                //         : 'text-[#000]'
                                // }`}
                                >
                                    &raquo;
                                </div>
                            ),
                        },
                    }}
                    prevPageFunc={() =>
                        setPageNumber((prev) => {
                            if (prev != 0) {
                                return prev - 1;
                            } else {
                                return prev;
                            }
                        })
                    }
                    nextPageFunc={() =>
                        setPageNumber((prev) => {
                            
                            
                            
                            if (
                                prev + 1 !=
                                    Math.ceil(rowsLength / rowsPerPage) &&
                                Math.ceil(rowsLength / rowsPerPage) != 0
                            ) {
                                return prev + 1;
                            } else {
                                return prev;
                            }
                        })
                    }
                />
                <PositiveNegativeButtons
                    parentDivClassName={"confirm__cancel__generate"}
                    positiveClassName={"positive"}
                    negativeClassName={"negative"}
                    positiveText={"Close"}
                    negativeFunc={hideModal}
                    positiveFunc={hideModal}
                />
                <div></div>
            </div>
        </div>
    );
};
export default ExcelTable;
