import React from "react";
import HttpService from '../utils/Http.services';
import { siteConfig, siteTheme } from "../variables/config";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actions from '../stores/actionsList';
// import TabBar from "../views/TabBar";
import Configurer from "../components/Configurer";
// import ProfileImage from "../components/partials/ProfileImage";
// import TableViewer from "../components/TableViewer";
import { v4 as uuidv4 } from 'uuid';
import Collapsible from "react-collapsible";
import { getBoundingClientRect } from '../utils/functions';
import DiagramComponent from "../components/diagram/DiagramComponent";
import { diagramComponents, diagramGroups } from "../variables/diagrmaFunctions";
import DiagramPath from "../components/diagram/DiagramPath";
import DiagramSidebar from "../components/diagram/DiagramSidebar";
import { debounce, throttle } from 'throttle-debounce';
import Modal from '../components/Modal'
import FormViewer from '../components/FormViewer'

// const 

class Rigram extends React.Component {

    miniWidth = 200

    state = {

        components: {},
        paths: {},
        nodes: {},
        zoom: 1

    }

    // debounceFunc = throttle(1, false, (pathKey, as, points) => {
    debounceFunc = debounce(1, false, (paths) => {

        this.setState({ paths })

    });


    componentDidMount() {
        this.resizeListener = window.addEventListener('resize', this.updateMiniViewSize)
        setTimeout(() => {
            this.updateMiniViewSize()
        }, 10);
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.updateMiniViewSize)
        this.removeDoneEventListener()
    }





    onDragStart = (e, prop, type) => {

        if (type == 'component') {
            this.draggingId = prop.key
            e.dataTransfer.setDragImage(this.ghost, 0, 0);
            if (this[prop.key]) {
                let bound = getBoundingClientRect(this[prop.key].container)

                this.offsetX = e.clientX - ((bound.left * 1) + (bound.width * 1 / 2))
                this.offsetY = e.clientY - ((bound.top * 1) + (bound.height * 1 / 2))

            }
        } else {
            this.offsetX = 0
            this.offsetY = 0
        }

        e.dataTransfer.setData('id', prop.key)
        e.dataTransfer.setData('type', prop.key)


    }


    onDrag = (e) => {

        let x = e.clientX /// this.state.zoom
        let y = e.clientY /// this.state.zoom

        let components = this.state.components

        if (this.draggingId && x && y) {

            let componentX = (((x - this.offsetX)) + (this.rigramDroppable.scrollLeft)) / this.state.zoom
            let componentY = (((y - this.offsetY)) + (this.rigramDroppable.scrollTop)) / this.state.zoom


            if (componentX < 70) {
                componentX = 70
            }
            if (componentY < 70) {
                componentY = 70
            }

            this[this.draggingId].container.style.left = componentX + 'px'
            this[this.draggingId].container.style.top = componentY + 'px'


            let ratio = (this.miniWidth / this.rigramDroppable.scrollWidth)///this.state.zoom

            this['mini-' + this.draggingId].style.left = (componentX) * ratio * this.state.zoom + 'px'
            this['mini-' + this.draggingId].style.top = (componentY) * ratio * this.state.zoom + 'px'

            components[this.draggingId].positionX = componentX
            components[this.draggingId].positionY = componentY
            // 
            if (components[this.draggingId].paths) {
                let paths = this.state.paths
                Object.values(components[this.draggingId].paths).forEach(path => {

                    // this.debounceFunc(path.key, path.as, [componentX, componentY]);
                    paths[path.key][path.as] = [componentX, componentY]

                });

                this.debounceFunc(paths);


            }
        }

        if (x >= window.innerWidth - 100) {

            this.rigramDroppable.scrollLeft = this.rigramDroppable.scrollLeft + 20
            // this.rigramDroppable.scrollWidth = this.rigramDroppable.scrollWidth + 100


        }

        if (y >= window.innerHeight - 100) {
            this.rigramDroppable.scrollTop = this.rigramDroppable.scrollTop + 20


        }



    }

    onDragOver = (e) => {
        e.stopPropagation();
        e.preventDefault();
    }





    onDrop = (e) => {

        e.preventDefault()

        let components = this.state.components
        let x = e.clientX
        let y = e.clientY

        let componentX = (((x - this.offsetX)) + (this.rigramDroppable.scrollLeft)) / this.state.zoom
        let componentY = (((y - this.offsetY)) + (this.rigramDroppable.scrollTop)) / this.state.zoom

        let ratio = this.miniWidth / this.rigramDroppable.scrollWidth

        let id = e.dataTransfer.getData('id')
        let type = e.dataTransfer.getData('id')


        if (id && components[id]) {

            components[id].positionX = componentX //e.clientX + this.rigramDroppable.scrollLeft
            components[id].positionY = componentY //e.clientY + this.rigramDroppable.scrollTop
            this.setState({ components }, () => {
                if (components[id].paths) {
                    let paths = this.state.paths
                    // 
                    Object.values(components[id].paths).forEach(path => {
                        paths[path.key][path.as] = [componentX, componentY]

                    });

                    this.setState({ paths })
                }
            })

        } else {
            id = uuidv4()
            if (diagramComponents[type]) {
                let nodes = diagramComponents[type].defaultNodes ?? {}
                let title = diagramComponents[type].title
                let UI = diagramComponents[type].UI
                // let settings = diagramComponents[type].settings

                // let title = diagramComponents[type].title

                // 
                components[id] = { key: id, type, positionX: componentX, positionY: componentY, nodes, title, UI } // TODO DEFAULT NODES - Title
                this.setState({ components }, () => {

                    this[id].container.style.left = components[id].positionX + 'px'
                    this[id].container.style.top = components[id].positionY + 'px'

                    this['mini-' + id].style.left = (components[id].positionX) * ratio * this.state.zoom + 'px'
                    this['mini-' + id].style.top = (components[id].positionY) * ratio * this.state.zoom + 'px'

                })
            }
        }

    }

    dragEnd = (e) => {
        this.updateMiniViewSize()
    }


    updateMiniViewSize = (e) => {


        let ratio = (this.miniWidth / this.rigramDroppable.scrollWidth)

        let newHeight = this.rigramDroppable.scrollHeight * ratio ///this.rigramDroppable.scrollWidth

        let trackerNewWidth = window.innerWidth * ratio///this.rigramDroppable.scrollWidth)*200
        let trackerNewHeight = (window.innerHeight / this.rigramDroppable.scrollHeight) * newHeight

        this.miniView.style.height = newHeight + 'px'
        this.miniViewTracker.style.height = trackerNewHeight + 'px'
        this.miniViewTracker.style.width = trackerNewWidth + 'px'


        this.miniViewTracker.style.top = (this.rigramDroppable.scrollTop * ratio) + 'px'
        this.miniViewTracker.style.left = (this.rigramDroppable.scrollLeft * ratio) + 'px'

        Object.values(this.state.components).forEach(component => {
            this.updateMiniViewComponent(component, ratio)
        });

    }


    updateMiniViewComponent(component, sRatio) {
        let ratio = sRatio
        if (!ratio) {
            ratio = (this.miniWidth / this.rigramDroppable.scrollWidth)
        }
        ratio = ratio * this.state.zoom
        this['mini-' + component.key].style.left = (component.positionX) * ratio + 'px'
        this['mini-' + component.key].style.top = (component.positionY) * ratio + 'px'
    }


    handleZoom(type) {

        let zoom = this.state.zoom

        if (type == 'in') {
            if (zoom <= 2) {
                zoom = zoom + 0.2
            }
        }

        if (type == 'out') {
            if (zoom >= 0.5) {
                zoom = zoom - 0.2
            }

        }

        if (type == 'fit') {
            zoom = 1
        }

        this.setState({ zoom }, () => {
            this.updateMiniViewSize()
        })

    }



    toggleMiniView = () => {
        let miniClose = this.state.miniClose == null ? true : !this.state.miniClose
        this.setState({ miniClose })
    }



    setNodesPosition = (componentKey, nodes, componentWH) => {
        // 
        let components = this.state.components
        // Object.values(nodes).forEach(node => {
        //     node.relativePosition.x = this.rigramDroppable.scrollLeft + node.relativePosition.x
        //     node.relativePosition.y = this.rigramDroppable.scrollTop + node.relativePosition.y
        // });

        components[componentKey].nodes = Object.assign({}, nodes)
        if (componentWH) {
            components[componentKey].width = componentWH.width
            components[componentKey].height = componentWH.height
        }

        // 

        this.setState({ components })
        // 

    }


    nodeMouseDown = (component, node, e) => {
        // 
        let components = this.state.components

        if (this.state.activePath) {
            let activePath = this.state.activePath
            if (node.type === 'input') {
                // 
                let paths = this.state.paths
                let id = uuidv4()
                activePath.id = id

                let x = components[component.key].positionX //+ 
                let y = components[component.key].positionY //+ node.relativePosition.y 

                let endOffset = [node.relativePosition.x, node.relativePosition.y]


                activePath.end = [x, y]
                activePath.endOffset = endOffset

                activePath.ECKey = component.key
                activePath.ENKey = node.key

                paths[id] = activePath


                this.setState({ activePath: null, paths })

                if (!components[component.key].paths) {
                    components[component.key].paths = {}
                }
                if (!components[activePath.SCKey].paths) {
                    components[activePath.SCKey].paths = {}
                }

                components[component.key].paths[id] = { as: 'end', key: id, node: node.key }
                components[activePath.SCKey].paths[id] = { as: 'start', key: id, node: activePath.SNKey }

                // 
                // 

                // paths.push()

            }
        } else {

            if (node.type === 'output') {


                // let x = components[component.key].positionX + node.relativePosition.x //+ node.relativePosition.width / 2
                // let y = components[component.key].positionY + node.relativePosition.y //+ node.relativePosition.height / 2


                let x = components[component.key].positionX //+ node.relativePosition.x 
                let y = components[component.key].positionY //+ node.relativePosition.y 


                let clientX = ((e.clientX) + (this.rigramDroppable.scrollLeft)) / this.state.zoom
                let clientY = ((e.clientY) + (this.rigramDroppable.scrollTop)) / this.state.zoom


                let start = [x, y]
                let startOffset = [node.relativePosition.x, node.relativePosition.y]

                let end = [clientX, clientY]
                let endOffset = [0, 0]

                // 
                // 
                let activePath = {}
                activePath.start = start
                activePath.end = end
                activePath.startOffset = startOffset
                activePath.endOffset = endOffset

                activePath.SCKey = component.key
                activePath.SNKey = node.key

                this.setState({ activePath })
                window.addEventListener('mousemove', this.updateActivePath)
                setTimeout(() => {
                    window.addEventListener('mousedown', this.finishActivePath)
                }, 2);
            }
        }
    }


    updateActivePath = (e) => {
        let activePath = this.state.activePath//{ points: { start, end } }

        let clientX = ((e.clientX) + (this.rigramDroppable.scrollLeft)) / this.state.zoom
        let clientY = ((e.clientY) + (this.rigramDroppable.scrollTop)) / this.state.zoom

        activePath.end = [clientX, clientY]
        // 
        this.setState({ activePath })
    }


    finishActivePath = (e) => {
        window.removeEventListener('mousemove', this.updateActivePath)
        window.removeEventListener('mousedown', this.finishActivePath)
        setTimeout(() => {
            this.setState({ activePath: null })
        }, 300);
    }

    removePath = (id) => {
        let paths = this.state.paths
        let path = paths[id]

        if (path) {
            let components = this.state.components

            // 
            // 

            if (components[path.ECKey]?.paths) {
                delete components[path.ECKey].paths[path.id]
            }

            if (components[path.SCKey]?.paths) {
                delete components[path.SCKey].paths[path.id]
            }

            delete paths[id]

            this.setState({ paths, components })
        }
    }


    removeCurrentComponent = () => {
        let key = this.state.currentComponent.key
        let components = this.state.components
        let paths = this.state.paths
        
        let pathsToremove = []
        Object.values(paths).forEach((path, index) => {
            if (path.ECKey == key || path.SCKey == key) {
                pathsToremove.push(path)
            }
        });

        this.moreModal.hideModal()
        this.removeConfirmModal.hideModal()

        pathsToremove.forEach(path => {
            this.removePath(path.id)
        });

        delete components[key]
        this.setState({ components, currentComponent: null, paths })

    }



    openMore = (key, isDoubleClick) => {
        // 
        let component = this.state.components[key]
        let diagramComponent = diagramComponents[component.type]
        // 
        this.setState({ currentComponent: component, currentDiagramComponent: diagramComponent }, () => {

            if (isDoubleClick) {
                this.openConfig()
            } else {
                this.moreModal.showModal()
            }

        })


    }

    openConfig = () => {
        let component = this.state.currentComponent

        // 
        let headersData = component.configuration
        if (!headersData) {
            headersData = {}
        }

        headersData.label = component.title


        this.setState({ currentHeadersData: headersData }, () => {
            this.configModal.showModal()
        })
        window.addEventListener("keypress", this.donePressed)

        setTimeout(() => {
            if (this.moreModal) {
                this.moreModal.hideModal()
            }
        }, 100);
    }

    donePressed = (e) => {
        if (e?.keyCode == 13) {
            this.ConfigurationDone()
        }
    }

    removeDoneEventListener(){
        window.removeEventListener("keypress", this.donePressed)
    }


    ConfigurationDone = () => {
        let headersData = this.form.getForm()
        if (headersData) {
            let components = this.state.components
            let key = this.state.currentComponent.key
            // let component = components[key]
            components[key].title = headersData.label
            components[key].configuration = headersData

            this.setState({ components }, () => {
                this.configModal.hideModal()
            })
        }

    }

    render() {
        let ratio = 1
        if (this.rigramDroppable) {
            ratio = (this.miniWidth / this.rigramDroppable.scrollWidth) * this.state.zoom
        }

        return (

            <Configurer
                settingsInfo={{ showFooter: false, showTabBar: false, showHeader: false, headerTitle: "Rigram", button: {} }}
                title={"Rigram"}
                description={"Rigram Description"}
                className="diagram ltr"
            >


                <div className="w-100 blur-back" style={{ position: 'fixed', height: 50, borderBottom: '1px solid #eee', zIndex: 8, padding: '10px 20px', backgroundColor: '#ffffffcc' }}>
                    <p>Rigram</p>
                </div>


                <DiagramSidebar onDragStart={this.onDragStart} />


                <div onScroll={(e) => this.updateMiniViewSize(e)} className="rigramDroppable" ref={el => this.rigramDroppable = el} onDrop={(e) => this.onDrop(e, 'complete')} onDragOver={(e) => this.onDragOver(e)} style={{ direction: 'ltr', overflow: 'auto', padding: 100, width: '100%', height: '100%', zIndex: 5, backgroundImage: 'url(/images/dg-bg.jpg)', position: 'absolute', }}>
                    <div className="w-100" style={{ transformOrigin: '0px 0px', transform: 'scale(' + this.state.zoom + ')', position: 'absolute', top: 0, left: 0, height: '100vh' }}>
                        {Object.values(this.state.components).map((prop, index) => {
                            let thisFunction = diagramComponents[prop.type]
                            return (
                                <DiagramComponent ref={el => this[prop.key] = el} key={prop.key} data={prop} openMore={this.openMore} thisFunction={thisFunction} draggingId={this.draggingId} dragEnd={this.dragEnd} onDrag={this.onDrag} onDragStart={this.onDragStart} nodeMouseDown={this.nodeMouseDown} zoom={this.state.zoom} setNodesPosition={this.setNodesPosition} />
                            )
                        })}

                        {Object.values(this.state.paths).map((prop, index) => {
                            // 
                            return (
                                <DiagramPath removePath={this.removePath} ref={el => this[prop.key] = el} key={prop.id} data={prop} draggingId={this.draggingId} dragEnd={this.dragEnd} onDrag={this.onDrag} onDragStart={this.onDragStart} zoom={this.state.zoom} />
                            )
                        })}

                        {this.state.activePath && (
                            <DiagramPath isActivePath={true} ref={el => this.activePath = el} data={this.state.activePath} />
                        )}


                    </div>
                </div>


                {/* MINIVIEWER */}
                <div className={"miniViewContainer " + (this.state.miniClose ? 'miniClose' : '')} >
                    <button className=" mx-1 flexcc zoomToggleBut" onClick={() => this.toggleMiniView()} >
                        <img src="/images/nexts.png" width="20px" />
                    </button>

                    <div className="miniView" ref={el => this.miniView = el} >

                        {Object.values(this.state.components).map((prop, index) => {
                            let thisFunction = diagramComponents[prop.type]

                            return (
                                <div onDragEnd={(e) => this.dragEnd()} key={prop.key} ref={el => this['mini-' + prop.key] = el} style={{ padding: 30 / 200, position: 'absolute', transform: 'translate(-50%,-50%)' }}>
                                    <div style={{ backgroundColor: thisFunction?.UI?.bodyColorS, width: (prop.width ?? 1) * ratio, height: (prop.height ?? 1) * ratio, borderRadius: thisFunction?.UI?.type == "circle" ? '50%' : 3 }}>
                                    </div>
                                </div>
                            )
                        })}

                        <div className="mini-view-tracker" ref={el => this.miniViewTracker = el} ></div>
                    </div>

                    <div className="flexcc flex-column mld-2 ">
                        <button className="p-0 m-1 flexcc zoomBut" onClick={() => this.handleZoom('in')} >
                            <img src="/images/zoom-in.svg" width="25px" />
                        </button>
                        <button className="p-0 m-1 flexcc zoomBut" onClick={() => this.handleZoom('out')}>
                            <img src="/images/zoom-out.svg" width="25px" />
                        </button>
                        <button className="p-0 m-1 flexcc zoomBut" onClick={() => this.handleZoom('fit')}>
                            <img src="/images/zoom-fit.svg" width="25px" />
                        </button>
                    </div>
                </div>


                <div ref={el => this.ghost = el} style={{ width: 1, height: 1, position: 'absolute' }}></div>



                <Modal ref={el => this.moreModal = el} maxWidth={300}>
                    <div className="w-100  text-center" style={{ backgroundColor: '#ffffffee', borderRadius: 8 }}>
                        <div className="w-100" style={{ zIndex: 1, padding: '8px 15px', display: 'flex', justifyContent: 'space-between', alignItems: 'center', position: 'sticky', top: 0, borderBottom: '0px #eee solid', backgroundColor: '#f2f6f8ee', background: 'linear-gradient(to right,#d7e2f7dd,#dad6e4dd)', backdropFilter: 'blur(10px) saturate(180%)', WebkitBackdropFilter: 'blur(10px) saturate(180%)', borderRadius: '8px 8px 0px 0px' }}>

                            <div className="text-start">
                                <p className="text-smallest mt-2 opacity-5" style={{ lineHeight: 0.5 }}>WHAT TO DO?</p>
                                <p>{this.state.currentComponent?.title}</p>
                            </div>

                            <div className="cursor-pointer" onClick={() => this.moreModal.hideModal()} style={{ width: 30, height: 30, borderRadius: '50%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <img style={{ width: 14, height: 14 }} src="/images/close.svg" alt="" />
                            </div>

                        </div>
                        <div className="py-2 px-2">
                            <button onClick={() => this.openConfig()} className="py-2 w-100 flexc" style={{ borderBottom: '1px solid #eee' }}>
                                <img className="mrd-3" src="https://www.flaticon.com/svg/static/icons/svg/747/747914.svg" width="24px" />
                                <div className="text-start mb-1">
                                    <p className="text-normal">Configure</p>
                                    <p className="text-smaller" style={{ lineHeight: 1, opacity: 0.3 }}>Double click on component for faster access</p>
                                </div>
                            </button>



                            <button className="py-2 w-100 flexc" style={{ borderBottom: '1px solid #eee' }}>
                                <img className="mrd-3" src="https://www.flaticon.com/svg/static/icons/svg/747/747938.svg" width="24px" />
                                <p className="text-normal">Clone</p>
                            </button>

                            <button onClick={() => this.removeConfirmModal.showModal()} className="py-2 w-100 flexc" style={{ borderBottom: '0px solid #eee' }}>
                                <img className="mrd-3" src="https://www.flaticon.com/svg/static/icons/svg/747/747854.svg" width="24px" />
                                <p className="text-normal">Remove</p>
                            </button>

                        </div>
                    </div>
                </Modal>



                <Modal ref={el => this.removeConfirmModal = el} maxWidth={300}>
                    <div className="w-100  text-center" style={{ backgroundColor: '#ffffffee', borderRadius: 8 }}>
                        <div className="w-100" style={{ zIndex: 1, padding: '8px 15px', display: 'flex', justifyContent: 'space-between', alignItems: 'center', position: 'sticky', top: 0, borderBottom: '0px #eee solid', backgroundColor: '#f2f6f8ee', background: 'linear-gradient(to right,#dd3030dd,#ee5050dd)', backdropFilter: 'blur(10px) saturate(180%)', WebkitBackdropFilter: 'blur(10px) saturate(180%)', borderRadius: '8px 8px 0px 0px' }}>

                            <div className="text-start">
                                {/* <p className="text-smallest mt-2 opacity-5" style={{ lineHeight: 0.5 }}>WHAT TO DO?</p> */}
                                <p className="white">Remove</p>

                            </div>

                            <div className="cursor-pointer" onClick={() => this.removeConfirmModal.hideModal()} style={{ width: 30, height: 30, borderRadius: '50%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <img className="invert" style={{ width: 14, height: 14 }} src="/images/close.svg" alt="" />
                            </div>

                        </div>
                        <div className="py-2 px-2">

                            <p className="text-small pb-3 pt-2" style={{ borderBottom: '1px solid #eee' }}>Are you sure you want to remove this component?</p>


                            <button onClick={() => this.removeCurrentComponent()} className="py-2 w-100 flexc" style={{ borderBottom: '1px solid #eee' }}>
                                <img className="mrd-3" src="https://www.flaticon.com/svg/static/icons/svg/747/747854.svg" width="24px" />
                                <p className="text-normal">Remove</p>
                            </button>


                            <button onClick={() => this.removeConfirmModal.hideModal()} className="py-2 w-100 flexc" style={{ borderBottom: '0px solid #eee' }}>
                                <img className="mrd-3" src="https://www.flaticon.com/svg/static/icons/svg/747/747928.svg" width="24px" />
                                <p className="text-normal">Cancel</p>
                            </button>


                        </div>
                    </div>
                </Modal>



                <Modal ref={el => this.configModal = el} maxWidth={700}>
                    <div className="w-100  text-center" style={{ backgroundColor: '#ffffffee', borderRadius: 8 }}>
                        <div className="w-100 flexcb" style={{ zIndex: 1, padding: '8px 15px', position: 'sticky', top: 0, borderBottom: '0px #eee solid', backgroundColor: '#f2f6f8ee', background: 'linear-gradient(to right,#d7e2f7dd,#dad6e4dd)', backdropFilter: 'blur(10px) saturate(180%)', WebkitBackdropFilter: 'blur(10px) saturate(180%)', borderRadius: '8px 8px 0px 0px' }}>

                            <div className="text-start">
                                <p className="text-smallest mt-2 opacity-5" style={{ lineHeight: 0.5 }}>CONFIGURE</p>
                                <p>{this.state.currentComponent?.title}</p>
                            </div>

                            <div className="flexc">
                                <button onClick={() => this.ConfigurationDone()} className=" flexc px-3 mx-1 flexcc" style={{ height: 30, background: '#ffffff50', borderRadius: 20 }}>
                                    <p className="text-normal">Done</p>
                                </button>

                                <div className="cursor-pointer flexcc" onClick={() => this.configModal.hideModal(()=>{this.removeDoneEventListener()})} style={{ width: 30, height: 30, borderRadius: '50%', backgroundColor: '#ffffff50' }}>
                                    <img style={{ width: 14, height: 14 }} src="/images/close.svg" alt="" />
                                </div>
                            </div>


                        </div>
                        <div className="py-3 px-4">
                            {/* <p className="text-small description-text">{this.state.currentDiagramComponent?.title} Configuration</p> */}
                            <p className="text-smaller description-text">You can configure this {this.state.currentDiagramComponent?.title} component using below fields</p>

                            <FormViewer ref={el => this.form = el} headers={this.state.currentDiagramComponent?.headers} initData={this.state.currentHeadersData} errors={this.state.errors} inputClass={'modern-input'} />

                        </div>
                    </div>
                </Modal>



            </Configurer>
        )
    }


}



const mapStateToProps = state => ({ settings: state.settings, user: state.user })
const mapDispatchToProps = dispatch => ({ actions: bindActionCreators(actions, dispatch) })

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Rigram)
