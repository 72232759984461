import React from "react";
import Configurer from "../../components/Configurer";


class Dashboard extends React.Component {

    state = {
    }

    render() {
        return (

            <Configurer
                // settingsInfo={{ headerTitle: "Dashboard" }}
                settingsInfo={{ headerTitle: "Dashboard", button: { goBack: false } }}

                title={"Dashboard"}
                description={"This is the dashboard"}
                className="p-0"
            >


                <div className="w-100 py-4 px-5 mb-4 flexcb" style={{ backgroundImage: 'linear-gradient(#fbdfaa,#fce7b4)', borderRadius: 8 }}>
                    <div>
                        <p className="font-bold text-big">Premium account</p>
                        <p className="text-small mt-2">With using out premium account you can have blah blah blah ...</p>
                        <button className="px-4 py-2 mt-2" style={{ borderRadius: 8, backgroundColor: '#fff' }}>
                            TRY NOW
                                </button>
                    </div>
                    <div>
                        <img src="/images/icons/crown.svg" width="90px" alt="" />
                    </div>
                </div>
                <div className="px-2 mt-4">
                    <p className="text-ultra-big font-bold">Dashboard</p>
                    <p className="text-small" style={{ color: '#9ab' }}>{this.props.user?.info?.fullname}</p>
                </div>


                <div className="row mx-0 mt-4 p-0">

                    {this.props.pages.map((prop, index) => {
                        if (!prop.dontShowInDashboard) {
                            return (
                                <div className="col-6 col-lg-4" key={index}>
                                    <button onClick={() => this.props.changePage(prop.key)} className="text-center p-3 button-light-hover border-radius-8 w-100">
                                        <img src={prop.icon} width="70px" />
                                        <p className="font-bold mt-2 text-normal">{prop.name}</p>
                                    </button>
                                </div>
                            )
                        }
                    })}

                </div>

            </Configurer>
        )
    }


}


export default Dashboard
