import React from "react";
// import HttpService from '../utils/Http.services';
// import { siteConfig, siteTheme } from "../variables/config";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actions from '../stores/actionsList';
// import TabBar from "../views/TabBar";
import Configurer from "../components/Configurer";
import Dashboard from "./profile/Dashboard";
import StagesManager from "../components/StagesManager";
import { pathMaker } from "../utils/useful";
import EditProfile from "./profile/EditProfile";
import Cookies from "universal-cookie";
import PageStatusViewer from "../components/PageStatusViewer";
import AccountType from "./profile/AccountType";
import ChangePasword from "./profile/ChangePasword";
import { withRouter } from "react-router";
// import { logoutUser } from "../stores/actionsList";


class Profile extends React.Component {

    state = {
        pages: [
            { name: 'Dashboard', key: '', dontShowInDashboard: true },
            { name: 'Edit Profile', icon: '/images/icons/crown.svg', key: 'edit' },
            { name: 'Account type', icon: '/images/icons/account.svg', key: 'type' },
            { name: 'Change Password', icon: '/images/icons/password1.svg', key: 'password' },
        ]
    }


    componentDidMount() {

        const cookies = new Cookies();
        let token = cookies.get('token');
        if (token) {
            this.setState({ isLoading: true })
            this.waitForId(10)
        } else {
            this.setState({ pageStatus: 401 })
        }

        // setInterval(() => {
        //     // 
        //     if(this.configurer){
        //         
        //         
        //     }
        // }, 2000);

        // if(this.configurer){
        //     
        //     
        // }
    }


    waitForId = (leftAttempt) => {
        
        if (this.props.user?.info?._id || this.props.user?.info?.id || this.props.user?.info?.name || this.props.user?.info?.phoneNumber) {
            this.setState({ isLoading: false }, () => {
                this.checkDefaultTab()
            })
        } else {
            setTimeout(() => {
                if (leftAttempt != 0) {
                    this.waitForId(leftAttempt - 1)
                } else {
                    // this.props.actions.logoutUser()
                    this.setState({ pageStatus: 401 })
                }
            }, 500);
        }
    }


    componentDidUpdate(newProps) {
        let windowPath = this.props.location.pathname.split('/')
        windowPath = windowPath[windowPath.length - 1]

        // 
        // 
        if (this.state.oldPath && this.state.oldPath != this.props.location.pathname) {
            // 
            this.setState({ oldPath: this.props.location.pathname }, () => {
                this.checkDefaultTab()
            })
        }
    }



    checkDefaultTab() {

        let def = ''

        let pathname = window.location.pathname.split('/')
        pathname.forEach((path, index) => {
            // 
            if (path == 'profile') {
                def = pathname[index + 1]
            }
        });

        let pageIsValid = false

        this.state.pages.forEach(tab => {
            if (tab.key.toLowerCase() === def?.toLowerCase()) {
                pageIsValid = true
            }
        });
        if (!def) {
            def = ''
        }
        // 
        if (pageIsValid || def === '') {
            this.setState({ oldPath: this.props.location.pathname, pageStatus: 200 }, () => {
                this.setState({ path: def, }, () => {
                    this.stageManager.changeStage(def, () => {
                        window.scrollTo({ top: 0, behavior: 'smooth' })
                    })
                })

                // this.changePage(def)
                // if (def == '' || !def) {
                // this.stageManager.changeStage('dashboard')
                // this.stageManager.changeStage(def)
                // // this.props.history.push(pathMaker('/profile'))
                // } else {

                //     this.stageManager.changeStage(def)

                // }
            })
        } else {
            this.setState({ pageStatus: 404 })
        }
    }








    changePage = (page) => {
        // 
        // this.stageManager.changeStage(page)
        // this.stageManager.changeStage(page, () => {
        //     // window.scrollTo(0, 0)
        // })
        let path = pathMaker('/profile/' + page)
        // if (this.props.history.pathname != path) {
        //     this.setState({ path: page }, () => {
        //         // 
        this.props.history.push(path)
        // })
        // }

        // 
        // window.history.pushState(null, null, path);

    }


    setUser = (data) => {
        
        this.props.actions.setUser(data, null, true)
    }


    addNotif = (options) => {
        this.props.actions.addNotif(options)
    }

    logout = () => {
        this.props.actions.logoutUser()
        setTimeout(() => {
            this.props.history.push(pathMaker('/login'))

        }, 500);
    }



    render() {
        return (

            <Configurer
                settingsInfo={{ showFooter: true, showTabBar: true, showHeader: true, headerTitle: "Profile", button: {} }}
                title={"Profile"}
                description={"Profile Description"}
                className="p-0 min-full-height"
                ref={el => this.configurer = el}
            >

                <PageStatusViewer status={this.state.pageStatus}>


                    <div className="row m-0">

                        <div className="col-12 col-sm-12 col-md-4 col-lg-3 pageSide px-0 d-md-block d-none" >
                            <div>
                                <div className="text-center py-3">
                                    <img src="/images/male.png" width="90px" style={{ borderRadius: 100 }} />
                                    <p className="font-bold mt-2">Username</p>
                                    <p className="text-small">Account type</p>
                                    <button onClick={() => this.logout()} className="px-5 py-2 mt-1" style={{ borderRadius: 20, backgroundColor: '#677dc420' }}>
                                        <p className="text-smaller" style={{ color: '#677dc4' }}>Log out</p>
                                    </button>
                                </div>

                                <div className="mt-2">
                                    {this.state.pages.map((prop, index) => {
                                        return (
                                            <button onClick={() => this.changePage(prop.key)} key={index} className={"pageSideOption " + (prop.key == this.state.path ? "selected" : '')}>
                                                <p>{prop.name}</p>
                                            </button>
                                        )
                                    })}
                                </div>

                            </div>
                        </div>


                        <div className="col-12 col-sm-12 col-md-8 col-lg-9" style={{ padding: '3% 4%' }}>

                            <StagesManager ref={el => this.stageManager = el} >
                                <Dashboard pages={this.state.pages} stage={0} user={this.props.user} stageName={''} changePage={this.changePage} />
                                <EditProfile pages={this.state.pages} stage={1} user={this.props.user} setUser={this.setUser} addNotif={this.addNotif} stageName={'edit'} />
                                <AccountType pages={this.state.pages} stage={2} user={this.props.user} stageName={'type'} />
                                <ChangePasword pages={this.state.pages} stage={3} user={this.props.user} addNotif={this.addNotif} stageName={'password'} />

                            </StagesManager>

                        </div>
                    </div>

                </PageStatusViewer>

            </Configurer>
        )
    }


}



const mapStateToProps = state => ({ settings: state.settings, user: state.user })
const mapDispatchToProps = dispatch => ({ actions: bindActionCreators(actions, dispatch) })

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(Profile));
