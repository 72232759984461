import React from "react";
import Configurer from "../../components/Configurer";


class ThingLatestInfo extends React.Component {

    state = {
        statusInfo: [
            { title: 'Battery', key: '', value: '80%' },
            { title: 'Connection', key: '', value: 'Connected' },
            { title: 'RSSI', key: '', value: '-40 dbm' },
        ],

        characteristicsInfo: [
            { title: 'Temperature', key: '', value: '27 Celsius' },
            { title: 'Humidity', key: '', value: '30%' },
        ],

        locationInfo: [
            { title: 'Latitude', key: '', value: '53.7' },
            { title: 'longitude', key: '', value: '57.2' },
        ],

    }

    render() {
        return (



            <section className="thingInfoPage" >
                <div className="w-100 pt-4 px-5 mb-4 pb-5">

                    <div className={'thingInfoList mt-3'}>

                        <p className={'text-small mb-1'} style={{ color: '#9ab' }}>Status</p>
                        {this.state.statusInfo.map((prop, index) => {
                            return (
                                <div className="flexc mt-1" key={index}>
                                    <div className="thingInfoTitle">
                                        <span className=" text-small ">{prop.title}</span>
                                    </div>
                                    <div className="thingInfoValue">
                                        <span className=" text-small ">{prop.value}</span>
                                    </div>
                                </div>
                            )
                        })}
                        <div className="w-100 mt-4" style={{ background: '#eee', height: 1 }}></div>


                        <p className={'text-small mb-1 mt-4'} style={{ color: '#9ab' }}>Characteristics</p>
                        {this.state.characteristicsInfo.map((prop, index) => {
                            return (
                                <div className="flexc mt-1" key={index}>
                                    <div className="thingInfoTitle">
                                        <span className=" text-small ">{prop.title}</span>
                                    </div>
                                    <div className="thingInfoValue">
                                        <span className=" text-small ">{prop.value}</span>
                                    </div>
                                </div>
                            )
                        })}

                        <div className="w-100 mt-4" style={{ background: '#eee', height: 1 }}></div>

                        <p className={'text-small mb-1 mt-4'} style={{ color: '#9ab' }}>Location</p>
                        {this.state.locationInfo.map((prop, index) => {
                            return (
                                <div className="flexc mt-1" key={index}>
                                    <div className="thingInfoTitle">
                                        <span className=" text-small ">{prop.title}</span>
                                    </div>
                                    <div className="thingInfoValue">
                                        <span className=" text-small ">{prop.value}</span>
                                    </div>
                                </div>
                            )
                        })}



                    </div>


                </div>




            </section>

        )
    }


}


export default ThingLatestInfo
