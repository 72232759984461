import React from 'react'
import { checkTextTranslation } from '../../utils/useful'

class TextInput extends React.Component {
    state = {
    }


    changeInputValue(target) {
        let value = (target.validity.valid) ? target.value : null
        if (value !== null) {
            this.props.changeValue(this.props.header.key, value)
        }
    }


    render() {
        return (
            <div className="w-100 h-100">
                <p className="text-small" style={{ color: '#051015', textOverflow: 'ellipsis', overflow: 'hidden', width: '100%' }}>{this.props.data}</p>
            </div>
        )
    }
}

export default TextInput;