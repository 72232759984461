import React from "react";

import Modal from "../components/Modal";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { v4 as uuidv4 } from 'uuid';
import * as actions from "../stores/actionsList";

import TableViewer from "../components/TableViewer";
import ItemModal from "./ItemModal";

// import ProfileImage from "../components/partials/ProfileImage";

class ModelAdditionalParams extends React.Component {
    state = {};
    openEditModal = (prop, index) => {
        this.itemModal.modal.showModal();
        if (prop) {
            this.setState({ currentItem: prop || {}});
        }
    };

    openRemoveModal = (prop) => {
        const data = [...this.props.data];
        const newData = data.filter((item) => item?.id !== prop.id);
        this.props.updateData(newData);
    };

    hideModal = () => {
        this.setState(
            {
                currentItem: null,
            },
            () => {
                this.modal.hideModal();
            }
        );
    };

    itemAdded = (newItem) => {
        newItem["id"] = uuidv4();
        const data = [...this.props.data];
        data.push(newItem);
        this.props.updateData(data);
    };

    itemEdited = (editedItem) => {
        let data = [...this.props.data];
        const foundItem = data.find((item) => item?.id === editedItem?.id);
        Object.assign(foundItem, editedItem);
        this.props.updateData(data);
    };

    updateData = (data) => {
        this.props.updateData(data);
    };

    changeData = (item) => {
        const data = [...this.props.data];
        const foundItem = data.find((itemData) => itemData.id === item.id);
        if (foundItem) {
            Object.assign(foundItem, item);
        }
        else {
            item["id"] = uuidv4()
            data.push(item);
        }
        this.props.updateData(data);
        this.itemModal.hideModal();
    };
    itemActions = {
        edit: {
            key: "edit",
            label: "{{lang}}edit",
            color: "#677dc4",
            function: this.openEditModal,
            permission: ["view"],
        },
        remove: {
            key: "remove",
            label: "{{lang}}remove",
            color: "#ee5050",
            function: this.openRemoveModal,
            permission: ["delete"],
        },
    };
    render() {
        //
        return (
            <Modal ref={(el) => (this.modal = el)} maxWidth={"min-content"}>
                <div
                    className="w-100  text-center pb-5"
                    style={{ backgroundColor: "#ffffffee", borderRadius: 8 }}
                >
                    <div
                        className="w-100 flexcb white"
                        style={{
                            zIndex: 1,
                            padding: "8px 15px",
                            position: "sticky",
                            top: 0,
                            borderBottom: "0px #eee solid",
                            backgroundColor: "#f2f6f8ee",
                            background:
                                "linear-gradient(to right,#007aff,#007affdd)",
                            backdropFilter: "blur(10px) saturate(180%)",
                            WebkitBackdropFilter: "blur(10px) saturate(180%)",
                            borderRadius: "8px 8px 0px 0px",
                        }}
                    >
                        <div className="text-start">
                            {/* <p className="text-smallest mt-2 opacity-5" style={{ lineHeight: 0.5 }}>CALCULATION IS DONE</p> */}
                            <p>Variables</p>
                        </div>
                        <div className="flexc">
                            <button
                                onClick={() => this.modal.hideModal()}
                                className=" flexc px-3 mx-1 flexcc"
                                style={{
                                    height: 30,
                                    background: "#33333350",
                                    borderRadius: 20,
                                }}
                            >
                                <p className="text-normal white">Done</p>
                            </button>
                        </div>
                    </div>
                    <div className="container ">
                        <div className="row m-0">
                            <div className="col-12 p-0">
                                <div
                                    className="p-0"
                                    style={{ borderRadius: 0 }}
                                >
                                    <div className="flexcb" style={{}}>
                                        <div className="flexc mt-2">
                                            {/* <ProfileImage width={54} borderRadius={50} notif={0} border={'2px solid #eee'} className={'mrd-3'} data={{ image: this.props.page?.image }} /> */}

                                            <div>
                                                {/* <p className="text-big font-bold m-0 " style={{ color: '#0d172a' }}>{this.props.page?.title}</p> */}
                                                <p
                                                    className="text-small mt-1 text-start mr-4"
                                                    style={{ color: "#456" }}
                                                >
                                                    Variables can be used in
                                                    equations. Without variables
                                                    you can not use equations
                                                    like Sigma
                                                </p>
                                            </div>
                                        </div>

                                        <div
                                            className="flexcc mt-2"
                                            style={{ alignItems: "flex-end" }}
                                        >
                                            <button
                                                onClick={() =>
                                                    this.openEditModal()
                                                }
                                                className="flexcc"
                                                style={{
                                                    boxShadow:
                                                        "0px 0px 15px #00000005",
                                                    backgroundImage:
                                                        "linear-gradient(#007aff,#007affdd)",
                                                    border: "0px solid #e9eaf0",
                                                    color: "#789",
                                                    borderRadius: 30,
                                                    padding: "7px 15px",
                                                }}
                                            >
                                                <img
                                                    className="mrd-2 invert"
                                                    src="/images/add.svg"
                                                    alt=""
                                                    width="12px"
                                                />
                                                <span
                                                    className="text-smaller m-0 white text-bold "
                                                    style={{
                                                        whiteSpace: "nowrap",
                                                    }}
                                                >
                                                    Add New
                                                </span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 mb-5 mt-4 w-100 p-0">
                                <TableViewer
                                    isLoading={false}
                                    headers={this.props.page?.headers}
                                    data={this.props.data}
                                    actions={{ ...this.itemActions }}
                                    settings={{ isSortable: false }}
                                    openEditModal={this.openEditModal}
                                    openRemoveModal={this.openRemoveModal}
                                    updateData={this.updateData}
                                />
                            </div>
                        </div>

                        <ItemModal
                            ref={(el) => (this.itemModal = el)}
                            page={this.props.page}
                            headers={this.props.page?.headers}
                            initData={{...this.state.currentItem}}
                            submit={this.changeData}
                            itemAdded={this.itemAdded}
                            itemEdited={this.itemEdited}
                            hideModalCb={() =>
                                this.setState({ currentItem: null })
                            }
                        />
                    </div>
                </div>
            </Modal>
        );
    }
}

const mapStateToProps = (state) => ({
    settings: state.settings,
    user: state.user,
});
const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps, null, {
    forwardRef: true,
})(ModelAdditionalParams);
