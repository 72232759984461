import React from "react";
import TableViewer from "../../../components/TableViewer";
import { v4 as uuidv4 } from 'uuid';


class ThingHistoryTable extends React.Component {

    state = {
        headers: [
            { key: 'name', type: 'TextInput', information: { label: '{{lang}}name', icon: 'https://image.flaticon.com/icons/svg/13/13305.svg', placeholder: '{{lang}}insertName', required: true } },
            { key: 'family', type: 'TextInput', information: { label: '{{lang}}family', icon: 'https://image.flaticon.com/icons/svg/13/13305.svg', placeholder: '{{lang}}insertFamily', required: true } },
            { key: 'education', type: 'TextInput', information: { label: '{{lang}}Education', icon: 'https://image.flaticon.com/icons/svg/13/13305.svg', placeholder: '{{lang}}insertEducation', required: true } },
            { key: 'age', type: 'TextInput', information: { label: '{{lang}}age', icon: 'https://image.flaticon.com/icons/svg/13/13305.svg', placeholder: '{{lang}}insertage', required: true } }
        ],
        data: [
            { id: uuidv4(), name: 'pouya pezhman', family: 'pezhman', education: "Master of NanoElectronics", age: 29 },
            { id: uuidv4(), name: 'pedram', family: 'pezhman', education: "Master of NanoElectronics", age: 29 },
            { id: uuidv4(), name: 'mohsen', family: 'pezhman', education: "Master of NanoElectronics", age: 29 },
            { id: uuidv4(), name: 'ali', family: 'pezhman', education: "Master of NanoElectronics", age: 29 },
            { id: uuidv4(), name: 'sadegh', family: 'pezhman', education: "Master of NanoElectronics", age: 29 },
            { id: uuidv4(), name: 'sadegh', family: 'pezhman', education: "Master of NanoElectronics", age: 29 },
            { id: uuidv4(), name: 'sadegh', family: 'pezhman', education: "Master of NanoElectronics", age: 29 },
            { id: uuidv4(), name: 'sadegh', family: 'pezhman', education: "Master of NanoElectronics", age: 29 },
            { id: uuidv4(), name: 'sadegh', family: 'pezhman', education: "Master of NanoElectronics", age: 29 },
        ],

        characteristics: [
            { name: 'All', key: '*' },
            { name: 'Temperature', key: 'Temperature' },
            { name: 'Humidity', key: 'Humidity' },
            { name: 'Other', key: 'Other' },
        ],

        currentTab: '*'
    }

    changeTab = (key) => {
        this.setState({ currentTab: key })
    }

    render() {
        return (

            <div className={'thingInfoList mt-3'}>

                <div className="flexcb">
                    <button onClick={() => this.props.changeStage("main")} className="flexc mb-3">
                        <img className="back mrd-2" src="/images/next-2.png" width="14px" alt="" />
                        <span> Back to list</span>
                    </button>

                    <button className="flexcc" style={{ boxShadow: '0px 0px 15px #00000005', backgroundImage: 'linear-gradient(#3869f4,#3362e5)', border: '0px solid #e9eaf0', color: '#789', borderRadius: 8, padding: '7px 15px' }}>
                        <img className="mrd-2 invert" src="/images/settings-2.svg" alt="" width="15px" />
                        <span className="text-smaller m-0 white text-bold" >Filter</span>
                    </button>
                </div>


                <div className="-100">

                    <div className="flexc mt-4 mb-4">

                        {this.state.characteristics.map((prop, index) => {
                            return (
                                <button key={index} onClick={() => this.changeTab(prop.key)} className="mrd-2 px-3 py-2" style={{ transition: 'all 0.5s', backgroundColor: this.state.currentTab == prop.key ? '#789' : '#f2f6f8', borderRadius: 8 }}>
                                    <p className="text-small" style={{ color: this.state.currentTab == prop.key ? '#fff' : '#789' }}>{prop.name}</p>
                                </button>
                            )
                        })}
                    </div>


                    <TableViewer headers={this.state.headers} data={this.state.data} />

                </div>
            </div>

        )
    }


}


export default ThingHistoryTable
