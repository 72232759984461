import React from "react";
import inputComponents from "./inputs/inputComponents";
import { checkTextTranslation, getObject, setObject } from "../utils/useful";

class FormViewer extends React.Component {
    state = {
        errors: {},
        data: {},
        width: window.innerWidth,
    };

    componentDidMount() {
        this.init();
        window.addEventListener("resize", this.setFormWidth);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.setFormWidth);
    }

    componentDidUpdate(prevProps) {
        if (
            this.props.initData !== prevProps.initData ||
            this.props.headers !== prevProps.headers
        ) {
            this.init();
        }
    }

    getForm = () => {
        if (this.validator()) {
            return this.state.data ? this.state.data : {};
        } else {
            return null;
        }
    };

    init = () => {
        this.makeInitValue(this.props.initData);
    };

    validator() {
        let valid = true;
        let errors = {};
        this.props.headers.forEach((header) => {
            if (header.information?.required) {
                if (header.type == "ImageInput" || header.type == "FileInput") {
                    let value = getObject(
                        this.state.data,
                        "**files." + header.key,
                        1
                    );
                    if (!value || value === "") {
                        valid = false;
                        errors[header.key] = "{{lang}}errors.required-field";
                    }
                } else {
                    let value = getObject(this.state.data, header.key);
                    if (!value || value === "") {
                        valid = false;
                        errors[header.key] = "{{lang}}errors.required-field";
                    }
                }
                if (header.type == "PhoneInput") {
                    let value = getObject(this.state.data, header.key);
                    if (!value || value.length < 14) {
                        valid = false;
                        errors[header.key] = "{{lang}}errors.required-field";
                    }
                }

                if (header.information.max) {
                    let value = getObject(this.state.data, header.key);

                    if (value > header.information.max) {
                        valid = false;
                        errors[header.key] = "{{lang}}errors.maxLimit";
                    }
                }

                if (header.information.min) {
                    let value = getObject(this.state.data, header.key);

                    if (value < header.information.min) {
                        valid = false;
                        errors[header.key] = "{{lang}}errors.minLimit";
                    }
                }
            }
        });

        this.setState({ errors });
        return valid;
    }

    makeInitValue(data) {

        this.setState({ data: data, show: true }, () => {
            this.setFormWidth();
        });
        // })
    }

    setFormWidth = () => {
        if (this.form) {
            this.setState({ width: this.form?.getBoundingClientRect().width });
        }
    };

    changeFiles = (value, key, extra) => {
        let data = this.state.data ?? {};

        if (data["**files"] == null) {
            data["**files"] = {};
        }
        value.formType = "";

        this.props.headers.forEach((header) => {
            if (header.key == key) {
                value.formType = header.type;
                // value.single = header.information?.single
            }
        });

        data["**files"][key] = value;

        this.setState({ data });
    };

    changeValue = (key, value, extra) => {
        let data = { ...this.state.data };

        data = setObject(data, key, value);

        this.setState({ data });
    };

    formColBuilder(col) {
        let finalCol = "col-12";

        if (600 < this.state.width && col) {
            finalCol = "col-" + col;
        }

        return finalCol;
    }

    render() {
        if (this.state.show) {
            return (
                <section className="w-100 form" ref={(el) => (this.form = el)}>
                    <div className="row ">
                        {this.props.headers &&
                            this.props.headers.map((header, index) => {
                                let Component = inputComponents[header.type];
                                let data = getObject(
                                    this.state.data,
                                    header.key
                                ); //this.state.data[header.key] ? this.state.data[header.key] : ''
                                let files;
                                if (
                                    this.state.data &&
                                    this.state.data["**files"]
                                ) {
                                    files = getObject(
                                        this.state.data["**files"],
                                        header.key
                                    ); //this.state.data[header.key] ? this.state.data[header.key] : ''
                                }

                                if (Component && !header?.hideHeader) {
                                    return (
                                        <div
                                            key={index}
                                            className={
                                                " " +
                                                this.formColBuilder(
                                                    header.col
                                                ) +
                                                (index !== header.length - 1
                                                    ? " mb-3"
                                                    : "")
                                            }
                                        >
                                            <div className="row">
                                                {header.information?.label && (
                                                    <div className="col-12 mb-1 text-start">
                                                        <label
                                                            className={
                                                                "text-small mr-1 ml-1  " +
                                                                (header
                                                                    .information
                                                                    ?.required
                                                                    ? "required"
                                                                    : "")
                                                            }
                                                        >
                                                            {checkTextTranslation(
                                                                header
                                                                    .information
                                                                    ?.label
                                                            )}
                                                        </label>
                                                    </div>
                                                )}

                                                <div className="col-12">
                                                    <div
                                                        className={
                                                            "flexc " +
                                                            (header.information
                                                                .inputClass ??
                                                                (this.props
                                                                    .inputClass
                                                                    ? this.props
                                                                          .inputClass
                                                                    : "default-input-view"))
                                                        }
                                                    >
                                                        {header.information
                                                            ?.icon && (
                                                            <img
                                                                className="mrd-3"
                                                                src={
                                                                    header
                                                                        .information
                                                                        ?.icon
                                                                }
                                                                height="25px"
                                                                alt="icon"
                                                            />
                                                        )}
                                                        <Component
                                                            header={header}
                                                            data={data}
                                                            files={files}
                                                            changeFiles={
                                                                this.changeFiles
                                                            }
                                                            changeValue={
                                                                this.changeValue
                                                            }
                                                        />
                                                    </div>
                                                </div>

                                                {header.information?.hint && (
                                                    <div className="col-12 mt-1 mld-2 text-start">
                                                        <small
                                                            style={{
                                                                color: "#9ab",
                                                                fontSize: 12,
                                                            }}
                                                        >
                                                            {checkTextTranslation(
                                                                header
                                                                    .information
                                                                    ?.hint
                                                            )}
                                                        </small>
                                                    </div>
                                                )}

                                                {this.props.errors &&
                                                    this.props.errors[
                                                        header.key
                                                    ] && (
                                                        <div className="col-12 mt-1 mld-2 text-start">
                                                            <small
                                                                style={{
                                                                    color:
                                                                        "#ee5050",
                                                                }}
                                                            >
                                                                {checkTextTranslation(
                                                                    this.props
                                                                        .errors[
                                                                        header
                                                                            .key
                                                                    ]
                                                                )}
                                                            </small>
                                                        </div>
                                                    )}

                                                {this.state.errors &&
                                                    this.state.errors[
                                                        header.key
                                                    ] && (
                                                        <div className="col-12 mt-1 mld-2 text-start">
                                                            <small
                                                                style={{
                                                                    color:
                                                                        "#ee5050",
                                                                }}
                                                            >
                                                                {checkTextTranslation(
                                                                    this.state
                                                                        .errors[
                                                                        header
                                                                            .key
                                                                    ]
                                                                )}
                                                            </small>
                                                        </div>
                                                    )}
                                            </div>
                                        </div>
                                    );
                                }
                            })}
                        {this.props.errors &&
                            typeof this.props.errors == "string" && (
                                <div className="col-12 text-start">
                                    <small style={{ color: "#ee5050" }}>
                                        {checkTextTranslation(
                                            this.props.errors
                                        )}
                                    </small>
                                </div>
                            )}
                    </div>
                </section>
            );
        } else {
            return <div></div>;
        }
    }
}

export default FormViewer;
