import React from "react";


class RippleButton extends React.Component {
    state = {
        isFocuced: false
    }




    rippleHover = (event) => {
        var notes = this.buttonBack;

        let rect = this.button?.getBoundingClientRect()
        let top = event.clientY - rect.top
        let left = event.clientX - rect.left

        if (notes) {
            notes.style.top = top + 'px'
            notes.style.left = left + 'px'
            notes.style.transition = ' width 1s, height 1s'
            // notes.style.transform = 'translate(-50%,-50%)'
            // notes.style.width = "500px"

            notes.style.width = "1200px"
            notes.style.height = "1200px"
            notes.style.background = "#677dc4"


        }

    }

    rippleOut = (event) => {

        var notes = this.buttonBack;


        let rect = this.button?.getBoundingClientRect()
        let top = event.clientY - rect.top
        let left = event.clientX - rect.left

        if (notes) {
            notes.style.transition = ' width 0.5s, height 0.5s'

            notes.style.top = top + 'px'
            notes.style.left = left + 'px'

            notes.style.width = "0px"
            notes.style.height = "0px"
            // notes.style.background = "transparent"


        }

    }


    render() {

        return (
            <button ref={el => this.button = el} class="ripple-but" onMouseEnter={e => this.rippleHover(e)} onMouseLeave={e => this.rippleOut(e)} >
                <div ref={el => this.buttonBack = el} class="ripple-back"></div>
                <span className="position-relative text-small">{this.props.label}</span>
                {/* style={{ backgroundColor: '#677dc4', padding: '10px 15px', color: '#fff', textAlign: 'center', width: '100%', borderRadius: 8, fontSize: 16 }} */}
            </button>

        )
    }
}






export default RippleButton;
