import React from 'react'
import { siteConfig, siteTheme } from "../variables/config";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actions from '../stores/actionsList';
import FastHelmet from './FastHelmet'
import i18n from 'i18next';
import { Link } from 'react-router-dom'

class Configurer extends React.Component {

    lastSettings = {}

    componentDidMount() {

        if (this.props.settingsInfo) {
            for (const [key, value] of Object.entries(this.props.settingsInfo)) {
                this.lastSettings[key] = this.props.settings[key]
                this.props.actions.changeSetting(key, value)
            }
        }
    }

    componentWillUnmount() {
        if (this.props.changeOnUnmount) {
            let changeConfig = this.props.parentConfigure ?? this.lastSettings

            if (changeConfig) {
                for (const [key, value] of Object.entries(changeConfig)) {
                    // this.lastSettings[key] = value
                    this.props.actions.changeSetting(key, value)
                }
            }
        }
    }


    render() {

        return (
            <div className={this.props.className} style={this.props.style}>
                <FastHelmet title={this.props.title} description={this.props.description} />
                {this.props.children}
            </div>
        )
    }
}

const mapStateToProps = state => ({ settings: state.settings, user: state.user })
const mapDispatchToProps = dispatch => ({ actions: bindActionCreators(actions, dispatch) })

export default connect(
    mapStateToProps,
    mapDispatchToProps,
    null,
    { forwardRef: true }
)(Configurer);
