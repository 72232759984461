import React from "react";
import TLink from "../../components/TLink";


class ThingDevelopers extends React.Component {

    state = {
 
    }



    render() {
        return (
            <section className="thingInfoPage">

                <div className="w-100 pt-4 px-5 mb-4 pb-5">
                   
                </div>
            </section>

        )
    }


}


export default ThingDevelopers
