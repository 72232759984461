import React from 'react'
import LoaderButton from '../../components/LoaderButton'
import FormViewer from '../../components/FormViewer'
import { chooseWallpaper, translate } from "../../utils/useful";
import TLink from '../../components/TLink';

// import HttpService from '../../utils/Http.services';

class AddWikiInfo extends React.Component {
    state = {
        key: '',
        selectedCountry: {},
        resendTime: 60,
        resendRequested: false,

        formHeaders: [
            // { key: 'wikiId', type: 'AdvancedSelectInput', information: { label: '{{lang}}texts.addThing.findThing', isSearchable: true, icon: '/images/search.svg', placeholder: '{{lang}}texts.addThing.findThing', required: true } }
            { type: 'SelectInput', key: 'brand', information: { label: '{{lang}}brand', address: 'getValuesWithData', filter: { page: 'brands' }, fields: { title: 'values.name', value: '_id' }, type: 'api', placeholder: '{{lang}}insertBrand', required: true } },
            { type: 'TextInput', key: 'partNumber', information: { label: '{{lang}}texts.thing.partNumber', placeholder: '{{lang}}placeholders.insertPartNumber', required: true } },
            { type: 'SelectInput', key: 'type', information: { label: '{{lang}}useful.type', type: 'local', items: [{ value: 'Gateway', title: 'Gateway' }, { value: 'Sensor', title: 'Sensor' }, { value: 'Actuator', title: 'Actuator' }], placeholder: '{{lang}}placeholders.insertType', required: true } },
            { type: 'MultiSelectInput', key: 'categories', information: { label: '{{lang}}categories', address: 'getValuesWithData', filter: { page: 'categories' }, fields: { title: 'values.name', value: '_id' }, type: 'api', isSearchable: true, placeholder: '{{lang}}placeholders.insertCategories', required: true } },
            { type: 'TextInput', key: 'name', information: { label: '{{lang}}texts.thing.name', placeholder: '{{lang}}placeholders.insertName', required: true } },
            { type: 'ImageInput',key: 'image',  col: '12', information: { label: '{{lang}}image', single: false, cropper: false, placeholder: '{{lang}}chooseImage', required: true } },

            // { type: 'TextInput', key: 'uuid', information: { label: '{{lang}}texts.thing.uuid', placeholder: '{{lang}}placeholders.insertUUID', required: false } },
            // { type: 'TextInput', key: 'ckey1', information: { label: '{{lang}}texts.thing.ckey1', placeholder: '{{lang}}placeholders.insertckey', required: false } },
            // { type: 'TextInput', key: 'ckey2', information: { label: '{{lang}}texts.thing.ckey2', placeholder: '{{lang}}placeholders.insertckey', required: false } },

        ]
    }

    componentDidMount() {
        // this.changeResendTime()
    }



    postActicationCode = () => {
        this.setState({ errors: {} })
        let data = this.form.getForm()
        if (data) {
            this.setState({ isLoading: true})
            this.props.setData(data, () => {
                this.props.changeStage('structure')
                window.scrollTo(0,0)
                // this.props.postActicationCode((result, err) => {
                //     if (err) { this.setState({ errors: err }) }
                //     this.setState({ isLoading: false })
                // })
            })
        }
    }

   



    render() {
        return (

            <div className="flexcc flex-column">

                <img src="https://www.flaticon.com/svg/static/icons/svg/300/300231.svg" height="60px" />
                <h2 className="mt-3 mb-0 text-big">{translate('information').toUpperCase()}</h2>
                <div className="w-100" style={{ maxWidth: 400 }}>

                    <p className="text-normal mt-1 text-center mb-3" >{translate('useful.fillForm')}</p>


                    <FormViewer ref={el => this.form = el} headers={this.state.formHeaders} initData={this.props.initData} errors={this.state.errors} inputClass={'modern-input'} />

                    <div className="text-center">
                        <LoaderButton
                            onClick={this.postActicationCode}
                            isLoading={this.state.isLoading}
                            text={translate('useful.nextStep')}
                            type={"Oval"}
                            className="mt-4 mb-4"
                            buttonStyle={{ outline: 'none', backgroundColor: '#222', cursor: 'pointer', padding: '10px 50px', borderRadius: 4, fontSize: 15, fontWeight: 'bold', color: '#fff', border: 'none' }}
                            width={40}
                            height={40}
                            color={'#202020'}
                        />

                        <div>
                            <button onClick={() => this.props.changeStage('init')} style={{ fontSize: 13, opacity: 0.5, marginTop: 0 }}>{translate('useful.backOneStep')}</button>
                        </div>

                    </div>
                </div>
            </div>


        )
    }
}

export default AddWikiInfo;