import React from "react";
import { getBoundingClientRect } from "../../utils/functions";


class DiagramComponent extends React.Component {

    state = {
        showDesciption: false,
        node: [

        ]
    }

    componentDidMount() {
        setTimeout(() => {
            this.setNodesPosition()
        }, 5);
    }

    setNodesPosition() {
        let data = this.props.data
        let nodes = data.nodes
        // 
        let newNodes = {}
        let containerPosition = this.container?.getBoundingClientRect()
        let contentPosition = this.content?.getBoundingClientRect()
        this.setState({ height: contentPosition.height })
        let componentOffsetX = containerPosition.x + ((containerPosition.width / 2))//*this.props.zoom)//data.positionX
        let componentOffsetY = containerPosition.y + ((containerPosition.height / 2))//*this.props.zoom)//data.positionY


        Object.values(nodes).forEach(node => {
            let key = node.key
            let newNode = {}
            if (this[key]) {
                let nodePosition = getBoundingClientRect(this[key])
                // 
                // 
                // 

                newNode.relativePosition = {
                    width: nodePosition.width,
                    height: nodePosition.height,
                    x: Math.round((nodePosition.x - componentOffsetX + (nodePosition.width / 2)) / this.props.zoom),
                    y: Math.round((nodePosition.y - componentOffsetY + (nodePosition.height / 2)) / this.props.zoom),

                }

                newNode = { ...newNode, ...node }
                newNodes[key] = newNode
            }
        });

        this.props.setNodesPosition(this.props.data?.key, newNodes, { width: contentPosition?.width, height: contentPosition?.height })

    }

    nodeMouseDown(prop, nprop, e) {
        this.props.nodeMouseDown(prop, nprop, e)
    }

    onClick = () => {

        if (this.isOntime) {
            this.isOntime = false
            this.props.openMore(this.props.data.key, true)
        } else {
            // if(this.state.showDesciption){

            // }else{

            // }
            // this.setState({ showDesciption: !this.state.showDesciption }, () => {
            // if (this.state.showDesciption) {
            this.isOntime = true
            setTimeout(() => {
                if (this.isOntime) {
                    this.setState({ showDesciption: !this.state.showDesciption })
                }
                this.isOntime = false
            }, 300);
            //     }
            // })
        }

    }

    onMouseEnter = () => {
        if (this.label && this.labelContainer) {
            this.label.style.width = 'auto'
            this.label.style.overflow = 'visible'
            let labelWidth = this.label?.getBoundingClientRect().width
            let mainWidth = this.labelContainer?.getBoundingClientRect().width
            let moveWidth = labelWidth - mainWidth
            if (moveWidth > 0) {
                this.label.style.transition = 'left 1.5s'
                this.label.style.left = (-1 * moveWidth) + 'px'
                this.timer = setTimeout(() => {
                    this.onMouseLeave(true)
                }, 3000);
            }
        }
    }

    onMouseLeave = (isFake) => {
        if (this.label) {

            this.label.style.transition = 'left 0.5s'
            this.label.style.left = '0px'
            if (isFake) {
                this.timer = setTimeout(() => {
                    this.onMouseEnter(true)
                }, 2500);
            } else {
                this.label.style.width = '100%'
                this.label.style.overflow = 'hidden'
                if (this.timer) {
                    clearTimeout(this.timer)
                }
            }

        }
    }



    render() {
        let prop = this.props.data
        let thisFunction = this.props.thisFunction
        return (
            <div className="flexc flex-column" ref={el => this.container = el} style={{ zIndex: prop.key === this.props.draggingId ? 4 : null, padding: 30, position: 'absolute', transform: 'translate(-50%,-50%)', pointerEvents: 'none' }}>


                <div onMouseEnter={() => this.onMouseEnter()} onMouseLeave={() => this.onMouseLeave()} className="d-flex" style={{ position: 'relative' }}>


                    <div onClick={() => this.onClick()} draggable onDragEnd={(e) => this.props.dragEnd()} onDrag={(e) => this.props.onDrag(e)} onDragStart={(e) => this.props.onDragStart(e, prop, 'component')} style={{ pointerEvents: 'all', borderRadius: prop.UI?.borderRadius, position: 'absolute', top: 0, left: 0, cursor: 'move', padding: 0, width: '100%', height: '100%' }}>
                    </div>

                    {/* {prop.UI?.type === 'trigger' && (
                        <>
                            <div draggable onDragEnd={(e) => this.props.dragEnd()} onDrag={(e) => this.props.onDrag(e)} onDragStart={(e) => this.props.onDragStart(e, prop, 'component')} style={{ pointerEvents: 'all', borderRadius: 100, position: 'absolute', top: 0, left: 0, cursor: 'move', padding: 0, width: '100%', height: '100%' }}>
                            </div>

                            <div ref={el => this.content = el} className=" flexcc" style={{ border: '2px solid ' + prop.UI?.bodyColorS, backgroundImage: 'linear-gradient(' + prop.UI?.bodyColorS + ',' + prop.UI?.bodyColorE + ')', borderRadius: 100, padding: 0, width: 120, height: 120, boxShadow: '0px 0px 30px #00000015' }}>

                                <div className="d-flex " >


                                    <div className="flexcc flex-column text-center">
                                        <img className={(prop.UI?.showIcon ? "invert" : '')} src={(prop.UI?.showIcon ?? thisFunction?.icon)} width="34px" />
                                        <p className="white text-smaller mt-2 w-100 text-bold" style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden', maxWidth: 110 }}>{prop?.title.toUpperCase()}</p>
                                        <button onClick={() => this.props.openMore(this.props.data.key)} className="m-0 flexcc moreBut rotate-90 p-0">
                                            <img className="invert" draggable={false} src={'/images/more.svg'} height="22px" />
                                        </button>
                                    </div>


                                    <div className="" style={{ position: 'absolute', top: 60, right: 0, transform: 'translateY(-50%)' }} >
                                        <div >
                                            {Object.values(prop.nodes)?.map((nprop, index) => {
                                                if (nprop.position == 'right') {
                                                    return (
                                                        <div key={index} className="flexc mb-2 mt-1" style={{ justifyContent: 'flex-end', marginRight: -8 }}>
                                                            <div ref={el => this[nprop.key] = el} className="flexcc" style={{ zIndex: 7 }}>
                                                                <button onMouseDown={(e) => this.nodeMouseDown(prop, nprop, e)} className="contactBut">
                                                                </button>
                                                            </div>

                                                        </div>
                                                    )
                                                }
                                            })}
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </>
                    )} */}







                    {/* {prop.UI?.type === 'action' && (
                        <>
                            <div draggable onDragEnd={(e) => this.props.dragEnd()} onDrag={(e) => this.props.onDrag(e)} onDragStart={(e) => this.props.onDragStart(e, prop, 'component')} style={{ pointerEvents: 'all', borderRadius: 100, position: 'absolute', top: 0, left: 0, cursor: 'move', padding: 0, width: '100%', height: '100%' }}>
                            </div>

                            <div className=" flexcc" style={{ border: '2px solid ' + prop.UI?.bodyColorS, backgroundImage: 'linear-gradient(' + prop.UI?.bodyColorS + ',' + prop.UI?.bodyColorE + ')', borderRadius: 100, padding: 0, width: 120, height: 120, boxShadow: '0px 0px 30px #00000015' }}>

                                <div className="d-flex " >


                                    <div className="flexcc flex-column text-center">
                                        <img className={(prop.UI?.showIcon ? "invert" : '')} src={(prop.UI?.showIcon ?? thisFunction?.icon)} width="34px" />
                                        <p className="white text-smaller mt-2 w-100 text-bold" style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden', maxWidth: 110 }}>{prop?.title.toUpperCase()}</p>
                                        <button onClick={() => this.props.openMore(this.props.data.key)} className="m-0 flexcc moreBut rotate-90 p-0">
                                            <img className="invert" draggable={false} src={'/images/more.svg'} height="22px" />
                                        </button>
                                    </div>


                                    <div className="" style={{ position: 'absolute', top: 60, left: 0, transform: 'translateY(-50%)' }} >
                                        <div >
                                            {Object.values(prop.nodes)?.map((nprop, index) => {
                                                if (nprop.position == 'left') {
                                                    return (
                                                        <div key={index} className="flexc mb-2 mt-1" style={{ justifyContent: 'flex-end', marginLeft: -8 }}>
                                                            <div ref={el => this[nprop.key] = el} className="flexcc" style={{ zIndex: 7 }}>
                                                                <button onMouseDown={(e) => this.nodeMouseDown(prop, nprop, e)} className="contactBut">
                                                                </button>
                                                            </div>

                                                        </div>
                                                    )
                                                }
                                            })}
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </>
                    )} */}







                    {prop.UI?.type === 'condition' && (
                        <>
                            {/* <div draggable onDragEnd={(e) => this.props.dragEnd()} onDrag={(e) => this.props.onDrag(e)} onDragStart={(e) => this.props.onDragStart(e, prop, 'component')} style={{ pointerEvents: 'all', borderRadius: prop.UI?.borderRadius, position: 'absolute', top: 0, left: 0, cursor: 'move', padding: 0, width: '100%', height: '100%' }}>
                            </div> */}
                            <div ref={el => this.content = el} className="pb-2 " style={{ position: 'relative', backgroundImage: 'linear-gradient(' + prop.UI?.bodyColorS + ',' + prop.UI?.bodyColorE + ')', border: '0px solid ' + thisFunction?.UI?.bodyColorS, borderRadius: prop.UI?.borderRadius, padding: 0, width: prop.UI?.width, boxShadow: '0px 0px 30px #00000015' }}>
                                <div className="w-100 flexcb py-2 mt-0 px-2" style={{ backgroundColor: thisFunction?.UI?.headerColor, borderRadius: '10px 10px 0px 0px' }}>
                                    <div className="flexcc " style={{ maxWidth: prop.UI?.width - 37 }}>
                                        <img className={(prop.UI?.showIcon ? "invert" : '')} src={(prop.UI?.showIcon ?? thisFunction?.icon)} width="20px" />
                                        {/* <p className="white text-smaller mx-1 text-bold ">{prop?.title?.toUpperCase()}</p> */}
                                        <div ref={el => this.labelContainer = el} className="w-100 mx-1" style={{ overflow: 'hidden', position: 'relative' }}>
                                            <p className=" text-smaller  text-bold" style={{ color: 'transparent', whiteSpace: 'nowrap' }}>{prop?.title.toUpperCase()}</p>
                                            <p ref={el => this.label = el} className="white text-smaller   text-bold" style={{ textOverflow: 'ellipsis', overflow: 'hidden', width: '100%', whiteSpace: 'nowrap', position: 'absolute', left: 0, top: 0, transition: 'left 2s' }}>{prop?.title.toUpperCase()}</p>
                                        </div>
                                    </div>
                                    <div>
                                        <button onClick={() => this.props.openMore(this.props.data.key)} className="flexcc moreBut px-0 py-1 " style={{}}>
                                            <img className="invert rotate-90" draggable={false} src={'/images/more.svg'} height="16px" />
                                        </button>
                                    </div>
                                    {/* <button onClick={() => this.props.openMore(this.props.data.key)} className="m-0 flexcc pl-1 pr-0 moreBut" style={{position:'absolute',top:10,left:10}}>
                                        <img draggable={false} className="invert" src={'/images/more.svg'} height="14px" />
                                    </button> */}
                                </div>




                                <div className="d-flex mt-0" style={{ justifyContent: 'space-between' }}>


                                    <div className="" style={{ position: 'relative' }}>
                                        <div >
                                            {Object.values(prop.nodes)?.map((nprop, index) => {
                                                if (nprop.position == 'left') {
                                                    return (
                                                        <div key={index} className="flexc mb-2 mt-1" style={{ justifyContent: 'flex-start', marginLeft: -8 }}>
                                                            <div ref={el => this[nprop.key] = el} className="flexcc" style={{ zIndex: 7 }}>
                                                                <button onMouseDown={(e) => this.nodeMouseDown(prop, nprop, e)} className="contactBut">
                                                                </button>
                                                            </div>
                                                            <p className=" text-bold mx-1 white opacity-7" style={{ fontSize: 11 }}>{nprop.label?.toUpperCase()}</p>

                                                        </div>
                                                    )
                                                }
                                            })}
                                        </div>
                                    </div>


                                    <div className="" style={{ position: 'relative' }} >
                                        <div >
                                            {Object.values(prop.nodes)?.map((nprop, index) => {
                                                if (nprop.position == 'right') {
                                                    return (
                                                        <div key={index} className="flexc mb-2 mt-1" style={{ justifyContent: 'flex-end', marginRight: -8 }}>
                                                            <p className=" text-bold mx-1 white opacity-7" style={{ fontSize: 11 }}>{nprop.label?.toUpperCase()}</p>
                                                            <div ref={el => this[nprop.key] = el} className="flexcc" style={{ zIndex: 7 }}>
                                                                <button onMouseDown={(e) => this.nodeMouseDown(prop, nprop, e)} className="contactBut">
                                                                </button>
                                                            </div>

                                                        </div>
                                                    )
                                                }
                                            })}
                                        </div>


                                    </div>
                                </div>


                                {/* <div className="flexcc mb-1">
                                    <button onClick={() => this.props.openMore(this.props.data.key)} className="m-0 flexcc moreBut  " style={{ backgroundColor: '#ffffff20', borderRadius: 20, padding: '2px 10px' }}>
                                        <img className="invert rotate-90" draggable={false} src={'/images/more.svg'} height="22px" />
                                    </button>
                                </div> */}


                                {/* <div className="px-2 mt-1">
                                    <p className="white" style={{ opacity: 0.8, fontSize: 11 }}>This is the description of this component</p>
                                </div> */}

                                {/* <div className="px-2 pt-0 pb-1">
                            <p className="text-smaller mt-1 text-bold" style={{opacity:0.5}}>PERMISSIONS</p>
                            <p className="text-smaller" style={{opacity:0.5}}>User admin marketing</p>
                        </div> */}
                            </div>
                        </>
                    )}





                    {prop.UI?.type === 'circle' && (
                        <>
                            {/* <div draggable onDragEnd={(e) => this.props.dragEnd()} onDrag={(e) => this.props.onDrag(e)} onDragStart={(e) => this.props.onDragStart(e, prop, 'component')} style={{ pointerEvents: 'all', borderRadius: prop.UI?.borderRadius, position: 'absolute', top: 0, left: 0, cursor: 'move', padding: 0, width: '100%', height: '100%' }}>
                            </div> */}

                            {/* <div className=" flexcc" style={{ backgroundColor: prop.UI?.bodyColor, borderRadius: 100, padding: 0, width: 130, height: 130, boxShadow: '0px 0px 30px #00000015' }}> */}
                            <div ref={el => this.content = el} className=" flexcc" style={{ position: 'relative', border: '2px solid ' + prop.UI?.bodyColorS, backgroundImage: 'linear-gradient(' + prop.UI?.bodyColorS + ',' + prop.UI?.bodyColorE + ')', borderRadius: prop.UI?.borderRadius, padding: 0, width: prop.UI?.width, height: prop.UI?.width, boxShadow: '0px 0px 30px #00000015' }}>

                                <div className="d-flex " style={{ height: '78%' }} >

                                    <div className="d-flex flex-column text-center h-100" style={{ alignItems: 'center', }}>
                                        <div>
                                            <img className={"mt-1 " + (prop.UI?.showIcon ? "invert" : '')} src={(prop.UI?.showIcon ?? thisFunction?.icon)} height={prop.UI?.width / 3.5 + 'px'} />
                                        </div>
                                        <div className="flexcc flex-column" >
                                            <div ref={el => this.labelContainer = el} className="w-100" style={{ maxWidth: prop.UI?.width - 16, overflow: 'hidden', position: 'relative' }}>
                                                <p className="  text-bold" style={{ color: 'transparent', whiteSpace: 'nowrap', fontSize: 11 }}>{prop?.title.toUpperCase()}</p>
                                                <p ref={el => this.label = el} className="white  text-bold" style={{ textOverflow: 'ellipsis', overflow: 'hidden', width: '100%', whiteSpace: 'nowrap', fontSize: 11, position: 'absolute', left: 0, top: 0, transition: 'left 2s' }}>{prop?.title.toUpperCase()}</p>
                                            </div>
                                            <button onClick={() => this.props.openMore(this.props.data.key)} className="m-0 flexcc moreBut  p-0"  >
                                                <img className="invert rotate-90" draggable={false} src={'/images/more.svg'} height="22px" />
                                            </button>

                                        </div>
                                    </div>


                                    <div className="" style={{ position: 'absolute', top: prop.UI?.width / 2, right: 0, transform: 'translateY(-50%)' }} >
                                        <div >
                                            {Object.values(prop.nodes)?.map((nprop, index) => {
                                                if (nprop.position == 'right') {
                                                    return (
                                                        <div key={index} className="flexc mb-2 mt-1" style={{ justifyContent: 'flex-end', marginRight: -8 }}>
                                                            {/* <p className="text-smallest mx-1">{nprop.label}</p> */}
                                                            <div ref={el => this[nprop.key] = el} className="flexcc" style={{ zIndex: 7 }}>
                                                                <button onMouseDown={(e) => this.nodeMouseDown(prop, nprop, e)} className="contactBut">
                                                                </button>
                                                            </div>

                                                        </div>
                                                    )
                                                }
                                            })}

                                        </div>
                                    </div>



                                    <div className="" style={{ position: 'absolute', top: prop.UI?.width / 2, left: 0, transform: 'translateY(-50%)' }} >
                                        <div >

                                            {Object.values(prop.nodes)?.map((nprop, index) => {
                                                if (nprop.position == 'left') {
                                                    return (
                                                        <div key={index} className="flexc mb-2 mt-1" style={{ justifyContent: 'flex-end', marginLeft: -8 }}>
                                                            {/* <p className="text-smallest mx-1">{nprop.label}</p> */}
                                                            <div ref={el => this[nprop.key] = el} className="flexcc" style={{ zIndex: 7 }}>
                                                                <button onMouseDown={(e) => this.nodeMouseDown(prop, nprop, e)} className="contactBut">
                                                                </button>
                                                            </div>

                                                        </div>
                                                    )
                                                }
                                            })}
                                        </div>


                                    </div>
                                </div>

                            </div>
                        </>
                    )}





                    {!prop.UI?.type && (
                        <>
                            <div draggable onDragEnd={(e) => this.props.dragEnd()} onDrag={(e) => this.props.onDrag(e)} onDragStart={(e) => this.props.onDragStart(e, prop, 'component')} style={{ pointerEvents: 'all', borderRadius: 100, position: 'absolute', top: 0, left: 0, cursor: 'move', padding: 0, width: '100%', height: '100%' }}>
                            </div>
                            <div ref={el => this.content = el} className="pb-3 " style={{ backgroundColor: thisFunction?.bodyColor, border: '1px solid ' + thisFunction?.headerColor + '20', borderRadius: 6, padding: 0, width: 180, boxShadow: '0px 0px 30px #00000015' }}>
                                <div className="w-100 flexcb p-2" style={{ backgroundColor: thisFunction?.headerColor, borderRadius: '5px 5px 0px 0px' }}>
                                    <div className="flexc">
                                        <img src={thisFunction?.icon} width="16px" />
                                        <p className="white text-smaller mx-1">{prop?.title}</p>
                                    </div>
                                    <button onClick={() => this.props.openMore(this.props.data.key)} className="m-0 flexcc pl-1 pr-0 moreBut">
                                        <img draggable={false} className="invert" src={'/images/more.svg'} height="14px" />
                                    </button>
                                </div>




                                <div className="d-flex mt-2" style={{ justifyContent: 'space-between' }}>


                                    <div className="" style={{ position: 'relative' }}>
                                        <div >
                                            {Object.values(prop.nodes)?.map((nprop, index) => {
                                                if (nprop.position == 'left') {
                                                    return (
                                                        <div key={index} className="flexc mb-2 mt-1" style={{ justifyContent: 'flex-start', marginLeft: -8 }}>
                                                            <div ref={el => this[nprop.key] = el} className="flexcc" style={{ zIndex: 7 }}>
                                                                <button onMouseDown={(e) => this.nodeMouseDown(prop, nprop, e)} className="contactBut">
                                                                </button>
                                                            </div>
                                                            <p className="text-smallest mx-1">{nprop.label}</p>

                                                        </div>
                                                    )
                                                }
                                            })}
                                        </div>
                                    </div>


                                    <div className="" style={{ position: 'relative' }} >
                                        <div >
                                            {Object.values(prop.nodes)?.map((nprop, index) => {
                                                if (nprop.position == 'right') {
                                                    return (
                                                        <div key={index} className="flexc mb-2 mt-1" style={{ justifyContent: 'flex-end', marginRight: -8 }}>
                                                            <p className="text-smallest mx-1">{nprop.label}</p>
                                                            <div ref={el => this[nprop.key] = el} className="flexcc" style={{ zIndex: 7 }}>
                                                                <button onMouseDown={(e) => this.nodeMouseDown(prop, nprop, e)} className="contactBut">
                                                                </button>
                                                            </div>

                                                        </div>
                                                    )
                                                }
                                            })}
                                        </div>
                                    </div>



                                </div>


                                {/* <div className="px-2 mt-1">
                                    <p className="" style={{ opacity: 0.5, fontSize: 11 }}>This is the description of this component</p>
                                </div> */}

                                {/* <div className="px-2 pt-0 pb-1">
                            <p className="text-smaller mt-1 text-bold" style={{opacity:0.5}}>PERMISSIONS</p>
                            <p className="text-smaller" style={{opacity:0.5}}>User admin marketing</p>
                        </div> */}
                            </div>
                        </>
                    )}


                </div>

                {prop?.configuration?.description && (
                    <div className="px-2 w-100  flexcc text-center " style={{ position: 'absolute', top: this.state.height + 36, pointerEvents: 'none', maxWidth: prop.UI?.width ? prop.UI?.width + 30 : 140, transition: 'all 0.3s', opacity: this.state.showDesciption ? 1 : 0 }}>
                        <div className="blur-back" style={{ backgroundColor: '#001020aa', borderRadius: 6, padding: '5px 5px' }}>
                            <p className="white" style={{ opacity: 0.8, fontSize: 11, whiteSpace: 'pre-wrap' }}>{prop?.configuration?.description}</p>
                        </div>
                    </div>
                )}



            </div>
        )
    }
}



export default DiagramComponent;
