import React from "react";


class ThingHistoryLocation extends React.Component {

    state = {
       
    }

    render() {
        return (

            <div className={'thingInfoList mt-3'}>

                <button onClick={() => this.props.changeStage("main")} className="flexc mb-3">
                    <img className="back mrd-2" src="/images/next-2.png" width="14px" alt="" />
                    <span> Back to list</span>
                </button>
                
            </div>

        )
    }


}


export default ThingHistoryLocation
