import React from "react";
import HttpService from '../utils/Http.services';
import { siteConfig, siteTheme } from "../variables/config";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actions from '../stores/actionsList';
// import TabBar from "../views/TabBar";
import Configurer from "../components/Configurer";
import SmartInput from "../components/inputs/SmartInput";
import TLink from "../components/TLink";
import { checkTextTranslation } from "../utils/useful";
import Modal from "../components/Modal";
import Loader from "react-loader-spinner";
import moment from "jalali-moment";


class History extends React.Component {

    state = {
      
      
    }

    componentDidMount() {
        this.fetchHistory()
    }


    fetchHistory = () => {
        this.setState({ isLoadingHistory: true })

        HttpService.request("getHistory", {}, (fetchResult, fetchError) => {
            this.setState({ isLoadingHistory: false })

            if (fetchError) {
                
                return
            }

            
            this.setState({ history: fetchResult.info })
        })
    }

    openOptions = (prop) => {

        this.optionsModal.hideModal()
        if (this.optionsModal.getStatus()) {

            setTimeout(() => {
                this.setState({ selectedModule: prop })
                this.optionsModal.showModal()
            }, 500);
        } else {
            this.setState({ selectedModule: prop })
            this.optionsModal.showModal()

        }

    }

    openAddNew = () => {
        this.addModal.showModal()
    }

    render() {
        return (

            <Configurer
                settingsInfo={{ showFooter: true, showTabBar: true, showHeader: true, headerTitle: "Dashboard", button: {} }}
                title={"History"}
                description={"History Description"}
                className="px-4 py-5"
            >

                <div className="container">

                   

                    <div className="row">

                        <div className="col-12 mb-2">
                            <h1 className="text-big text-center mb-4">{"Models History".toUpperCase()}</h1>
                        </div>

                        {this.state.isLoadingHistory ? (
                            <div className="flexcc mx-2 pt-2 w-100">
                                <Loader
                                    type={"Oval"}
                                    color={"rgb(57,111,180)"}
                                    height={50}
                                    width={50}
                                />
                            </div>
                        ) : (

                            <div className="col-12 col-md-12 order-2 order-md-1 " style={{ color: '#567' }}>

                                {!this.state.history || this.state.history.length == 0 ? (
                                    <div className="px-5 py-5 text-center flexcc flex-column" style={{ backgroundColor: '#f2f6f8', borderRadius: 15 }}>

                                        <img className="mb-2" width="80px" src={"/images/icons/history.svg"} />
                                        <p className="text-big mb-2 mt-2 text-bold" >HISTORY IS EMPTY</p>
                                        <p className="text-small" style={{ width: 420 }}>From top menu you should start a new model. You can then view the history of your solved models in this section</p>
    
                                    </div>

                                ) : (
                                    <div className={'row'}>
                                        {this.state.history.map((prop, index) => {
                                            // 
                                            return (
                                                <TLink to={'/editor/' + prop.MODEL_NAME_V_SIM} key={index} className="text-start col-12 col-md-6 col-lg-4 mb-4" >
                                                    <div className="h-100 w-100 py-3 px-4 flexc " style={{ backgroundColor: '#f2f6f8', borderRadius: 8 }}>
                                                        <img className="mb-2" width="50px" src={"/images/icons/history.svg"} />
                                                        <div className="mld-4">
                                                            <p className="text-semibig font-bold" style={{ color: '#101010' }}>{prop.MODEL_NIKE_NAME_V_SIM}</p>
                                                            <p className="text-smaller" style={{ color: '#101010',wordBreak:'break-all' }}>{prop.MODEL_TEXT_V_SIM.substring(0,100)}</p>
                                                            <div className="flexc">
                                                            <p className="text-smaller mt-1" style={{whiteSpace:'nowrap'}}>Variables: {prop.K_V_SIM}</p>
                                                            <p className="text-smaller mt-1 mx-2" style={{whiteSpace:'nowrap'}}>Constraints: {prop.M_V_SIM}</p>
                                                            </div>
                                                            <p className="text-smaller mt-1">Update date: {moment(prop.DATE_DUE_V_SIM * 1000).locale('fa').format('jYYYY-jMM-jDD')}</p>
                                                        </div>
                                                    </div>
                                                </TLink>
                                            )
                                        })}
                                    </div>
                                )}

                            </div>

                        )}

                        
                    </div>





                </div>

            </Configurer>
        )
    }


}



const mapStateToProps = state => ({ settings: state.settings, user: state.user })
const mapDispatchToProps = dispatch => ({ actions: bindActionCreators(actions, dispatch) })

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(History);
