import { createStore, applyMiddleware, compose } from 'redux';
import { persistedState, saveState } from './preloadedState.js';
import reducers from './reducersList';
import middlewares from './middlewaresList'


const store = createStore(
    reducers,
    persistedState,
    applyMiddleware(...middlewares),
    // window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__(applyMiddleware(
    //     ...middlewares,
    // )),
);

store.subscribe(() => {
    saveState(store.getState());
});


export default store;