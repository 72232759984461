import React from 'react'


class Draggable extends React.Component {


    onDragOver = (e) => {
        // 
        // e.preventDefault()

        // if (!this.state.isDragging) {
            // 
            e.preventDefault()
            // this.setState({ isDraggingOver: false })

            this.props.dragOver(this.props.id)
        // }

        // setIsOnDragOver(true)
    }
    

    onDragStart = (e) => {
        // e.preventDefault()
        // 
        // e.dataTransfer.setDragImage(this.draggableItem,0,0);
        // 
        e.dataTransfer.setData('id', this.props.id)
        this.props.dragStarted(this.props.id)
        // this.setState({ isDragging: true })
        // this.dragIndicator.addClass('hide')

        // 

        // this.props.cloneRow(row.current)
    }

    onDrop = (e) => {
        // e.preventDefault()
        let id = e.dataTransfer.getData('id', this.props.id)
        this.props.onDrop(id)

        // 
        // 
    }

    onDragEnd = (e) => {
        this.props.dragEnd(e)

        // this.dragIndicator.removeClass('hide')
    }

    render() {
        return (
            <div
                className="draggable-component"
                ref={(el) => (this.draggableItem = el)}
                onDrop={(e) => this.onDrop(e, "complete")}
                onDragOver={(e) => this.onDragOver(e)}
                onDragEnd={(e) => this.onDragEnd(e)}
            >
                {this.props.children}

                {this.props?.isDraggable ? (
                    <div
                        className={"drag-indicator"}
                        draggable
                        onDragStart={(e) => this.onDragStart(e)}
                        onDrag={(e) => this.props.onDrag(e)}
                    >
                        {" "}
                    </div>
                ) : null}
            </div>
        );
    }

}

export default Draggable
