import React from "react";
import Configurer from "../../components/Configurer";


class ServiceDetails extends React.Component {

  

    render() {
        return (



            <section className="thingInfoPage" >
                <div className="w-100 pt-5 px-5 mb-4 pb-5">

                    <p>This is the datails of the system</p>


                </div>




            </section>

        )
    }


}


export default ServiceDetails
