import React from "react";
import LoaderButton from "../../components/LoaderButton";
import FormViewer from "../../components/FormViewer";
import TLink from "../../components/TLink";
import { translate } from "../../utils/useful";

class LoginPasswordStep extends React.Component {
    state = {
        errors: {},
        formHeaders: [
            {
                key: "password",
                type: "PasswordInput",
                information: {
                    label: "{{lang}}insertPasword",
                    icon: "/images/pass.svg",
                    placeholder: "{{lang}}insertPasword",
                    required: true,
                },
            },
        ],
    };

    componentDidMount() {
        document.addEventListener("keydown", this._handleKeyDown);
    }

    componentWillUnmount = () => {
        document.removeEventListener("keydown", this._handleKeyDown);
    };

    _handleKeyDown = (e) => {
        if (e.key === "Enter") {
            this.postPassword();
        }
    };

    postPassword = () => {
        let data = this.form.getForm();
        if(data){
            this.setState({ isLoading: true, errors: {} }, () => {
                this.props.setData(data, () => {
                    this.props.postPassword((result, err) => {
                        if (err) {
                            this.setState({ errors: err });
                        }
                        this.setState({ isLoading: false });
                    });
                });
            });
        }
    };

    render() {
        return (
            <div className="flexcc flex-column">
                <h2 className="mt-4 mb-0" style={{ fontSize: 27 }}>
                    {translate("password")}
                </h2>
                <p className="mb-4 mt-0" style={{ fontSize: 15 }}>
                    {translate("insertPasword")}
                </p>

                <div className="w-100" style={{ maxWidth: 400 }}>
                    <FormViewer
                        ref={(el) => (this.form = el)}
                        headers={this.state.formHeaders}
                        initData={this.props.initData}
                        errors={this.state.errors}
                        inputClass={"modern-input"}
                    />

                    <div className="text-center">
                        <LoaderButton
                            onClick={this.postPassword}
                            isLoading={this.state.isLoading}
                            text={translate("send-password")}
                            type={"Oval"}
                            className="mt-4 mb-4"
                            buttonStyle={{
                                outline: "none",
                                backgroundColor: "#222",
                                cursor: "pointer",
                                padding: "10px 50px",
                                borderRadius: 4,
                                fontSize: 15,
                                fontWeight: "bold",
                                color: "#fff",
                                border: "none",
                            }}
                            width={40}
                            height={40}
                            color={"#202020"}
                        />
                    </div>
                    <div className="w-100 text-center">
                        <TLink
                            pathname={"/forgotpassword"}
                            style={{
                                cursor: "pointer",
                                fontSize: 15,
                                border: "none",
                                backgroundColor: "transparent",
                                outline: "none",
                                marginBottom: 10,
                            }}
                        >
                            {translate("forgot-password")}
                        </TLink>
                    </div>
                    <div className="w-100 text-center mt-2">
                        <button
                            className="mb-3"
                            onClick={() => this.props.lastStage()}
                            style={{ fontSize: 13 }}
                        >
                            {translate("back-one-stage")}
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}

export default LoginPasswordStep;
