import React from "react";
import HttpService from '../utils/Http.services';
import { siteConfig, siteTheme } from "../variables/config";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actions from '../stores/actionsList';
import TLink from "../components/TLink";
// import RandomBack from "../components/RandomBack";
import StagesManager from "../components/StagesManager";
import LoginFirstStep from "./login/LoginFirstStep";
import LoginCodeStep from "./login/LoginCodeStep";
import LoginPasswordStep from "./login/LoginPasswordStep";
import LoginInfoStep from "./login/LoginInfoStep";
import LoginWelcomeStep from "./login/LoginWelcomeStep";
import { chooseWallpaper, getToken, pathMaker, translate } from "../utils/useful";
import Configurer from '../components/Configurer'
import AddThingFirstStep from "./addThing/AddThingFirstStep";
import AddThingFromWiki from "./addThing/AddThingFromWiki";
import AddThingGateway from "./addThing/AddThingGateway";
import AddThingServices from "./addThing/AddThingServices";
import AddThingSuccess from "./addThing/AddThingSuccess";
import AddWikiFirstStep from "./addWiki/AddWikiFirstStep";
import AddWikiInfo from "./addWiki/AddWikiInfo";
import AddWikiSuccess from "./addWiki/AddWikiSuccess";
import AddWikiStructure from "./addWiki/AddWikiStructure";

class AddWiki extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      // colors: ['d7e2f7', 'eee1ff', 'fedfe7', 'dad6e4', 'f7dbd7', 'fff2e1'],
      loginInfo: {},
      stage: 0,
      canResend: true,
      errors: [],
      data: {},
      phoneNumber: '',
      settings: { indicatorType: 'phoneNumber', mode: 'login', loginWithCode: false, passwordRequired: true }
    }
  }


  componentDidMount() {
    let settings = this.state.settings
    let pathname = this.props.location.pathname.split('/')
    pathname = pathname[pathname.length - 1]
    if (pathname.toLowerCase() === 'forgotpassword') {
      settings.mode = 'forgotPassword'
    }
    this.setState({ data: { 'userIndicator': '0098', indicatorType: settings.indicatorType, settings } })
    // this.props.actions.changeSetting('hideTabBar', true)
    this.changeStage('init')

  }

  goToInitPage = () => {
    this.changeStage('init')
  }


  // goToPage = (page) => {
  //   this.changeStage(page)
  // }




  postForgotPassword = (cb) => {
    let data = this.state.data
    if (data.userIndicator && data.userIndicator.length >= 5) {
      HttpService.request("postForgotPassword", data, (fetchResult, fetchError) => {
        if (fetchError) { if (cb) { cb(null, fetchError.message) }; return }
        this.changeStage('code')
        if (cb) { cb() }
      })
    } else {
      if (this.state.settings.indicatorType === 'phone') {
        cb(null, { userIndicator: "{{lang}}errors.phoneLength" })
      }
      if (this.state.settings.indicatorType === 'email') {
        cb(null, { userIndicator: "{{lang}}errors.emailPattern" })
      }
    }
  }


  postActicationCode = (cb) => {

    let data = this.state.data
    if (data.userIndicator && data.userIndicator.length >= 0 && data.code && String(data.code).length >= 4) {
      HttpService.request((this.state.data.exist || this.state.settings.mode === "forgotPassword") ? "signinActicationCode" : 'signupActicationCode', data, (fetchResult, fetchError) => {
        if (fetchError) { if (cb) { cb(null, fetchError.message) }; return }
        if (this.state.data.exist || this.state.settings.mode === "forgotPassword") {
          if (this.state.settings.passwordRequired || this.state.settings.mode === "forgotPassword") {
            this.changeStage('password')
          } else {
            // LOGIN
            this.loginDone(fetchResult)
          }
        } else {
          this.changeStage('info')
        }
        if (cb) { cb() }
      })
    } else {
      cb(null, { code: "{{lang}}errors.codeLength" })
    }
  }


  postPassword = (cb) => {

    let data = this.state.data
    if (data.userIndicator && data.userIndicator.length >= 0) {
      HttpService.request(this.state.settings.mode === 'forgotPassword' ? "postNewPassword" : 'postPassword', data, (fetchResult, fetchError) => {
        if (fetchError) { if (cb) { cb(null, fetchError.message) }; return }
        this.loginDone(fetchResult)
        if (cb) { cb() }
      })
    } else {
      cb(null, { code: "{{lang}}errors.codeLength" })
    }
  }


  postUserInfo = (cb) => {
    let data = this.state.data
    if (data.userIndicator && data.userIndicator.length >= 0 && data.code && String(data.code).length >= 4) {
      HttpService.request("postUserInfo", data, (fetchResult, fetchError) => {
        if (fetchError) { if (cb) { cb(null, fetchError.message) }; return }
        this.loginDone(fetchResult)
        if (cb) { cb() }
      })
    } else {
      cb(null, { code: "{{lang}}errors.codeLength" })
    }
  }


  loginDone = (data) => {
    this.setData(data.user)
    // 
    if (data.auth?.token) {
      this.props.actions.setUser(data.user, data.auth, true)
      this.changeStage('welcome')
    }
  }


  setData = (data, cb) => {
    if (data && typeof data == 'object') {
      let newData = this.state.data
      for (const [key, value] of Object.entries(data)) {
        newData[key] = value
      }
      this.setState({ data: newData }, () => {
        if (cb) { cb() }
      })
    }
  }



  changeStage = (newStage, cb) => {
    if (this.stageManager) {
      this.stageManager.changeStage(newStage, cb)
    }
  }

  lastStage = () => {
    if (this.stageManager) {
      this.stageManager.lastStage()
    }
  }



  render() {
    return (

      <Configurer
        settingsInfo={{ showFooter: true, showTabBar: false, showHeader: true, headerTitle: 'Add wiki' }}
        title={"Add Wiki"}
        description={"Add Wiki"}
      >

        <section className="d-flex w-100 position-relative" style={{ minHeight: '100vh', padding: '0px' }}>

          <section className="row w-100 m-0">

            <section className="d-none d-md-block col-12 col-md-5 col-lg-4" style={{ height: '100vh', position: 'sticky', top: 0, padding: '70px 30px 20px 30px', textAlign: 'center', backgroundImage: "url(" + 'https://images.unsplash.com/photo-1451187580459-43490279c0fa?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1652&q=80' + ")", backgroundPosition: 'center', backgroundSize: 'cover' }}>
              <div className="h-100 w-100" style={{ background: '#00000050', position: 'absolute', top: 0, left: 0 }}></div>
              <div style={{ position: 'relative' }}>
                <img className=" mb-2" src={siteTheme.logo} width={'90px'} />
                <h1 className="text-big white">{siteConfig.siteName}</h1>
                <h1 className="text-ultra-big white mt-1" style={{ whiteSpace: 'nowrap' }}>{translate('Add Wiki')}</h1>
                <p className="white opacity-7" style={{ cursor: 'pointer', fontSize: 15, marginTop: 10, }}>{translate('texts.addThing.warning')}</p>
              </div>
            </section>

            <section className="col-12 col-md-7 col-lg-8 pb-5" style={{ opacity: this.state.mainOpacity, transition: 'all 0.5s', flex: '1 1 auto', paddingTop: 70 }}>

              <StagesManager ref={el => this.stageManager = el} >
                <AddWikiFirstStep initData={this.state.data} setData={this.setData} settings={this.state.settings} stage={0} stageName={'init'} info={this.state.loginInfo} />
                <AddWikiInfo initData={this.state.data} setData={this.setData} postUserIndicator={this.postUserIndicator} postActicationCode={this.postActicationCode} stage={1} stageName={'info'} />
                <AddWikiStructure initData={this.state.data} setData={this.setData} postUserInfo={this.postUserInfo} stage={3} stageName={'structure'} />
                <AddWikiSuccess initData={this.state.data} settings={this.state.settings} goToInitPage={this.goToInitPage} stage={4} stageName={'success'} />
              </StagesManager>

            </section>
          </section>
        </section>

      </Configurer>

    )
  }
}


const mapStateToProps = state => ({ settings: state.settings, user: state.user })
const mapDispatchToProps = dispatch => ({ actions: bindActionCreators(actions, dispatch) })

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddWiki);
