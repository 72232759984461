import React from 'react'
import LoaderButton from '../../components/LoaderButton'
import FormViewer from '../../components/FormViewer'
import { imageAddress, translate } from '../../utils/useful'
import NumberInput from '../../components/inputs/NumberInput'
import Modal from '../../components/Modal'
// import HttpService from '../../utils/Http.services';
import XLSX from 'xlsx';
import { siteConfig } from '../../variables/config'
const excelToJson = require('convert-excel-to-json');

class WizardTableStep extends React.Component {
    state = {
        errors: {},

        constraints: [],
        variables: [],
        formHeaders: [
            // { key: 'type', type: 'SelectInput', information: { label: '{{lang}}wizard.type', type: 'local', items: [{ title: 'Max', value: 'max' }, { title: 'Min', value: 'min' }], icon: 'https://image.flaticon.com/icons/svg/13/13305.svg', placeholder: '{{lang}}insert', required: true } },
            { key: 'excel', type: 'FileInput', information: { label: '{{lang}}file', accept: '.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel', single: true, placeholder: '{{lang}}insert', required: true } },

            // { key: 'limitsCount', type: 'NumberInput', information: { label: '{{lang}}wizard.limitsCount', icon: 'https://image.flaticon.com/icons/svg/13/13305.svg', placeholder: '{{lang}}insert', required: true } },
            // { key: 'variablesCount', type: 'NumberInput', information: { label: '{{lang}}wizard.variablesCount', icon: 'https://image.flaticon.com/icons/svg/13/13305.svg', placeholder: '{{lang}}insert', required: true } }
        ]
    }


    componentDidMount() {
        let data = this.props.initData


        let variables = data.variables ?? this.state.variables
        let constraints = data.constraints ?? this.state.constraints

        // 
        if (!data.constraints) {
            for (let i = 0; i < data.constraintsCount; i++) {
                constraints.push({ name: 'C' + (i + 1), variables: {}, sign: "2" })
            }
        }

        if (!data.variables) {
            for (let i = 0; i < data.variablesCount; i++) {
                variables.push({ name: 'V' + (i + 1) })
            }
        }

        // 

        this.setState({ variables, constraints })

    }

    setData = () => {
        this.setState({ isLoading: true, errors: {} })
        // let data = this.form.getForm()
        let data = { variables: this.state.variables, constraints: this.state.constraints }

        this.props.setData(data, () => {
            this.props.changeStage('compile')
            // this.props.postUserInfo((result, err) => {
            //     if (err) { this.setState({ errors: err }) }
            //     this.setState({ isLoading: false })
            // })
        })
    }

    checkValue(value) {

        let isValid = false
        // 
        let regex = /^[-+]?[0-9]\d{0,20}(\.)*(\/)?(\d+)?$/g
        let firstChar = value.charAt(0)
        // 
        if (value == null || value == "" || regex.test(value) || firstChar == '-') {
            isValid = true
        }
        

        // if(target.validity.valid){
        // }
        // 
        return isValid
    }


    changeCoefficient = (index, value) => {
        if (this.checkValue(value)) {
            let variables = this.state.variables
            variables[index].value = value
            this.setState({ variables })
        }
        // 
    }

    changeConstraintVariable = (index, name, value) => {
        if (this.checkValue(value)) {
            let constraints = this.state.constraints
            constraints[index].variables[name] = value
            this.setState({ constraints })
        }

    }

    changeSign = (index, value) => {
        let constraints = this.state.constraints
        constraints[index].sign = value
        this.setState({ constraints })
    }

    changeConstraintValue = (index, value) => {
        if (this.checkValue(value)) {
            let constraints = this.state.constraints
            constraints[index].value = value
            this.setState({ constraints })
        }
    }


    openExcelImport = () => {
        this.excelModal.showModal()
    }

    importExcel = () => {
        let data = this.form.getForm()
        
        if (data) {
            let file = data['**files']?.excel[0]
            if (file) {
                
                const reader = new FileReader();
                reader.onload = async (e) => {
                    
                    const bstr = e.target.result

                    // let result = excelToJson({
                    //     sourceFile: fileee//fs.readFileSync(fileee)
                    // });
                    // 
                    // alert(text)

                    const wb = XLSX.read(bstr, { type: "binary" });
                    // const wb = XLSX.read(file);
                    const wsname = wb.SheetNames[0];
                    const ws = wb.Sheets[wsname];

                    let headers = []

                    this.state.variables.forEach(element => {
                        headers.push(element.name)
                    });
                    headers.push("Sign")
                    headers.push("Value")

                    // 

                    const result = XLSX.utils.sheet_to_json(ws, { header: headers, defval: '', blankrows: true, skipHeader: false });
                    
                    this.insertJsonToTable(result)
                    this.excelModal.hideModal()

                };
                reader.readAsBinaryString(file)



                // let wb = XLSX.read(file)
                // const wb = XLSX.read(file);
                // 
                // const wsname = wb.SheetNames[0];
                // 
                // const ws = wb.Sheets[wsname];
                // 
                // const result = XLSX.utils.sheet_to_json(ws, {header:["code","name","family"]});
                // 

            }
        }
    }


    insertJsonToTable = (json) => {
        let constraints = this.state.constraints
        let variables = this.state.variables
        
        

        variables.forEach((variable, index) => {

            if (json[0]) {
                // Object.entries(json[0]).forEach(entries => {
                variable.value = Object.entries(json[0])[index][1]//entries[1]
                // });
            }

        });
        


        constraints.forEach((constraint, index) => {
            if (json[index + 1]) {
                Object.entries(json[index + 1]).forEach(entries => {
                    // 
                    if (entries[0] == 'Sign') {
                        // 
                        constraint.sign = String(entries[1])
                    } else if (entries[0] == 'Value') {
                        constraint.value = entries[1]
                    } else {
                        constraint.variables[entries[0]] = entries[1]
                    }
                });

                // if (index == constraints.length - 1) {
                //     if (Object.entries(json[index + 2])) {
                //         constraint.sign = Object.entries(json[index + 2])[1]
                //     }
                //     constraint.value = Object.entries(json[index + 3])[1]

                // }
            }

        });


        this.setState({ constraints })
    }


    render() {
        return (

            <div className="flexcc flex-column">
                <h2 className="mt-0 mb-0" style={{ fontSize: 27 }}>{translate("information")}</h2>
                <p className="mb-4 mt-0" style={{ fontSize: 15 }}>{translate('insert-information')}</p>

                <div className="w-100 px-2" style={{}}>




                    {/* <p style={{color:'#845EC2'}} className="mt-4 mb-2 text-normal text-bold">Technical coefficients of constraints (a_ij)</p> */}
                    <div style={{ overflow: 'auto' }}>
                        <div className="d-flex" style={{ alignItems: 'flex-end', justifyContent: 'space-between' }}>
                            <p className="text-small text-bold">Calculation type: {this.props.initData.type}</p>
                            <div>
                                <button onClick={() => this.openExcelImport()} className="py-2 px-3" style={{ backgroundColor: '#007aff', borderRadius: 4, color: '#fff' }}>
                                    <span className="text-small">Import from excel</span>
                                </button>
                            </div>
                        </div>
                        <table className="w-100 mt-3 lined-table">
                            <thead>
                                <tr>
                                    <th>
                                        <p className="text-smaller">variable</p>
                                        <p className="text-smaller">Constraints</p>
                                    </th>
                                    {this.state.variables?.map((prop, index) => {
                                        return (
                                            <th className="text-center">
                                                <span className="text-small">V{index + 1}</span>
                                                {/* <p className="text-smaller">{prop.name}</p> */}
                                            </th>
                                        )
                                    }
                                    )}

                                    <th className="text-center">
                                        <span className="text-small">Sign</span>
                                    </th>

                                    <th className="text-center">
                                        <span className="text-small">Resources</span>
                                    </th>

                                </tr>
                            </thead>
                            <tbody>


                                <tr style={{ backgroundColor: '#f2f6f8' }}>
                                    <td><span className="text-small" style={{ whiteSpace: 'nowrap' }}>coefficients</span></td>
                                    {this.state.variables?.map((variable, indexv) => {
                                        return (
                                            // <td key={indexv}>{constraint.variables[variable.name]?.value ?? 0}</td>
                                            <td key={indexv} style={{ padding: 0 }}>
                                                <input value={variable.value} onChange={(e) => this.changeCoefficient(indexv, e.target.value)} type="number  " className="h-100 text-center text-bold" style={{ border: 'none', backgroundColor: 'transparent', fontSize: this.state.variables?.length > 10 ? 12 : 14, padding: '4px 2px', width: '100%', boxSizing: 'border-box', WebkitBoxSizing: 'border-box' }} />
                                            </td>

                                        )
                                    })}

                                    <td style={{ padding: 0, backgroundColor: '#e6e8ea', border: '1px solid #ddd' }}>
                                    </td>

                                    <td style={{ padding: 0, backgroundColor: '#e6e8ea', border: '1px solid #ddd' }}>
                                    </td>

                                </tr>


                                {this.state.constraints?.map((constraint, index) => {
                                    return (
                                        <tr key={index}>
                                            <td><span className="text-small" style={{ whiteSpace: 'nowrap' }}>constraint {index + 1}</span></td>
                                            {this.state.variables?.map((variable, indexv) => {
                                                // 
                                                return (
                                                    // <td key={indexv}>{constraint.variables[variable.name]?.value ?? 0}</td>
                                                    <td key={indexv} style={{ padding: 0 }}>
                                                        <input onChange={(e) => this.changeConstraintVariable(index, variable.name, e.target.value)} value={constraint.variables[variable.name] != null ? constraint.variables[variable.name] : ''} type="number  " className="h-100 text-center text-bold" style={{ border: 'none', fontSize: this.state.variables?.length > 10 ? 12 : 14, padding: '4px 2px', width: '100%', boxSizing: 'border-box', WebkitBoxSizing: 'border-box' }} />
                                                    </td>

                                                )
                                            })}

                                            <td style={{ padding: 0 }}>
                                                <select onChange={(e) => this.changeSign(index, e.target.value)} value={constraint.sign} className="h-100 text-center text-bold w-100" style={{ textAlignLast: 'center', flex: 1, border: 'none', outline: 'none', backgroundColor: 'none', WebkitAppearance: 'none', fontSize: this.state.variables?.length > 10 ? 12 : 14, padding: '4px 2px', width: '100%', boxSizing: 'border-box', WebkitBoxSizing: 'border-box' }} >
                                                    {/* <option className="text-center text-small" value={null}>select</option> */}
                                                    <option className="text-center text-small" value={'2'}>==</option>
                                                    <option className="text-center text-small" value={'0'}>{"<="}</option>
                                                    <option className="text-center text-small" value={'1'}>{">="}</option>
                                                </select>
                                            </td>

                                            <td style={{ padding: 0 }}>
                                                <input onChange={(e) => this.changeConstraintValue(index, e.target.value)} value={constraint.value} type="number  " className="h-100 text-center text-bold" style={{ border: 'none', fontSize: this.state.variables?.length > 10 ? 12 : 14, padding: '4px 2px', width: '100%', boxSizing: 'border-box', WebkitBoxSizing: 'border-box' }} />
                                            </td>

                                        </tr>
                                    )
                                })}

                            </tbody>

                        </table>
                    </div>



                    <div className="text-center">
                        <LoaderButton
                            onClick={this.setData}
                            isLoading={this.state.isLoading}
                            text={translate("convert").toUpperCase()}
                            type={"Oval"}
                            className="mt-4 mb-4"
                            buttonStyle={{ outline: 'none', backgroundColor: '#222', cursor: 'pointer', padding: '10px 50px', borderRadius: 4, fontSize: 15, fontWeight: 'bold', color: '#fff', border: 'none' }}
                            width={40}
                            height={40}
                            color={'#202020'}
                        />
                    </div>

                    <div className="w-100 text-center">
                        <button className="mb-3" onClick={() => this.props.lastStage()} style={{ fontSize: 13, }}>{translate('back-one-stage')}</button>
                    </div>

                </div>








                <Modal ref={el => this.excelModal = el} maxWidth={700}>
                    <div className="w-100  text-center pb-5" style={{ backgroundColor: '#ffffffee', borderRadius: 8 }}>
                        <div className="w-100 flexcb" style={{ zIndex: 1, padding: '8px 15px', position: 'sticky', top: 0, borderBottom: '0px #eee solid', backgroundColor: '#f2f6f8ee', background: 'linear-gradient(to right,#d7e2f7dd,#dad6e4dd)', backdropFilter: 'blur(10px) saturate(180%)', WebkitBackdropFilter: 'blur(10px) saturate(180%)', borderRadius: '8px 8px 0px 0px' }}>

                            <div className="text-start">
                                <p className="text-smallest mt-2 opacity-5" style={{ lineHeight: 0.5 }}>CONFIGURE</p>
                                <p>Import From Excel</p>
                            </div>

                            <div className="flexc">
                                {/* <button onClick={() => this.excelModal.hideModal()} className=" flexc px-3 mx-1 flexcc" style={{ height: 30, background: '#ffffff50', borderRadius: 20 }}>
                                    <p className="text-normal">Done</p>
                                </button> */}

                                <div className="cursor-pointer flexcc" onClick={() => this.excelModal.hideModal()} style={{ width: 30, height: 30, borderRadius: '50%', backgroundColor: '#ffffff50' }}>
                                    <img style={{ width: 14, height: 14 }} src="/images/close.svg" alt="" />
                                </div>
                            </div>
                        </div>
                        <div className="py-3 px-4">


                            <a href={'/sample.xlsx'}><p style={{color:'#007aff'}}>Downlaod Sample file</p></a>
                            <p className="text-small">{"For signs use 0 for <= , 1 for >= , 2 for = "}</p>


                            {/* <p className="text-smaller description-text">Results of this compile are as below</p> */}
                            <FormViewer ref={el => this.form = el} headers={this.state.formHeaders} initData={this.state.excelData} errors={this.state.errors} inputClass={'modern-input'} />

                            <div className="text-center">
                                <LoaderButton
                                    onClick={this.importExcel}
                                    isLoading={this.state.isLoading}
                                    text={translate("submit").toUpperCase()}
                                    type={"Oval"}
                                    className="mt-4 mb-4"
                                    buttonStyle={{ outline: 'none', backgroundColor: '#222', cursor: 'pointer', padding: '10px 50px', borderRadius: 4, fontSize: 15, fontWeight: 'bold', color: '#fff', border: 'none' }}
                                    width={40}
                                    height={40}
                                    color={'#202020'}
                                />
                            </div>

                        </div>
                    </div>
                </Modal>



            </div>

        )
    }
}

export default WizardTableStep;