import Login from '../pages/Login'
import Home from '../pages/Home'
import Profile from '../pages/Profile'
import Things from '../pages/Things'
import ThingSingle from '../pages/ThingSingle'
import Rigram from '../pages/Rigram'
import Explore from '../pages/Explore'
import Service from '../pages/Service'
import AddThing from '../pages/AddThing'
import AddWiki from '../pages/AddWiki'
import Admin from '../pages/Admin'
import Editor from '../pages/Editor'
import Wizard from '../pages/Wizard'
import Library from '../pages/Library'
import History from '../pages/History'


const routes = [


    { protected: true, path: "/", name: "Home", icon: "", component: Home, public: true, exact: true, settings: { hideTabBar: false, hideHeader: true }, protected: true },
    { protected: false, path: "/login", name: "Log in", icon: "", component: Login, public: true, settings: { hideTabBar: true, hideHeader: false }, protected: false },
    { protected: true, path: "/forgotpassword", name: "forgotPassword", icon: "", component: Login, public: true, protected: true },
    { protected: true, path: "/profile", name: "Log in", icon: "", component: Profile, public: true, settings: { hideTabBar: true, hideHeader: false }, protected: true },
    { protected: true, path: "/history", name: "history", icon: "", component: History, public: true, exact: true, settings: { hideTabBar: false, hideHeader: true }, protected: true },


    // { path: "/things", name: "things", icon: "", component: Things, public: true, },

    // { path: "/thing/:id", name: "thing", icon: "", component: ThingSingle, public: true, },

    // { path: "/rigram", name: "rigram", icon: "", component: Rigram, public: true, },

    // { path: "/explore", name: "explore", icon: "", component: Explore, public: true, },
    // { path: "/service", name: "service", icon: "", component: Service, public: true, },

    // { path: "/addThing", name: "add thing", icon: "", component: AddThing, public: true, },
    // { path: "/addWiki", name: "add wiki", icon: "", component: AddWiki, public: true, },
    // { path: "/admin/:page?", name: "add wiki", icon: "", component: Admin, public: true, },

    { protected: true, path: "/editor/:name?", name: "editor", icon: "", component: Editor, public: true, protected: true },
    { protected: true, path: "/wizard", name: "wizard", icon: "", component: Wizard, public: true, protected: true },
    { protected: true, path: "/library/:id?", name: "library", icon: "", component: Library, public: true, protected: true },

    { protected: true, path: "/*", name: "home", icon: "", component: Home, public: true, protected: true },

]


export default routes