import React from "react";


class SearchBar extends React.Component {
    state = {
        isFocuced: false
    }




    render() {

        return (
            <div className={"w-100 flexc searchbar " + (this.state.isFocuced ? "isFocuced" : '')}>
                <input onFocus={() => this.setState({ isFocuced: true })} onBlur={() => this.setState({ isFocuced: false })} className="h-100 w-100" placeholder="Search ..." />
                <div>
                    <button className=" search-but flexcc">
                        <img src="/images/search1.svg" alt="" />
                    </button>
                </div>
            </div>
        )
    }
}






export default SearchBar;
