import React from 'react'


const ProfileImage = props => {

    let notif = props.notif?props.notif:null
    return (
        <div>
            <div className={"flexcc " + props.className} style={{ position: 'relative', width: props.width ?? 50, height: props.width ?? 50, borderRadius:props.borderRadius?? "50%", padding: 1, background: '#fff', border: notif ? '3px solid #1787fc' : (props.border ?? 'none') }}>
                <div className="flexcc w-100 h-100" style={{ backgroundColor: '#f2f6f8', borderRadius: 40 }}>
                    <div className="h-100 w-100" >
                        <img className="" src={props.data?.image} style={{ width: '100%', height: '100%', borderRadius: props.borderRadius?? "50%", objectFit: 'cover' }} alt="" />
                    </div>
                </div>
                {notif && (
                    <div className="blur-back flexcc" style={{ position: 'absolute', top: -2, right: -7, backgroundColor: '#1787fcdd', borderRadius: props.borderRadius?? "50%", minWidth: 22, minHeight: 22 }}>
                        <span className="text-small white">{notif}</span>
                    </div>
                )}

            </div>
        </div>
    )

}

export default ProfileImage
