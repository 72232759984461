import React, { StrictMode, Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Router, Route, Switch } from "react-router-dom";
import './styles/index.css';
import './styles/usful.scss';
import './styles/views.scss';
import './styles/animations.scss';
import './styles/grid.css';
import './styles/components.scss';
import './styles/imports.scss';
import './styles/theme.scss';


import * as serviceWorker from './serviceWorker';
import { createBrowserHistory } from "history";
import { Provider } from 'react-redux'
import App from './App.jsx';
import './i18n'
import { HelmetProvider } from 'react-helmet-async';
import store from './stores/storeConfig';


const hist = createBrowserHistory();
const helmetContext = {};

ReactDOM.render(

    <React.StrictMode>
        <Suspense fallback={(<div>LOADING ...</div>)}>
            <Provider store={store}>
                <HelmetProvider context={helmetContext}>
                    <Router history={hist}>
                        <Switch>
                            {/* {window.location.pathname.split('/')[1] ? ( */}
                            <Route path={'/:locale?'} component={App} />
                            {/* ) : ( */}
                            {/* <Route path={'/'} component={App} /> */}
                            {/* )} */}
                        </Switch>
                    </Router>
                </HelmetProvider>
            </Provider>
        </Suspense>
    </React.StrictMode>
    ,
    document.getElementById('root')
)


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
