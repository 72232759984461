import React from 'react'
import { getBoundingClientRect } from '../../utils/functions'
import { checkTranslation } from '../../utils/useful'


class TableHead extends React.Component {

    state = {

    }

    componentWillUnmount() {
        window.removeEventListener("mousemove", this.mouseMove)
        window.removeEventListener("mouseup", this.mouseUp)
    }

    onMouseDown = (e, key) => {
        // 

        this.initWidth = getBoundingClientRect(this[key]).width
        this.initLeft = getBoundingClientRect(this[key]).left

        this.setState({ selectedKey: key, initX: e.clientX })
        window.addEventListener("mousemove", this.mouseMove)
        window.addEventListener("mouseup", this.mouseUp)
    }

    mouseMove = (e) => {
        // 
        let x = e.clientX
        // 
        // let width = getBoundingClientRect(this[this.state.selectedKey]).width

        let dx = x - this.state.initX


        // 
        if (Math.abs(this.state.initX - this.initLeft) < 20) {
            dx = dx * -1
        }

        let newWidth = this.initWidth + dx

        // 
        // 
        // 
        // 

        // 
        // 
        // 


        if (newWidth >= 100) {
            this.props.changeWidth(this.state.selectedKey, newWidth)
        }
        // 
        // 
        // 
        // 

    }



    mouseUp = (e) => {
        // 
        this.setState({ selectedKey: null })
        window.removeEventListener("mousemove", this.mouseMove)
        window.removeEventListener("mouseup", this.mouseUp)
    }

    render() {
        // 
        return (
            <div className={"flexc blur-back tableHeader " + (this.state.selectedKey ? 'isResizingTable' : '')} style={{ width: this.props.widths['*'] }}>

                {this.props.headers?.map((prop, index) => {
                    let isSticky = false
                    if (index === 0 && Math.abs(this.props.scrollX) > 5) {
                        isSticky = true
                    }
                    //  if (index === 0){
                    //      
                    //  }
                    // transform: isSticky ? 'translateX(' + this.props.scrollX + 'px)' : 'translateX(0px)',
                    return (
                        <div ref={el => this[prop.key] = el} className={"tableHeaderItem blur-back " + (isSticky ? 'stickyTableHeaderItem' : '')} key={prop.key} style={{ flex: index == 0 ? '1 0' : '0 0', maxWidth: index == 0 ? null : this.props.widths[prop.key], flexBasis: this.props.widths[prop.key] }}>
                            <span>{checkTranslation(prop.information?.label)}</span>
                            <div className={"resizer " + (this.state.selectedKey === prop.key ? 'activedResizer' : '')} onMouseDown={e => this.onMouseDown(e, prop.key)}></div>
                        </div>
                    )
                })}


                {/* {this.props.settings?.extendedActions && this.props.actions?.map((prop, index) => {
                    let isSticky = false

                    return (
                        <div ref={el => this["actions-" + prop.key] = el} className={"tableHeaderItem blur-back "} style={{ flex: '0 0', maxWidth: 120, flexBasis: 100 }}>
                            <span>{checkTranslation(prop.information?.label)}</span>
                            <div className={"resizer " + (this.state.selectedKey === prop.key ? 'activedResizer' : '')} onMouseDown={e => this.onMouseDown(e, prop.key)}></div>
                        </div>
                    )
                })} */}

                {!this.props.settings?.extendedActions && this.props.actions && (
                    <div ref={el => this['action-actions'] = el} className={"tableHeaderItem blur-back "} style={{ flex: '0 0', maxWidth: 120, flexBasis: 120 }}>
                        <span>{checkTranslation("actions")}</span>
                        {/* <div className={"resizer " + (this.state.selectedKey === prop.key ? 'activedResizer' : '')} onMouseDown={e => this.onMouseDown(e, prop.key)}></div> */}
                    </div>
                )}


            </div>
        )
    }

}

export default TableHead
