import React from "react";
import Configurer from "../../components/Configurer";
import FormViewer from "../../components/FormViewer";
import LoaderButton from "../../components/LoaderButton";
import HttpServices from "../../utils/Http.services";


class EditProfile extends React.Component {
    state = {
        headers: [
            // { key: 'image', type: 'ProfileImageInput', col: '12', information: { placeholder: '{{lang}}profileImage', required: false, inputClass: 'classic-input mb-2' } },
            {
                key: "name",
                type: "TextInput",
                col: "6",
                information: {
                    label: "{{lang}}name",
                    icon: "../images/icons/profile.png",
                    placeholder: "{{lang}}insertName",
                    required: true,
                },
            },
            {
                key: "familyName",
                type: "TextInput",
                col: "6",
                information: {
                    label: "{{lang}}family",
                    icon: "../images/icons/profile.png",
                    placeholder: "{{lang}}insertFamily",
                    required: true,
                },
            },
            {
                key: "email",
                type: "TextInput",
                col: "6",
                information: {
                    label: "{{lang}}email",
                    icon: "../images/icons/mail.png",
                    placeholder: "{{lang}}insertEmail",
                },
            },
            {
                key: "phoneNumber",
                type: "PhoneInput",
                col: "6",
                information: {
                    label: "{{lang}}phone",
                    placeholder: "{{lang}}insertPhone",
                    disabled: true,
                    required: true,
                },
            },
        ],
    };

    componentDidMount() {
        // 
    }

    updateUserInfo = () => {
        let data = this.form.getForm();
        if (data) {
            this.setState({ isLoading: true });

            HttpServices.request(
                "updateUserInfo",
                data,
                (fetchResult, fetchError) => {
                    this.setState({ isLoading: false });
                    if (fetchError) {
                        this.setState({ errors: fetchError.errors });
                        
                        this.props.addNotif({
                            type: "error",
                            title: "{{lang}}errors.profileNotUpdated",
                            description: fetchError.message,
                        });
                        return;
                    }
                    this.setState({ errors: null });
                    
                    this.props.setUser(fetchResult.user, null, true);
                    this.props.addNotif({
                        type: "success",
                        title: "{{lang}}info.profileUpdated",
                        description: "{{lang}}info.profileUpdatedSuccessfully",
                    });
                }
            );
        }
    };

    render() {
        return (
            <Configurer
                settingsInfo={{
                    headerTitle: "Edit Profile",
                    button: { goBack: true },
                }}
                title={"Edit Profile"}
                description={"This is the dashboard"}
                className="p-3"
                changeOnUnmount={true}
                // parentConfigure={this.props.parentConfigure??null}
            >
                <div className="px-2 mt-4">
                    <p className="text-ultra-big font-bold">Edit Profile</p>
                    <p className="text-small" style={{ color: "#9ab" }}>
                        {this.props.user.info?.fullname}
                    </p>

                    <div className="w-100 mt-4">
                        <FormViewer
                            ref={(el) => (this.form = el)}
                            headers={this.state.headers}
                            initData={this.props.user.info}
                            errors={this.state.errors}
                            inputClass={"modern-input"}
                        />

                        <div className="text-center">
                            <LoaderButton
                                onClick={this.updateUserInfo}
                                isLoading={this.state.isLoading}
                                text={"{{lang}}update"}
                                type={"Oval"}
                                className="mt-4 mb-4"
                                buttonStyle={{
                                    outline: "none",
                                    backgroundColor: "#222",
                                    cursor: "pointer",
                                    padding: "10px 50px",
                                    borderRadius: 4,
                                    fontSize: 15,
                                    fontWeight: "bold",
                                    color: "#fff",
                                    border: "none",
                                }}
                                width={40}
                                height={40}
                                color={"#202020"}
                            />
                        </div>
                    </div>
                </div>
            </Configurer>
        );
    }
}


export default EditProfile
