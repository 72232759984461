import PhoneInput from './PhoneInput'
import NumberInput from './NumberInput'
import PasswordInput from './PasswordInput'
import TextInput from './TextInput'
import SelectInput from './SelectInput'
import TextAreaInput from './TextAreaInput'
import ProfileImageInput from './ProfileImageInput'
import SelectListInput from './SelectListInput'
import CheckboxInput from './CheckboxInput'
import FromToInput from './FromToInput'
import PriceInput from './PriceInput'
import SliderInput from './SliderInput'
import AdvancedSelectInput from './AdvancedSelectInput'
import MultiSelectInput from "./MultiSelectInput"
import ImageInput from "./ImageInput"
import FileInput from "./FileInput"
import SelectVariableType from "../editor/SelectVariableType";

const inputComponents = {
    PhoneInput,
    NumberInput,
    PasswordInput,
    TextInput,
    SelectInput,
    TextAreaInput,
    ProfileImageInput,
    SelectListInput,
    CheckboxInput,
    SelectVariableType,
    FromToInput,
    PriceInput,
    SliderInput,
    AdvancedSelectInput,
    MultiSelectInput,
    ImageInput,
    FileInput

}

export default (inputComponents);