import React, { Component } from 'react';
import { Route, Redirect } from 'react-router-dom'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actions from '../stores/actionsList';
import Cookies from 'universal-cookie';
import { pathMaker } from '../utils/useful';
const cookies = new Cookies();

class ProtectedRoute extends React.Component {

  isAuthenticated() {
    let authenticated = false
    let token = cookies.get('token')
    if (token) {
      authenticated = true
    }
    return authenticated
  }



  checkPermission(page, reference) {
    if (!page.roles) {
      return true
    }


    if (reference.info) {
      for (let i = 0; i < page.roles.length; i++) {
        const oneRole = page.roles[i];
        if (oneRole === reference.info.role) {
          return true
        }
      }
    }
  }


  render() {
    if (this.isAuthenticated()) {
      
      if (this.checkPermission(this.props.prop, this.props.user)) {

        return <Route component={this.props.component} path={this.props.path} exact={this.props.exact} />
      } else {
        return null
        
      }
    } else {
      return <Redirect to={{ pathname: pathMaker('/login'), state: { from: this.props.location } }} />
    }

  }
}

const mapStateToProps = state => ({ user: state.user })
const mapDispatchToProps = dispatch => ({ actions: bindActionCreators(actions, dispatch) })

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProtectedRoute);
