import React from "react";
import HttpService from '../utils/Http.services';
import { siteConfig, siteTheme } from "../variables/config";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actions from '../stores/actionsList';
// import TabBar from "../views/TabBar";
import Configurer from "../components/Configurer";
import ProfileImage from "../components/partials/ProfileImage";
import TableViewer from "../components/TableViewer";
import { v4 as uuidv4 } from 'uuid';
import StagesManager from "../components/StagesManager";
import ThingLatestInfo from "./ThingSingle/ThingLatestInfo";
import ThingServices from "./ThingSingle/ThingServices";
import ThingHistory from "./ThingSingle/ThingHistory";
import ThingConnectedThings from "./ThingSingle/ThingConnectedThings";
import ThingStatistics from "./ThingSingle/ThingStatistics";
import ThingDevelopers from "./ThingSingle/ThingDevelopers";



class ThingSingle extends React.Component {


    state = {
        infoList: [
            { title: 'Model', key: '', value: 'D32-52' },
            { title: 'Brand', key: '', value: 'Xiaomi' },
            { title: 'Owner', key: '', value: 'Pouya Pezhman' },
            { title: 'Create Date', key: '', value: '2020/12/2' },
            { title: 'Address', key: '', value: '238, test st, other sq, Folana, That country' },

        ],

        tabs: [
            { name: 'Latest info', key: 'info' },
            { name: 'Data history', key: 'history' },
            { name: 'Services', key: 'services' },
            { name: 'Connected Things', key: 'connected' },
            { name: 'Statistics', key: 'statistics' },
            { name: 'Developers', key: 'developers' },
        ],
    }


    componentDidMount() {
        let initTab = this.state.tabs[0]
        this.stageManager.changeStage(initTab.key)
        this.setState({ currentTab: initTab.key })

    }

    changeTab=(key)=>{
        this.setState({currentTab:key})
        this.stageManager.changeStage(key)
        window.history.pushState(null, null, '#' + key)
    }

    

    render() {
        return (

            <Configurer
                settingsInfo={{showFooter:true, showTabBar: true, showHeader: true, headerTitle: "Things", button: {} }}
                title={"Things"}
                description={"Things Description"}
                className="pt-3"
            >

                <div className="w-100 singleThing">
                    <div className="container " >
                        <div className="row m-0">
                            <div className="col-12 p-0" >
                                <div className="p-4" style={{ borderRadius: 0 }}>

                                    <div className="row">

                                        <div className="col-12 col-sm-12 col-md-5 col-lg-4">
                                            <div className=" w-100 mrd-2">
                                                <img className="mrd-3" src="https://images.unsplash.com/photo-1518770660439-4636190af475?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1350&q=80" style={{ width: '100%', borderRadius: 15, objectFit: 'cover' }} alt="" />
                                            </div>


                                            <div className="flexc" style={{ flexWrap: 'wrap' }}>
                                                {[1, 1, 1, 1,].map((prop, index) => {
                                                    return (
                                                        <div key={index} className="mrd-2" style={{ width: 'calc(20% - 5px)' }}>
                                                            <img src="https://images.unsplash.com/photo-1518770660439-4636190af475?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1350&q=80" style={{ width: "100%", borderRadius: 8, objectFit: 'cover' }} alt="" />
                                                        </div>
                                                    )

                                                })}
                                            </div>



                                        </div>

                                        <div className="col-12 col-sm-12 col-md-7 col-lg-8">


                                            <div className="d-flex">
                                                {/* <div>
                                                <div className="flexcc mrd-3" style={{ position: 'relative', width: 50, height: 50, borderRadius: 50, padding: 1, background: '#fff', border: '3px solid #1787fc' }}>
                                                    <div className="flexcc w-100 h-100" style={{ backgroundColor: '#f2f6f8', borderRadius: 40 }}>
                                                        <div className="h-100 w-100">
                                                            <img className="mrd-3" src="https://images.unsplash.com/photo-1518770660439-4636190af475?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1350&q=80" style={{ width: '100%', height: '100%', borderRadius: 50, objectFit: 'cover' }} alt="" />
                                                        </div>
                                                    </div>

                                                    <div className="blur-back flexcc" style={{ position: 'absolute', top: -2, right: -7, backgroundColor: '#1787fcdd', borderRadius: 20, minWidth: 22, minHeight: 22 }}>
                                                        <span className="text-small white">5</span>
                                                    </div>

                                                </div>
                                            </div> */}

                                                <div>

                                                    <div className={"flexc mb-2"}>
                                                        {["Hot", "Need Update", "New"].map((prop, index) => {
                                                            return (
                                                                <div key={index} className="mrd-2">
                                                                    <div className="" style={{ padding: '2px 10px', borderRadius: 6, backgroundImage: 'linear-gradient(-90deg, #ee5050, #ee6060)', border: "0px solid #eaecf0" }}>

                                                                        <span className=" text-smaller m-0 font-bold" style={{ color: '#fff' }}>{prop}</span>
                                                                    </div>
                                                                </div>
                                                            )
                                                        })}
                                                    </div>

                                                    <p className="text-semibig font-bold m-0" style={{ color: '#0d172a' }}> Device name and part number</p>

                                                    <p className="text-small mt-2" style={{ color: '#789' }} >Lorem ipsum dolor sit amet consectetur adipisicing elit. Quia doloribus explicabo assumenda quam magnam quaerat, totam sit alias, voluptates sed possimus, libero nostrum voluptatem quod. Non eum iure quis expedita.</p>

                                                    <div className="w-100 mt-3" style={{ background: '#eee', height: 1 }}></div>


                                                    <div className={'thingInfoList mt-3'}>
                                                        {this.state.infoList.map((prop, index) => {
                                                            return (
                                                                <div className="flexc mt-1" key={index}>
                                                                    <div className="thingInfoTitle">
                                                                        <span className=" text-small ">{prop.title}</span>
                                                                    </div>
                                                                    <div className="thingInfoValue">
                                                                        <span className=" text-small ">{prop.value}</span>
                                                                    </div>
                                                                </div>
                                                            )
                                                        })}



                                                    </div>


                                                </div>
                                            </div>


                                        </div>






                                    </div>




                                </div>

                            </div>

                        </div>

                    </div>


                    <div className="w-100 mt-4 pb-5" style={{ backgroundColor: '#f2f6f8', minHeight: 500 }}>

                        <div className="container">
                            <div className="d-flex align-items-end pt-5 no-scrollbar w-100" style={{overflow:'auto',flexWrap:'nowrap'}}>
                                {this.state.tabs.map((prop, index) => {
                                    return (
                                        <button onClick={() => this.changeTab(prop.key)} className={"ml-0 mb-0 mrd-2 thingsTabs " + (this.state.currentTab === prop.key ? 'active' : '')} key={index} >
                                            <span>{prop.name}</span>
                                        </button>
                                    )
                                })}
                            </div>
                            <div>
                                <StagesManager ref={el => this.stageManager = el}>
                                    <ThingLatestInfo stageName="info" stage={0} />
                                    <ThingServices stageName="services" stage={1} />
                                    <ThingHistory stageName="history" stage={2} />
                                    <ThingConnectedThings stageName="connected" stage={2} />
                                    <ThingStatistics stageName="statistics" stage={2} />
                                    <ThingDevelopers stageName="developers" stage={2} />


                                </StagesManager>
                            </div>

                        </div>
                    </div>



                </div>
            </Configurer>
        )
    }

}



const mapStateToProps = state => ({ settings: state.settings, user: state.user })
const mapDispatchToProps = dispatch => ({ actions: bindActionCreators(actions, dispatch) })

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ThingSingle);
