// import Auth from '../utils/Auth';
// import Notify from './../Common/Notify';
import { siteConfig, apisList } from '../variables/config';
import Security from './Security.services'
import { getToken, getObject, setObject } from './useful'


class HttpService {
    constructor({ apisList }) {
        this.requestSecurity = new Security();
        this.apisList = apisList;

    }
    request(action, params, cb) {
        let api = this.apisList[action]
        if (api) {
            this.checkParams(api, params, (newParams, paramsError) => {
                if (!paramsError) {
                    this.requestSecurity.notHashData(newParams, api, (hashedData, hashError) => {
                        if (!hashError) {
                                fetch(siteConfig.domain + api.route, this.serviceMethod(api, hashedData))
                                    .then(res => this.handleResponseError(res, (result, err, status) => {
                                        cb(result, err, status)
                                    })).catch((err) => cb(null, err))

                            } else {
                                cb(null, { error: "Security prevention" }) // TODO: Language
                            }
                        })
                } else {
                    cb(null, paramsError) // TODO: Language
                }
            })
        } else {
            cb(null, "Irrelevant request") // TODO: Language
        }
    }


    handleFiles(api, newParams, cb) {

        if (!newParams['**files']) {
            cb()
            return
        }

        let files = newParams['**files']
        let promises = []

        for (const [key, value] of Object.entries(files)) {
            
            promises.push(new Promise((resolve, reject) => {
                this.fileUpload(value, newParams['applet'], key, (fileInfo) => {
                    
                    if (Array.isArray(fileInfo)) {
                        newParams[key] = Array.isArray(newParams[key]) ? newParams[key] : []
                        setObject(newParams, key, [...fileInfo], true)
                    } else {
                        setObject(newParams, key, fileInfo)
                    }
                    resolve(fileInfo)
                })
            }))

        }

        Promise.all(promises).then(() => {
            delete newParams['**files']
            cb()
        })

    }



    fileUpload(files) {
        return new Promise(async (resolve, reject) => {
            const allFiles = Array.isArray(files) ? files : [files];
            let filesInfo = [];
            let count = 0;
            for (let i = 0; i < allFiles.length; i++) {
                const file = allFiles[i];
                let fetchAddress = file.fileProps?.address || '/values/fileuploader';
                const formData = new FormData();
                formData.append('file', file);
                for (const [key, value] of Object.entries(file.fileProps)) {
                    if (value && value) {
                        formData.append(key, value);
                    }
                }
                try {
                    const response = await fetch(siteConfig.domain + fetchAddress, {
                        method: 'POST',
                        body: formData,
                    });
                    const data = await response.json();
                    if (!Array.isArray(files)) {
                        filesInfo = data.info;
                        resolve(filesInfo);
                    } else {
                        filesInfo.push(data.info);
                        count += 1;
                        if (count === files.length) {
                            resolve(filesInfo);
                        }
                    }
                } catch (error) {
                    reject(error?.message);
                }
            }
        })

    }

    fileDownload({ api, body }) {
        return new Promise(async (resolve, reject) => {
            try {
            const {route, auth, method} = this.apisList[api];
            const URL = siteConfig.domain + route;
                const response = await fetch(URL, {
                    responseType: 'blob', // Important to treat the response as a binary blob,
                    method,
                    body: JSON.stringify(body),
                    headers: {
                        'Content-Type': 'application/json',
                        authorization: auth === "token" ? `bearer ` + getToken() : null
                    }
                });
                if (!response.ok) {
                    console.log("not found")
                    return reject({ message: `File Not Found!` });
                }
                const filename = response.headers.get('Content-Disposition').split('=')[1];
                const blob = await response.blob();
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = filename;
                link.click();
                resolve();
            } catch (error) {
                reject(error)
            }
        })
    }

    urlShaderrequest(action, params, cb) {
        let api = this.apisList[action]
        if (api) {
            this.checkParams(api, params, (chekcedParams, paramsError) => {
                if (!paramsError) {
                    this.requestSecurity.hashData(params, api, (hashedData, hashError) => {
                        if (!hashError) {
                            fetch(siteConfig.domain + '/apiv1', this.serviceMethod(api, hashedData))
                                .then(res => this.handleResponseError(res))
                                .then((data, err) => cb(data, err))
                                .catch(err => cb(null, err))
                        } else {
                            cb(null, { error: "Security prevention" }) // TODO: Language
                        }
                    })
                } else {
                    cb(null, { error: paramsError }) // TODO: Language
                }
            })
        } else {
            cb(null, "Irrelevant request") // TODO: Language
        }
    }

    chooseServer() {
        // TODO :FUTURE
    }


    checkParams(api, params, cb) {
        let newParams = {}
        api.params.forEach(param => {
            newParams[param.key] = params[param.key]
            if (param.required) {
                if (params[param.key] == null && params[param.key] === '') {
                    cb(null, {
                        [param.key]: 'This field is required'
                    })
                }
                if (param.type && typeof params[param.key] != param.type && param.required) {
                    cb(null, {
                        [param.key]: 'Input type error'
                    })
                }
            }
        });
        if (api.flexibleParams) {
            cb(params)
        } else {
            cb(newParams)
        }
    }

    serviceMethod(api, data) {
        const token = getToken();
        const extraParams = {};
        const request = {
            method: api.method ? api.method.toUpperCase() : 'POST' ,
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json', 'Accept': 'application/json',
                authorization: token ? `bearer ${token}` : null
            },
            ...extraParams
        };
        
        return request
    }
    readableStreamtoJSON(body) {
        const reader = body.getReader(); // `ReadableStreamDefaultReader`
        const decoder = new TextDecoder();
        const chunks = [];

        async function read() {
            const { done, value } = await reader.read();
            
            // all chunks have been read?
            if (done) {
                
                const chunkData = chunks?.[0]
                
                return JSON.parse(chunkData);
            }

            const chunk = decoder.decode(value, { stream: true });
            chunks.push(chunk);
            
            return read(); // read the next chunk
        }

        return read();
    }

    async handleResponseError(res, cb) {
        if (res?.headers?.get('content-type') === 'text/event-stream') {
            const reader = await this.readableStreamtoJSON(res.body);
            
            return cb(reader, null, res.status);
        }
        res.json().then(result => {
            if (!res.ok) {
                // 
                cb(null, result, res.status)
                // return { result: null, err: result }
                // throw new Error("HTTP error, status = " + res.status);
            } else {
                // return { result }
                cb(result, null, res.status)
            }
        }).catch(err => {
            cb(null, err, res.status)
        })

    }

    async handlerResponseService(data) {
        // 
        if (data && data.responseMessages) {
            for (let item of data.responseMessages) {
                // await Notify.notifycation({
                //     msg: `${item.name ? item.name + ': ' : ''} ${item.message}`,
                //     type: data.success ? 'success' : 'error',
                //     position: 'bottom-right'
                // })
            }
        }

        return data;
    }

    handleError(error) {
        
    }

}


export default new HttpService({apisList});