import React from 'react'
import { checkTextTranslation } from '../../utils/useful';
import Collapsible from 'react-collapsible';

class TabBarItem extends React.Component {

    state = {
        open: false
    }

    componentDidMount() {
    }

    componentWillUnmount() {
        window.removeEventListener('mousedown', this.closeChildren)
    }

    componentDidUpdate(prevProps) {
        if (((prevProps.tabBarOpen != this.props.tabBarOpen) || (prevProps.isMobile != this.props.isMobile && this.props.isMobile)) && this.state.open) {
            window.addEventListener('mousedown', this.closeChildren)
        }

        // this.checkScroll()
    }

    handleClick = (prop) => {
        if (this.props.data.children?.length > 0) {
            this.changeStatus()

        } else {
            this.props.changePage(prop)
        }
    }

    changeStatus(status) {
        this.setState({ open: status != null ? status : !this.state.open }, () => {
            if (this.state.open) {
                if ((!this.props.tabBarOpen || this.props.isMobile) && !this.props.isChild) {
                    window.addEventListener('mousedown', this.closeChildren)
                }

                if ((!this.props.tabBarOpen || !this.props.isMobile) && !this.props.isChild) {
                    this.checkScroll()
                    // setInterval(() => {

                    // }, interval);
                    // document.addEventListener('wheel', this.checkScroll)
                }

            } else {
                setTimeout(() => {
                    window.removeEventListener('mousedown', this.closeChildren)
                }, 100);
            }
        })
    }


    closeChildren = (event) => {
        if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
            this.changeStatus(false)
        }
    }


    handelHover = () => {
        // this.setState({ open: true })
    }

    getBoundingClientRect = (element) => {
        const { top, right, bottom, left, width, height, x, y } = element?.getBoundingClientRect()
        return { top, right, bottom, left, width, height, x, y }
    }


    checkScroll = () => {
        let bounding = this?.getBoundingClientRect(this.wrapperRef)
        let boxBounding = this?.getBoundingClientRect(this.tabBarListChildren)

        // 

        // let bounding = {}
        // 
        // for (const [key, value] of Object.entries(refBounding)) {
        //     bounding[key] = value
        // }

        // let bounding = //this.wrapperRef?.getBoundingClientRect()
        // 

        if (window.innerHeight - bounding.top < boxBounding.height) {
            bounding.top = bounding.top - (boxBounding.height - (window.innerHeight - bounding.top) + 20)
        } else {
            // bounding.top = 
        }

        // 
        if (bounding.top != this.state.currentPosition?.top) {
            // 
            this.setState({ currentPosition: bounding })
        }

        setTimeout(() => {
            if ((!this.props.tabBarOpen || !this.props.isMobile) && !this.props.isChild && this.state.open) {
                this.checkScroll()
                // 
            }
        }, 20);
        // console
    }

    render() {
        let prop = this.props.data
        let styleObject = {}

        if (!this.props.tabBarOpen && !this.props.isMobile && !this.props.isChild) {

            styleObject.left = this.state.currentPosition?.width
            styleObject.top = this.state.currentPosition?.top

        }
        // { top: ( && )?null:,  }

        return (
            <div className="w-100">
                <div ref={el => this.wrapperRef = el} className="w-100 flexcc flex-column " style={{ position: 'relative' }} >
                    <button className={'tabBarListItems'} onMouseOver={() => this.handelHover(true)} onClick={() => this.handleClick(prop)} style={{ background: this.props.selected ? '#f2f6f8' : 'transparent' }}>
                        
                        <img className="tabBarListItemsImg" src={prop.icon} alt="" />
                        <span className="ListItemName">{checkTextTranslation(prop.name)}</span>

                        {(prop.children?.length > 0) && (
                            <div className={"flexcc listItemChildIndicator " + (this.state.open ? 'open' : '')}>
                                <img src="https://www.flaticon.com/svg/static/icons/svg/149/149657.svg" width="10px" />
                            </div>
                        )}
                        {/* <div style={{ position: 'relative' }}> */}
                        {prop.notif && prop.notif > 0 && (
                            <div className="flexcc notif">
                                <p >{prop.notif}</p>
                            </div>
                        )}
                        {/* </div> */}
                    </button>
                    {/* <div  style={{ position: 'relative' }}> */}

                    {/* <div style={{ position: 'fixed',top: this.state.currentPosition?.top, left: this.state.currentPosition?.width  }}> */}
                    {(prop.children?.length > 0) && (
                        <React.Fragment>
                            <div ref={el => this.tabBarListChildren = el} className={"blur-back tabBarListChildren " + (this.state.open ? 'open' : 'close')} style={styleObject} >
                                <div className={"collapse " + (this.state.open ? 'open' : 'close')}>
                                    <div className="tabBarListChildrenContainer"  >
                                        <div >
                                            {/* style={{ [document.body.classList.contains('rlt') ? "paddingRight" : "paddingLeft"]: this.props.padding + 12 }} */}
                                            {prop.children.map((childProp, index) => {
                                                return (
                                                    <TabBarItem isChild={true} tabBarOpen={this.props.tabBarOpen} isMobile={this.props.isMobile} data={childProp} index={index} selected={false} key={index} changePage={this.props.changePage} padding={this.props.padding + 12} />
                                                )
                                            })}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <div style={{position:'absolute',zIndex:-10,width:100,backgroundColor:'#ff0000',top:0,left:0,height:this.tabBarListChildren?this.tabBarListChildren?.getBoundingClientRect().height:0}}>

                            </div> */}
                        </React.Fragment>
                    )}







                    {/* </div> */}
                    {/* </div> */}

                </div>
            </div>
        )
    }
}

export default TabBarItem
