import React from 'react'
import LoaderButton from '../../components/LoaderButton'
import FormViewer from '../../components/FormViewer'
import { translate } from '../../utils/useful'

class LoginFirstStep extends React.Component {
    state = {
        key: '',
        selectedCountry: {},
        data: {},
        isLoading: false,
        formHeaders: []
    }

    componentDidMount() {
        let formHeaders = [{ key: 'userIndicator', type: 'PhoneInput', information: { label: '{{lang}}phonenumber', placeholder: '{{lang}}phonenumber', required: true } }]
        if (this.props.initData.indicatorType && this.props.initData.indicatorType === 'email') {
            formHeaders = [{ key: 'userIndicator', type: 'EmailInput', information: { label: '{{lang}}phonenumber', placeholder: '{{lang}}phonenumber', required: true } }]
        }
        document.addEventListener("keydown", this._handleKeyDown);
        this.setState({ formHeaders })
    }


    componentWillUnmount = () => {
        document.removeEventListener("keydown", this._handleKeyDown);
    }

    _handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            this.postUserIndicator()
        }
    }




    postUserIndicator = () => {
        let data = this.form.getForm()
        if (data) {
            this.setState({ isLoading: true, errors: {} })
            this.props.setData(data, () => {
                this.props.postUserIndicator((result, err) => {
                    if (err) { this.setState({ errors: err }) }
                    this.setState({ isLoading: false })
                })
            })
        }
    }


    render() {
        return (
            <div className="flexcc flex-column">
                {this.props.settings.mode === 'forgotPassword' ? (
                    <h2 className="mt-4 " style={{ fontSize: 27 }}>Forgot Password</h2>
                ) : (
                        <h2 className="mt-4 " style={{ fontSize: 27, whiteSpace: 'nowrap' }}>{translate('Signin-Signup')}</h2>
                    )}
                <div className="w-100" style={{ maxWidth: 400 }}>

                    <FormViewer ref={el => this.form = el} headers={this.state.formHeaders} initData={this.props.initData} errors={this.state.errors} inputClass={'modern-input'} />

                    <div className="text-center">
                        <LoaderButton
                            onClick={this.postUserIndicator}
                            isLoading={this.state.isLoading}
                            text={translate('send')}
                            type={"Oval"}
                            className="mt-4 mb-4"
                            buttonStyle={{ outline: 'none', backgroundColor: '#222', cursor: 'pointer', padding: '10px 50px', borderRadius: 4, fontSize: 15, fontWeight: 'bold', color: '#fff', border: 'none' }}
                            width={40}
                            height={40}
                            color={'#202020'}
                        />
                        <p style={{ fontSize: 15, margin: '10px 20px 20px' }}>{translate("insert-phone-desc")}</p>
                        <p style={{ fontSize: 13, opacity: 0.5, marginTop: 10 }}>{translate('cookie-warning')}</p>
                    </div>
                </div>
            </div>
        )
    }
}

export default LoginFirstStep