import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as actions from "./stores/actionsList";
import { Route, Switch } from "react-router-dom";

import i18n from "i18next";
import { siteConfig } from "./variables/config";
import {
    changeUrlWithLng,
    getToken,
    getUserInfo,
} from "./utils/useful";

import routes from "./variables/routes";
import Header from "./components/Header";
import Footer from "./components/Footer";
import TabBar from "./components/TabBar";
import NotifManager from "./components/NotifManager";
import ProtectedRoute from "./components/ProtectedRoute";

class App extends React.Component {
    state = {
        lngRoute: "",
        showPage: false,
    };

    componentDidMount() {
        this.checkUser();
        this.initConfig();
        if (!this.props?.user?.loggedin) {
            this.props.actions.logoutUser();
            this.props.history.push("/login")
        }
        if (
            this.props.user.loggedin &&
            !this.props.socket?.connected
        ) {
            this.initSocketConnection()
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (
            prevProps.history.action === "PUSH" &&
            this.state.prevLoc !== prevProps.history.location
        ) {
            window.scrollTo(0, 0);
            this.props.actions.changeSetting(
                "lastLocation",
                this.state.prevLoc
            );
            this.setState({ prevLoc: prevProps.history.location });
        }
        const loginCheck =
            this.props.user.loggedin !== prevProps.user.loggedin &&
            this.props.user.loggedin;

        if (loginCheck) {
            this.checkUser();
        }
    }

    initSocketConnection = () => {
        this.props.actions.initSocket();
    };

    initConfig() {
        i18n.on("languageChanged", (lng) => {
            this.setState({ showPage: false });

            if (siteConfig.forceUrl) {
                changeUrlWithLng(lng);
            }
            this.props.actions.changeLanguage("language", lng);
            this.setState({ showPage: true });
        });
        this.setSize();

        window.addEventListener("resize", this.setSize);
    }

    setSize = () => {
        let sizes = { isMobile: false, isTablet: false, isDesktop: false };
        let key = "isDesktop";
        let width = window.innerWidth;
        if (width < 768 && width >= 576) {
            key = "isTablet";
        }
        if (width < 576) {
            key = "isMobile";
        }

        sizes[key] = true;

        for (const [key, value] of Object.entries(sizes)) {
            this.props.actions.changeSetting(key, value);
        }
    };

    checkUser = (dontShowNotif) => {
        // TODO setTimeout prevents the delay in seeing the new value of login and it works fine. However, I guess the logic should be working 
        // without setTimeout
        setTimeout(() => {
            console.log(this.props.user.loggedin)
            if (this.props.user.loggedin) {
                if (getToken()) {
                    getUserInfo((data, err, status) => {
                        if (status === 401) {
                            this.props.actions.logoutUser();
                        }
                        if (status === 200 && data) {
                            //
                            if (data.authorized || data.authoried) {
                                this.props.actions.setUser(
                                    data.user,
                                    null,
                                    true
                                );
                            } else {
                                this.props.actions.logoutUser();
                            }
                        }

                        if (err) {
                            if (!dontShowNotif) {
                                this.props.actions.addNotif({
                                    type: "error",
                                    title: "{{lang}}errors.cantConnectToServer",
                                    description:
                                        "{{lang}}errors.checkYourInternetConnection",
                                });
                            }

                            this.props.actions.setUser({}, null, true);
                            setTimeout(() => {
                                this.checkUser(true);
                            }, 10000);
                        }
                    });
                } else {
                    this.props.actions.logoutUser();
                }
            }
        }, 1000);
    };

    render() {
        if (this.state.showPage) {
            return (
                <div className={"container-fluid default-theme m-0 p-0"}>
                    <NotifManager>
                        <div className="d-flex">
                            {this.props.settings.showTabBar && <TabBar />}

                            <div
                                style={{ width: "100%", position: "relative" }}
                            >
                                {this.props.settings.showHeader && <Header />}

                                <div
                                    className=""
                                    style={{ minHeight: "100vh" }}
                                >
                                    <Switch>
                                        {routes.map((prop, index) => {
                                            if (prop.protected) {
                                                return (
                                                    <ProtectedRoute
                                                        prop={prop}
                                                        key={index}
                                                        exact={prop.exact}
                                                        path={
                                                            (siteConfig.dontShowUrlForDefaultLng
                                                                ? i18n.language ===
                                                                    siteConfig.defaultLng
                                                                    ? ""
                                                                    : "/" +
                                                                    i18n.language
                                                                : "/" +
                                                                i18n.language) +
                                                            prop.path
                                                        }
                                                        component={
                                                            prop.component
                                                        }
                                                    />
                                                );
                                            } else {
                                                return (
                                                    <Route
                                                        key={index}
                                                        exact={prop.exact}
                                                        path={
                                                            (siteConfig.dontShowUrlForDefaultLng
                                                                ? i18n.language ===
                                                                    siteConfig.defaultLng
                                                                    ? ""
                                                                    : "/" +
                                                                    i18n.language
                                                                : "/" +
                                                                i18n.language) +
                                                            prop.path
                                                        }
                                                        component={
                                                            prop.component
                                                        }
                                                    />
                                                );
                                            }
                                        })}
                                    </Switch>
                                </div>

                                {this.props.settings.showFooter && <Footer />}
                            </div>
                        </div>
                    </NotifManager>
                </div>
            );
        } else {
            return <div></div>;
        }
    }
}

const mapStateToProps = (state) => ({
    settings: state.settings,
    user: state.user,
    resource: state.resource,
    notif: state.notif,
    socket: state.socket,
});
const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
