import React, { Component } from "react";
import SelectInput from "../SelectInput"



class SelectVariableType extends Component {

    render(){
        const { header, data, changeValue } = this.props;
        return (
            <>
                <SelectInput
                    selectHolder={{
                        styles: {},
                        className: "editorVariableSelectInput",
                    }}
                    selectOptions={{
                        holderStyle: {},
                        className: "selectOptions",
                        selectedValue: {
                            defaultText: data || "Select...",
                            style: {},
                            className: `selectedValue`,
                        },
                        list: {
                            holderStyle: {},
                            className: `list`,
                            options: [...header.listOptions],
                            singleOption: {
                                divStyle: {
                                    zIndex: 70,
                                },
                                divClassName: `divClassName`,
                                labelStyle: {
                                    cursor: "pointer",
                                    width: "100%",
                                    height: "100%",
                                },
                                labelClassName: "labelClassName",
                                inputStyle: {
                                    display: "none",
                                },
                            },
                        },
                    }}
                    isEditable={true}
                    optionName={"key"}
                    selectFunction={({ value }) => {
                        
                        changeValue(header.key, value);
                    }}
                />
            </>
        );
    }
}


export default SelectVariableType;