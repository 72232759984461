import React, { useRef, useState } from 'react'
import { checkTranslation, getObject } from '../../utils/useful'
import tableComponents from '../tableComponents'


class TableRow extends React.Component {

    render() {

        return (

            <div ref={el => this.row = el} className="tableRow" style={{ width: this.props.widths['*'] }}>

                {this.props.headers?.map((header, index) => {
                    let isSticky = false
                    if (index === 0 && Math.abs(this.props.scrollX) > 5) {
                        isSticky = true
                    }
                    let data = getObject(this.props.data, header.key)
                    let Component = tableComponents[header.type]
                    return (
                        <div className={"tableBodyItem " + (isSticky ? 'stickytableBodyItem' : '')} key={header.key} style={{ transition: 'all 0.3', flex: index == 0 ? '1 0' : '0 0', maxWidth: index == 0 ? null : this.props.widths[header.key], flexBasis: this.props.widths[header.key] }}>
                            {Component &&(
                            <Component data={data} />
                            )}
                        </div>
                    )
                })}
                {!this.props.settings?.extendedActions && this.props.actions && (
                    <div className={"tableBodyItem px-3 py-2"} style={{ transition: 'all 0.3', flex: '0 0', maxWidth: 120, flexBasis: 120 }}>
                        <button onClick={() => this.props.openActionsModal(this.props.data, this.props.index)} style={{ background: '#677dc415', borderRadius: 20, padding: '4px 12px' }}>
                            <p className="text-smaller text-bold text-uppercase" style={{ color: '#677dc4aa' }}>{checkTranslation('actions')}</p>
                        </button>
                    </div>
                )}

            </div>


        )
    }

}

export default TableRow
