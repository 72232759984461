import React from "react"
const PositiveNegativeButtons = ({
    positiveFunc,
    negativeFunc,
    negativeText,
    positiveText,
    positiveClassName,
    negativeClassName,
    styles,
    positiveActive,
    negativeActive,
    parentDivClassName,
}) => {
    return (
        <div className={parentDivClassName}>
            <button
                style={{ display: positiveText?.length ? "block" : "none" }}
                disabled={positiveActive}
                onClick={() => positiveFunc()}
                className={!positiveActive ? positiveClassName : ""}
            >
                {positiveText}
            </button>
            <button
                style={{ display: negativeText?.length ? "block" : "none" }}
                disabled={negativeActive}
                onClick={() => negativeFunc()}
                className={negativeClassName}
            >
                {negativeText}
            </button>
        </div>
    );
}

export default PositiveNegativeButtons
