import React from "react";
// import HttpService from '../utils/Http.services';
// import { siteConfig, siteTheme } from "../variables/config";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actions from '../stores/actionsList';
// import TabBar from "../views/TabBar";
import Configurer from "../components/Configurer";
import Dashboard from "./profile/Dashboard";
import StagesManager from "../components/StagesManager";
import { checkTranslation, pathMaker, translate } from "../utils/useful";
import EditProfile from "./profile/EditProfile";
import Cookies from "universal-cookie";
import PageStatusViewer from "../components/PageStatusViewer";
import AccountType from "./profile/AccountType";
import ChangePasword from "./profile/ChangePasword";
import AdminDashboard from "./admin/AdminDashboard";
import AdminFormApplet from "./admin/AdminFormApplet";
import { adminPages } from "../variables/adminPages";
// import { logoutUser } from "../stores/actionsList";


class Admin extends React.Component {

    state = {
        modules: [],
        pages: [
            { name: 'Dashboard', key: '', dontShowInDashboard: true },
            { name: 'Edit Profile', icon: 'https://www.flaticon.com/svg/static/icons/svg/2444/2444452.svg', key: 'edit' },
            { name: 'Account type', icon: 'https://www.flaticon.com/svg/static/icons/svg/744/744922.svg', key: 'type' },
            { name: 'Change Password', icon: 'https://www.flaticon.com/svg/static/icons/svg/3039/3039392.svg', key: 'password' },
        ]
    }


    componentDidMount() {

        const cookies = new Cookies();
        let token = cookies.get('token');
        if (token) {
            this.setState({ isLoading: true })
            this.waitForId(10)
        } else {
            this.setState({ pageStatus: 401 })
        }

        // setInterval(() => {
        //     // 
        //     if(this.configurer){
        //         
        //         
        //     }
        // }, 2000);

        // if(this.configurer){
        //     
        //     
        // }
    }


    waitForId = (leftAttempt) => {
        if (this.props.user?.info?._id) {
            this.setState({ isLoading: false }, () => {
                this.checkDefaultTab()
                let accessPages = Object.values(adminPages)
                this.setState({ modules: accessPages })
            })
        } else {
            setTimeout(() => {
                if (leftAttempt != 0) {
                    this.waitForId(leftAttempt - 1)
                } else {
                    // this.props.actions.logoutUser()
                    this.setState({ pageStatus: 401 })
                }
            }, 500);
        }
    }


    componentDidUpdate(newProps) {
        let windowPath = this.props.location.pathname.split('/')
        windowPath = windowPath[windowPath.length - 1]

        // 
        // 
        if (this.state.oldPath && this.state.oldPath != this.props.location.pathname) {
            this.setState({ oldPath: this.props.location.pathname }, () => {
                this.checkDefaultTab()
            })
        }
    }



    checkDefaultTab() {

        let def = ''
        let pageType = null
        let pageInfo = null
        let pathname = window.location.pathname.split('/')
        pathname.forEach((path, index) => {
            if (path == 'admin') {
                def = pathname[index + 1]
            }
        });

        // 


        let pageIsValid = false

        Object.values(adminPages).forEach(page => {
            if (page.key.toLowerCase() === def?.toLowerCase()) {
                pageIsValid = true
                pageType = page.pageType
                // 
                pageInfo = page
            }
        });
        if (!def) {
            def = ''
        }
        // 
        if (def === '') {
            pageType = ''
            pageIsValid = true
        }
        if (pageIsValid) {
            // this.state.page = page
            // 
            this.setState({ oldPath: this.props.location.pathname, pageStatus: 200 }, () => {
                this.setState({ path: def, }, () => {
                    this.stageManager.changeStage(pageType, () => {
                        window.scrollTo({ top: 0, behavior: 'smooth' })
                    })
                    setTimeout(() => {
                        this.setState({ page: null },()=> {

                            this.setState({ page: pageInfo })
                        })
                    }, 400);
                })

                // this.changePage(def)
                // if (def == '' || !def) {
                // this.stageManager.changeStage('dashboard')
                // this.stageManager.changeStage(def)
                // // this.props.history.push(pathMaker('/profile'))
                // } else {

                //     this.stageManager.changeStage(def)

                // }
            })
        } else {
            this.setState({ pageStatus: 404 })
        }

    }



    changePage = (page) => {

        let path = pathMaker('/admin/' + page)

        this.props.history.push(path)

    }


    setUser = (data) => {
        
        this.props.actions.setUser(data, null, true)
    }


    addNotif = (options) => {
        this.props.actions.addNotif(options)
    }

    render() {
        return (

            <Configurer
                settingsInfo={{ showFooter: true, showTabBar: true, showHeader: true, headerTitle: "Admin", button: {} }}
                title={"Admin"}
                description={"Admin Description"}
                className="p-0 min-full-height"
                ref={el => this.configurer = el}
            >

                <PageStatusViewer status={this.state.pageStatus}>

                    <div className="row m-0">

                        <div className="col-12 col-sm-12 col-md-4 col-lg-2 pageSide px-0 d-md-block d-none" >
                            <div>
                                <div className="text-center py-3">
                                    <img src="/images/male.png" width="70px" style={{ borderRadius: 100 }} />
                                    <p className="font-bold mt-1">{this.props.user?.info?.name} {this.props.user?.info?.family}</p>
                                    <p className="text-small">Admin Panel</p>
                                </div>

                                <div className="mt-2">
                                    <button onClick={() => this.changePage('')} className={"pageSideOption " + ('' == this.state.path ? "selected" : '')}>
                                        <p>{translate('dashboard')}</p>
                                    </button>

                                    {this.state.modules.map((prop, index) => {
                                        return (
                                            <button onClick={() => this.changePage(prop.key)} key={index} className={"pageSideOption " + (prop.key == this.state.path ? "selected" : '')}>
                                                <p>{checkTranslation(prop.title)}</p>
                                            </button>
                                        )
                                    })}
                                </div>

                            </div>
                        </div>


                        <div className="col-12 col-sm-12 col-md-8 col-lg-10" style={{ padding: '2% 3%' }}>

                            <StagesManager ref={el => this.stageManager = el} >
                                <AdminDashboard pages={this.state.pages} stage={0} user={this.props.user} modules={this.state.modules} stageName={''} changePage={this.changePage} />
                                <AdminFormApplet pages={this.state.pages} page={this.state.page} stage={1} user={this.props.user} setUser={this.setUser} addNotif={this.addNotif} stageName={'form'} />
                            </StagesManager>

                        </div>
                    </div>

                </PageStatusViewer>

            </Configurer>
        )
    }


}



const mapStateToProps = state => ({ settings: state.settings, user: state.user })
const mapDispatchToProps = dispatch => ({ actions: bindActionCreators(actions, dispatch) })

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Admin);
