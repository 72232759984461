import { combineReducers } from 'redux';
import settingsReducer from './reducers/settings.reducer';
import userReducer from './reducers/user.reducer';
import cartReducer from './reducers/cart.reducer';
import notifsReducer from './reducers/notifs.reducer';
import { socketConnection } from './reducers/socket.reducer';

import resourceReducer from './reducers/resource.reducer';


export default combineReducers({
    settings: settingsReducer,
    user: userReducer,
    cart: cartReducer,
    resource: resourceReducer,
    notifs: notifsReducer,
    socket: socketConnection
});