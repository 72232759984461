import React from "react";
import { translate } from '../utils/useful'
import { siteConfig } from '../variables/config'
import i18n from "../i18n";
import TLink from "../components/TLink";

class Footer extends React.Component {

  state = {
    subscribed: false,
    email: '',
    socialNetworks: [
      { name: 'facebook', icon: 'https://image.flaticon.com/icons/svg/1409/1409943.svg' },
      { name: 'linkedin', icon: 'https://image.flaticon.com/icons/svg/1409/1409945.svg' },
      { name: 'instagram', icon: '/images/insta.svg' },
      { name: 'youtube', icon: 'https://image.flaticon.com/icons/svg/2965/2965363.svg' },
      { name: 'aparat', icon: 'https://cdn.iconscout.com/icon/free/png-512/aparat-569197.png' },

    ],

    links: {
      company: [
        { title: translate('aboutus'), link: '/aboutus' },
        { title: translate('Tutorials'), link: '/tutorials' },
        { title: translate('services'), link: '/services' },

      ],

      fastAccess: [
        { title: translate('faq'), link: '/pages/faq' },
        { title: translate('payment-methods'), link: '/pages/payments' },
        { title: translate("privacy-policy"), link: '/pages/policy' },
      ],


      agreements: [
        { title: translate("privacy-policy"), link: '/pages/policy' },
        { title: translate("terms"), link: '/pages/terms' },
      ],



    }


  }


  // subscribe() {
  //   localStorage.setItem('subscribed', this.state.email)
  //   this.setState({ subscribed: true })
  // }

  render() {
    return (
      <footer
        className={"w-100"}
        style={{ bottom: 0, position: 'relative' }}
      >
        <div className={''} style={{ color: 'rgba(10,12,15,1)', backgroundColor: '#e2eaf0', margin: 0, paddingBottom: 40 }}>


          <div className={'container px-4'}>
            <div className="row w-100 pt-4">

              <div className={'col-6 col-sm-6 col-md-4 mt-4'}>
                <div className="mb-2" style={{ fontSize: 17, fontWeight: 'bold', color: '#202020' }}>{translate("fast-access")}</div>
                {this.state.links.company.map((prop, index) => {
                  return (
                    <div key={index} style={{ display: 'block-inline' }}>
                      <TLink to={prop.link}>
                        <span style={{ fontSize: 15, marginTop: 5 }}>{prop.title}</span>
                      </TLink>
                    </div>
                  )
                })}
              </div>


              <div className={'col-6 col-sm-6 col-md-4 mt-4'}>
                <div className="mb-2" style={{ fontSize: 17, fontWeight: 'bold', color: '#202020' }}>{translate("customer-service")}</div>
                {this.state.links.fastAccess.map((prop, index) => {
                  return (
                    <div key={index} style={{ display: 'block-inline' }}>
                      <TLink to={prop.link}>
                        <span style={{ fontSize: 15, marginTop: 5 }}>{prop.title}</span>
                      </TLink>
                    </div>
                  )
                })}
              </div>


              {/* <div className={'col-6 col-sm-6 col-md-4 mt-4'}>
                <div className="mb-2" style={{ fontSize: 17, fontWeight: 'bold', color: '#202020' }}>{translate("fast-access")}</div>
                {this.state.links.company.map((prop, index) => {
                  return (
                    <div key={index} style={{ display: 'block-inline' }}>
                      <TLink to={prop.link}>
                        <span style={{ fontSize: 15, marginTop: 5 }}>{prop.title}</span>
                      </TLink>
                    </div>
                  )
                })}
              </div> */}

              {/* text-sm-left */}
              <div className={'col-6 col-md-4 mt-4'}>
                <div className={"mb-2"} style={{ fontSize: 17, fontWeight: 'bold', color: '#202020' }}>{translate('contactus')}</div>

                <div className="mt-3 mb-3 d-flex d-block  align-items-center  "  >

                  <img className="" src="/images/phone.svg" width="20px" alt="" />
                  <div className="mld-2 mt-2 mt-lg-0">
                    <a href={"tell:" + siteConfig.phone} style={{ fontSize: 15 }}>{siteConfig.phone}</a>
                    {/* <span style={{ color: '#757575', fontSize: 13 }}>{translate('phone-desc')}</span> */}
                  </div>
                </div>

                <div className="mt-2 mb-3 d-flex d-block  align-items-center mt-md-0  "  >
                  <img className="" src="/images/email-1.svg" width="20px" alt="" />
                  <div className="mld-2 mt-2 mt-lg-0">
                    <a href={"mailto:" + siteConfig.email} style={{ fontSize: 15 }}>{siteConfig.email}</a>
                    {/* <span style={{ color: '#757575', fontSize: 13 }}>{translate("email-desc")}</span> */}
                  </div>
                </div>


              </div>


              <div className={'col-12  p-0'}>
                <hr style={{ borderTop: "1px solid #bcd", opacity: 1 }}></hr>
              </div>


              <div className={'col-12 col-md-6 '}>
                <span className='' style={{ whiteSpace: 'pre-wrap', fontSize: 14, }}>{i18n.language === "fa" ? siteConfig.addressFa : siteConfig.addressEn}</span>
                <span className='flexc' style={{ whiteSpace: 'pre-wrap', fontSize: 14, }}>&copy; {translate('copyright', { name: siteConfig.name })}</span>



              </div>


              <div className={'col-12 col-md-6 text-right'}>
                <div className="d-flex mb-1 justify-content-start justify-content-md-end">
                  {this.state.links.agreements.map((prop, index) => {
                    return (
                      <div key={index} className="mld-2 flexcc">
                        <TLink to={prop.link}>
                          <span style={{ fontSize: 15, marginTop: 5 }}>{prop.title}</span>
                        </TLink>
                        {index == 0 && (
                          <p className="mx-2 my-0 opacity-7">|</p>
                        )}
                      </div>
                    )
                  })}
                </div>

                <div className="mt-1 d-flex justify-content-start justify-content-md-end" style={{ filter: 'grayscale(0%) contrast(100%)' }}>


                  {this.state.socialNetworks.map((prop, index) => {
                    if (siteConfig[prop.name] && siteConfig[prop.name] != '')
                      return (
                        <a key={index} rel="no-follow noopener noreferrer" target="_blank" className="mld-2" href={siteConfig[prop.name]}>
                          <img src={prop.icon} width="20px" alt="" />
                        </a>
                      )
                  })}


                </div>
              </div>


            </div>




          </div>


        </div>
      </footer>
    );
  }
}



export default Footer;
