import React from 'react'
import LoaderButton from '../../components/LoaderButton'
import FormViewer from '../../components/FormViewer'
import { translate } from '../../utils/useful'
import NumberInput from '../../components/inputs/NumberInput'
import SmartInput from '../../components/inputs/SmartInput'
import { withRouter } from 'react-router'
import HttpServices from '../../utils/Http.services'
import ResultModal from '../../modals/ResultModal'
// import HttpService from '../../utils/Http.services';

class WizardCompileStep extends React.Component {
    state = {
        errors: {},

        constraints: [],
        variables: [],
        formHeaders: [
            { key: 'type', type: 'SelectInput', information: { label: '{{lang}}wizard.type', type: 'local', items: [{ title: 'Max', value: 'max' }, { title: 'Min', value: 'min' }], icon: 'https://image.flaticon.com/icons/svg/13/13305.svg', placeholder: '{{lang}}insert', required: true } },
            { key: 'limitsCount', type: 'NumberInput', information: { label: '{{lang}}wizard.limitsCount', icon: 'https://image.flaticon.com/icons/svg/13/13305.svg', placeholder: '{{lang}}insert', required: true } },
            { key: 'variablesCount', type: 'NumberInput', information: { label: '{{lang}}wizard.variablesCount', icon: 'https://image.flaticon.com/icons/svg/13/13305.svg', placeholder: '{{lang}}insert', required: true } }]
    }


    componentDidMount() {

        // 
        let data = this.props.initData
        let variables = data.variables
        let constraints = data.constraints
        let type = data.type

        let compiled = type + ' = '
        let isFirstVariable = true

        // 

        variables.forEach(variable => {
            if (variable.value && variable.value != '' && variable.value != 0) {
                if (!isFirstVariable) {
                    compiled = compiled + (Number(variable.value) > 0 ? " + " : ' ')
                } else {
                    isFirstVariable = false
                }
                compiled = compiled + (variable.value == 1 ? '' : (variable.value + '*')) + variable.name
            }
        });


        constraints.forEach(constraint => {
            let isFirstVariable = true
            let hasRow = false
            Object.entries(constraint.variables).forEach(entries => {
                if (entries[1] && entries[1] != '' && entries[1] != 0) {

                    if (!isFirstVariable) {
                        compiled = compiled + (Number(entries[1]) > 0 ? " + " : ' ')
                    } else {
                        compiled = compiled + ';' + '\n'
                        isFirstVariable = false
                        hasRow = true
                    }
                    compiled = compiled + (entries[1] == 1 ? '' : (entries[1] + '*')) + entries[0]

                }
            });
            if (hasRow) {
                compiled = compiled + ' ' + this.convertSign(constraint.sign) + ' ' + ((constraint.value != null && constraint.value != '') ? constraint.value : 0)
            }

            // 
        });

        compiled = compiled + ';'

        // 
        this.setState({ compiled })

    }

    convertSign(sign) {
        
        switch (sign) {
            case '2':
                return '='
                break;
            case '1':
                return '>='
                break;
            case '0':
                return '<='
                break;
            default:
                return '='
                break;
        }
    }

    postEquation = () => {
        // 
        let text = this.state.compiled//this.smartInput.getInputValue()
        let data = this.props.initData
        let variables = data.variables
        let constraints = data.constraints
        let type = data.type

        constraints = []

        data.constraints.forEach(constraint => {
            // constraints.push (constraint)
            let newConstraint = { ...constraint }
            newConstraint.sign = this.convertSign(constraint.sign)
            newConstraint.variables = {}
            for (let [key, value] of Object.entries(constraint.variables)) {
                newConstraint.variables[key] = { name: key, value: (value && value != '' ? value : 0) }
            }
            newConstraint.value = (newConstraint.value && newConstraint.value != '' ? newConstraint.value : 0)
            constraints.push(newConstraint)
        });


        data.variables.forEach(variable => {
            if (!variable.value || variable.value == '') {
                variable.value = 0
            }
        });


        let postdata = {
            constraints: constraints,
            type: type,
            variables: variables,
            name: data.name,
            nickname: data.nickname,
            text
        }

        
        this.setState({ isLoadingPost: true })

        HttpServices.request("postEquation", postdata, (fetchResult, fetchError) => {
            this.setState({ isLoadingPost: false })

            if (fetchError) {
                // 
                return
            }

            this.resultModal.modal.showModal()


            
            fetchResult.result.time = fetchResult.time
            fetchResult.result.insertTime = fetchResult.insertTime
            fetchResult.result.removeTime = fetchResult.removeTime
            fetchResult.result.readTime = fetchResult.readTime

            this.setState({ result: fetchResult.result })

            if (!this.state.name || this.state.name == '') {
                // window.history.pushState(null, null, pathMaker('/editor/' + fetchResult.name));

                this.props.setData({ name: fetchResult.name }, () => {

                })
                // this.setState({ name: fetchResult.name })
            }


        })


    }


    openEditor = () => {
        this.props.history.push({
            pathname: '/editor/' + (this.props.initData?.name ?? ''),
            state: { compiled: this.state.compiled, nickname:this.props.initData?.nickname }
        })
    }


    render() {
        return (

            <div className="flexcc flex-column">
                <h2 className="mt-0 mb-0" style={{ fontSize: 27 }}>{translate("Compiled")}</h2>
                <div className="flexcc" style={{ maxWidth: 400 }}>
                    <p className="mb-4 mt-0 text-center" style={{ fontSize: 15 }}>{translate('This is the compiled calculation. If you confirm it, press the confirm button to see the results')}</p>
                </div>
                <div className="w-100 px-4" style={{}}>




                    {/* <p style={{color:'#845EC2'}} className="mt-4 mb-2 text-normal text-bold">Technical coefficients of constraints (a_ij)</p> */}
                    <div className="px-3 py-4 text-small" style={{ overflow: 'auto', whiteSpace: 'pre-wrap', backgroundColor: '#f2f6f8', borderRadius: 4 }}>
                        {this.state.compiled}
                    </div>


                    {/* <SmartInput ref={el => this.smartInput = el} data={this.state.compiled} /> */}



                    <div className="text-center">
                        <LoaderButton
                            onClick={this.postEquation}
                            isLoading={this.state.isLoading}
                            text={translate("Solve").toUpperCase()}
                            type={"Oval"}
                            className="mt-4 mb-2"
                            buttonStyle={{ outline: 'none', backgroundColor: '#222', cursor: 'pointer', padding: '10px 50px', borderRadius: 4, fontSize: 15, fontWeight: 'bold', color: '#fff', border: 'none' }}
                            width={40}
                            height={40}
                            color={'#202020'}
                        />


                        <LoaderButton
                            onClick={this.openEditor}
                            isLoading={this.state.isLoading}
                            text={translate("Open in Editor").toUpperCase()}
                            type={"Oval"}
                            className="mt-2 mb-4"
                            buttonStyle={{ outline: 'none', backgroundColor: '#789', cursor: 'pointer', padding: '10px 50px', borderRadius: 4, fontSize: 13, fontWeight: 'bold', color: '#fff', border: 'none' }}
                            width={40}
                            height={40}
                            color={'#202020'}
                        />

                    </div>

                    <div className="w-100 text-center">
                        <button className="mb-3" onClick={() => this.props.lastStage()} style={{ fontSize: 13, }}>{translate('back-one-stage')}</button>
                    </div>

                </div>

                <ResultModal ref={el => this.resultModal = el} data={this.state.result} variables={this.props.initData.variables} constraints={this.props.initData.constraints} type={this.props.initData.type} />

            </div>

        )
    }
}

export default withRouter(WizardCompileStep);