import React, { useState } from "react";
import { useEffect, useRef } from "react";

const SelectInput = ({
    selectHolder,
    selectLabel,
    selectOptions,
    isEditable,
    selectFunction,
    optionName,
    alwaysShowOptions
}) => {
    const selectRef = useRef(null);
    const [state, setState] = useState({
        displayOptions: false,
    });
    const setSelectedOption = (opt) => {
        setState({ displayOptions: false });
        selectFunction(opt);
    };
    useEffect(() => {
        function handleClickOutside(event) {
            if (
                selectRef.current &&
                !selectRef.current.contains(event.target)
            ) {
                setState((prev) => {
                    return {
                        ...prev,
                        displayOptions: false,
                    };
                });
            }
        }
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [selectRef]);

    return (
        <div
            style={selectHolder?.styles}
            className={selectHolder?.className}
            ref={selectRef}
        >
            <div style={selectLabel?.style}>{selectLabel?.text}</div>
            <div
                style={selectOptions?.holderStyle}
                className={selectOptions?.className}
            >
                <div
                    style={selectOptions?.selectedValue?.style}
                    className={selectOptions?.selectedValue?.className}
                    onClick={() =>
                        setState((prev) => {
                            return {
                                ...prev,
                                displayOptions:
                                    isEditable && !prev?.displayOptions,
                            };
                        })
                    }
                >
                    {selectOptions?.selectedValue?.defaultText}
                </div>
                <div
                    className={selectOptions?.list?.className}
                    style={Object.assign({
                        ...selectOptions?.list?.holderStyle,
                        display: alwaysShowOptions ? "block"  : state?.displayOptions
                            ? "block"
                            : "none",
                    })}
                >
                    {selectOptions?.list?.options?.map((opt, index) => {
                        return (
                            <div
                                key={index}
                                onClick={() => setSelectedOption(opt)}
                                style={
                                    selectOptions?.list?.singleOption?.divStyle
                                }
                                className={
                                    selectOptions?.list?.singleOption
                                        ?.divClassName
                                }
                            >
                                <div
                                    style={
                                        selectOptions?.list?.singleOption
                                            .labelStyle
                                    }
                                    className={
                                        selectOptions?.list?.singleOption
                                            .labelClassName
                                    }
                                    // className="text-8xl"
                                >
                                    {opt[optionName]}
                                </div>
                        
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

export default SelectInput;
