import React from "react";
import HttpService from "../utils/Http.services";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as actions from "../stores/actionsList";
// import TabBar from "../views/TabBar";
import Configurer from "../components/Configurer";
import TLink from "../components/TLink";
import { checkTextTranslation } from "../utils/useful";
import Modal from "../components/Modal";
import Loader from "react-loader-spinner";
import moment from "jalali-moment";

class Home extends React.Component {
    state = {
        strategies: [
            {
                name: "Editor",
                description: "You the editor to insert info all by your self",
                address: "editor",
                icon: "/images/editor.svg",
            },
            {
                name: "Wizard",
                description:
                    "Fast and easy way to insert data and do the calculation",
                address: "wizard",
                icon: "/images/wizard.svg",
            },
        ],
        functions: {
            deterministic: {
                key: "deterministic",
                name: "Deterministic Model",
                description:
                    "This is the description of this action. It can be about what this module does",
                icon: "/images/icons/3485642.svg",
            },
            hybrid: {
                key: "hybrid",
                name: "Hybrid Model",
                description:
                    "This is the description of this action. It can be about what this module does",
                icon: "/images/icons/3485694.svg",
            },
            stochastic: {
                key: "stochastic",
                name: "Stochastic Model",
                description:
                    "This is the description of this action. It can be about what this module does",
                icon: "/images/icons/3485609.svg",
            },

            linearOptimization: {
                key: "linearOptimization",
                name: "Linear Optimization",
                parent: "deterministic",
                description:
                    "This is the description of this action. It can be about what this module does",
                icon: "/images/icons/angle.svg",
                function: "openOptions",
            },
            nonlinearOptimization: {
                key: "nonlinearOptimization",
                name: "Nonlinear Optimization",
                parent: "deterministic",
                description:
                    "This is the description of this action. It can be about what this module does",
                icon: "/images/icons/graph.svg",
                function: null,
            },

            linearProgramming: {
                key: "linearProgramming",
                name: "Linear Programming",
                parent: "linearOptimization",
                description:
                    "This is the description of this action. It can be about what this module does",
                icon: "/images/icons/angle.svg",
                function: "openAddNew",
            },
            multiCriteria: {
                key: "multiCriteria",
                name: "Multi-criteria desision-making programming",
                parent: "linearOptimization",
                description:
                    "This is the description of this action. It can be about what this module does",
                icon: "/images/icons/barrier.svg",
            },

            integerProgramming: {
                key: "integerProgramming",
                name: "Integer Programming",
                parent: "linearOptimization",
                description:
                    "This is the description of this action. It can be about what this module does",
                icon: "/images/icons/barrier.svg",
            },
            transportation: {
                key: "transportation",
                name: "transportation and assignment model",
                parent: "linearOptimization",
                description:
                    "This is the description of this action. It can be about what this module does",
                icon: "/images/icons/barrier.svg",
            },
            networkModel: {
                key: "networkModel",
                name: "Network Model",
                parent: "linearOptimization",
                description:
                    "This is the description of this action. It can be about what this module does",
                icon: "/images/icons/barrier.svg",
            },

            pert_cpm: {
                key: "pert_cpm",
                name: "Pert - CMP",
                parent: "hybrid",
                description:
                    "This is the description of this action. It can be about what this module does",
                icon: "/images/icons/barrier.svg",
                function: null,
            },
            inventoryModel: {
                key: "inventoryModel",
                parent: "hybrid",
                name: "Inventory Model",
                description:
                    "This is the description of this action. It can be about what this module does",
                icon: "/images/icons/barrier.svg",
                function: null,
            },
            simulationModel: {
                key: "simulationModel",
                parent: "hybrid",
                name: "Simulation Model",
                description:
                    "This is the description of this action. It can be about what this module does",
                icon: "/images/icons/barrier.svg",
                function: null,
            },
            dynamicProgramming: {
                key: "dynamicProgramming",
                parent: "hybrid",
                name: "Dynamic Programming",
                description:
                    "This is the description of this action. It can be about what this module does",
                icon: "/images/icons/barrier.svg",
                function: null,
            },

            markov: {
                key: "markov",
                parent: "stochastic",
                name: "Markov Model",
                description:
                    "This is the description of this action. It can be about what this module does",
                icon: "/images/icons/barrier.svg",
                function: null,
            },
            queuing: {
                key: "queuing",
                parent: "stochastic",
                name: "Queuing Model",
                description:
                    "This is the description of this action. It can be about what this module does",
                icon: "/images/icons/barrier.svg",
                function: null,
            },
            descriptionAnalysis: {
                key: "descriptionAnalysis",
                parent: "stochastic",
                name: "Description Analysis Model",
                description:
                    "This is the description of this action. It can be about what this module does",
                icon: "/images/icons/barrier.svg",
                function: null,
            },
        },
    };

    componentDidMount() {
        this.fetchHistory();
    }

    fetchHistory = () => {
        this.setState({ isLoadingHistory: true });

        HttpService.request(
            "getHistory",
            { limit: 6 },
            (fetchResult, fetchError) => {
                this.setState({ isLoadingHistory: false });

                if (fetchError) {
                    return;
                }

                this.setState({ history: fetchResult.info });
            }
        );
    };

    openOptions = (prop) => {
        setTimeout(() => {
            this.optionsModal.hideModal(() => {
                this.setState({ selectedModule: prop }, () => {
                    this.optionsModal.showModal();
                });
            });
        }, 200);
    };

    openAddNew = () => {
        this.addModal.showModal();
    };

    render() {
        return (
            <Configurer
                settingsInfo={{
                    showFooter: true,
                    showTabBar: true,
                    showHeader: true,
                    headerTitle: "Dashboard",
                    button: {},
                }}
                title={"Home"}
                description={"Home Description"}
                className="px-4 py-5"
            >
                <div className="container">
                    <div className="row mb-5">
                        <div className="col-12 mb-2">
                            <h1 className="text-big text-center mb-4">
                                {"Start a new model".toUpperCase()}
                            </h1>
                        </div>
                        {Object.values(this.state.functions).map(
                            (prop, index) => {
                                if (prop.parent == null)
                                    return (
                                        <button
                                            onClick={() =>
                                                this.openOptions(prop)
                                            }
                                            className="text-center col-12 col-md-4 col-lg-4 mb-2 px-2"
                                            key={index}
                                        >
                                            <div
                                                className="h-100 w-100 p-4 "
                                                style={{
                                                    backgroundColor: "#f2f6f8",
                                                    borderRadius: 8,
                                                }}
                                            >
                                                <img
                                                    className="mb-1"
                                                    src={prop.icon}
                                                    width="70px"
                                                />
                                                <p className="text-normal font-bold mt-1 mb-1">
                                                    {prop.name}
                                                </p>
                                                <p
                                                    className="text-smaller"
                                                    style={{ color: "#789" }}
                                                >
                                                    {prop.description}
                                                </p>
                                            </div>
                                        </button>
                                    );
                            }
                        )}
                    </div>

                    <div className="row">
                        <div className="col-12 mb-4 text-center">
                            <h1 className="text-big text-center ">
                                {"Models History".toUpperCase()}
                            </h1>
                            <TLink
                                to={"/history"}
                                className="text-small"
                                style={{ color: "#007aff" }}
                            >
                                View Full History
                            </TLink>
                        </div>

                        {this.state.isLoadingHistory ? (
                            <div className="flexcc mx-2 pt-2 w-100">
                                <Loader
                                    type={"Oval"}
                                    color={"rgb(57,111,180)"}
                                    height={50}
                                    width={50}
                                />
                            </div>
                        ) : (
                            <div
                                className="col-12 col-md-12 order-2 order-md-1 "
                                style={{ color: "#567" }}
                            >
                                {!this.state.history ||
                                this.state.history.length == 0 ? (
                                    <div
                                        className="px-5 py-5 text-center flexcc flex-column"
                                        style={{
                                            backgroundColor: "#f2f6f8",
                                            borderRadius: 15,
                                        }}
                                    >
                                        <img
                                            className="mb-2"
                                            width="80px"
                                            src={"/images/icons/history.svg"}
                                        />
                                        <p className="text-big mb-2 mt-2 text-bold">
                                            HISTORY IS EMPTY
                                        </p>
                                        <p
                                            className="text-small"
                                            style={{ width: 420 }}
                                        >
                                            From top menu you should start a new
                                            model. You can then view the history
                                            of your solved models in this
                                            section
                                        </p>

                                        {/* <div className="mt-4">
                                    <button onClick={() => this.openAddNew()} to={'/editor'} style={{ backgroundImage: 'linear-gradient(#3869f4,#3362e5)', borderRadius: 6, padding: '10px 20px' }}>
                                        <span className="white text-small">New Calculation</span>
                                    </button>
                                </div> */}
                                    </div>
                                ) : (
                                    <div className={"row"}>
                                        {this.state.history.map(
                                            (prop, index) => {
                                                return (
                                                    <TLink
                                                        to={
                                                            "/editor/" +
                                                            prop.MODEL_NAME_V_SIM
                                                        }
                                                        key={index}
                                                        className="text-start col-12 col-md-6 col-lg-4 mb-4"
                                                    >
                                                        <div
                                                            className="h-100 w-100 py-3 px-4 d-flex "
                                                            style={{
                                                                alignItems:
                                                                    "flex-start",
                                                                backgroundColor:
                                                                    "#f2f6f8",
                                                                borderRadius: 8,
                                                            }}
                                                        >
                                                            <img
                                                                className="mb-2 mt-2"
                                                                width="50px"
                                                                src={
                                                                    "/images/icons/history.svg"
                                                                }
                                                            />
                                                            <div className="mld-4">
                                                                <p
                                                                    className="text-semibig font-bold"
                                                                    style={{
                                                                        color:
                                                                            "#101010",
                                                                    }}
                                                                >
                                                                    {
                                                                        prop.MODEL_NIKE_NAME_V_SIM
                                                                    }
                                                                </p>
                                                                <p
                                                                    className="text-smaller"
                                                                    style={{
                                                                        color:
                                                                            "#101010",
                                                                        wordBreak:
                                                                            "break-all",
                                                                    }}
                                                                >
                                                                    {prop.MODEL_TEXT_V_SIM.substring(
                                                                        0,
                                                                        100
                                                                    )}
                                                                </p>

                                                                {/* <p className="text-smaller">ID: {prop.MODEL_NAME_V_SIM}</p> */}
                                                                <div className="flexc">
                                                                    <p
                                                                        className="text-smaller mt-1"
                                                                        style={{
                                                                            whiteSpace:
                                                                                "nowrap",
                                                                        }}
                                                                    >
                                                                        Variables:{" "}
                                                                        {
                                                                            prop.K_V_SIM
                                                                        }
                                                                    </p>
                                                                    <p
                                                                        className="text-smaller mt-1 mx-2"
                                                                        style={{
                                                                            whiteSpace:
                                                                                "nowrap",
                                                                        }}
                                                                    >
                                                                        Constraints:{" "}
                                                                        {
                                                                            prop.M_V_SIM
                                                                        }
                                                                    </p>
                                                                </div>

                                                                <p className="text-smaller mt-1">
                                                                    Update date:{" "}
                                                                    {moment(
                                                                        prop.DATE_DUE_V_SIM *
                                                                            1000
                                                                    )
                                                                        .locale(
                                                                            "fa"
                                                                        )
                                                                        .format(
                                                                            "jYYYY-jMM-jDD"
                                                                        )}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </TLink>
                                                );
                                            }
                                        )}
                                    </div>
                                )}
                            </div>
                        )}

                        {/* <div className="col-12 col-md-6 flexcc order-1 order-md-2">

                            <img src="/images/dash.jpg" alt="" width="90%" />

                        </div> */}
                    </div>

                    <Modal
                        ref={(el) => (this.optionsModal = el)}
                        maxWidth={400}
                    >
                        <div
                            className="text-center px-3"
                            style={{
                                backgroundColor: "#fff",
                                borderRadius: 4,
                                padding: "20px 15px",
                            }}
                        >
                            <p className="text-bold text-semibig">Options</p>

                            <div className="row text-center mt-4 w-100 m-0">
                                {Object.values(this.state.functions).map(
                                    (prop, index) => {
                                        if (
                                            prop.parent &&
                                            prop.parent ==
                                                this.state.selectedModule?.key
                                        ) {
                                            return (
                                                <div
                                                    key={index}
                                                    className="mb-3 col-6"
                                                >
                                                    <button
                                                        onClick={() => {
                                                            if (prop.function)
                                                                this[
                                                                    prop
                                                                        .function
                                                                ](prop);
                                                        }}
                                                    >
                                                        <img
                                                            src={prop.icon}
                                                            width="60px"
                                                        />
                                                        <p className="text-small text-bold mb-1 mt-1">
                                                            {checkTextTranslation(
                                                                prop.name
                                                            )}
                                                        </p>
                                                        {!prop.function && (
                                                            <p
                                                                className="text-smaller mb-1"
                                                                style={{
                                                                    color:
                                                                        "#fff",
                                                                    backgroundColor:
                                                                        "#ee5050",
                                                                    borderRadius: 20,
                                                                    padding:
                                                                        "4px 8px",
                                                                }}
                                                            >
                                                                {checkTextTranslation(
                                                                    "Comming Soon"
                                                                )}
                                                            </p>
                                                        )}
                                                        {prop.function && (
                                                            <p className="text-smaller">
                                                                {checkTextTranslation(
                                                                    prop.description
                                                                )}
                                                            </p>
                                                        )}
                                                    </button>
                                                </div>
                                            );
                                        }
                                    }
                                )}
                            </div>
                        </div>
                    </Modal>

                    <Modal ref={(el) => (this.addModal = el)} maxWidth={400}>
                        <div
                            className="text-center"
                            style={{
                                backgroundColor: "#fff",
                                borderRadius: 4,
                                padding: "20px 15px",
                            }}
                        >
                            <p className="text-bold text-semibig">
                                What you prefer?
                            </p>

                            <div className="d-flex text-center mt-4">
                                {this.state.strategies.map((prop, index) => {
                                    return (
                                        <div
                                            key={index}
                                            className="mx-2"
                                            style={{ flex: 1 }}
                                        >
                                            <TLink to={prop.address}>
                                                <img
                                                    src={prop.icon}
                                                    width="60px"
                                                />
                                                <p className="text-big text-bold">
                                                    {checkTextTranslation(
                                                        prop.name
                                                    )}
                                                </p>
                                                <p className="text-small">
                                                    {checkTextTranslation(
                                                        prop.description
                                                    )}
                                                </p>
                                            </TLink>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </Modal>
                </div>
            </Configurer>
        );
    }
}

const mapStateToProps = (state) => ({
    settings: state.settings,
    user: state.user,
});
const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Home);
