import { SET_USER, LOGOUT_USER } from '../actionsList';
import {Router} from 'react-router-dom'
import Cookies from 'universal-cookie';
const cookies = new Cookies();
function loginUser(state, action) {
    let auth = action.auth
    if (auth) {
        cookies.set('token', auth.token, { path: '/' })
    }
}

function logoutUser(action) {
    cookies.remove('token', { path: '/' });
    
}

const user = store => next => action => {
    let result = next(action)
    if (action.type === SET_USER) {
        loginUser(store.getState(), action)
    }
    if (action.type === LOGOUT_USER) {
        logoutUser(action)
    }
    return result
}

export default user;