import React from 'react'
import LoaderButton from '../../components/LoaderButton'
import FormViewer from '../../components/FormViewer'
import { translate } from '../../utils/useful'
// import HttpService from '../../utils/Http.services';

class AddThingServices extends React.Component {
    state = {
        errors: {},
        formHeaders: [
            { key: 'name', type: 'TextInput', information: { label: '{{lang}}name', icon: 'https://image.flaticon.com/icons/svg/13/13305.svg', placeholder: '{{lang}}insertName', required: true } },
            { key: 'family', type: 'TextInput', information: { label: '{{lang}}family', icon: 'https://image.flaticon.com/icons/svg/13/13305.svg', placeholder: '{{lang}}insertFamily', required: true } },
            { key: 'password', type: 'PasswordInput', information: { label: '{{lang}}password', icon: 'https://image.flaticon.com/icons/svg/13/13305.svg', placeholder: '{{lang}}insertPassword', required: true } }]
    }

    postUserInfo = () => {
        this.setState({ isLoading: true, errors: {} })
        let data = this.form.getForm()
        this.props.setData(data, () => {
            this.props.postUserInfo((result, err) => {
                if (err) { this.setState({ errors: err }) }
                this.setState({ isLoading: false })
            })
        })
    }


    render() {
        return (

            <div className="flexcc flex-column">
                <h2 className="mt-4 mb-0" style={{ fontSize: 27 }}>{translate("information")}</h2>
                <p className="mb-4 mt-0" style={{ fontSize: 15 }}>{translate('insert-information')}</p>

                <div className="w-100" style={{ maxWidth: 400 }}>

                    <FormViewer ref={el => this.form = el} headers={this.state.formHeaders} initData={this.props.initData} errors={this.state.errors} inputClass={'modern-input'} />

                    <div className="text-center">
                        <LoaderButton
                            onClick={this.postUserInfo}
                            isLoading={this.state.isLoading}
                            text={translate("signup").toUpperCase()}
                            type={"Oval"}
                            className="mt-4 mb-4"
                            buttonStyle={{ outline: 'none', backgroundColor: '#222', cursor: 'pointer', padding: '10px 50px', borderRadius: 4, fontSize: 15, fontWeight: 'bold', color: '#fff', border: 'none' }}
                            width={40}
                            height={40}
                            color={'#202020'}
                        />
                    </div>

                    <div className="w-100 text-center">
                        <button className="mb-3" onClick={() => this.props.lastStage()} style={{ fontSize: 13, }}>{translate('back-one-stage')}</button>
                    </div>

                </div>
            </div>

        )
    }
}

export default AddThingServices;