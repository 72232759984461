import React from "react";


class DiagramPath extends React.Component {

    state = {
        // points: { start: [0, 0], end: [200, 100] }
    }

    componentWillUnmount() {
        window.removeEventListener('mousedown', this.mouseDown)
    }

    mouseHover = (e, isClick) => {
        if (!this.props.isActivePath) {
            this.setState({ hovered: true })
            window.addEventListener('mousedown', this.mouseDown)
        }
    }

    mouseLeave = (e) => {
        // 
        // 
        this.setState({ hovered: false })

    }

    // mouseButHover = (e, isClick) => {
    //     // 
    //     this.setState({ hovered: true })
    // }

    // mouseButLeave = (e) => {
    //     this.setState({ hovered: false })

    // }


    mouseDown = () => {
        window.removeEventListener('mousedown', this.mouseDown)
        this.setState({ hovered: false })
        // 
    }

    removePath = () => {
        window.removeEventListener('mousedown', this.mouseDown)
        if (this.props.removePath) {
            this.props.removePath(this.props.data.id)
        }
    }


    render() {
        let data = this.props.data
        let points = {}
        points.start = [data.start[0] + data.startOffset[0], data.start[1] + data.startOffset[1]]
        points.end = [data.end[0] + data.endOffset[0], data.end[1] + data.endOffset[1]]

        // let data = prop.data
        let width = points.end[0] - points.start[0]
        let height = points.end[1] - points.start[1]
        let xStart = width > 0 ? 0 : -width
        let yStart = height > 0 ? 0 : -height

        let curveX = 90
        let curveY = 10

        if (Math.abs(width) < 90) {
            curveX = width
            curveY = 0
        }

        if (Math.abs(height) < 10) {
            curveY = height
            curveX = 0
        }

        if(height === 0 || height ===1){
            height = 2
        }



        // 
        // 

        return (
            <>
                <svg height={Math.abs(height)} width={Math.abs(width)} style={{ position: 'absolute', top: points.start[1] - yStart, left: points.start[0] - xStart, overflow: 'visible', zIndex: -1, pointerEvents: 'none' }}>
                    {/* <path d="M0 0 L75 200 L225 200 S 270,200 270,250 L270 300 " fill="none" stroke="blue" stroke-width="3" /> */}
                    {/* <path d="M0 0 L75 200 L225 200 C260,200  270,200 270,220 L270 300" fill="none" stroke="blue" stroke-width="3" /> */}
                    {/* {height > 40  && (
                    <path d={
                        'M3 3 L ' + (width / 2 - 20) + ' 3 C ' + (width / 2 - 10) + ',3  ' + width / 2 + ',3 ' + width / 2 + ',20 L' + width / 2 + ' ' + (height - 20) +
                        ' C ' + (width / 2) + ',' + (height - 10) + '  ' + width / 2 + ',' + (height - 3) + ' ' + (width / 2 + 20) + ',' + (height - 3) + ' L' + width + ' ' + (height - 3)
                    }

                        fill="none" stroke="blue" stroke-width="3" />
                )} */}

                    {/* <path d={
                         'M3 3  C ' + (width / 2) + ', 0 '  +(width / 2) + ', '+(height) +' ' + (width-3) + ' ' + (height-3) 
                     } */}

                    {/* {(height > 40) && ( */}





                    <path d={
                        'M ' + xStart + ' ' + yStart + '' +
                        'C ' + (xStart + curveX) + ' ' + (yStart + curveY) + ', ' + (width + xStart - curveX) + ', ' + (height + yStart - curveY) + ' ' + (width + xStart) + ' ' + (height + yStart)
                    }

                        onMouseEnter={(e) => this.mouseHover(e)}
                        onMouseLeave={(e) => this.mouseLeave(e)}
                        onClick={(e) => this.mouseHover(e, true)}

                        fill="none" stroke="transparent" strokeWidth="30" pointerEvents="visibleStroke" />


                    <path d={
                        'M ' + xStart + ' ' + yStart + '' +
                        'C ' + (xStart + curveX) + ' ' + (yStart + curveY) + ', ' + (width + xStart - curveX) + ', ' + (height + yStart - curveY) + ' ' + (width + xStart) + ' ' + (height + yStart)
                    }
                        fill="none" stroke={this.state.hovered ? "#007aff" : "#345"} strokeWidth="3" style={{ transition: 'stroke 0.5s' }} />


                </svg>


                <div
                    onMouseEnter={(e) => this.mouseHover(e)}
                    onMouseLeave={(e) => this.mouseLeave(e)}
                    onClick={() => { if (this.removePath) this.removePath() }}
                    className={"flexcc pathRemoveBut " + (this.state.hovered ? 'hovered' : '')}
                    style={{ top: points.start[1] - yStart + Math.abs(height) / 2, left: points.start[0] - xStart + Math.abs(width) / 2, }}>

                    <img className="invert" src="https://www.flaticon.com/svg/static/icons/svg/98/98921.svg" width="22px" />
                </div>

            </>
        )
    }
}



export default DiagramPath;
