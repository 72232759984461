import React from "react";
import inputComponents from "./inputs/inputComponents";
import {
    checkTextTranslation,
    checkTranslation,
    getObject,
    setObject,
    translate,
} from "../utils/useful";
import TableRow from "./partials/TableRow";
import TableHead from "./partials/TableHead";
import ReactDOM from "react-dom";
import DraggableList from "./dragLibrary/DraggableList";
import { getBoundingClientRect } from "../utils/functions";
import { detectBrowser } from "../utils/useful";
import Loader from "react-loader-spinner";
import Modal from "./Modal";

class TableViewer extends React.Component {
    state = {
        errors: {},
        width: window.innerWidth,
    };
    headerIsScrolling = false;
    scrollTrigger = null;

    componentDidMount() {
        this.init();
        window.addEventListener("resize", this.setWidth);

        this.setState({ broswer: detectBrowser() });
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.setWidth);
    }

    componentDidUpdate(prevProps) {
        if (
            this.props.data != prevProps.data ||
            this.props.headers !== prevProps.headers
        ) {
            this.init();
        }
    }

    init = () => {
        // 
        if (Array.isArray(this.props.headers)) {
            let cellWidths = this.calcCellWidths();
            let headers = this.prepareHeader();
            this.setState(
                {  cellWidths, show: true, headers },
                () => {
                    this.setWidth();
                }
            );
        }
    };

    prepareHeader() {
        return this.props.headers;
    }

    calcCellWidths() {
        let cellWidths = {};
        // TODO: CONSIDER SETTINGS
        // 
        cellWidths["*"] = 0;
        let baseWidth = 150;
        let actionsWidth = 150;

        if (this.tableContainer) {
            let tableWidth = getBoundingClientRect(this.tableContainer).width;
            tableWidth = tableWidth - actionsWidth;
            if (this.props.headers?.length * baseWidth < tableWidth) {
                baseWidth = tableWidth / this.props.headers?.length;
            }
            // 
            // 
        }
        this.props.headers.forEach((header) => {
            cellWidths[header.key] = baseWidth; // TODO: CHANGE BY TYPE
            cellWidths["*"] += baseWidth;
        });
        cellWidths["*actions"] = actionsWidth; // TODO: CHANGE BY TYPE
        cellWidths["*"] = actionsWidth; // TODO: CHANGE BY TYPE

        // cellWidths['*'] = cellWidths['*'] + 150

        return cellWidths;
    }

    setWidth = () => {
        if (this.tableContainer) {
            // 
            this.setState(
                { width: this.tableContainer?.getBoundingClientRect().width },
                () => {
                    this.changeWidth();
                }
            );
        }
    };

    tableColBuilder(col) {
        let finalCol = "col-12";

        if (600 < this.state.width && col) {
            finalCol = "col-" + col;
        }

        return finalCol;
    }



    changeWidth = (key, width) => {
        // 
        let cellWidths = this.state.cellWidths;
        cellWidths[key] = width;

        cellWidths["*"] = 0;
        for (const [key, value] of Object.entries(cellWidths)) {
            if (value) {
                cellWidths["*"] += value;
            }
        }

        if (cellWidths["*"] < this.state.width) {
            cellWidths["*"] = this.state.width;
        }
        // 
        this.setState({ cellWidths });
    };

    tableScroll = (e, ref) => {
        let scrollLeft = e.target.scrollLeft;
        this.syncronizer("table", scrollLeft, e.timeStamp);
        // }
    };

    headerScroll = (e) => {
        let scrollLeft = e.target.scrollLeft;
        this.syncronizer("header", scrollLeft, e.timeStamp);
    };

    scrollMouseDown = (e) => {
        this.initWidth = getBoundingClientRect(this.scrollIndicator).width;
        this.initLeft = getBoundingClientRect(this.scrollIndicator).left;
        this.tableInitLeft = getBoundingClientRect(this.table).left;

        this.initX = e.clientX;
        // 
        // this.setState({ selectedKey: key, initX: e.clientX })
        // document.body.
        document.body.classList.add("no-user-select");

        window.addEventListener("mousemove", this.scollMouseMove);
        window.addEventListener("mouseup", this.scollMouseUp);
    };

    scollMouseMove = (e) => {
        let x = e.clientX;
        let dx = x - this.initX;
        let scrollIndicator = this.initLeft - this.tableInitLeft + dx; //scrollX * 100

        if (scrollIndicator + this.initWidth > this.state.width) {
            scrollIndicator = this.state.width - this.initWidth;
        }

        if (scrollIndicator <= 0) {
            scrollIndicator = 0; //this.state.width - this.initWidth
        }

        let scrollChange =
            (this.state.cellWidths["*"] / this.state.width) * scrollIndicator;

        this.syncronizer("indicator", scrollChange);
    };

    lastTrigger = null;
    lastTriggerTime = null;

    syncronizer(trigger, scrollLeft, timeStamp) {
        if (!this.lastTrigger) {
            // 
            this.lastTrigger = trigger;
            this.lastTriggerTime = timeStamp;
            //new Date().getTime()
        } else {
            if (this.lastTrigger != trigger) {
                // 
                // 

                if (timeStamp && timeStamp > this.lastTriggerTime + 100) {
                    this.lastTrigger = trigger;
                    this.lastTriggerTime = timeStamp;
                } else {
                    if (!timeStamp) {
                        this.lastTrigger = trigger;
                    }
                }
            } else {
                this.lastTriggerTime = timeStamp;
            }
        }


        if (this.lastTrigger != "header") {
            this.header.scrollTo(scrollLeft, 0);
            // this.header.scrollLeft = scrollLeft
        }

        if (this.lastTrigger != "table") {
            this.table.scrollTo(scrollLeft, 0);
        }
        this.scrollTheIndicator(trigger, scrollLeft);

    }

    scrollTheIndicator = (trigger, scrollLeft) => {
        let scrollIndicator = (scrollLeft / this.state.cellWidths["*"]) * 100;

        let isRTL = false;
        if (document.body.classList.contains("rtl")) {
            isRTL = true;
        }

        if (this.scrollIndicator) {
            this.scrollIndicator.style.transition = "none";
            if (!isRTL) {
                this.scrollIndicator.style.marginLeft = scrollIndicator + "%";
                this.scrollIndicator.style.marginRight = "auto";
            } else {
                this.scrollIndicator.style.marginRight =
                    -1 * scrollIndicator + "%";
                this.scrollIndicator.style.marginLeft = "auto";
            }
        }
    };

    scollMouseUp = (e) => {
        document.body.classList.remove("no-user-select");
        this.lastTrigger = null;

        window.removeEventListener("mousemove", this.scollMouseMove);
        window.removeEventListener("mouseup", this.scollMouseUp);
    };

    openActionsModal = (rowData, index) => {
        this.selectedRowData = rowData;
        this.selectedRowIndex = index;
        this.actionsModal.showModal();
    };

    actionClicked = (prop, index) => {
        prop.function(this.selectedRowData, this.selectedRowIndex);
        this.actionsModal.hideModal();
    };

    render() {
        if (this.state.show) {
            let RowContainerComponent = "div";
            if (this.props.settings?.isSortable) {
            }
            RowContainerComponent = DraggableList;
            return (
                <section
                    ref={(el) => (this.tableContainer = el)}
                    className={
                        "w-100 table defaultTable  pb-3 " + this.state.broswer
                    }
                >
                    <div
                        onScrollCapture={(e) => this.headerScroll(e)}
                        ref={(el) => (this.header = el)}
                        className="text-center w-100 no-scrollbar"
                        style={{
                            overflow: "auto",
                            zIndex: 4,
                            position: "sticky",
                            top: 47,
                        }}
                    >
                        {/* <div style={{}}> */}
                        <TableHead
                            headers={this.props.headers}
                            widths={this.state.cellWidths}
                            changeWidth={this.changeWidth}
                            actions={this.props.actions}
                            settings={this.props.settings}
                        />

                        {/* </div> */}
                    </div>

                    {this.props.isLoading && (
                        <div className="flexcc mt-4 mb-3">
                            <Loader
                                type={"ThreeDots"}
                                color={"#789"}
                                height={20}
                                width={50}
                            />
                        </div>
                    )}

                    {!this.props.isLoading && !this.props.data?.length && (
                        <div className="flexcc mt-4 mb-3 w-100">
                            <p className="description-text text-small">
                                {translate("foundNothing").toUpperCase()}
                            </p>
                        </div>
                    )}

                    <div
                        onScroll={(e) => {
                            this.scrollTrigger = "table";
                            this.tableScroll(e);
                        }}
                        ref={(el) => (this.table = el)}
                        className="w-100 h-100 no-scrollbar test"
                        style={{ overflow: "auto" }}
                    >
                        <div
                            className="tableBody "
                            style={{ width: this.state.cellWidths["*"] }}
                        >
                            <RowContainerComponent
                                dragStarted={this.dragStarted}
                                dragOver={this.dragOver}
                                data={this.props.data}
                                updateData={this.props.updateData}
                                width={this.state.width}
                            >
                                {this.props.data?.map((prop, index) => {
                                    return (
                                        <TableRow
                                            ref={(el) => (this[prop.id] = el)}
                                            index={index}
                                            data={prop}
                                            headers={this.props.headers}
                                            key={
                                                prop?._id ??
                                                prop?.id ??
                                                prop.name
                                            }
                                            id={
                                                prop._id ?? prop.id ?? prop.name
                                            }
                                            widths={this.state.cellWidths}
                                            scrollX={this.state.scrollX}
                                            actions={this.props.actions}
                                            settings={this.props.settings}
                                            openActionsModal={
                                                this.openActionsModal
                                            }
                                        />
                                    );
                                })}
                            </RowContainerComponent>
                        </div>
                    </div>

                    {this.state.cellWidths["*"] > this.state.width && (
                        <div className="tableScroll blur-back">
                            <div
                                ref={(el) => (this.scrollIndicator = el)}
                                className="scrollIndicator"
                                onMouseDown={(e) => this.scrollMouseDown(e)}
                                style={{
                                    width:
                                        (this.state.width /
                                            this.state.cellWidths["*"]) *
                                            100 +
                                        "%",
                                }}
                            ></div>
                        </div>
                    )}

                    <Modal
                        ref={(el) => (this.actionsModal = el)}
                        maxWidth={300}
                    >
                        <div
                            className="modal-body py-4 px-4 text-center "
                            style={{ borderRadius: 12 }}
                        >
                            <p className="text-bold text-uppercase mb-0">
                                {translate("choose Action")}
                            </p>
                            <p className="text-smaller  mb-3 text-description">
                                {translate("List of available actions")}
                            </p>

                            {this.props.actions &&
                                Object.values(this.props.actions).map(
                                    (prop, index) => {
                                        return (
                                            <div
                                                key={index}
                                                className="mb-1 w-100"
                                            >
                                                <button
                                                    onClick={() =>
                                                        this.actionClicked(
                                                            prop,
                                                            index
                                                        )
                                                    }
                                                    className="w-100"
                                                    style={{
                                                        backgroundColor: prop.color
                                                            ? prop.color
                                                            : "#007aff15",
                                                        borderRadius: 8,
                                                        padding: "10px 20px",
                                                    }}
                                                >
                                                    <p
                                                        className="text-bold text-uppercase text-normal"
                                                        style={{
                                                            color: "#fff",
                                                        }}
                                                    >
                                                        {checkTranslation(
                                                            prop.label
                                                        )}
                                                    </p>
                                                </button>
                                            </div>
                                        );
                                    }
                                )}
                        </div>
                    </Modal>
                </section>
            );
        } else {
            return <div></div>;
        }
    }
}

export default TableViewer;
