import React, { useEffect } from "react";

const Pagination = ({ range, setPage, page, props: {parentDiv,btn, prev,next}, prevPageFunc,nextPageFunc }) => {
      return (
        <div className={parentDiv.className} style={parentDiv.style}>
            <div style={prev.style} onClick={() => prevPageFunc()}>{prev.content}</div>
          {range?.map((el, index) => {
              return (
                <button
                key={index}
                className={`${btn.className} ${
                  page === el ? "pagination__activeButton" : "pagination__inactiveButton"
                }`}
                onClick={() => setPage(el)}
                >
                {el + 1}
              </button>
            )
          })}
          <div style={next.style} onClick={() => nextPageFunc()}>{next.content}</div>
          </div>
          );
}
 
export default Pagination;

