import React from "react";
import HttpService from '../../utils/Http.services';
import { siteConfig, siteTheme } from "../../variables/config";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actions from '../../stores/actionsList';
// import TabBar from "../views/TabBar";
import Configurer from "../../components/Configurer";
import ProfileImage from "../../components/partials/ProfileImage";
import TableViewer from "../../components/TableViewer";
import { v4 as uuidv4 } from 'uuid';
import ItemModal from "../../modals/ItemModal";
import HttpServices from "../../utils/Http.services";


class AdminFormApplet extends React.Component {


    openEditModal = (prop, index) => {
        // 
        this.itemModal.modal.showModal()
        this.setState({ currentItem: prop, currentIndex: index })
    }

    openRemoveModal = () => {
        
    }

    state = {
        data: [],
        actions: {
            add: { key: "edit", label: '{{lang}}edit', color: '#677dc4', function: this.openEditModal },
            edit: { key: "remove", label: '{{lang}}remove', color: '#ee5050', function: this.openRemoveModal }
        },
    }


    componentDidMount() {
        this.init()
    }

    componentDidUpdate(prevProps) {
        if (this.props.page !== prevProps.page) {
            // setTimeout(() => {
            this.init()
            // }, 500);
        }
    }


    init() {
        if (this.props.page) {
            this.setState({ data: [] }, () => {
                this.fetchData()
            })
        }
    }

    fetchData = () => {

        this.setState({ isLoadingData: true })
        let body = {}
        let page = this.props.page
        body.page = page.key

        HttpServices.request(page.fetchUrl, body, (fetchResult, fetchError) => {
            this.setState({ isLoadingData: false })

            if (fetchError) {
                this.props.actions.addNotif({ type: 'error', title: '{{lang}}errors.loadDataFailed', description: fetchError.message })
                return
            }
            
            this.setState({ data: fetchResult.info })
        })
    }

    itemAdded = (newItem) => {
        let data = this.state.data
        data.splice(0, 0, newItem)
        
        
        this.setState(data)
    }

    itemEdited = (editedItem) => {
        let data = this.state.data
        data.forEach(element => {
            let id = element.id ?? element._id
            let currentId = this.state.currentItem.id ?? this.state.currentItem._id
            if (id === currentId) {
                element = editedItem
            }
        });
        this.setState(data)
    }

    updateData = (data) => {
        this.setState(data)
    }


    render() {
        return (

            <Configurer
                settingsInfo={{ showFooter: true, showTabBar: true, showHeader: true, headerTitle: "Things", button: {} }}
                title={"Things"}
                description={"Things Description"}
                className=""
            >


                <div className="container " >
                    <div className="row m-0">
                        <div className="col-12 p-0" >
                            <div className="p-0" style={{ borderRadius: 0 }}>

                                <div className="flexcb" style={{ flexWrap: 'wrap' }}>

                                    <div className="flexc mt-2">

                                        <ProfileImage width={54} borderRadius={50} notif={0} border={'2px solid #eee'} className={'mrd-3'} data={{ image: this.props.page?.image }} />

                                        <div>
                                            <p className="text-big font-bold m-0 " style={{ color: '#0d172a' }}>{this.props.page?.title}</p>
                                            <p className="text-small m-0" style={{ color: '#89a' }} >{this.props.page?.description}</p>
                                        </div>
                                    </div>


                                    <div className="flexcc mt-2" style={{ alignItems: 'flex-end' }}>

                                        <button onClick={() => this.openEditModal()} className="flexcc" style={{ boxShadow: '0px 0px 15px #00000005', backgroundImage: 'linear-gradient(#000,#102030)', border: '0px solid #e9eaf0', color: '#789', borderRadius: 30, padding: '7px 15px' }}>
                                            <img className="mrd-2 invert" src="/images/add.svg" alt="" width="12px" />
                                            <span className="text-smaller m-0 white text-bold" >Add New</span>
                                        </button>
                                    </div>

                                </div>
                            </div>

                        </div>



                        <div className="col-12 mb-5 mt-4 w-100 p-0">
                            <TableViewer
                                isLoading={this.state.isLoadingData}
                                headers={this.props.page?.headers}
                                data={this.state.data}
                                actions={this.state.actions}
                                settings={{ isSortable: false }}
                                openEditModal={this.openEditModal}
                                openRemoveModal={this.openRemoveModal}
                                updateData={this.updateData}

                            />
                        </div>


                    </div>

                    <ItemModal
                        ref={el => this.itemModal = el}
                        page={this.props.page}
                        headers={this.props.page?.headers}
                        initData={this.state.currentItem}
                        itemAdded={this.itemAdded}
                        itemEdited={this.itemEdited}
                    />
                </div>
            </Configurer>
        )
    }


}



const mapStateToProps = state => ({ settings: state.settings, user: state.user })
const mapDispatchToProps = dispatch => ({ actions: bindActionCreators(actions, dispatch) })

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AdminFormApplet);
