import React from "react";


class ThingHistoryMain extends React.Component {

    state = {
        data: [
            { name: 'Log Based',key:"table", description: 'Show data logs in a table. you can track changes', icon: 'https://images.unsplash.com/photo-1521579498714-ff08ba4836ab?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1568&q=80' },
            { name: 'Chart Based',key:"chart", description: 'Show data logs as a chart. you can track and compare changes', icon: 'https://images.unsplash.com/photo-1543286386-2e659306cd6c?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1350&q=80' },
            { name: 'Location Based',key:"location", description: 'Show data based on their location changes', icon: 'https://images.unsplash.com/photo-1548345680-f5475ea5df84?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1652&q=80' },

        ]
    }

    

    render() {
        return (

            <div className={'thingInfoList mt-3'}>
                <div className="row">

                    {this.state.data.map((prop, index) => {
                        return (
                            <div className="col-6 col-md-4 d-flex" key={index}>
                                <button onClick={()=>this.props.changeStage(prop.key)} className=" p-3 h-100 d-flex flex-column " key={index} style={{  backgroundColor: '#f2f6f8', border: '1px solid #f0f2f4', boxShadow: '0px 0px 15px #00000000', borderRadius: 15 }}>
                                    <div className="d-flex" style={{ flex: 1 }}>
                                        <div className="mrd-4 mt-1">
                                            <img src={prop.icon} style={{ width: 50, height: 50, borderRadius: 50, objectFit: 'cover' }} alt="" />
                                        </div>
                                        <div className="text-start">
                                            <span className="font-bold text-normal">{prop.name}</span>
                                            <p className={'text-smaller  mt-1'} style={{ color: '#789' }}>{prop.description}</p>
                                        </div>

                                    </div>
                                </button>
                            </div>
                        )
                    })}
                </div>
            </div>

        )
    }


}


export default ThingHistoryMain
