import React from 'react'
import LoaderButton from '../../components/LoaderButton'
import { translate } from '../../utils/useful'

class WizardFirstStep extends React.Component {
    state = {
        key: '',
        selectedCountry: {},
        data: {},
        isLoading: false,
        formHeaders: []
    }

    componentDidMount() {

        document.addEventListener("keydown", this._handleKeyDown);
        // this.setState({ formHeaders })
    }


    componentWillUnmount = () => {
        document.removeEventListener("keydown", this._handleKeyDown);
    }

    _handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            this.goToPage()
        }
    }




    goToPage = () => {
        this.props.changeStage('info')
    }


    render() {
        return (
            <div className="flexcc flex-column pt-4">
                <img src="/images/wizard.svg" width="70px" />
                <h2 className="mt-3 mb-2" style={{ fontSize: 27, whiteSpace: 'nowrap' }}>{translate('wizard.title').toUpperCase()}</h2>
                <div className="w-100" style={{ maxWidth: 400 }}>

                    <p className="text-normal text-center" >{translate('wizard.description')}</p>
                    
                    <div className="text-center">
                        <LoaderButton
                            onClick={this.goToPage}
                            isLoading={this.state.isLoading}
                            text={translate("Let's start")}
                            type={"Oval"}
                            className="mt-4 mb-4"
                            buttonStyle={{ outline: 'none', backgroundColor: '#222', cursor: 'pointer', padding: '10px 50px', borderRadius: 4, fontSize: 15, fontWeight: 'bold', color: '#fff', border: 'none' }}
                            width={40}
                            height={40}
                            color={'#202020'}
                        />
                        {/* <p style={{ fontSize: 13, opacity: 0.5, marginTop: 10 }}>{translate('texts.addThing.warning')}</p> */}
                    </div>
                </div>
            </div>
        )
    }
}

export default WizardFirstStep;