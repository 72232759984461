import React from "react";

class Modal extends React.Component {
    state = {
        opacity: 1,
        showModal: false,
        maxWidth: 600,
    };

    getStatus = () => {
        return this.state.showModal;
    };

    hideModal = (cb) => {
        document.body.classList.remove("body-no-sroll");
        window.removeEventListener("keydown", this.keyPress);
        if (this.state.showModal) {
            this.setState({ closing: true }, () => {
                this.props.onHide && this.props.onHide();
                setTimeout(() => {
                    this.setState({ showModal: false }, () => {
                        cb && cb();
                        this.props.hideModalExternalCb &&
                            this.props.hideModalExternalCb();
                        });
                    }, 500);
                });
            }
            else {
                cb && cb()
                this.props.hideModalExternalCb &&
                    this.props.hideModalExternalCb();
        }
    };

    showModal = (cb) => {
        window.addEventListener("keydown", this.keyPress);
        this.setState({ showModal: true, closing: false }, () => {
            setTimeout(() => {
                document.body.classList.add("body-no-sroll");
            }, 50);
            if (cb) {
                cb();
            }
        });
    };

    keyPress = (e) => {
        if (e?.keyCode == 27) {
            if (!this.props.notClosable) {
                this.hideModal();
            }
        }
    };

    componentDidMount() {
        if (this.props.maxWidth) {
            this.setState({ maxWidth: this.props.maxWidth });
        }
    }
    componentWillUnmount() {
        window.removeEventListener("keydown", this.keyPress);
        document.body.classList.remove("body-no-sroll");
    }

    onScroll = () => {
        if (this.props.onScroll) {
            this.props.onScroll();
        }
    };

    render() {
        return (
            <div
                className={"modal " + (this.state.closing ? "closing" : "")}
                onClick={() => {
                    if (!this.props.notClosable) this.hideModal();
                }}
                style={{ display: this.state.showModal ? "flex" : "none" }}
            >
                <div
                    onScroll={() => this.onScroll()}
                    className="modalContent"
                    style={{ paddingBottom: 100 }}
                    
                >
                    <div
                        className="w-100"
                        style={{
                            maxWidth: this.state.maxWidth,
                            marginTop: "5%",
                            borderTopLeftRadius: 8,
                        }}
                        onClick={(e) => e.stopPropagation()}
                    >
                        {this.props.children}

                        <div className="clearfix" style={{ height: 50 }}>
                            <div></div>
                            <div></div>
                        </div>
                    </div>
                </div>
                {!this.props.notClosable && (
                    <button
                        className="flexcc blur-back"
                        style={{
                            position: "absolute",
                            top: 15,
                            right: 15,
                            zIndex: 20,
                            backgroundColor: "#00000010",
                            borderRadius: 30,
                            width: 30,
                            height: 30,
                        }}
                        onClick={() => {
                            if (!this.props.notClosable) this.hideModal();
                        }}
                    >
                        <img
                            src="/images/close.svg"
                            alt="close"
                            style={{
                                width: 14,
                                height: 14,
                                filter: "invert(100%)",
                            }}
                        />
                    </button>
                )}

                <div className="clearfix">
                    <div></div>
                    <div></div>
                </div>
            </div>
        );
    }
}

export default Modal;
