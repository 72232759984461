import React from "react";
import TableViewer from "../../../components/TableViewer";
import { v4 as uuidv4 } from 'uuid';
import { Line } from 'react-chartjs-2';


class ThingHistoryChart extends React.Component {

    state = {
        headers: [
            { key: 'name', type: 'TextInput', information: { label: '{{lang}}name', icon: 'https://image.flaticon.com/icons/svg/13/13305.svg', placeholder: '{{lang}}insertName', required: true } },
            { key: 'family', type: 'TextInput', information: { label: '{{lang}}family', icon: 'https://image.flaticon.com/icons/svg/13/13305.svg', placeholder: '{{lang}}insertFamily', required: true } },
            { key: 'education', type: 'TextInput', information: { label: '{{lang}}Education', icon: 'https://image.flaticon.com/icons/svg/13/13305.svg', placeholder: '{{lang}}insertEducation', required: true } },
            { key: 'age', type: 'TextInput', information: { label: '{{lang}}age', icon: 'https://image.flaticon.com/icons/svg/13/13305.svg', placeholder: '{{lang}}insertage', required: true } }
        ],
        data: [
            { id: uuidv4(), name: 'pouya pezhman', family: 'pezhman', education: "Master of NanoElectronics", age: 29 },
            { id: uuidv4(), name: 'pedram', family: 'pezhman', education: "Master of NanoElectronics", age: 29 },
            { id: uuidv4(), name: 'mohsen', family: 'pezhman', education: "Master of NanoElectronics", age: 29 },
            { id: uuidv4(), name: 'ali', family: 'pezhman', education: "Master of NanoElectronics", age: 29 },
            { id: uuidv4(), name: 'sadegh', family: 'pezhman', education: "Master of NanoElectronics", age: 29 },
            { id: uuidv4(), name: 'sadegh', family: 'pezhman', education: "Master of NanoElectronics", age: 29 },
            { id: uuidv4(), name: 'sadegh', family: 'pezhman', education: "Master of NanoElectronics", age: 29 },
            { id: uuidv4(), name: 'sadegh', family: 'pezhman', education: "Master of NanoElectronics", age: 29 },
            { id: uuidv4(), name: 'sadegh', family: 'pezhman', education: "Master of NanoElectronics", age: 29 },
        ],


    }


    componentDidMount() {
        // 
        // const { datasets } = this.chart.chartInstance.data
        // 
    }


    data = {
        labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun'],
        datasets: [
            {
                label: 'Temperature',
                data: [12, 19, 3, 5, 2, 3],
                fill: false,
                backgroundColor: 'rgb(255, 99, 132)',
                borderColor: 'rgba(255, 99, 132, 0.2)',
                yAxisID: 'y-axis-1',
            },
            {
                label: 'Humidity',
                data: [1, 2, 4, 1, 2, 3],
                fill: false,
                backgroundColor: 'rgb(54, 162, 235)',
                borderColor: 'rgba(54, 162, 235, 0.2)',
                yAxisID: 'y-axis-2',
            },
            {
                label: 'Other',
                data: [4, 7, 1, 5, 5, 1],
                fill: false,
                backgroundColor: '#90aaee',
                borderColor: '#90aaee20',
                yAxisID: 'y-axis-3',
            },
        ],
    }

    options = {
        scales: {
            yAxes: [
                {
                    type: 'linear',
                    display: true,
                    position: 'left',
                    id: 'y-axis-1',
                },
                {
                    type: 'linear',
                    display: true,
                    position: 'right',
                    id: 'y-axis-2',
                    gridLines: {
                        drawOnArea: false,
                    },
                },
                {
                    // type: 'linear',
                    display: true,
                    position: 'right',
                    id: 'y-axis-3',
                    gridLines: {
                        drawOnArea: false,
                    },
                },
            ],
        },
    }





    render() {
        return (

            <div className={'thingInfoList mt-3'}>

                <button onClick={() => this.props.changeStage("main")} className="flexc mb-3">
                    <img className="back mrd-2" src="/images/next-2.png" width="14px" alt="" />
                    <span> Back to list</span>
                </button>
                <div className="w-100">

                    <Line
                        data={this.data}
                        options={this.options}
                        ref={el => this.chart = el}
                    // height={100}
                    />

                </div>
            </div>

        )
    }


}


export default ThingHistoryChart
