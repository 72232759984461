import React from 'react'
import { translate } from '../../utils/useful'
import { siteConfig } from '../../variables/config'
import { withRouter } from 'react-router-dom'
import { pathMaker, imageAddress } from '../../utils/useful'
import { siteTheme } from '../../variables/config'

class LoginWelcomeStep extends React.Component {

    state = {
        timer: 2,
    }

    componentDidMount() {
        this.timer = setInterval(this.checkTimer, 1000);
    }

    componentWillUnmount() {
        if (this.timer) clearInterval(this.timer)
    }

    checkTimer = () => {
        if (this.state.timer > 0) {
            this.setState({ timer: this.state.timer - 1 })
        } else {
            let search = this.props.location.search;
            let params = new URLSearchParams(search);
            let ref = params.get('ref');
            if (ref && ref !== '') {
                this.props.history.push(ref)
            } else {
                const path = pathMaker('/');
                this.props.history.push(path)
            }
        }
    }

    render() {
        return (

            <div className="flexcc flex-column">
                {/* <img src={ siteTheme.logo} width="300px" alt="logo" /> */}
                <div className="w-100 mt-2" style={{ maxWidth: 400 }}>
                    <div className="p-4 mt-3 text-center" style={{ backgroundColor: '#f2f6f8', borderRadius: 15 }}>

                        <img src={imageAddress(this.props.initData?.image, 'profile')} style={{ borderRadius: 8 }} width="80px" alt="profile" />
                        <h2 className="mt-2 mb-0" style={{ fontSize: 27 }}>{this.props.initData?.fullname}</h2>
                        <p className="mb-2 mt-2" style={{ fontSize: 15 }}>{translate('messages.welcome', { name: siteConfig.name })}</p>
                        {this.props.settings.mode === "forgotPassword" && (
                            <p className="mb-1 mt-0" style={{ fontSize: 15 }}>{translate('messages.passwordChanged')}</p>
                        )}
                        <p className="mb-4 mt-0" style={{ fontSize: 15 }}>{translate('messages.youAreLoggedin')}</p>
                        <p style={{ fontSize: 13, opacity: 0.5, marginTop: 0 }}>{translate('messages.wouldRedirect', { timer: this.state.timer })}</p>

                    </div>


                </div>
            </div>

        )
    }
}

export default withRouter(LoginWelcomeStep);