
import SelectInput from '../SelectInput';
import SelectVariableType from '../editor/SelectVariableType';
import TextInput from './TextInput'

const inputComponents = {
    PhoneInput: TextInput,
    NumberInput: TextInput,
    PasswordInput: TextInput,
    TextInput: TextInput,
    SelectVariableType: TextInput,
    TextAreaInput: TextInput,
    ProfileImageInput: TextInput,
    SelectListInput: TextInput,
    CheckboxInput: TextInput,
    FromToInput: TextInput,
    PriceInput: TextInput,
    SliderInput: TextInput,
    AdvancedSelectInput: TextInput,
    MultiSelectInput: TextInput,
};

export default (inputComponents);