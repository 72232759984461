import React from "react";


class ServiceComments extends React.Component {

  

    render() {
        return (



            <section className="thingInfoPage" >
                <div className="w-100 pt-5 px-5 mb-4 pb-5">

                    <p className="text-center">Nothing here</p>

                </div>

            </section>

        )
    }


}


export default ServiceComments
