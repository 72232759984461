import React from "react";
import Configurer from "../../components/Configurer";
import TLink from "../../components/TLink";


class ThingServices extends React.Component {

    state = {
        data: [
            1, 1, 1, 1
        ]
    }

    render() {
        return (



            <section className="thingInfoPage">
                <div className="w-100 pt-4 px-5 mb-4 pb-5">

                    <div className={'thingInfoList mt-3'}>

                        <div className="row">

                            {this.state.data.map((prop, index) => {
                                return (
                                    <div className="col-6 col-md-6 mb-4" key={index}>

                                        <TLink to={'/service'} className="flexc p-3" key={index} style={{  backgroundColor: '#f2f6f8', border: '1px solid #f0f2f4', boxShadow: '0px 0px 15px #00000000', borderRadius: 15 }}>

                                            <div className="d-flex">
                                                <div className="mrd-4 mt-1">
                                                    <img src="https://images.unsplash.com/photo-1518770660439-4636190af475?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1350&q=80" style={{ width: 60, height: 60, borderRadius: 50, objectFit: 'cover' }} alt="" />
                                                </div>
                                                <div>
                                                    <span className="font-bold text-normal">Service Title</span>
                                                    <p className={'text-smaller  mt-1'} style={{ color: '#789' }}>Included characteristics can be named here</p>
                                                    <p className={'text-smaller '} style={{ color: '#789' }}>122 Connected Applets</p>
                                                    <p className={'text-smaller '} style={{ color: '#789' }}>40 Connected Users</p>
                                                    <p className={'text-smaller '} style={{ color: '#789' }}>Public - 30 Toman/packet</p>

                                                </div>

                                            </div>
                                        </TLink>
                                    </div>
                                )
                            })}
                        </div>



                    </div>


                </div>




            </section>

        )
    }


}


export default ThingServices
