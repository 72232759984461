import React from 'react'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import i18n from 'i18next';
import { siteTheme, languagesInfo, siteConfig } from '../variables/config'
import TLink from '../components/TLink.jsx'
import * as actions from '../stores/actionsList';
import { translate, checkTextTranslation } from '../utils/useful'
import routes from '../variables/routes';
import { withRouter } from 'react-router-dom';


class Header extends React.Component {

    state = {
        showMoileMenu: false,
        languagesInfo
    }

    changeLanguage = (key, value) => {
        i18n.changeLanguage(value)
        this.closeBox()
    }

    countCart() {
        let count = 0
        Array.isArray(this.props.cart.items) && this.props.cart.items.forEach(element => {
            count += element.count
        });
        return count
    }


    checkPermission(page, reference) {
        if (!page.roles) {
            return true
        }
        if (reference.info) {
            for (let i = 0; i < page.roles.length; i++) {
                const oneRole = page.roles[i];
                if (oneRole.role === reference.info.role) {
                    return true
                }
            }
        }
    }


    render() {
        // 
        
        
        const releaseDate = ' 12/06/2023 | 17:00';
        return (
            <header
                className="header "
                style={{
                    color: siteTheme.themeColors.headerFont,
                    position: 'sticky',
                    top: 0,
                    left: 0,
                    width: '100%',
                    zIndex: 10,
                    borderBottom: '#e6eaf2 solid 1px',
                }}
            >
                <div
                    className="flexcb blur-back"
                    style={{
                        backgroundColor:
                            siteTheme.themeColors.headerBack + 'dd',
                        height: 45,
                        padding: '5px 15px',
                    }}
                >
                    <div className="flexcc">
                        {!this.props.settings.showTabBar && (
                            <TLink pathname="/" className="flexcc">
                                <img
                                    className={
                                        siteTheme.invertLogo ? 'invert' : ''
                                    }
                                    src={siteTheme.logo_s}
                                    height="35px"
                                    alt={siteConfig.name + ' logo'}
                                />
                            </TLink>
                        )}
                        <div className="flexc">
                            {this.props.settings.button?.goBack && (
                                <button
                                    className="h-100 reverse flexcc  py-0"
                                    onClick={() => this.props.history.goBack()}
                                >
                                    <img
                                        src="/images/next.png"
                                        style={{ width: 18 }}
                                        alt=""
                                    />
                                </button>
                            )}
                            <p
                                className="text-semibig font-bold"
                                style={{ color: '#101010' }}
                            >
                                {this.props.settings.headerTitle} |  
                                {releaseDate}
                            </p>
                        </div>
                        {/* 3a84da */}

                        <div className="flexcc d-none d-lg-flex">
                            {routes.map((prop, index) => {
                                if (prop.header) {
                                    if (
                                        this.checkPermission(
                                            prop,
                                            this.props.user
                                        )
                                    ) {
                                        return (
                                            <nav
                                                key={index}
                                                className="mld-10 cursor-pointer text-normal"
                                            >
                                                <TLink
                                                    pathname={
                                                        prop.pathForHeader
                                                            ? prop.pathForHeader
                                                            : prop.path
                                                    }
                                                >
                                                    {checkTextTranslation(
                                                        prop.name
                                                    )}
                                                </TLink>
                                            </nav>
                                        );
                                    }
                                } else {
                                    return;
                                }
                            })}
                        </div>
                    </div>

                    <div className="flexcc">
                        {!this.props.user.loggedin &&
                            siteConfig.showProfile && (
                                <div className="mrd-3">
                                    {/* <TLink pathname='/login' className=" text-small cursor-pointer" style={{ padding: '2px 20px', borderRadius: 40, backgroundColor: siteTheme.themeColors.headerFont + '10', border: '#ffffff30 0px solid' }}>{translate('login')}</TLink> */}
                                    <TLink
                                        pathname="/login"
                                        className=" text-small cursor-pointer p-0 flexcc"
                                        style={{}}
                                    >
                                        <img
                                            src="/images/profile.svg"
                                            className={
                                                siteTheme.invertLogo
                                                    ? 'invert'
                                                    : ''
                                            }
                                            width="22px"
                                        />
                                    </TLink>
                                </div>
                            )}

                        {this.props.user.loggedin && siteConfig.showProfile && (
                            <div className="mrd-3">
                                {/* <TLink pathname='/me' className=" text-small cursor-pointer" style={{ padding: '2px 20px', borderRadius: 40, backgroundColor: siteTheme.themeColors.headerFont + '10', border: '#ffffff30 0px solid' }}>{translate('profile')}</TLink> */}
                                <TLink
                                    pathname="/profile"
                                    className=" text-small cursor-pointer p-0 flexcc"
                                    style={{}}
                                >
                                    <img
                                        src="/images/profile2.svg"
                                        className={
                                            siteTheme.invertLogo ? 'invert' : ''
                                        }
                                        width="22px"
                                    />
                                </TLink>
                            </div>
                        )}

                        {siteConfig.showCart && (
                            <TLink
                                pathname="/checkout"
                                className="flexcc mld-4"
                            >
                                <div className="position-relative flexcc">
                                    {/* <img className={siteTheme.invertLogo ? 'invert' : ''} src="https://image.flaticon.com/icons/svg/1656/1656850.svg" height="20px" alt="" /> */}
                                    <img
                                        className={
                                            siteTheme.invertLogo ? 'invert' : ''
                                        }
                                        src="/images/profile.svg"
                                        height="20px"
                                        alt=""
                                    />

                                    {this.countCart() > 0 && (
                                        <div
                                            className="flexcc position-absolute"
                                            style={{
                                                top: 0,
                                                left: 10,
                                                backgroundColor: '#ee5050ee',
                                                borderRadius: 30,
                                                minWidth: 20,
                                                height: 20,
                                                fontSize: 14,
                                            }}
                                        >
                                            <span>{this.countCart()}</span>
                                        </div>
                                    )}
                                </div>
                            </TLink>
                        )}

                        {siteConfig.addEstate && (
                            <TLink
                                pathname="/addestate"
                                className="flexcc mld-4"
                            >
                                <div className="position-relative flexcc">
                                    {/* <img className={siteTheme.invertLogo ? 'invert' : ''} src="https://image.flaticon.com/icons/svg/1656/1656850.svg" height="20px" alt="" /> */}
                                    <p
                                        className="mb-0 mrd-3 mt-0 text-small"
                                        style={{ lineHeight: 1 }}
                                    >
                                        افزودن ملک
                                    </p>

                                    <img
                                        className={
                                            siteTheme.invertLogo ? '' : ''
                                        }
                                        src="https://image.flaticon.com/icons/svg/753/753317.svg"
                                        width="20px"
                                        alt=""
                                    />
                                </div>
                            </TLink>
                        )}

                        {siteConfig.showLanguage && (
                            <div className="flexcc mld-4">
                                {languagesInfo[
                                    this.props.settings.language
                                ] && (
                                    <div
                                        className="d-none d-lg-block"
                                        style={{ position: 'relative' }}
                                    >
                                        <button
                                            onClick={() =>
                                                this.toggleLanguagesBox()
                                            }
                                            className="flexcc language-but"
                                        >
                                            <img
                                                className=""
                                                src={
                                                    languagesInfo[
                                                        this.props.settings
                                                            .language
                                                    ].icon
                                                }
                                                height="20px"
                                                alt=""
                                            />
                                            <span
                                                className=" mld-2"
                                                style={{
                                                    color: siteTheme.themeColors
                                                        .headerFont,
                                                }}
                                            >
                                                {
                                                    languagesInfo[
                                                        this.props.settings
                                                            .language
                                                    ].name
                                                }
                                            </span>
                                        </button>
                                        {this.state.showLanguagesBox && (
                                            <div
                                                ref={(el) =>
                                                    (this.language_select_box =
                                                        el)
                                                }
                                                className="blur-back language-select-box fadein"
                                            >
                                                {Object.values(
                                                    languagesInfo
                                                ).map((prop, index) => {
                                                    return (
                                                        <button
                                                            key={index}
                                                            className={
                                                                ' flexc language-select-option ' +
                                                                (index !== 0
                                                                    ? 'mt-3'
                                                                    : '')
                                                            }
                                                            onClick={() =>
                                                                this.changeLanguage(
                                                                    'language',
                                                                    prop.shortName
                                                                )
                                                            }
                                                        >
                                                            <img
                                                                className="mrd-2"
                                                                src={prop.icon}
                                                                alt={
                                                                    'language-' +
                                                                    prop.name
                                                                }
                                                            />
                                                            <div>
                                                                {prop.name}
                                                            </div>
                                                        </button>
                                                    );
                                                })}
                                            </div>
                                        )}
                                    </div>
                                )}
                            </div>
                        )}

                        <button
                            className="flexcc mld-2 p-0 d-lg-none"
                            onClick={() =>
                                this.setState({ showMoileMenu: true })
                            }
                        >
                            <img
                                className={siteTheme.invertLogo ? 'invert' : ''}
                                src="/images/menu.png"
                                height="30px"
                                alt=""
                            />
                        </button>
                    </div>
                </div>

                {/* MOBILE MENU */}
                {this.state.showMoileMenu && (
                    <div
                        className="w-100 blur-back d-lg-none"
                        style={{
                            overflow: 'auto',
                            height: 'calc(100vh)',
                            backgroundColor:
                                siteTheme.themeColors.headerBack + 'dd',
                            position: 'fixed',
                            top: 0,
                            left: 0,
                            zIndex: 1000,
                        }}
                    >
                        <button
                            onClick={() =>
                                this.setState({ showMoileMenu: false })
                            }
                            style={{ position: 'absolute', top: 10, left: 10 }}
                        >
                            <img
                                className={siteTheme.invertLogo ? 'invert' : ''}
                                src="https://image.flaticon.com/icons/svg/748/748122.svg"
                                width="25px"
                            />
                        </button>

                        <div className="flexcc mt-5">
                            <button
                                onClick={() =>
                                    this.setState({ showMoileMenu: false })
                                }
                            >
                                <TLink pathname="/" className="flexcc">
                                    <img
                                        className={
                                            siteTheme.invertLogo ? 'invert' : ''
                                        }
                                        src={siteTheme.logo_s}
                                        height="45px"
                                        alt={siteConfig.name + ' logo'}
                                    />
                                </TLink>
                            </button>
                        </div>

                        <div className="flexcc flex-column text-center mt-4">
                            {routes.map((prop, index) => {
                                if (prop.header) {
                                    if (
                                        this.checkPermission(
                                            prop,
                                            this.props.user
                                        )
                                    ) {
                                        return (
                                            <nav
                                                key={index}
                                                className="cursor-pointer  mb-4"
                                                onClick={() =>
                                                    this.setState({
                                                        showMoileMenu: false,
                                                    })
                                                }
                                            >
                                                <TLink
                                                    pathname={
                                                        prop.pathForHeader
                                                            ? prop.pathForHeader
                                                            : prop.path
                                                    }
                                                >
                                                    <p className="text-semibig">
                                                        {checkTextTranslation(
                                                            prop.name
                                                        )}
                                                    </p>
                                                </TLink>
                                            </nav>
                                        );
                                    }
                                } else {
                                    return;
                                }
                            })}
                        </div>

                        {siteConfig.showLanguage && (
                            <div className="flexcc ">
                                {languagesInfo[
                                    this.props.settings.language
                                ] && (
                                    <div
                                        className=""
                                        style={{ position: 'relative' }}
                                    >
                                        <button
                                            onClick={() =>
                                                this.toggleLanguagesBox()
                                            }
                                            className="flexcc language-but"
                                        >
                                            <img
                                                className=""
                                                src={
                                                    languagesInfo[
                                                        this.props.settings
                                                            .language
                                                    ].icon
                                                }
                                                height="20px"
                                                alt=""
                                            />
                                            <span
                                                className=" mld-2"
                                                style={{
                                                    color: siteTheme.themeColors
                                                        .headerFont,
                                                }}
                                            >
                                                {
                                                    languagesInfo[
                                                        this.props.settings
                                                            .language
                                                    ].name
                                                }
                                            </span>
                                        </button>
                                        {this.state.showLanguagesBox && (
                                            <div
                                                ref={(el) =>
                                                    (this.language_select_box =
                                                        el)
                                                }
                                                className="blur-back language-select-box fadein"
                                            >
                                                {Object.values(
                                                    languagesInfo
                                                ).map((prop, index) => {
                                                    return (
                                                        <button
                                                            key={index}
                                                            className={
                                                                ' flexc language-select-option ' +
                                                                (index !== 0
                                                                    ? 'mt-3'
                                                                    : '')
                                                            }
                                                            onClick={() =>
                                                                this.changeLanguage(
                                                                    'language',
                                                                    prop.shortName
                                                                )
                                                            }
                                                        >
                                                            <img
                                                                className="mrd-2"
                                                                src={prop.icon}
                                                                alt={
                                                                    'language-' +
                                                                    prop.name
                                                                }
                                                            />
                                                            <div>
                                                                {prop.name}
                                                            </div>
                                                        </button>
                                                    );
                                                })}
                                            </div>
                                        )}
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                )}
            </header>
        );
    }




    toggleLanguagesBox() {
        if (!this.state.showLanguagesBox) {
            document.addEventListener('mousedown', this.closeBox);
            this.setState({ showLanguagesBox: true })
        } else {
            this.closeBox()
        }
    }

    closeBox = (event) => {
        if (!event || (this.language_select_box && !this.language_select_box.contains(event.target))) {
            document.removeEventListener('mousedown', this.closeBox);
            let box = document.querySelectorAll('.language-select-box')
            if (box) {
                box[0].classList.add('fadeout')
            }

            setTimeout(() => {
                this.setState({ showLanguagesBox: false })
            }, 400);
        }
    }



}


const mapStateToProps = state => ({ settings: state.settings, cart: state.cart, user: state.user })
const mapDispatchToProps = dispatch => ({ actions: bindActionCreators(actions, dispatch) })

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(Header));

