
import { customAlphabet } from 'nanoid'
import React from 'react'
function Sigma() {
    let id = customAlphabet('abcdefghijklmnopqrstuvwxyz', 10)()

    return (
        <span id={id} class="equation-container" contentEditable={false}>
            <span className="d-none">#~sigma~ </span>
            <span contentEditable={false}>
                <span className="notremovable main-sign" contentEditable={false}>∑</span>
                <span class="" contentEditable={false}> </span>
                <span className="d-none"> ~~</span>

                <span class="sigma-bottom notremovable " contentEditable={false}>
                    <span className="sigma-bottom-content typable" contentEditable={true}> </span>
                    {/* <span className="sigma-bottom-content typable" contentEditable={true}><span className={"placeholder placeholder-"+id} contentEditable={false}>PH</span> </span> */}

                </span>
                <span className="d-none"> ~~</span>

                <span class="" contentEditable={false}> </span>

                <span class="sigma-front notremovable " contentEditable={false}>
                    <span className="sigma-front-content typable" contentEditable={true}> </span>
                    {/* <span className="sigma-front-content typable" contentEditable={true} id={'front-' + id}><span id={'PH-front-' + id} className={"placeholder placeholder-"+id} contentEditable={false}>PH</span> </span> */}

                </span>
                <span class="" contentEditable={false}> </span>
                {/* <span> */}
                    <span id={"rem-" + id} className="equation-close-but" onClick={"removeEquation"}><img src="/images/close.svg" height="8px" width={'8px'} /></span>
                {/* </span> */}

                {/* <span class="sigma-top"></span> */}
            </span>



            <span className="d-none"> |#</span>

        </span>
    )
}



function ForAll() {
    let id = customAlphabet('abcdefghijklmnopqrstuvwxyz', 10)()

    return (
        <span id={id} class="equation-container" contentEditable={false}>
            <span className="d-none">#~for_all~ </span>

            <span contentEditable={false}>
                <span className="notremovable main-sign" contentEditable={false}>∀</span>
                <span class="" contentEditable={false}> </span>


                <span class="sigma-front notremovable " contentEditable={false}>
                    <span className="sigma-front-content typable" contentEditable={true}> </span>
                </span>
                <span class="" contentEditable={false}> </span>
            </span>


            <span id={"rem-" + id} className="equation-close-but" onClick={"removeEquation"}><img src="/images/close.svg" height="8px" /></span>

            <span className="d-none"> |#</span>

        </span>
    )
}



function Index() {
    let id = customAlphabet('abcdefghijklmnopqrstuvwxyz', 10)()

    return (
        <span id={id} className="index-func equation-container" style={{ margin: '0px 2px', padding: '2px 2px' }} contentEditable={false}>
            <span className="d-none">#~index~ </span>
            <span contentEditable={false}>
                <span class="" contentEditable={false}> </span>

                <span class=" sigma-front notremovable " contentEditable={false}>
                    <span className="sigma-front-content typable" style={{ padding: '0px 3px' }} contentEditable={true}> </span>
                    {/* <span className="sigma-front-content typable" contentEditable={true} id={'front-' + id}><span id={'PH-front-' + id} className={"placeholder placeholder-"+id} contentEditable={false}>PH</span> </span> */}

                </span>
                {/* <span class="" contentEditable={false}> </span> */}

                <span className="d-none"> ~~</span>


                <span class="sigma-bottom notremovable " contentEditable={false}>
                    <span className="sigma-bottom-content typable" style={{ padding: '0px 3px' }} contentEditable={true}> </span>
                    {/* <span className="sigma-bottom-content typable" contentEditable={true}><span className={"placeholder placeholder-"+id} contentEditable={false}>PH</span> </span> */}

                </span>
                <span className="d-none" contentEditable={false}> ~~</span>


                {/* <span class="" contentEditable={false}> </span> */}



                <span contentEditable={false} id={"rem-" + id} className="equation-close-but" onClick={"removeEquation"}><img src="/images/close.svg" height="8px" width={'8px'} /></span>


                {/* <span class="sigma-top"></span> */}
            </span>



            <span className="d-none"> |#</span>

        </span>
    )
}



export { Sigma, ForAll, Index }