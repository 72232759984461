import React, { Component } from "react";

class DescriptionsBox extends Component {
    state = {
        description: ""
    }
    updateDescription = (newDescription) => {
        this.setState({description: newDescription})
    }
    render() {
        return (
            <div ref={el => this.wrapperRef = el}  style={{ position: "fixed", 
            display:"none",
            ...this.props.styles
             }} >
                {this.state.description}
            </div>
        );
    }
}

export default DescriptionsBox;