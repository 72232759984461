import React from "react";
import HttpService from '../utils/Http.services';
import { siteConfig, siteTheme } from "../variables/config";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actions from '../stores/actionsList';
// import TabBar from "../views/TabBar";
import Configurer from "../components/Configurer";
import ProfileImage from "../components/partials/ProfileImage";
import TableViewer from "../components/TableViewer";
import { v4 as uuidv4 } from 'uuid';
import StagesManager from "../components/StagesManager";
import ThingLatestInfo from "./ThingSingle/ThingLatestInfo";
import ThingServices from "./ThingSingle/ThingServices";
import ThingHistory from "./ThingSingle/ThingHistory";
import ThingConnectedThings from "./ThingSingle/ThingConnectedThings";
import ThingStatistics from "./ThingSingle/ThingStatistics";
import ThingDevelopers from "./ThingSingle/ThingDevelopers";
import { translate } from "../utils/useful";
import RippleButton from "../components/RippleButton";
import TLink from "../components/TLink";
import ServiceDetails from "./service/ServiceDetails";
import ServiceComments from "./service/ServiceComments";



class Service extends React.Component {


    state = {
        infoList: [
            { title: 'Model', key: '', value: 'D32-52' },
            { title: 'Brand', key: '', value: 'Xiaomi' },
            { title: 'Owner', key: '', value: 'Pouya Pezhman' },
            // { title: 'Create Date', key: '', value: '2020/12/2' },
            // { title: 'Address', key: '', value: '238, test st, other sq, Folana, That country' },

        ],

        tabs: [
            { name: 'Details', key: 'details',icon:'/images/search1.svg' },
            { name: 'Support', key: 'support',icon:'/images/settings-1.svg' },
            { name: 'Comments', key: 'comments',icon:'/images/cd-2.svg' },
     
        ],

        // tabs: [
        //     { name: 'Details', key: 'details' },
        //     { name: 'Support', key: 'support' },
        //     { name: 'Comments', key: 'Comments' },
        // ],
    }


    componentDidMount() {
        let initTab = this.state.tabs[0]
        this.stageManager.changeStage(initTab.key)
        this.setState({ currentTab: initTab.key })

    }

    changeTab = (key) => {
        this.setState({ currentTab: key })
        this.stageManager.changeStage(key)
        window.history.pushState(null, null, '#' + key)
    }


    // showCartBut = () => {
    //     let data = this.state.info
    //     let done = false

    //     // 

    //     for (let i = 0; i < this.props.cart?.items?.length; i++) {
    //       let item = this.props.cart.items[i]
    //       if (item?.data?._id == data._id && (!data.values?.colors || item?.color?.code == data.values?.colors[this.state.selectedColor]?.code)) {
    //         done = true
    //         return (
    //           <div className="mb-4 d-flex" style={{ justifyContent: 'space-between', backgroundColor: '#007aff', padding: '10px 10px', color: '#fff', fontWeight: 'bold', textAlign: 'center', width: '100%', borderRadius: 30, fontSize: 20 }}>
    //             <button onClick={() => this.reduceFromCart({ ...data })} className="text-big p-0 white h-100" style={{ backgroundColor: '#ffffff20', width: 36, borderRadius: 30 }}>-</button>
    //             <span style={{ fontWeight: 'normal' }}>{item.count} <span style={{ opacity: 0.5 }}>{this.state.info?.values?.unit ? this.state.info?.values?.unit : translate('items')}</span></span>
    //             <button onClick={() => this.addToCart({ data })} className="text-big p-0 white h-100" style={{ backgroundColor: '#ffffff20', width: 36, borderRadius: 30 }}>+</button>
    //           </div>
    //         )
    //       }
    //     }

    //     if (!done) {
    //       return (
    //         <button onClick={() => { this.addToCart({ data }); }} className="mb-4" style={{ backgroundColor: 'rgb(238,80,80)', padding: '15px 25px', color: '#fff', textAlign: 'center', width: '100%', borderRadius: 30, fontSize: 16 }}>{translate('add-to-bag')}</button>
    //       )
    //     }
    //   }

    changeImage=(index)=>{

    }


    render() {
        return (

            <Configurer
                settingsInfo={{ showFooter: true, showTabBar: true, showHeader: true, headerTitle: "Service", button: {} }}
                title={"Service"}
                description={"Service Description"}
                className="pt-3"
            >

                <div className="w-100 singleThing">
                    <div className="container " >
                        <div className="row m-0">
                            <div className="col-12 p-0" >
                                <div className="px-5 pt-5 pb-5" style={{ borderRadius: 0 }}>

                                    <div className="row">

                                        <div className="col-12 col-sm-12 col-md-5 col-lg-5">
                                            <div className=" w-100 mrd-2">
                                                <img className="mrd-3" src="https://images.unsplash.com/photo-1519558260268-cde7e03a0152?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1650&q=80" style={{ width: '100%', borderRadius: 8, objectFit: 'cover' }} alt="" />
                                            </div>


                                            <div className="flexc" style={{ flexWrap: 'wrap' }}>
                                                {[1, 1, 1, 1].map((prop, index) => {
                                                    return (
                                                        <button onClick={()=>this.changeImage(index)} key={index} className="mrd-2 p-0" style={{ width: 'calc(25% - 5px)' }}>
                                                            <img src="https://images.unsplash.com/photo-1519558260268-cde7e03a0152?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1650&q=80" style={{ width: "100%", borderRadius: 4, objectFit: 'cover' }} alt="" />
                                                        </button>
                                                    )

                                                })}
                                            </div>



                                        </div>

                                        <div className="col-12 col-sm-12 col-md-7 col-lg-7 px-3 px-md-5 mt-4 mt-md-0">


                                            <p className="text-big font-bold m-0" style={{ color: '#345' }}>Service Name</p>
                                            <p className="text-small mt-2" style={{ color: '#33445' }} >Lorem ipsum dolor sit amet consectetur adipisicing elit. Quia doloribus explicabo assumenda quam magnam quaerat, totam sit alias, voluptates sed possimus, libero nostrum voluptatem quod. Non eum iure quis expedita.</p>

                                            <p className="mt-3 mb-3" style={{ color: '#5468ab' }}>Purchase a licence for <span className="text-bold text-normal" style={{ color: '#5468ab' }}>$25</span></p>
                                            <div className="mt-2 mb-2">
                                                <RippleButton label={'Get Service'} />
                                            </div>
                                            <p className="text-smaller description-text">Consider <TLink to={'/pages/terms'} className="link-text">terms</TLink> and <TLink to={'/pages/policies'} className="link-text">policies</TLink> for the website during using it. We respect your policies and you respect out terms</p>

                                            <div className={'thingInfoList mt-3'}>
                                                {this.state.infoList.map((prop, index) => {
                                                    return (
                                                        <div className="flexc mt-1" key={index}>
                                                            <div className="thingInfoTitle">
                                                                <span className=" text-small ">{prop.title}</span>
                                                            </div>
                                                            <div className="thingInfoValue">
                                                                <span className=" text-small ">{prop.value}</span>
                                                            </div>
                                                        </div>
                                                    )
                                                })}
                                            </div>

                                        </div>




                                    </div>




                                </div>

                            </div>

                        </div>

                    </div>


                    <div className="w-100  pb-5 px-3 px-md-5" style={{ backgroundColor: '#f2f6f8', minHeight: 500 }}>

                        <div className="container">

                            <div className="w-100 px-5">
                                <div className="d-flex align-items-end pt-5 no-scrollbar w-100" style={{ overflow: 'auto', flexWrap: 'nowrap' }}>
                                    {this.state.tabs.map((prop, index) => {
                                        return (
                                            <button onClick={() => this.changeTab(prop.key)} className={"flexcc mb-0 thingsTabs " + (this.state.currentTab === prop.key ? 'active' : '')} key={index} >
                                                {prop.icon &&(
                                                    <img className="mrd-3" src={prop.icon} height="17px" alt="" />
                                                )}
                                                <span>{prop.name}</span>
                                            </button>
                                        )
                                    })}
                                </div>
                            </div>


                            <div className="px-5 mb-5">
                                <StagesManager ref={el => this.stageManager = el}>
                                    <ServiceDetails stageName="details" stage={0} />
                                    <ServiceComments stageName="support" stage={1} />
                                    <ServiceComments stageName="comments" stage={2} />
                                    {/* <ThingConnectedThings stageName="connected" stage={2} />
                                    <ThingStatistics stageName="statistics" stage={2} />
                                    <ThingDevelopers stageName="developers" stage={2} /> */}
                                </StagesManager>
                            </div>

                        </div>
                    </div>



                </div>
            </Configurer>
        )
    }

}



const mapStateToProps = state => ({ settings: state.settings, user: state.user })
const mapDispatchToProps = dispatch => ({ actions: bindActionCreators(actions, dispatch) })

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Service);
