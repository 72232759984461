const diagramGroups = {
    'trigger': { key: 'trigger', title: 'Triggers' },
    'action': { key: 'action', title: 'Actions' },
    // 'condition': { key: 'condition', title: 'Conditions' },
    'flowControl': { key: 'flowControl', title: 'Flow Controls' },

    // 'proccess': { key: 'proccess', title: 'Proccess' }
}


const diagramComponents = {


    ///////***** TRIGGERS *******

    // 'triggerDevices': {
    //     key: 'triggerDevices',
    //     title: 'Trigger Devices',
    //     group: 'trigger',
    //     isParent: true,
    //     icon: 'https://www.flaticon.com/svg/static/icons/svg/726/726623.svg',
    // },

    'triggerThing': {
        key: 'triggerThing',
        title: 'Thing',
        group: 'trigger',

        UI: { type: 'circle', borderRadius: '50%', width: 120, bodyColor: '#D098A5', bodyColorS: '#8F82B3', bodyColorE: '#906EA0' },
        icon: 'https://www.flaticon.com/svg/static/icons/svg/726/726623.svg',
        headers: [
            { key: 'label', type: 'TextInput', col: '12', information: { label: '{{lang}}label', icon: 'https://www.flaticon.com/svg/static/icons/svg/3799/3799967.svg', placeholder: '{{lang}}insertLabel', required: true } },
            // TREE SELECT
            { key: 'description', type: 'TextAreaInput', col: '12', information: { label: '{{lang}}description', placeholder: '{{lang}}insertDescription', required: false } },
        ],
        defaultNodes: {
            'output': { key: 'output', label: 'Output', type: 'output', position: 'right' },
        }
    },


    'thirdParty': {
        key: 'thirdParty',
        title: 'Third Party',
        group: 'trigger',
        // UI: { type: 'circle', borderRadius: '50%', width: 120, bodyColor: '#D098A5', bodyColorS: '#93A8D0', bodyColorE: '#A3ABBD', showIcon: 'https://www.flaticon.com/svg/static/icons/svg/3036/3036996.svg' },
        UI: { type: 'circle', borderRadius: '50%', width: 120, bodyColorS: '#94B0B2', bodyColorE: '#88C4BB', showIcon: 'https://www.flaticon.com/svg/static/icons/svg/3036/3036996.svg' },

        icon: 'https://www.flaticon.com/svg/static/icons/svg/726/726582.svg',
        headers: [
            { key: 'label', type: 'TextInput', col: '12', information: { label: '{{lang}}label', icon: 'https://www.flaticon.com/svg/static/icons/svg/3799/3799967.svg', placeholder: '{{lang}}insertLabel', required: true } },
            // TREE SELECT
            { key: 'description', type: 'TextAreaInput', col: '12', information: { label: '{{lang}}description', placeholder: '{{lang}}insertDescription', required: false } },
        ],
        defaultNodes: {
            'output': { key: 'output', label: 'Output', type: 'output', position: 'right' },
        }
    },

    'triggerOrders': {
        key: 'triggerOrders',
        title: 'Trigger Orders',
        group: 'trigger',
        UI: { type: 'circle', borderRadius: '50%', width: 120, bodyColor: '#30C193', bodyColorS: '#30C193', bodyColorE: '#3DB9B1' },
        icon: 'https://www.flaticon.com/svg/static/icons/svg/893/893268.svg',
        headers: [
            { key: 'label', type: 'TextInput', col: '12', information: { label: '{{lang}}label', icon: 'https://www.flaticon.com/svg/static/icons/svg/3799/3799967.svg', placeholder: '{{lang}}insertLabel', required: true } },
            // TREE SELECT
            { key: 'description', type: 'TextAreaInput', col: '12', information: { label: '{{lang}}description', placeholder: '{{lang}}insertDescription', required: false } },

        ],
        defaultNodes: {
            'output': { key: 'output', label: 'Output', type: 'output', position: 'right' },
        }
    },


    'schedule': {
        key: 'schedule',
        title: 'Schedule',
        group: 'trigger',
        UI: { type: 'circle', borderRadius: '50%', width: 120, bodyColor: '#D098A5', bodyColorS: '#D098A5', bodyColorE: '#BEA5AA', showIcon: 'https://www.flaticon.com/svg/static/icons/svg/215/215518.svg' },
        icon: 'https://www.flaticon.com/svg/static/icons/svg/893/893257.svg',
        headers: [
            { key: 'label', type: 'TextInput', col: '12', information: { label: '{{lang}}label', icon: 'https://www.flaticon.com/svg/static/icons/svg/3799/3799967.svg', placeholder: '{{lang}}insertLabel', required: true } },
            // SCHEDULE
            { key: 'description', type: 'TextAreaInput', col: '12', information: { label: '{{lang}}description', placeholder: '{{lang}}insertDescription', required: false } },
        ],
        defaultNodes: {
            'output': { key: 'output', label: 'Output', type: 'output', position: 'right' },
        }
    },


    'onUpdate': {
        key: 'onUpdate',
        title: 'On Update',
        group: 'trigger',
        UI: { type: 'circle', borderRadius: '50%', width: 120, bodyColor: '#D098A5', bodyColorS: '#93A8D0', bodyColorE: '#A3ABBD' },

        icon: 'https://www.flaticon.com/svg/static/icons/svg/726/726623.svg',
        headers: [
            { key: 'label', type: 'TextInput', col: '12', information: { label: '{{lang}}label', icon: 'https://www.flaticon.com/svg/static/icons/svg/3799/3799967.svg', placeholder: '{{lang}}insertLabel', required: true } },
            { key: 'description', type: 'TextAreaInput', col: '12', information: { label: '{{lang}}description', placeholder: '{{lang}}insertDescription', required: false } },
        ],
        defaultNodes: {
            'output': { key: 'output', label: 'Output', type: 'output', position: 'right' },

        }
    },



    'reference': {
        key: 'reference',
        title: 'Reference',
        group: 'trigger',
        UI: { type: 'circle', borderRadius: '50%', width: 120, bodyColor: '#D098A5', bodyColorS: '#93A8D0', bodyColorE: '#A3ABBD' },

        icon: 'https://www.flaticon.com/svg/static/icons/svg/768/768903.svg',
        headers: [
            { key: 'label', type: 'TextInput', col: '12', information: { label: '{{lang}}label', icon: 'https://www.flaticon.com/svg/static/icons/svg/3799/3799967.svg', placeholder: '{{lang}}insertLabel', required: true } },
            { key: 'description', type: 'TextAreaInput', col: '12', information: { label: '{{lang}}description', placeholder: '{{lang}}insertDescription', required: false } },
        ],
        defaultNodes: {
            'output': { key: 'output', label: 'Output', type: 'output', position: 'right' },
        }
    },









    ///////***** ACTIONS *******


    // 'actionDevices': {
    //     key: 'actionDevices',
    //     title: 'Action Devices',
    //     group: 'action',
    //     isParent: true,
    //     icon: 'https://www.flaticon.com/svg/static/icons/svg/726/726623.svg',
    // },

    'actionDevices': {
        key: 'actionDevices',
        title: 'Action Devices',
        group: 'action',
        // UI: { type: 'action', bodyColor: '#D098A5', bodyColorS: '#93A8D0', bodyColorE: '#A3ABBD' },
        // UI: { type: 'action', bodyColor: '#D5CABD', bodyColorS: '#04004A', bodyColorE: '#250B65', showIcon: 'https://www.flaticon.com/svg/static/icons/svg/59/59118.svg' },
        UI: { type: 'circle', borderRadius: '50%', width: 120, bodyColorS: '#0086AC', bodyColorE: '#00B0B8', showIcon: 'https://www.flaticon.com/svg/static/icons/svg/320/320281.svg' },

        icon: 'https://www.flaticon.com/svg/static/icons/svg/726/726623.svg',
        headers: [
            { key: 'label', type: 'TextInput', col: '12', information: { label: '{{lang}}label', icon: 'https://www.flaticon.com/svg/static/icons/svg/3799/3799967.svg', placeholder: '{{lang}}insertLabel', required: true } },
            // TREE SELECT
            { key: 'description', type: 'TextAreaInput', col: '12', information: { label: '{{lang}}description', placeholder: '{{lang}}insertDescription', required: false } },
            /// SELECT (VALUE)

        ],
        defaultNodes: {
            'input': { key: 'input', label: 'Input', type: 'input', position: 'left' },
        }
    },



    'condition': {
        key: 'condition',
        title: 'Condition',
        group: 'action',
        UI: { type: 'condition', width: 160, borderRadius: 10, bodyColorS: '#3DB9B1', bodyColorE: '#00AAA0', showIcon: 'https://www.flaticon.com/svg/static/icons/svg/3721/3721726.svg' },

        // UI: { type: 'condition', bodyColor: '#D5CABD', bodyColorS: '#04004A', bodyColorE: '#250B65', showIcon: 'https://www.flaticon.com/svg/static/icons/svg/59/59118.svg' },
        icon: 'https://www.flaticon.com/svg/static/icons/svg/893/893257.svg',
        headers: [
            { key: 'label', type: 'TextInput', col: '12', information: { label: '{{lang}}label', icon: 'https://www.flaticon.com/svg/static/icons/svg/3799/3799967.svg', placeholder: '{{lang}}insertLabel', required: true } },
            // TREE SELECT
            { key: 'description', type: 'TextAreaInput', col: '12', information: { label: '{{lang}}description', placeholder: '{{lang}}insertDescription', required: false } },
        ],
        defaultNodes: {
            'input-1': { key: 'input-1', label: 'Input 1', type: 'input', position: 'left' },
            // 'input-2': { key: 'input-2', label: 'Input 2', type: 'input', position: 'left' },
            'output-1': { key: 'output-1', label: 'True', type: 'output', position: 'right' },
            'output-2': { key: 'output-2', label: 'ELSE', type: 'output', position: 'right' },
        }
    },



    // 'getValue': {
    //     key: 'getValue',
    //     title: 'Get Value',
    //     group: 'action',

    //     icon: 'https://www.flaticon.com/svg/static/icons/svg/893/893257.svg',
    //     UI: { type: 'circle', borderRadius: '50%', width: 100, bodyColorS: '#3DB9B1', bodyColorE: '#00AAA0', showIcon: 'https://www.flaticon.com/svg/static/icons/svg/3721/3721726.svg' },
    //     headers: [
    //         { key: 'label', type: 'TextInput', col: '12', information: { label: '{{lang}}label', icon: 'https://www.flaticon.com/svg/static/icons/svg/3799/3799967.svg', placeholder: '{{lang}}insertLabel', required: true } },
    //         // TREE SELECT
    //         { key: 'description', type: 'TextAreaInput', col: '12', information: { label: '{{lang}}description', placeholder: '{{lang}}insertDescription', required: false } },
    //     ],
    //     defaultNodes: {
    //         'input': { key: 'input', label: 'Input', type: 'input', position: 'left' },
    //         'output': { key: 'output', label: 'Output', type: 'output', position: 'right' },

    //     }
    // },




    'email': {
        key: 'email',
        title: 'Email',
        group: 'action',

        icon: 'https://www.flaticon.com/svg/static/icons/svg/726/726623.svg',
        UI: { type: 'circle', borderRadius: '50%', width: 100, bodyColorS: '#3DB9B1', bodyColorE: '#00AAA0', showIcon: 'https://www.flaticon.com/svg/static/icons/svg/3721/3721726.svg' },
        headers: [
            { key: 'label', type: 'TextInput', col: '12', information: { label: '{{lang}}label', icon: 'https://www.flaticon.com/svg/static/icons/svg/3799/3799967.svg', placeholder: '{{lang}}insertLabel', required: true } },
            { key: 'subject', type: 'TextInput', col: '12', information: { label: '{{lang}}subject', icon: 'https://www.flaticon.com/svg/static/icons/svg/3799/3799967.svg', placeholder: '{{lang}}subject', required: true } },
            { key: 'recipient', type: 'MultiSelectInput', col: '12', information: { label: '{{lang}}recipient', icon: 'https://www.flaticon.com/svg/static/icons/svg/3799/3799967.svg', placeholder: '{{lang}}subject', required: true } },
            { key: 'content', type: 'EditorInput', col: '12', information: { label: '{{lang}}content', required: true } },
            { key: 'description', type: 'TextAreaInput', col: '12', information: { label: '{{lang}}description', placeholder: '{{lang}}insertDescription', required: false } },
        ],
        defaultNodes: {
            'input': { key: 'input', label: 'Input', type: 'input', position: 'left' },
            'output': { key: 'output', label: 'Output', type: 'output', position: 'right' },

        }
    },


    'sms': {
        key: 'sms',
        title: 'SMS',
        group: 'action',

        icon: 'https://www.flaticon.com/svg/static/icons/svg/726/726582.svg',
        UI: { type: 'circle', borderRadius: '50%', width: 100, bodyColorS: '#3DB9B1', bodyColorE: '#00AAA0', showIcon: 'https://www.flaticon.com/svg/static/icons/svg/3721/3721726.svg' },
        headers: [
            { key: 'label', type: 'TextInput', col: '12', information: { label: '{{lang}}label', icon: 'https://www.flaticon.com/svg/static/icons/svg/3799/3799967.svg', placeholder: '{{lang}}insertLabel', required: true } },
            { key: 'recipient', type: 'MultiSelectInput', col: '12', information: { label: '{{lang}}recipient', icon: 'https://www.flaticon.com/svg/static/icons/svg/3799/3799967.svg', placeholder: '{{lang}}subject', required: true } },
            { key: 'content', type: 'TextAreaInput', col: '12', information: { label: '{{lang}}content', required: true } },
            { key: 'description', type: 'TextAreaInput', col: '12', information: { label: '{{lang}}description', placeholder: '{{lang}}insertDescription', required: false } },
        ],
        defaultNodes: {
            'input': { key: 'input', label: 'Input', type: 'input', position: 'left' },
            'output': { key: 'output', label: 'Output', type: 'output', position: 'right' },

        }
    },


    'call': {
        key: 'call',
        title: 'Call',
        group: 'action',

        icon: 'https://www.flaticon.com/svg/static/icons/svg/726/726582.svg',
        UI: { type: 'circle', borderRadius: '50%', width: 100, bodyColorS: '#3DB9B1', bodyColorE: '#00AAA0', showIcon: 'https://www.flaticon.com/svg/static/icons/svg/3721/3721726.svg' },
        headers: [
            { key: 'label', type: 'TextInput', col: '12', information: { label: '{{lang}}label', icon: 'https://www.flaticon.com/svg/static/icons/svg/3799/3799967.svg', placeholder: '{{lang}}insertLabel', required: true } },
            { key: 'description', type: 'TextAreaInput', col: '12', information: { label: '{{lang}}description', placeholder: '{{lang}}insertDescription', required: false } },
        ],
        defaultNodes: {
            'input': { key: 'input', label: 'Input', type: 'input', position: 'left' },
            'output': { key: 'output', label: 'Output', type: 'output', position: 'right' },

        }
    },


    'notification': {
        key: 'notification',
        title: 'Notification',
        group: 'action',

        icon: 'https://www.flaticon.com/svg/static/icons/svg/893/893268.svg',
        UI: { type: 'circle', borderRadius: '50%', width: 100, bodyColorS: '#3DB9B1', bodyColorE: '#00AAA0', showIcon: 'https://www.flaticon.com/svg/static/icons/svg/3721/3721726.svg' },
        headers: [
            { key: 'label', type: 'TextInput', col: '12', information: { label: '{{lang}}label', icon: 'https://www.flaticon.com/svg/static/icons/svg/3799/3799967.svg', placeholder: '{{lang}}insertLabel', required: true } },
            { key: 'subject', type: 'TextInput', col: '12', information: { label: '{{lang}}subject', icon: 'https://www.flaticon.com/svg/static/icons/svg/3799/3799967.svg', placeholder: '{{lang}}subject', required: true } },
            { key: 'recipient', type: 'MultiSelectInput', col: '12', information: { label: '{{lang}}recipient', icon: 'https://www.flaticon.com/svg/static/icons/svg/3799/3799967.svg', placeholder: '{{lang}}subject', required: true } },
            { key: 'content', type: 'TextAreaInput', col: '12', information: { label: '{{lang}}content', required: true } },
            { key: 'description', type: 'TextAreaInput', col: '12', information: { label: '{{lang}}description', placeholder: '{{lang}}insertDescription', required: false } },
        ],
        defaultNodes: {
            'input': { key: 'input', label: 'Input', type: 'input', position: 'left' },
            'output': { key: 'output', label: 'Output', type: 'output', position: 'right' },

        }
    },


    'wait': {
        key: 'wait',
        title: 'Wait',
        group: 'action',

        icon: 'https://www.flaticon.com/svg/static/icons/svg/726/726573.svg',
        UI: { type: 'circle', borderRadius: '50%', width: 100, bodyColorS: '#3DB9B1', bodyColorE: '#00AAA0', showIcon: 'https://www.flaticon.com/svg/static/icons/svg/3721/3721726.svg' },
        headers: [
            { key: 'label', type: 'TextInput', col: '12', information: { label: '{{lang}}label', icon: 'https://www.flaticon.com/svg/static/icons/svg/3799/3799967.svg', placeholder: '{{lang}}insertLabel', required: true } },
            // TREE SELECT
            { key: 'description', type: 'TextAreaInput', col: '12', information: { label: '{{lang}}description', placeholder: '{{lang}}insertDescription', required: false } },
        ],
        defaultNodes: {
            'input': { key: 'input', label: 'Input', type: 'input', position: 'left' },
            'output': { key: 'output', label: 'Output', type: 'output', position: 'right' },

        }
    },


    'telegram': {
        key: 'telegram',
        title: 'Telegram',
        group: 'action',
        icon: 'https://www.flaticon.com/svg/static/icons/svg/893/893268.svg',
        UI: { type: 'circle', borderRadius: '50%', width: 100, bodyColorS: '#3DB9B1', bodyColorE: '#00AAA0', showIcon: 'https://www.flaticon.com/svg/static/icons/svg/3721/3721726.svg' },
        headers: [
            { key: 'label', type: 'TextInput', col: '12', information: { label: '{{lang}}label', icon: 'https://www.flaticon.com/svg/static/icons/svg/3799/3799967.svg', placeholder: '{{lang}}insertLabel', required: true } },
            { key: 'recipient', type: 'MultiSelectInput', col: '12', information: { label: '{{lang}}recipient', icon: 'https://www.flaticon.com/svg/static/icons/svg/3799/3799967.svg', placeholder: '{{lang}}subject', required: true } },
            { key: 'content', type: 'TextAreaInput', col: '12', information: { label: '{{lang}}content', required: true } },
            { key: 'description', type: 'TextAreaInput', col: '12', information: { label: '{{lang}}description', placeholder: '{{lang}}insertDescription', required: false } },
        ],
        defaultNodes: {
            'input': { key: 'input', label: 'Input', type: 'input', position: 'left' },
            'output': { key: 'output', label: 'Output', type: 'output', position: 'right' },
        }
    },






    ///////***** CONDITOINS *******


    // 'if': {
    //     key: 'if',
    //     title: 'IF',
    //     group: 'condition',
    //     headerColor: '#778899',
    //     bodyColor: '#dfe6f0',
    //     icon: 'https://www.flaticon.com/svg/static/icons/svg/893/893257.svg',
    //     headers: [
    //         { key: 'name', type: 'TextInput', col: '6', information: { label: '{{lang}}name', icon: 'https://image.flaticon.com/icons/svg/941/941581.svg', placeholder: '{{lang}}insertName', required: true } },
    //     ],
    //     defaultNodes: {
    //         'input-1': { key: 'input-1', label: '1st Input', type: 'input', position: 'left' },
    //         'input-2': { key: 'input-2', label: '2st Input', type: 'input', position: 'left' },
    //         'output-1': { key: 'output-1', label: '1st Output', type: 'output', position: 'right' },
    //         'output-2': { key: 'output-2', label: '2st Output', type: 'output', position: 'right' },
    //     }
    // },



    // 'and': {
    //     key: 'and',
    //     title: 'AND',
    //     group: 'condition',
    //     headerColor: '#778899',
    //     bodyColor: '#dfe6f0',
    //     icon: 'https://www.flaticon.com/svg/static/icons/svg/726/726573.svg',
    //     headers: [
    //         { key: 'name', type: 'TextInput', col: '6', information: { label: '{{lang}}name', icon: 'https://image.flaticon.com/icons/svg/941/941581.svg', placeholder: '{{lang}}insertName', required: true } },
    //     ],
    //     defaultNodes: {
    //         'input-1': { key: 'input-1', label: '1st Input', type: 'input', position: 'left' },
    //         'input-2': { key: 'input-2', label: '2st Input', type: 'input', position: 'left' },
    //         'output-1': { key: 'output-1', label: '1st Output', type: 'output', position: 'right' },
    //         'output-2': { key: 'output-2', label: '2st Output', type: 'output', position: 'right' },
    //     }
    // },

    // 'or': {
    //     key: 'or',
    //     title: 'OR',
    //     group: 'condition',
    //     headerColor: '#778899',
    //     bodyColor: '#dfe6f0',
    //     icon: 'https://www.flaticon.com/svg/static/icons/svg/893/893268.svg',
    //     headers: [
    //         { key: 'name', type: 'TextInput', col: '6', information: { label: '{{lang}}name', icon: 'https://image.flaticon.com/icons/svg/941/941581.svg', placeholder: '{{lang}}insertName', required: true } },
    //     ],
    //     defaultNodes: {
    //         'input-1': { key: 'input-1', label: '1st Input', type: 'input', position: 'left' },
    //         'input-2': { key: 'input-2', label: '2st Input', type: 'input', position: 'left' },
    //         'output-1': { key: 'output-1', label: '1st Output', type: 'output', position: 'right' },
    //         'output-2': { key: 'output-2', label: '2st Output', type: 'output', position: 'right' },
    //     }
    // },




    ///////***** FLOW CONTROL *******


    // 'merge': {
    //     key: 'merge',
    //     title: 'Merge',
    //     group: 'flowControl',
    //     headerColor: '#778899',
    //     bodyColor: '#dfe6f0',
    //     icon: 'https://www.flaticon.com/svg/static/icons/svg/893/893257.svg',
    //     headers: [
    //         { key: 'name', type: 'TextInput', col: '6', information: { label: '{{lang}}name', icon: 'https://image.flaticon.com/icons/svg/941/941581.svg', placeholder: '{{lang}}insertName', required: true } },
    //     ],
    //     defaultNodes: {
    //         'input-1': { key: 'input-1', label: '1st Input', type: 'input', position: 'left' },
    //         'input-2': { key: 'input-2', label: '2st Input', type: 'input', position: 'left' },
    //         'output-1': { key: 'output-1', label: '1st Output', type: 'output', position: 'right' },
    //         'output-2': { key: 'output-2', label: '2st Output', type: 'output', position: 'right' },
    //     }
    // },



    // 'split': {
    //     key: 'split',
    //     title: 'Split',
    //     group: 'flowControl',
    //     headerColor: '#778899',
    //     bodyColor: '#dfe6f0',
    //     icon: 'https://www.flaticon.com/svg/static/icons/svg/726/726573.svg',
    //     headers: [
    //         { key: 'name', type: 'TextInput', col: '6', information: { label: '{{lang}}name', icon: 'https://image.flaticon.com/icons/svg/941/941581.svg', placeholder: '{{lang}}insertName', required: true } },
    //     ],
    //     defaultNodes: {
    //         'input-1': { key: 'input-1', label: '1st Input', type: 'input', position: 'left' },
    //         'input-2': { key: 'input-2', label: '2st Input', type: 'input', position: 'left' },
    //         'output-1': { key: 'output-1', label: '1st Output', type: 'output', position: 'right' },
    //         'output-2': { key: 'output-2', label: '2st Output', type: 'output', position: 'right' },
    //     }
    // },


    'exitFromWorkflow': {
        key: 'exitFromWorkflow',
        title: 'Exit From Workflow',
        group: 'flowControl',
        // headerColor: '#778899',
        // bodyColor: '#dfe6f0',
        icon: 'https://www.flaticon.com/svg/static/icons/svg/893/893268.svg',
        UI: { type: 'circle', borderRadius: '50%', width: 100, bodyColorS: '#ee5050', bodyColorE: '#E5417C', showIcon: 'https://www.flaticon.com/svg/static/icons/svg/3721/3721726.svg' },
        headers: [
            { key: 'label', type: 'TextInput', col: '12', information: { label: '{{lang}}label', icon: 'https://www.flaticon.com/svg/static/icons/svg/3799/3799967.svg', placeholder: '{{lang}}insertLabel', required: true } },
            { key: 'description', type: 'TextAreaInput', col: '12', information: { label: '{{lang}}description', placeholder: '{{lang}}insertDescription', required: false } },
        ],
        defaultNodes: {
            'input': { key: 'input', label: 'Input', type: 'input', position: 'left' },
            // 'output': { key: 'output', label: 'Output', type: 'output', position: 'right' },
        }
    },



    'linkToAnotherWorkflow': {
        key: 'linkToAnotherWorkflow',
        title: 'Link To Another Workflow',
        group: 'flowControl',

        icon: 'https://www.flaticon.com/svg/static/icons/svg/893/893268.svg',
        UI: { type: 'circle', borderRadius: '50%', width: 100, bodyColorS: '#007aff', bodyColorE: '#6B6CED', showIcon: 'https://www.flaticon.com/svg/static/icons/svg/3721/3721726.svg' },
        headers: [
            { key: 'label', type: 'TextInput', col: '12', information: { label: '{{lang}}label', icon: 'https://www.flaticon.com/svg/static/icons/svg/3799/3799967.svg', placeholder: '{{lang}}insertLabel', required: true } },
            // TREE SELECT
            { key: 'description', type: 'TextAreaInput', col: '12', information: { label: '{{lang}}description', placeholder: '{{lang}}insertDescription', required: false } },
        ],
        defaultNodes: {
            'input': { key: 'input', label: 'Input', type: 'input', position: 'left' },
            // 'output': { key: 'output', label: 'Output', type: 'output', position: 'right' },
        }
    },












    /////////////////

    // 'formSubmit': {
    //     key: 'formSubmit',
    //     title: 'Form Submit',
    //     group: 'trigger',
    //     headerColor: '#778899',
    //     bodyColor: '#dfe6f0',
    //     icon: 'https://www.flaticon.com/svg/static/icons/svg/726/726623.svg',
    //     headers: [
    //         { key: 'name', type: 'TextInput', col: '6', information: { label: '{{lang}}name', icon: 'https://image.flaticon.com/icons/svg/941/941581.svg', placeholder: '{{lang}}insertName', required: true } },
    //     ],
    //     defaultNodes: {
    //         'input-1': { key: 'input-1', label: '1st Input', type: 'input', position: 'left' },
    //         'input-2': { key: 'input-2', label: '2st Input', type: 'input', position: 'left' },
    //         'output-1': { key: 'output-1', label: '1st Output', type: 'output', position: 'right' },
    //         'output-2': { key: 'output-2', label: '2st Output', type: 'output', position: 'right' },
    //     }
    // },








    // 'listEntry': { key: 'listEntry', title: 'List Entry', group: 'trigger', headerColor: '#778899', bodyColor: '#dfe6f0', icon: 'https://www.flaticon.com/svg/static/icons/svg/726/726582.svg' },
    // 'fieldUpdate': { key: 'fieldUpdate', title: 'Field Update', group: 'trigger', headerColor: '#778899', bodyColor: '#dfe6f0', icon: 'https://www.flaticon.com/svg/static/icons/svg/893/893268.svg' },
    // 'openTrigger': { key: 'openTrigger', title: 'Open Trigger', group: 'trigger', headerColor: '#778899', bodyColor: '#dfe6f0', icon: 'https://www.flaticon.com/svg/static/icons/svg/893/893257.svg' },

    'sendEmail': { key: 'sendEmail', title: 'Send Email', group: 'proccess', headerColor: '#778899', bodyColor: '#dfe6f0', icon: 'https://www.flaticon.com/svg/static/icons/svg/893/893257.svg' },
    'sendSMS': { key: 'sendSMS', title: 'Send SMS', group: 'proccess', headerColor: '#778899', bodyColor: '#dfe6f0', icon: 'https://www.flaticon.com/svg/static/icons/svg/726/726573.svg' },
    // 'wait': { key: 'wait', title: 'Wait', group: 'proccess', headerColor: '#778899', bodyColor: '#dfe6f0', icon: 'https://www.flaticon.com/svg/static/icons/svg/726/726623.svg' },


}


export { diagramComponents, diagramGroups }