import React from "react";
import HttpService from '../utils/Http.services';
import { siteConfig, siteTheme } from "../variables/config";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actions from '../stores/actionsList';
// import TabBar from "../views/TabBar";
import Configurer from "../components/Configurer";
import ProfileImage from "../components/partials/ProfileImage";
import TableViewer from "../components/TableViewer";
import { v4 as uuidv4 } from 'uuid';


class Things extends React.Component {


    state = {
        headers: [
            { key: 'name', type: 'TextInput', information: { label: '{{lang}}name', icon: 'https://image.flaticon.com/icons/svg/13/13305.svg', placeholder: '{{lang}}insertName', required: true } },
            { key: 'family', type: 'TextInput', information: { label: '{{lang}}family', icon: 'https://image.flaticon.com/icons/svg/13/13305.svg', placeholder: '{{lang}}insertFamily', required: true } },
            { key: 'education', type: 'TextInput', information: { label: '{{lang}}Education', icon: 'https://image.flaticon.com/icons/svg/13/13305.svg', placeholder: '{{lang}}insertEducation', required: true } },
            { key: 'age', type: 'TextInput', information: { label: '{{lang}}age', icon: 'https://image.flaticon.com/icons/svg/13/13305.svg', placeholder: '{{lang}}insertage', required: true } }
        ],
        data: [
            { id: uuidv4(), name: 'pouya pezhman', family: 'pezhman', education: "Master of NanoElectronics", age:29 },
            { id: uuidv4(), name: 'pedram', family: 'pezhman', education: "Master of NanoElectronics", age:29 },
            { id: uuidv4(), name: 'mohsen', family: 'pezhman', education: "Master of NanoElectronics", age:29 },
            { id: uuidv4(), name: 'ali', family: 'pezhman', education: "Master of NanoElectronics", age:29 },
            { id: uuidv4(), name: 'sadegh', family: 'pezhman', education: "Master of NanoElectronics", age:29 },
            { id: uuidv4(), name: 'sadegh', family: 'pezhman', education: "Master of NanoElectronics", age:29 },
            { id: uuidv4(), name: 'sadegh', family: 'pezhman', education: "Master of NanoElectronics", age:29 },
            { id: uuidv4(), name: 'sadegh', family: 'pezhman', education: "Master of NanoElectronics", age:29 },
            { id: uuidv4(), name: 'sadegh', family: 'pezhman', education: "Master of NanoElectronics", age:29 },
            { id: uuidv4(), name: 'sadegh', family: 'pezhman', education: "Master of NanoElectronics", age:29 },
            { id: uuidv4(), name: 'sadegh', family: 'pezhman', education: "Master of NanoElectronics", age:29 },
            { id: uuidv4(), name: 'sadegh', family: 'pezhman', education: "Master of NanoElectronics", age:29 },
            { id: uuidv4(), name: 'sadegh', family: 'pezhman', education: "Master of NanoElectronics", age:29 },
            { id: uuidv4(), name: 'sadegh', family: 'pezhman', education: "Master of NanoElectronics", age:29 },
            { id: uuidv4(), name: 'sadegh', family: 'pezhman', education: "Master of NanoElectronics", age:29 },
            { id: uuidv4(), name: 'sadegh', family: 'pezhman', education: "Master of NanoElectronics", age:29 },
            { id: uuidv4(), name: 'sadegh', family: 'pezhman', education: "Master of NanoElectronics", age:29 },
            { id: uuidv4(), name: 'sadegh', family: 'pezhman', education: "Master of NanoElectronics", age:29 },
            { id: uuidv4(), name: 'sadegh', family: 'pezhman', education: "Master of NanoElectronics", age:29 },
            { id: uuidv4(), name: 'sadegh', family: 'pezhman', education: "Master of NanoElectronics", age:29 },
            { id: uuidv4(), name: 'sadegh', family: 'pezhman', education: "Master of NanoElectronics", age:29 },
            { id: uuidv4(), name: 'sadegh', family: 'pezhman', education: "Master of NanoElectronics", age:29 },
            { id: uuidv4(), name: 'sadegh', family: 'pezhman', education: "Master of NanoElectronics", age:29 },
            { id: uuidv4(), name: 'sadegh', family: 'pezhman', education: "Master of NanoElectronics", age:29 },
            { id: uuidv4(), name: 'sadegh', family: 'pezhman', education: "Master of NanoElectronics", age:29 },
            { id: uuidv4(), name: 'sadegh', family: 'pezhman', education: "Master of NanoElectronics", age:29 },
            { id: uuidv4(), name: 'sadegh', family: 'pezhman', education: "Master of NanoElectronics", age:29 },
            { id: uuidv4(), name: 'sadegh', family: 'pezhman', education: "Master of NanoElectronics", age:29 },
            { id: uuidv4(), name: 'sadegh', family: 'pezhman', education: "Master of NanoElectronics", age:29 },
            { id: uuidv4(), name: 'sadegh', family: 'pezhman', education: "Master of NanoElectronics", age:29 },
            { id: uuidv4(), name: 'sadegh', family: 'pezhman', education: "Master of NanoElectronics", age:29 },
            { id: uuidv4(), name: 'sadegh', family: 'pezhman', education: "Master of NanoElectronics", age:29 },
            { id: uuidv4(), name: 'sadegh', family: 'pezhman', education: "Master of NanoElectronics", age:29 },
            { id: uuidv4(), name: 'sadegh', family: 'pezhman', education: "Master of NanoElectronics", age:29 },
            { id: uuidv4(), name: 'sadegh', family: 'pezhman', education: "Master of NanoElectronics", age:29 },
            { id: uuidv4(), name: 'sadegh', family: 'pezhman', education: "Master of NanoElectronics", age:29 },
            { id: uuidv4(), name: 'sadegh', family: 'pezhman', education: "Master of NanoElectronics", age:29 },
            { id: uuidv4(), name: 'sadegh', family: 'pezhman', education: "Master of NanoElectronics", age:29 },
            { id: uuidv4(), name: 'sadegh', family: 'pezhman', education: "Master of NanoElectronics", age:29 },
            { id: uuidv4(), name: 'sadegh', family: 'pezhman', education: "Master of NanoElectronics", age:29 },
            { id: uuidv4(), name: 'sadegh', family: 'pezhman', education: "Master of NanoElectronics", age:29 },
            { id: uuidv4(), name: 'sadegh', family: 'pezhman', education: "Master of NanoElectronics", age:29 },
            { id: uuidv4(), name: 'sadegh', family: 'pezhman', education: "Master of NanoElectronics", age:29 },
            { id: uuidv4(), name: 'sadegh', family: 'pezhman', education: "Master of NanoElectronics", age:29 },
            { id: uuidv4(), name: 'sadegh', family: 'pezhman', education: "Master of NanoElectronics", age:29 },
            { id: uuidv4(), name: 'sadegh', family: 'pezhman', education: "Master of NanoElectronics", age:29 },
            { id: uuidv4(), name: 'sadegh', family: 'pezhman', education: "Master of NanoElectronics", age:29 },
            { id: uuidv4(), name: 'sadegh', family: 'pezhman', education: "Master of NanoElectronics", age:29 },
            { id: uuidv4(), name: 'sadegh', family: 'pezhman', education: "Master of NanoElectronics", age:29 },

        ]
    }
    render() {
        return (

            <Configurer
                settingsInfo={{showFooter:true, showTabBar: true, showHeader: true, headerTitle: "Things", button: {} }}
                title={"Things"}
                description={"Things Description"}
                className=""
            >


                <div className="container" >
                    <div className="row m-0">
                        <div className="col-12 p-0" >
                            <div className="p-4" style={{ borderRadius: 0 }}>

                                <div className="flexcb">

                                    <div className="flexc">

                                        <div className="flexcc mrd-3" style={{ position: 'relative', width: 50, height: 50, borderRadius: 50, padding: 1, background: '#fff', border: '3px solid #1787fc' }}>
                                            <div className="flexcc w-100 h-100" style={{ backgroundColor: '#f2f6f8', borderRadius: 40 }}>
                                                <div className="h-100 w-100">
                                                    <img className="mrd-3" src="https://images.unsplash.com/photo-1518770660439-4636190af475?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1350&q=80" style={{ width: '100%', height: '100%', borderRadius: 50, objectFit: 'cover' }} alt="" />
                                                </div>
                                            </div>

                                            <div className="blur-back flexcc" style={{ position: 'absolute', top: -2, right: -7, backgroundColor: '#1787fcdd', borderRadius: 20, minWidth: 22, minHeight: 22 }}>
                                                <span className="text-small white">5</span>
                                            </div>

                                        </div>

                                        <div>
                                            <p className="text-semibig font-bold m-0" style={{ color: '#0d172a' }}> Manage things</p>
                                            <p className="text-smaller m-0" style={{ color: '#9ab' }} >Description of this potal</p>
                                        </div>
                                    </div>


                                    <div className="flexcc" style={{ alignItems: 'flex-end' }}>

                                        <div className="flexc mrd-4">
                                            {[1, 1, 1, 1, 1, 1].map((prop, index) => {
                                                if (index < 4) {
                                                    return (
                                                        <div key={index} style={{ marginRight: -7 }}>
                                                            <ProfileImage {...prop} key={index} width={34} />
                                                        </div>
                                                    )
                                                } else {
                                                    return (
                                                        <div key={index}></div>
                                                    )
                                                }
                                            })}
                                        </div>


                                        <button className="flexcc mrd-2" style={{ boxShadow: '0px 0px 15px #00000005', backgroundImage: 'linear-gradient(#f2f6f8,#f0f2f6)', border: '1px solid #e9eaf0', color: '#9ab', borderRadius: 8, padding: '7px 15px' }}>
                                            {/* <img className="mrd-2 " src="/images/settings-2.svg" alt="" width="15px" /> */}
                                            <span className="text-smaller m-0 text-bold" >Users</span>
                                        </button>

                                        <button className="flexcc" style={{ boxShadow: '0px 0px 15px #00000005', backgroundImage: 'linear-gradient(#3869f4,#3362e5)', border: '0px solid #e9eaf0', color: '#789', borderRadius: 8, padding: '7px 15px' }}>
                                            <img className="mrd-2 invert" src="/images/settings-2.svg" alt="" width="15px" />
                                            <span className="text-smaller m-0 white text-bold" >Settings</span>
                                        </button>
                                    </div>

                                </div>


                                <div className="w-100 mt-3" style={{ color: '#9ab' }}>
                                    <div className="px-5 py-4" style={{ backgroundColor: '#f2f6f8', borderRadius: 15 }}>
                                        <p className="text-big text-bold" style={{ color: '#345' }} >Users</p>
                                        <p className="text-small" style={{ color: '#789' }}>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Cumque velit omnis fugiat veritatis impedit, adipisci pariatur? Mollitia dolores suscipit provident, nemo nobis nisi eum cum maxime deserunt reiciendis, corrupti dolorum?</p>

                                        <button className="mt-3" style={{ backgroundImage: 'linear-gradient(#abc,#9ab)', borderRadius: 10, padding: '12px 20px' }}>
                                            <span className="white">View Profile</span>
                                        </button>
                                    </div>

                                </div>



                                <div className="w-100 mt-3" >
                                    <div className="px-5 py-4" style={{ backgroundColor: '#f2f6f8', borderRadius: 15 }}>

                                        <div className="flexcb">
                                            <div>
                                                <p className="text-big text-bold" style={{ color: '#345' }} >Users</p>
                                                <p className="text-small" style={{ color: '#789' }} >List of users of this applet</p>
                                            </div>

                                            <button className="mt-3" style={{ backgroundImage: 'linear-gradient(#abc,#9ab)', borderRadius: 10, padding: '12px 20px' }}>
                                                <span className="white">Manage</span>
                                            </button>
                                        </div>

                                        <div className="flexc mt-3">
                                            {[1, 1, 1, 1, 1, 1].map((prop, index) => {
                                                return (
                                                    <div key={index} className="mrd-3 flexcc flex-column">
                                                        <ProfileImage {...prop} key={index} border={'2px solid #abc'} />
                                                        <span className="text-smaller mt-1" style={{ color: '#789' }}>Name</span>
                                                    </div>
                                                )
                                            })}
                                        </div>


                                    </div>

                                </div>




                                <div className="w-100 mt-3" style={{ background: '#eee', height: 1 }}></div>


                                <div className={'w-100 mt-4'}>
                                    <div className="row text-center">
                                        <div className="col-6 col-md-3">
                                            <div style={{ borderRight: '1px solid #eee' }}>
                                                <p className="text-smaller" style={{ color: '#000' }}>Title of section</p>
                                                <p className="text-big text-bold">$50000</p>
                                                <p className="text-smaller" style={{ color: '#9ab' }}>More info</p>
                                            </div>
                                        </div>
                                        <div className="col-6 col-md-3">
                                            <div style={{ borderRight: '1px solid #eee' }}>
                                                <p className="text-smaller" style={{ color: '#000' }}>Title of section</p>
                                                <p className="text-big text-bold">$50000</p>
                                                <p className="text-smaller" style={{ color: '#9ab' }}>More info</p>
                                            </div>
                                        </div>
                                        <div className="col-6 col-md-3">
                                            <div style={{ borderRight: '1px solid #eee' }}>
                                                <p className="text-smaller" style={{ color: '#000' }}>Title of section</p>
                                                <p className="text-big text-bold">$50000</p>
                                                <p className="text-smaller" style={{ color: '#9ab' }}>More info</p>
                                            </div>
                                        </div>
                                        <div className="col-6 col-md-3">
                                            <div >
                                                <p className="text-smaller" style={{ color: '#000' }}>Title of section</p>
                                                <p className="text-big text-bold">$50000</p>
                                                <p className="text-smaller" style={{ color: '#9ab' }}>More info</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="w-100 mt-3" style={{ background: '#eee', height: 1 }}></div>


                            </div>

                        </div>



                        <div className="col-12 mb-5 mt-5 w-100">
                            <TableViewer headers={this.state.headers} data={this.state.data} />
                        </div>



                    </div>
                </div>
            </Configurer>
        )
    }


}



const mapStateToProps = state => ({ settings: state.settings, user: state.user })
const mapDispatchToProps = dispatch => ({ actions: bindActionCreators(actions, dispatch) })

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Things);
