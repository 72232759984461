import React from 'react'
import TLink from '../TLink'

class ServiceBox extends React.Component {

    state = {
        open: false
    }



    render() {


        return (
            <div className="w-100 service-box">
                    <div className="w-100 cover-img-container m-0 p-0 flexcc">
                        <TLink className="m-0 p-0 w-100 h-100 flexcc" to={'/service'}>
                            <img className="cover-img m-0 p-0" src="https://images.unsplash.com/photo-1519558260268-cde7e03a0152?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1650&q=80" alt=""  />
                        </TLink>
                    </div>
                

                {/* <div className="mx-3 pt-2  pb-3 service-box-content">
                    <TLink to={'/service'}><h3 className="font-bold text-small m-0">This is the title</h3></TLink>
                    <TLink to={'/service'}><p className="service-box-desc text-smaller">This is the secription of the service</p></TLink>
                    <p className="text-small">Price: 30$</p>
                </div>

                <div className="flexcc service-box-actions mb-4">
                    <TLink className="py-2"><p className="text-small">Preview</p></TLink>
                </div> */}


                <div className="mx-3 pt-3  pb-3 service-box-content">
                    <TLink to={'/service'}><h3 className="font-bold text-small m-0">This is the title</h3></TLink>
                    <TLink to={'/service'}><p className="service-box-desc text-smaller">This is the secription of the service</p></TLink>
                </div>

                <div className="flexcb service-box-actions  mx-3 pb-3 pt-3">
                    <TLink to={'/service'} className="py-1 px-3 preview-but">
                        <p className="text-smaller">Preview</p>
                    </TLink>
                    <div>
                        <p className="text-normal text-bold service-box-price"> 30$</p>
                    </div>
                </div>

            </div>
        )
    }
}

export default ServiceBox
