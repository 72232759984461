import React from "react";
import FormViewer from "../components/FormViewer";
import LoaderButton from "../components/LoaderButton";
import Modal from '../components/Modal'
import HttpServices from "../utils/Http.services";
import { checkTranslation } from "../utils/useful";
import { countriesCode } from '../variables/countries'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actions from '../stores/actionsList';
import { Chart, Line, Bar, Pie } from 'react-chartjs-2';
import Collapsible from "react-collapsible";
import ReactToPdf from "react-to-pdf"
import ReactToPrint from 'react-to-print';
import { CSVLink, CSVDownload } from "react-csv";
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

class ResultModal extends React.Component {



    submitForm = () => {
        this.postValue()
    }
    hideModal = () => {
        this.modal.hideModal()
    }

    state = {
        variables: [],
        constraints: [],
        chartOptions: {
            // legend: {
            //     display: false
            // },
            responsive: true,

            bezierCurve: false,
            // legend: {
            //     display: false
            // },
            elements: {
                line: {
                    tension: 0
                }
            },
            // pan: {
            //     enabled: true,
            //     mode: 'x',
            //     speed: 1
            //     // ...
            // },
            // zoom: {
            //     enabled: true,
            //     // drag:true,
            //     mode: 'x'
            // },

            scales: {
                xAxes: [
                    {
                        // type: "time",
                        // autoSkip: true,
                        // maxTicksLimit: 20,
                        // time: {
                        //     // unit: 'week',
                        //     displayFormats: {
                        //         hour: "MMM DD"
                        //     },
                        //     tooltipFormat: "MMM DD hh:mm"
                        // }
                    }
                ],
                yAxes: [
                    {
                        ticks: {
                            beginAtZero: true,

                        }
                    }
                ]
            }
        },
    }




    msToTime(s) {

        // Pad to 2 or 3 digits, default is 2
        function pad(n, z) {
            z = z || 2;
            return ('00' + n).slice(-z);
        }

        var ms = s % 1000;
        s = (s - ms) / 1000;
        var secs = s % 60;
        s = (s - secs) / 60;
        var mins = s % 60;
        var hrs = (s - mins) / 60;

        return pad(mins) + ':' + pad(secs) + '.' + pad(ms, 3);
    }


    makeChartData(dataList, LabelList) {
        let data = {

            labels: [],
            datasets: [{
                label: 'Reduced Costs',
                data: [],
                backgroundColor: "#007affaa",//'rgba(57, 111, 180,1)',
                borderColor: '#007aff',
                // yAxisID: 'y-axis-1',

            },
            {
                label: 'Values',
                data: [],
                backgroundColor: '#ee5050aa',
                borderColor: '#ee5050',
                // yAxisID: 'y-axis-1',

            }
            ]
        }
        dataList.forEach(element => {
            data.labels.push(LabelList[element.no - 1]?.name)
            data.datasets[0].data.push(element.reducedCost ?? 0)

            data.datasets[1].data.push(element.value ?? 0)

        });

        return data

    }



    makeSlackChartData(dataList, LabelList) {
        let data = {

            labels: [],
            datasets: [{
                label: 'Values',
                data: [],
                backgroundColor: "#007affaa",//'rgba(57, 111, 180,1)',
                borderColor: '#007aff',
                // yAxisID: 'y-axis-1',

            },
            {
                label: 'Shadow price',
                data: [],
                backgroundColor: '#ee5050aa',
                borderColor: '#ee5050',
                // yAxisID: 'y-axis-1',

            }
            ]
        }
        // 
        // 
        if (dataList) {
            dataList.forEach(element => {
                data.labels.push("C" + element.no)

                // data.labels.push(LabelList[element.no - 1]?.name)
                data.datasets[1].data.push(element.shadow ?? 0)
                data.datasets[0].data.push(LabelList[element.no - 1]?.value)


                // data.datasets[1].data.push(element.slackOrSurplus ?? 0)

            });
        }

        return data

    }





    makeCoefficientChartData(dataList, LabelList) {
        let data = {

            labels: [],
            datasets: [{
                label: 'Current',
                data: [],
                backgroundColor: "#007affaa",//'rgba(57, 111, 180,1)',
                borderColor: '#007aff',
                // yAxisID: 'y-axis-1',

            },
            {
                label: 'Allowable Decrease',
                data: [],
                backgroundColor: '#ee5050aa',
                borderColor: '#ee5050',
                hidden: true,
                // yAxisID: 'y-axis-1',

            },
            {
                label: 'Allowable Increase',
                data: [],
                backgroundColor: '#905FD7aa',
                borderColor: '#905FD7',
                hidden: true,
                // yAxisID: 'y-axis-1',

            }
            ]
        }

        if (dataList) {
            dataList.forEach(element => {
                data.labels.push(LabelList[element.VAR_NO_RAN_SIM - 1]?.name)

                data.datasets[0].data.push(LabelList[element.VAR_NO_RAN_SIM - 1]?.value)

                data.datasets[1].data.push(element.VAL_OBJ_RAN_MIN_SIM ?? 0)
                data.datasets[2].data.push(element.VAL_OBJ_RAN_MAX_SIM ?? 0)


            });
        }

        return data

    }




    makeTimeData(dataList, LabelList) {
        let data = {

            labels: [],
            datasets: [{
                label: 'Runtime',
                data: [],
                backgroundColor: [],//'rgba(57, 111, 180,1)',
                borderColor: [],//'rgb(57, 111, 180)',
            },
            ]
        }
        dataList.forEach(element => {
            data.labels.push(element.label)
            data.datasets[0].data.push(element.value)
            data.datasets[0].backgroundColor.push(element.color + 'aa')
            data.datasets[0].borderColor.push(element.color)


        });

        return data

    }




    exportToCSV = () => {

        let fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        let fileExtension = '.xlsx';

        let coefficientRanges = []
        let slackData = []
        let variablesData = []
        let resultsData = []
        // 

        this.props.data.coefficientRanges.forEach(prop => {
            coefficientRanges.push(
                {
                    "variable": this.props.variables[prop.VAR_NO_RAN_SIM - 1]?.name,
                    "Current Coefficient": this.props.variables[prop.VAR_NO_RAN_SIM - 1]?.value,
                    "Allowable Decrease": prop.VAL_OBJ_RAN_MIN_SIM,
                    "Allowable Increase": prop.VAL_OBJ_RAN_MAX_SIM
                }
            )
        });

        

        Object.values(this.props.data.slackOrSurplus).sort((a, b) => a.no - b.no).forEach(prop => {
            slackData.push(
                {
                    "Constraints No": prop.no,
                    "Value": this.props.constraints[prop.no - 1]?.value,
                    "Slack or Surplus": prop.slackOrSurplus,
                    "Dual/Shadow prices": prop.shadow
                }
            )
        }); 
        // 

        Object.values(this.props.data?.valueOfVariables).sort((a, b) => a.no - b.no).forEach(prop => {
            variablesData.push(
                {
                    "Variable": this.props.variables[prop.no - 1]?.name,
                    "Value": prop?.value ?? 0,
                    "Reduced Cost": prop?.reducedCost ?? 0,
                }
            )
        });

        resultsData.push({ Type: this.props.type, Value: this.props.data?.objectiveFunction?.value, Feasible: String(this.props.data?.objectiveFunction?.feasible) })

        const coefficientRangesWS = XLSX.utils.json_to_sheet(coefficientRanges);
        const slackWS = XLSX.utils.json_to_sheet(slackData);
        const variablesWS = XLSX.utils.json_to_sheet(variablesData);
        const resultWS = XLSX.utils.json_to_sheet(resultsData);

        const wb = { Sheets: { "Result": resultWS, "Value of variables": variablesWS, 'Slack or Surplus - Dual Prices': slackWS, 'Coefficient Ranges': coefficientRangesWS, }, SheetNames: ['Result', 'Value of variables', 'Slack or Surplus - Dual Prices', 'Coefficient Ranges'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, "Rashac Report" + fileExtension);
    }





    render() {
        return (

            <Modal ref={el => this.modal = el} maxWidth={700}>

                {/* <ReactToPdf targetRef={this.pdfView} filename="div-blue.pdf">
                    {({ toPdf }) => (
                        <button onClick={toPdf}>Generate pdf</button>
                    )}
                </ReactToPdf> */}



                <div className="w-100  text-center pb-5" style={{ backgroundColor: '#ffffffee', borderRadius: 8 }}>
                    <div className="w-100 flexcb white" style={{ zIndex: 1, padding: '8px 15px', position: 'sticky', top: 0, borderBottom: '0px #eee solid', backgroundColor: '#f2f6f8ee', background: 'linear-gradient(to right,#007aff,#007affdd)', backdropFilter: 'blur(10px) saturate(180%)', WebkitBackdropFilter: 'blur(10px) saturate(180%)', borderRadius: '8px 8px 0px 0px' }}>

                        <div className="text-start">
                            <p className="text-smallest mt-2 opacity-5" style={{ lineHeight: 0.5 }}>CALCULATION IS DONE</p>
                            <p>Compile results</p>
                        </div>





                        <div className="flexc">
                            <button onClick={() => this.modal.hideModal()} className=" flexc px-3 mx-1 flexcc" style={{ height: 30, background: '#33333350', borderRadius: 20 }}>
                                <p className="text-normal white">Done</p>
                            </button>

                            <div className="cursor-pointer flexcc white" onClick={() => this.modal.hideModal()} style={{ width: 30, height: 30, borderRadius: '50%', backgroundColor: '#33333350' }}>
                                <img className="invert" style={{ width: 14, height: 14 }} src="/images/close.svg" alt="" />
                            </div>
                        </div>


                    </div>

                    <div className="flexcc w-100 mt-2 px-4">
                        <ReactToPrint
                            copyStyles={true}
                            trigger={() => <p style={{ cursor: 'pointer', padding: '3px 20px 3px 20px', backgroundColor: '#789', borderRadius: 30, color: '#fff' }}>Print</p>}
                            content={() => this.printView}
                        />


                        {/* <CSVLink data={this.props.data?.coefficientRanges ?? []}>Download me</CSVLink>

                        <CSVDownload data={this.props.data?.coefficientRanges ?? []} target="_blank" /> */}

                        <p className="mx-1" onClick={() => this.exportToCSV()} style={{ cursor: 'pointer', padding: '3px 20px 3px 20px', backgroundColor: '#789', borderRadius: 30, color: '#fff' }}>Export Excel</p>

                    </div>

                    <div ref={el => this.printView = el} className="py-3 px-4">
                        <p className="text-smaller description-text flexcc">Results of this compile are as below </p>


                        {!this.props.data?.objectiveFunction?.feasible && (
                            <p className="text-normal mt-2 mb-3" style={{ color: '#ee5050', backgroundColor: '#ee505020', borderRadius: 4, padding: '7px 20px' }}>Equiation in <span style={{ color: '#ee5050', fontWeight: 'bold' }}>NOT</span> feasible</p>

                        )}



                        <p style={{ color: '#007aff' }} className="mt-2 text-normal flexcc">Resolve Summary <span style={{ backgroundColor: '#007aff', height: 15, width: 15, borderRadius: 30, color: '#fff', cursor: 'pointer' }} className="flexcc mx-1" onClick={() => this.setState({ summaryCollapse: !this.state.summaryCollapse })}>{this.state.summaryCollapse ? '+' : '-'}</span></p>
                        <Collapsible open={!this.state.summaryCollapse} >

                            <div className="mb-2" style={{ maxHeight: 300, overflow: 'auto' }}>

                                <table className="w-100 mt-2 lined-table">
                                    {/* <thead>
                                        <tr>
                                            <th>Database Time</th>
                                            <th>Feasible</th>


                                        </tr>
                                    </thead> */}
                                    <tbody>
                                        <tr>
                                            <th style={{ fontWeight: 'bold' }}>Solve runtime</th>
                                            <th>{this.props.data?.time} milliseconds</th>
                                        </tr>
                                        {/* {this.msToTime(this.props.data?.time)} */}
                                        <tr>
                                            <th ><p style={{ fontWeight: 'bold' }}>Database runtime</p></th>
                                            <th>{this.props.data?.insertTime + this.props.data?.removeTime + this.props.data?.readTime} milliseconds</th>
                                        </tr>

                                        <tr>
                                            <th style={{ fontWeight: 'bold' }}>Feasible</th>
                                            <th>{String(this.props.data?.objectiveFunction?.feasible)}</th>
                                        </tr>

                                        <tr>
                                            <th style={{ fontWeight: 'bold' }}>Total variables</th>
                                            <th>{String(this.props.variables?.length)}</th>
                                        </tr>

                                        <tr>
                                            <th style={{ fontWeight: 'bold' }}>Total constraints</th>
                                            <th>{String(this.props.constraints?.length)}</th>
                                        </tr>


                                    </tbody>
                                </table>
                            </div>
                        </Collapsible>


                        <div className="mt-2">
                            <button className="text-small" style={{ color: 'rgb(0, 122, 255)' }} onClick={() => this.setState({ runtimeChartCollapse: !this.state.runtimeChartCollapse })}>{this.state.runtimeChartCollapse ? 'Show Runtime Chart' : 'Hide Runtime Chart'}</button>
                            <Collapsible open={!this.state.runtimeChartCollapse}>
                                <Pie
                                    data={(this.makeTimeData([
                                        { label: 'Solve', value: this.props.data?.time, color: '#007aff' },
                                        { label: "Insert data", value: this.props.data?.insertTime, color: '#905FD7' },
                                        { label: "Remove data", value: this.props.data?.removeTime, color: '#ee5050' },
                                        { label: "Read data", value: this.props.data?.readTime, color: '#F12B5C' },

                                    ]))}
                                    options={this.state.chartOptions}
                                    ref={el => this.chart = el}
                                />
                                <p className="text-small" style={{ color: '#789' }}>Runtime Chart</p>
                            </Collapsible>
                        </div>


                        <p style={{ color: '#007aff' }} className="mt-4 text-normal flexcc">Objective Function <span style={{ backgroundColor: '#007aff', height: 15, width: 15, borderRadius: 30, color: '#fff', cursor: 'pointer' }} className="flexcc mx-1" onClick={() => this.setState({ objectiveCollapse: !this.state.objectiveCollapse })}>{this.state.objectiveCollapse ? '+' : '-'}</span></p>
                        <Collapsible open={!this.state.objectiveCollapse} >
                            <div style={{ maxHeight: 300, overflow: 'auto' }}>

                                <table className="w-100 mt-2 lined-table">
                                    <thead style={{ position: 'sticky', top: 0 }}>
                                        <tr>
                                            <th >Type</th>
                                            <th>Value</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <th>{this.props.type}</th>
                                            <th>{this.props.data?.objectiveFunction?.value}</th>

                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </Collapsible>



                        <p style={{ color: '#007aff' }} className="mt-2 text-normal flexcc">Value of variables <span style={{ backgroundColor: '#007aff', height: 15, width: 15, borderRadius: 30, color: '#fff', cursor: 'pointer' }} className="flexcc mx-1" onClick={() => this.setState({ vaiablesCollapse: !this.state.vaiablesCollapse })}>{this.state.vaiablesCollapse ? '+' : '-'}</span></p>
                        <Collapsible open={!this.state.vaiablesCollapse} >

                            <div style={{ maxHeight: 300, overflow: 'auto' }}>

                                <table className="w-100 mt-2 lined-table">
                                    <thead style={{ position: 'sticky', top: 0 }}>
                                        <tr>
                                            <th>Variable</th>
                                            <th>Value</th>
                                            <th>Reduced Cost</th>

                                        </tr>
                                    </thead>
                                    <tbody>

                                        {this.props.data?.valueOfVariables && Object.values(this.props.data?.valueOfVariables).sort((a, b) => a.no - b.no).map((prop, index) => {
                                            return (
                                                <tr>
                                                    <th>{this.props.variables[prop.no - 1]?.name}</th>
                                                    <th>{prop?.value ?? 0}</th>
                                                    <th>{prop?.reducedCost ?? 0}</th>


                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </Collapsible>


                        <div className="mt-2">
                            <button className="text-small" style={{ color: 'rgb(0, 122, 255)' }} onClick={() => this.setState({ vaiablesChartCollapse: !this.state.vaiablesChartCollapse })}>{this.state.vaiablesChartCollapse ? 'Show Variables Chart' : 'Hide Variables Chart'}</button>
                            <Collapsible open={!this.state.vaiablesChartCollapse}>
                                <Bar
                                    data={(this.props.data?.valueOfVariables ? this.makeChartData(Object.values(this.props.data?.valueOfVariables ?? this.props.data?.valueOfVariables), this.props.variables) : { datasets: [] })}
                                    options={this.state.chartOptions}
                                    ref={el => this.chart = el}
                                />
                            </Collapsible>
                        </div>


                        <p style={{ color: '#007aff' }} className="mt-4 mb-1 text-normal flexcc">Slack or Surplus/Dual Prices <span style={{ backgroundColor: '#007aff', height: 15, width: 15, borderRadius: 30, color: '#fff', cursor: 'pointer' }} className="flexcc mx-1" onClick={() => this.setState({ slackCollapse: !this.state.slackCollapse })}>{this.state.slackCollapse ? '+' : '-'}</span></p>
                        <Collapsible open={!this.state.slackCollapse} >

                            <div style={{ maxHeight: 300, overflow: 'auto' }}>

                                <table className="w-100 mt-3 lined-table" >
                                    {/* style={{display: "block",maxHeight:50,overflow:'auto'}} */}
                                    <thead style={{ position: "sticky", top: 0 }}>
                                        <tr>
                                            <th>Constraints No.</th>
                                            <th>Value</th>
                                            <th>Slack or Surplus</th>
                                            <th>Dual/Shadow prices</th>

                                        </tr>
                                    </thead>

                                    <tbody style={{}}>
                                        {this.props.data?.slackOrSurplus && Object.values(this.props.data?.slackOrSurplus).sort((a, b) => a.no - b.no).map((prop, index) => {
                                            return (
                                                <tr key={index}>
                                                    <th>{prop.no}</th>
                                                    {/* <th>{this.props.constraints[prop.no - 1]?.value}</th> */}
                                                    <th>{prop.value}</th>
                                                    <th>{prop.slackOrSurplus}</th>
                                                    <th>{(prop.shadow && prop.shadow != '') ? prop.shadow : 0}</th>
                                                </tr>
                                            )
                                        })}


                                    </tbody>

                                </table>
                            </div>
                        </Collapsible>


                        <div className="mt-2">
                            <button className="text-small" style={{ color: 'rgb(0, 122, 255)' }} onClick={() => this.setState({ valuesChartCollapse: !this.state.valuesChartCollapse })}>{this.state.valuesChartCollapse ? 'Show values Chart' : 'Hide values Chart'}</button>
                            <Collapsible open={!this.state.valuesChartCollapse}>
                                <Bar
                                    data={(this.makeSlackChartData(Object.values(this.props.data?.slackOrSurplus ?? {}), this.props.constraints))}
                                    options={this.state.chartOptions}
                                    ref={el => this.chart = el}
                                />
                            </Collapsible>
                        </div>




                        <p style={{ color: '#007aff' }} className="mt-4 mb-1 text-normal flexcc">Objective Coefficient Ranges <span style={{ backgroundColor: '#007aff', height: 15, width: 15, borderRadius: 30, color: '#fff', cursor: 'pointer' }} className="flexcc mx-1" onClick={() => this.setState({ rangesCollapse: !this.state.rangesCollapse })}>{this.state.rangesCollapse ? '+' : '-'}</span></p>
                        <Collapsible open={!this.state.rangesCollapse} >

                            <div style={{ maxHeight: 300, overflow: 'auto' }}>
                                <table className="w-100 mt-3 lined-table">
                                    <thead style={{ position: 'sticky', top: 0 }}>
                                        <tr>
                                            <th>Variables</th>
                                            <th>Current Coefficient</th>
                                            <th>Allowable Decrease</th>
                                            <th>Allowable Increase</th>

                                        </tr>
                                    </thead>
                                    <tbody>

                                        {this.props.data?.coefficientRanges && Object.values(this.props.data?.coefficientRanges).sort((a, b) => a.no - b.no).map((prop, index) => {
                                            return (
                                                <tr key={index}>
                                                    <th>{this.props.variables[prop.VAR_NO_RAN_SIM - 1]?.name}</th>
                                                    <th>{this.props.variables[prop.VAR_NO_RAN_SIM - 1]?.value}</th>
                                                    <th>{prop.VAL_OBJ_RAN_MIN_SIM != '99999999.9' ? prop.VAL_OBJ_RAN_MIN_SIM : "Infinity"}</th>
                                                    <th>{prop.VAL_OBJ_RAN_MAX_SIM != '99999999.9' ? prop.VAL_OBJ_RAN_MAX_SIM : "Infinity"}</th>

                                                </tr>
                                            )
                                        })}

                                    </tbody>

                                </table>
                            </div>
                        </Collapsible>


                        <div className="mt-2">
                            <button className="text-small" style={{ color: 'rgb(0, 122, 255)' }} onClick={() => this.setState({ oefficientChartCollapse: !this.state.oefficientChartCollapse })}>{this.state.oefficientChartCollapse ? 'Show Coefficient Chart' : 'Hide Coefficient Chart'}</button>
                            <Collapsible open={!this.state.oefficientChartCollapse}>
                                <Bar
                                    data={(this.makeCoefficientChartData(Object.values(this.props.data?.coefficientRanges ?? {}), this.props.variables))}
                                    options={this.state.chartOptions}
                                    ref={el => this.chart = el}
                                />
                            </Collapsible>
                        </div>



                        {/* <p className="mt-3 text-small" style={{ color: '#789' }}>Total Calculation time {this.msToTime(this.props.data?.time)} = {this.props.data?.time} milliseconds </p> */}




                        {/* <p style={{ color: '#007aff' }} className="mt-4 mb-1 text-normal ">Righthand Side Ranges</p>
                        <table className="w-100 mt-3 lined-table">
                            <thead>
                                <tr>
                                    <th>Constraints No.</th>
                                    <th>Current RHS</th>
                                    <th>Allowable Increase</th>
                                    <th>Allowable Decrease</th>

                                </tr>
                            </thead>
                            <tbody>
                               

                            </tbody>
                        </table>
 */}



                    </div>
                </div>
            </Modal >
        );

    }
}



const mapStateToProps = state => ({ settings: state.settings, user: state.user })
const mapDispatchToProps = dispatch => ({ actions: bindActionCreators(actions, dispatch) })

export default connect(
    mapStateToProps,
    mapDispatchToProps,
    null, { forwardRef: true }
)(ResultModal);
