import React from 'react'
import LoaderButton from '../../components/LoaderButton'
import FormViewer from '../../components/FormViewer'
import { chooseWallpaper, translate } from "../../utils/useful";
import TLink from '../../components/TLink';

// import HttpService from '../../utils/Http.services';

class AddThingFromWiki extends React.Component {
    state = {
        key: '',
        selectedCountry: {},
        resendTime: 60,
        resendRequested: false,
        // errors:{},
        formHeaders: [{ key: 'wikiId', type: 'AdvancedSelectInput', information: { label: '{{lang}}texts.addThing.findThing', isSearchable: true, icon: '/images/search.svg', placeholder: '{{lang}}texts.addThing.findThing', required: true } }]
    }

    componentDidMount() {
        // this.changeResendTime()
    }



    postActicationCode = () => {
        this.setState({ isLoading: true, errors: {} })
        let data = this.form.getForm()
        this.props.setData(data, () => {
            this.props.postActicationCode((result, err) => {
                if (err) { this.setState({ errors: err }) }
                this.setState({ isLoading: false })
            })
        })
    }

    requestResend() {
        if (!this.state.resendRequested) {
            this.setState({ resendRequested: true })
            this.props.postUserIndicator((result, err) => {
                this.setState({ resendRequested: false })
                if (err) { return }
                this.setState({ canResend: false, resendTime: 60 })
            })
        }
    }

    checkResendTime = () => {
        if (this.state.resendTime > 0) {
            this.setState({ resendTime: this.state.resendTime - 1 })
        } else { this.setState({ canResend: true }) }
    }




    render() {
        return (

            <div className="flexcc flex-column">

                <img src="https://www.flaticon.com/svg/static/icons/svg/3721/3721591.svg" height="60px" />
                <h2 className="mt-3 mb-0" style={{ fontSize: 27 }}>{translate('Find Thing').toUpperCase()}</h2>
                <div className="w-100" style={{ maxWidth: 400 }}>

                    <p className="text-normal mt-1 text-center mb-3" >{translate('texts.addThing.searchForThing')}</p>


                    <FormViewer ref={el => this.form = el} headers={this.state.formHeaders} initData={this.props.initData} errors={this.state.errors} inputClass={'modern-input'} />

                    <div className="text-center">
                        <LoaderButton
                            onClick={this.postActicationCode}
                            isLoading={this.state.isLoading}
                            text={translate('useful.nextStep')}
                            type={"Oval"}
                            className="mt-4 mb-4"
                            buttonStyle={{ outline: 'none', backgroundColor: '#222', cursor: 'pointer', padding: '10px 50px', borderRadius: 4, fontSize: 15, fontWeight: 'bold', color: '#fff', border: 'none' }}
                            width={40}
                            height={40}
                            color={'#202020'}
                        />

                        <TLink to={'/addWiki'} search={'?ref=addDevice'} className="mb-3" style={{ fontSize: 15, color: '#007aff' }}>{translate('texts.addThing.addCustomDevice')}</TLink>
                        <div>
                            <button onClick={() => this.props.changeStage('init')} style={{ fontSize: 13, opacity: 0.5, marginTop: 0 }}>{translate('useful.backOneStep')}</button>
                        </div>

                    </div>
                </div>
            </div>

          
        )
    }
}

export default AddThingFromWiki;