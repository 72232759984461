import React from 'react';
import { checkTextTranslation } from '../../utils/useful';
import { nanoid } from 'nanoid';
import { getBoundingClientRect } from '../../utils/functions';
import { findAllByTestId } from '@testing-library/react';
import {
    DirectionalLightShadow,
    DynamicDrawUsage,
    TriangleFanDrawMode,
} from 'three';
import * as equationsFuctions from './sigma';
import ReactDOMServer from 'react-dom/server';
import { customAlphabet } from 'nanoid';
import DescriptionsBox from '../DescriptionsBox';
import WordSuggestion from '../boxes/WordSuggestion';

class SmartInput extends React.Component {
    state = {
        variables: {},
        optionsList: [
            { key: 'variables', label: 'variables' },
            { key: 'calculator', label: 'calculator' },
            { key: 'function-name', label: 'Function Name' },
            { key: 'function-test', label: 'Function test' },
        ],
    };

    getInputValue = () => {
        let text = this.editor.textContent;
        // let text = this.editor.innerText
        return text;
        //  
    };

    editorKeyDown = (e) => {
        if (e.ctrlKey && e.key === 'c') {
            this.onCopy();
            // this.insertEquation('Index');
        }
    };
    componentDidMount() {
        // 
        window.addEventListener("scroll", this.scrollChange);
        this.editor.addEventListener('DOMCharacterDataModified', this.onChange);
        // document.execCommand("defaultParagraphSeparator", false, 'br')

        this.editor.addEventListener('mouseup', (e) => this.onChange(e, true));

        const config = {
            attributes: true,
            childList: true,
            subtree: true,
            characterData: true,
            characterDataOldValue: true,
        };

        this.observer = new MutationObserver(this.observeChanged);
        this.observer.observe(this.editor, config);

        document.getElementById('editor').setAttribute('spellcheck', false);
        if (this.props.data) {
            let lines = this.props.data.split('\n');

            lines.forEach((line, index) => {
                this.addNewLiniInit(line, index);
                this.addComponentEvents(line);
            });
            // }, 1000);
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.data != this.props.data && this.props.data) {
            let lines = this.props.data.split('\n');


            lines.forEach((line, index) => {
                this.addNewLiniInit(line, index);
                this.addComponentEvents(line);
            });

            // this.editor.innerHTML = this.props.data
        }
    }

    componentWillUnmount() {
        this.editor.removeEventListener(
            'DOMCharacterDataModified',
            this.onChange
        );
        window.removeEventListener("scroll", this.scrollChange);
        this.editor.removeEventListener('keypress', () => { });
        window.removeEventListener('mousedown', this.closeList);
        this.observer.disconnect();
    }

    addNewLiniInit(line, index) {
        if (index == 0) {
            this.editor.innerHTML = line;
        } else {
            let div = document.createElement('div');
            let br = document.createElement('br');
            div.appendChild(br);
            div.classList.add('line');
            div.innerHTML = line;
            this.editor.appendChild(div);
        }
    }

    addComponentEvents = (text) => {
        const regex1 = /"rem-([^"]*)"/g;

        let found = text.match(regex1);

        if (Array.isArray(found)) {
            found.forEach((element) => {
                let id = element.replace('"rem-', '').replace('"', '');

                setTimeout(() => {
                    // 

                    let but = document.querySelector('#rem-' + id);
                    let typables = document.querySelectorAll('.typable');

                    if (but) {
                        but.onclick = () => this.removeFunction(id);
                    }
                    let eq = document.querySelector('#' + id);

                    if (eq) {
                        eq.addEventListener(
                            'contextmenu',
                            (e) => {
                                e.preventDefault();
                                this.rightClickFunction(id, e);
                            },
                            false
                        );
                    }

                    if (typables) {
                        typables.forEach((typable) => {
                            typable.onclick = (e) =>
                                this.doFocus(e, id, typable);
                        });
                    }
                }, 200);
            });
        }
    };

    addNewLine() {
        let selection = document.getSelection();
        let node = selection.anchorNode;
        let div = document.createElement('div');
        let br = document.createElement('br');
        div.appendChild(br);
        div.classList.add('line');

        let line = this.getCurrentNodeParent(node);
        if (line) {
            line.parentNode.insertBefore(div, line.nextSibling);
            let range = document.createRange();
            document.getSelection().removeAllRanges();
            range.setStart(div, 0);
            range.setEnd(div, 0);
            document.getSelection().addRange(range);

            this.setState({ showList: false });
        }
    }

    getCurrentNodeParent(node) {

        if (!node) {
            return null;
        }
        if (
            node?.parentNode?.classList &&
            node?.parentNode?.classList.contains('editor')
        ) {
            return node;
        } else {
            if (node) return this.getCurrentNodeParent(node?.parentNode);
        }
    }

    changeInputValue(target) {
        let value = target.validity.valid ? target.value : null;
        if (value !== null) {
            this.props.changeValue(this.props.header.key, value);
        }
    }

    // changeAttribute = (attribute, value, boolean) => {
    //     document.execCommand(attribute, boolean, value)
    //     // 
    // }
    // textContent
    // innerHTML

    onPaste = (e) => {
        e.preventDefault();
        const editorTextContentCopy = this.editor.textContent;
        const selection = window.getSelection();
        
        const clipText = e.clipboardData.getData('text');
        if (selection.rangeCount > 0) {
            const range = selection.getRangeAt(0);
            const selectedText = range.cloneContents().textContent;

            
            const preCaretRange = range.cloneRange();
            preCaretRange.selectNodeContents(this.editor);
            preCaretRange.setEnd(range.endContainer, range.endOffset);
            const caretIndex = preCaretRange.toString().length;
            
            let result;
            if(selectedText.length){
                result = editorTextContentCopy.replace(selectedText, clipText);
            } 
            else{
                result = editorTextContentCopy.slice(0, caretIndex) + clipText + editorTextContentCopy.slice(caretIndex);
            }
            this.props.getOneEquation(result);

        }
    };

    onCopy = (e) => {
        const selection = window.getSelection();
        const range = selection.getRangeAt(0);
        const selectedText = range.cloneContents().textContent;
        if (navigator?.clipboard) {
            navigator.clipboard.writeText(selectedText).then(
                () => {
                    /* clipboard successfully set */
                    
                },
                () => {
                    /* clipboard write failed */
                    
                }
            );
        } else {
            
            const tempTextArea = document.createElement('textarea');
            tempTextArea.value = selectedText;
            document.body.appendChild(tempTextArea);
            tempTextArea.select();
            document.execCommand('copy');
            document.body.removeChild(tempTextArea);
        }
        // this.setState({ rId: null }, () => {
        // })
    };
    onInput = (e) => {
        let text = this.editor.innerText;
        if (!text || text == '' || text == ' ' || text == ' ' || text == '\n') {
            return this.editor.innerHTML = '';
        }
        const caretPosition = window.getSelection().getRangeAt(0)?.getBoundingClientRect();
        this.WordSuggestion.changePosition(caretPosition.left, caretPosition.top);
        const editorInput = e.target.textContent;
        const selection = window.getSelection();
        const range = selection.getRangeAt(0);
        const cursorPosition = range.endOffset;
        const lastWordMatch = editorInput.slice(0, cursorPosition).match(/\S+$/);
        const wordArray = this.props.properVariables.map(variable => variable.name);
        const caretPositionNode = range.startContainer.parentNode;
        this.caretCurrentElement = caretPositionNode;
        if (lastWordMatch) {
            const lastWord = lastWordMatch[0];
            const filteredSuggestions = wordArray.filter((word) =>
                word.toLowerCase().startsWith(lastWord.toLowerCase())
            );
            filteredSuggestions.length ? this.WordSuggestion.showWrapper() : this.WordSuggestion.hideWrapper();
            this.WordSuggestion.updateSuggestionWords(filteredSuggestions)
        } else {
            this.WordSuggestion.hideWrapper();
        }
    }
    selectSuggestionWord = (suggestionWord) => {
        
        
        this.WordSuggestion.hideWrapper();
        this.caretCurrentElement.textContent = suggestionWord;
        const indexesSpan = this.caretCurrentElement.parentElement.nextSibling.nextSibling.childNodes[0];
        const selectedVariable = this.props.properVariables?.find(el => el.name === suggestionWord);
        
        
        if (selectedVariable && selectedVariable) {
            if (selectedVariable?.indexes?.join){
                indexesSpan.textContent = selectedVariable?.indexes?.join("");
            }
            else{
                indexesSpan.textContent = selectedVariable?.indexes;
            }
        }
    };
    scrollChange = () => {
        if(window?.getSelection().rangeCount > 0 && window?.getSelection()?.getRangeAt(0)){
            const caretPosition = window.getSelection().getRangeAt(0)?.getBoundingClientRect();
            this.WordSuggestion.changePosition(caretPosition.left, caretPosition.top);
        }
    }
    // onKeyDown = (e) => {
    //     
    //     if(e.key.includes("Arrow") && this.WordSuggestion.getDisplay()){
    //         e.preventDefault();

    //     }
    // }
    observeChanged = (e) => {
        let event = e;
        if (!Array.isArray(event)) {
            event = [event];
        }

        event.forEach((element) => {
            // 
            if (element.removedNodes && element.removedNodes.length > 0) {
                element.removedNodes.forEach((removedNode) => {
                    if (
                        removedNode.className?.includes('smart-variable') &&
                        removedNode.attributes.id
                    ) {
                        // 
                        let variables = this.state.variables;
                        delete variables[removedNode.attributes.id.value];
                    }
                });
                // 
                // 
                // document.execCommand("foreColor", false, "")
            }

            if (element.type == 'characterData') {
                if (
                    element.target.parentNode?.className?.includes(
                        'smart-variable'
                    ) &&
                    element.target.parentNode?.attributes.id
                ) {
                    if (!element.target.nodeValue.startsWith('@')) {
                        let variables = this.state.variables;
                        delete variables[
                            element.target.parentNode?.attributes.id.value
                        ];
                        // 
                        let textNode = document.createTextNode(
                            element.target.nodeValue
                        );
                        element.target.parentNode.replaceWith(textNode);
                    }
                }
            }
            if (
                event.length == 1 &&
                element.removedNodes?.length > 0 &&
                element.removedNodes[0].nodeName == '#text' &&
                element.target.classList.contains('typable')
            ) {
                // 
                let selection = document.getSelection();

                let range = selection.getRangeAt(0);
                range.deleteContents();
                let textNode = document.createTextNode(' ');
                range.insertNode(textNode);

                // window.getSelection().removeAllRanges();
            }
        });

        // 
        let selection = document.getSelection();
        let classList = selection.anchorNode?.classList;
        if (classList?.contains('line') || classList?.contains('editor')) {
            let anchorNode = selection.anchorNode ?? this.editor;
            document.getSelection().removeAllRanges();
            let range = document.createRange();
            // let an
            // 
            range.setStart(anchorNode, 0);
            range.setEnd(anchorNode, 0);
            document.getSelection().addRange(range);
        }
    };

    onChange = (e, isClick) => {
        setTimeout(() => {
            // 

            let selection = document.getSelection();
            // 
            let node = selection.anchorNode;
            let offset = selection.focusOffset;

            if (node) {
                let currentVariable;
                if (node.parentNode.classList.contains('smart-variable')) {
                    currentVariable = node.parentNode.id;
                }

                let text = String(node.textContent);
               
                let range = document.createRange();

                if (currentVariable) {
                    if (e.prevValue !== e.newValue) {
                        if (text.startsWith('@') && text.endsWith('@')) {
                            let textNode = document.createTextNode(e.prevValue);
                            node.replaceWith(textNode);
                            let oldNode = textNode;
                            node = document.createTextNode('@');
                            oldNode.parentNode.parentNode.insertBefore(
                                node,
                                oldNode.parentNode.nextSibling
                            );
                            document.getSelection().removeAllRanges();
                            range.setStart(node, 1);
                            range.setEnd(node, 1);
                            document.getSelection().addRange(range);
                            text = String(node.textContent);
                        } else {
                            if (
                                text.charCodeAt(text.length - 1) == 32 ||
                                text.charCodeAt(text.length - 1) == 160
                            ) {
                                let oldNode = node;
                                node = document.createTextNode(e.prevValue);
                                oldNode.replaceWith(node);
                                let nextSibling = node.parentNode.nextSibling;
                                if (
                                    nextSibling &&
                                    nextSibling.nodeValue &&
                                    nextSibling.nodeValue != ''
                                ) {
                                    node = node.parentNode.nextSibling;
                                    // 
                                    // 
                                } else {
                                    let textNode = document.createTextNode(' ');
                                    node.parentNode.parentNode.insertBefore(
                                        textNode,
                                        node.parentNode.nextSibling
                                    );
                                    node = textNode;
                                    // 
                                }
                                document.getSelection().removeAllRanges();
                                if (!node.length == 0) {
                                    node.innerHTML = ' ';
                                }
                                range.setStart(node, 1);
                                range.setEnd(node, 1);
                                document.getSelection().addRange(range);
                                text = String(node.textContent);
                            } else {
                                let textNode = document.createTextNode(text);
                                node.parentNode.replaceWith(textNode);
                                node = textNode;
                                document.getSelection().removeAllRanges();
                                range.setStart(node, offset);
                                range.setEnd(node, offset);
                                document.getSelection().addRange(range);
                                let variables = this.state.variables;
                                delete variables[currentVariable];
                                // 
                            }
                        }
                    }
                }

                let lastTextArray = text.slice(0, selection.focusOffset);
                lastTextArray = lastTextArray.split(' ');
                lastTextArray.forEach((lastText) => {
                    // 

                    let focusOffset = selection.focusOffset;

                    if (lastText.charAt(0) != '@') {
                        // 
                        lastText = lastText.replace(/ /g, '');
                        lastText = lastText.replace(/ /g, '');
                    }

                    if (
                        node.length >=
                        selection.focusOffset - lastText.length
                    ) {
                        range.setStart(
                            node,
                            selection.focusOffset - lastText.length
                        );
                    }
                    if (node.length >= selection.focusOffset) {
                        range.setEnd(node, selection.focusOffset);
                    }

                    if (lastText.charAt(0) == '@') {
                        if (!this.state.showList) {
                            this.openList();
                            // this.setState({ showList: true })
                        }
                        let lastChar = lastText.charAt(lastText.length - 1);

                        if (node.length >= selection.focusOffset) {
                            // 
                            // 
                            range.setStart(node, selection.focusOffset);
                            range.setEnd(node, selection.focusOffset);
                            document.getSelection().addRange(range);
                            // document.getSelection().removeAllRanges()
                        }

                        if ((lastChar == ' ' || lastChar == ' ') && !isClick) {
                            // 

                            range.setStart(node, selection.focusOffset);
                            range.setEnd(node, selection.focusOffset);
                            document.getSelection().addRange(range);
                            setTimeout(() => {
                                // this.setState({ showList: false })
                                this.closeList();
                            }, 50);
                        }

                        if (node.textContent == '@') {
                            // 
                            document.getSelection().removeAllRanges();
                            range.setStart(node, 1);
                            range.setEnd(node, 1);
                            document.getSelection().addRange(range);
                        }

                      
                    } else {
                        // 
                        if (this.state.showList) {
                            this.closeList();
                            // this.setState({ showList: false })
                        }
                    }
                });
                var expression =
                    /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi;
                var regex = new RegExp(expression);

                var t = 'www.google.com';
            }
        }, 20);
    };

    findNextNode(node) {
        // if (!node.parentNode.contains('editor')) {
        // 
        let nNode = node.parentNode.nextElementSibling;
        // 
        if (nNode) {
            return nNode;
        } else {
            // if (!nNode.classList.contains('editor')) {
            return this.findNextNode(node.parentNode);
            // }
        }
        // }
    }

    optionSelected = (prop) => {
        let selection = document.getSelection();
        // 
        let node = selection.anchorNode;

        let variables = this.state.variables;

        // 

        // for (var i = 0, len = selection.rangeCount; i < len; ++i) {
        //     // html.appendChild(selection.getRangeAt(i).cloneContents());
        //   }

        // 
        if (node) {
            let currentVariable;
            if (node.parentNode.classList.contains('smart-variable')) {
                currentVariable = node.parentNode.id;
                delete variables[currentVariable];
                // 
            }

            let text = String(node.textContent);
            // 
            // 
            // 

            let start, end;

            for (let i = selection.anchorOffset; i < text.length; i++) {
                const char = text[i];
                // 
                // 
                if (
                    !char ||
                    char == '' ||
                    char == '@' ||
                    char == ' ' ||
                    char == ' ' ||
                    char == '\n'
                ) {
                    end = i;
                    break;
                }
            }

            if (!end) {
                end = text.length;
            }

            for (let i = selection.anchorOffset; i > 0; i--) {
                const char = text[i];
                if (
                    char == '' ||
                    char == '@' ||
                    char == ' ' ||
                    char == ' ' ||
                    char == '\n'
                ) {
                    start = i;
                    break;
                }
            }

            if (!start) {
                start = 0;
            }

            let word = text.substr(start, end - start);
            // 
            // 
            // 

            let id = nanoid(10);

            let span = this.createSmartVariable('@' + prop.key, id);

            // node.remove()
            let range = document.createRange();
            document.getSelection().removeAllRanges();

            // let parentClasses = node.parentNode.classList
            // let insertNode = node

            // if (!parentClasses.contains('line') && !parentClasses.contains('editor')) {
            //     insertNode = node.parentNode
            //     start = 0
            //     end = 0
            // }

            range.setStart(node, start);
            range.setEnd(node, end);
            document.getSelection().addRange(range);
            // range = document.selection.createRange()
            range.deleteContents();
            // var newspan = document.createElement("span");
            if (!node.parentNode.classList.contains('smart-variable')) {
                range.insertNode(span);
            } else {
                // let newSpan = document.createElement("span");
                // newSpan.innerHTML = ' '
                if (node.parentNode.parentNode) {
                    node.parentNode.parentNode.insertBefore(
                        span,
                        node.parentNode.nextSibling
                    );
                }
                // 
                // document.getSelection().removeAllRanges()
                // range.setStart(span, 1)
                // range.setEnd(span, 1)
                // document.getSelection().addRange(range)
                // range.insertNode(span)
            }

            // 
            // 
            let nextNode = null;
            // 
            // 

            let nextSibling = span.nextSibling;
            if (
                nextSibling &&
                nextSibling.nodeValue &&
                nextSibling.nodeValue != ''
            ) {
                nextNode = span.nextSibling;
            } else {
                // if (!span.nextSibling || !span.nextSibling.innerHTML) {
                // 
                let newNode = document.createTextNode(' ');
                // newNode.innerHTML =
                // 
                if (span.parentNode) {
                    span.parentNode.insertBefore(newNode, span.nextSibling);
                } else {
                    span.insertBefore(newNode, span.nextSibling);
                }
                nextNode = newNode;
            }

            document.getSelection().removeAllRanges();
            range.setStart(nextNode, 1);
            range.setEnd(nextNode, 1);
            document.getSelection().addRange(range);

            variables[id] = prop;

            // 
            this.closeList();
            // 
            // document.getSelection().removeAllRanges()
            // range.setStart(span, end-start-1)
            // range.setEnd(span, end-start-1)
            // document.getSelection().addRange(range)
            // span.parentNode.insertBefore(newspan, span.nextSibling);

            //  selection = document.getSelection()
            // 
            //   node = selection.anchorNode

            //  selection = document.getSelection()
            // 
            // let node = selection.anchorNode

            // let lastTextArray = text.slice(0, selection.focusOffset)
            // lastTextArray = lastTextArray.split(' ')

            // 
            // this.changeAttribute('delete', "")
            // this.changeAttribute('insertHTML', "<span> </span>")
            // this.closeList()
        }
    };

    openList = () => {
        let selection = document.getSelection();
        let node = selection.anchorNode;
        let range = selection.getRangeAt(0);
        if (node) {
            let position = getBoundingClientRect(range);
            let editorPosition = getBoundingClientRect(this.editor);

            position.top = position.top - editorPosition.top + 30;
            this.setState({ showList: true, listPostion: position });
            window.addEventListener('mousedown', this.closeList);
        }
    };

    closeList = (e) => {
        // 
        if (!e || (this.wrapperRef && !this.wrapperRef.contains(e.target))) {
            this.setState({ showList: false });
        }
    };

    insertEquation = (key) => {
        if (equationsFuctions[key]) {
            let equation = equationsFuctions[key]();
            this.insertInEditor(equation);
        }
    };

    insertInEditor(element) {
        let selection = document.getSelection();
        let node = selection.anchorNode;
        let hasParent = this.getCurrentNodeParent(node);

        if (node && hasParent) {
            // let range = document.createRange()
            let range = selection.getRangeAt(0);
            range.deleteContents();
            // document.getSelection().removeAllRanges()

            // range.setStart(node, 0)
            // range.setEnd(node, 0)
            // document.getSelection().addRange(range)
            // range.deleteContents();

            let parser = new DOMParser();
            let newElement = parser.parseFromString(
                ReactDOMServer.renderToString(element),
                'text/html'
            );
            let span = newElement.querySelector('span');
            let id = span.id;

            range.insertNode(span);

            setTimeout(() => {
                // 

                let but = document.querySelector('#rem-' + id);
                let typables = document.querySelectorAll('.typable');

                if (but) {
                    but.onclick = () => this.removeFunction(id);
                }
                // 
                let eq = document.querySelector('#' + id);

                if (eq) {
                    eq.addEventListener(
                        'contextmenu',
                        (e) => {
                            // alert("You've tried to open context menu"); //here you draw your own menu
                            e.preventDefault();
                            this.rightClickFunction(id, e);
                        },
                        false
                    );
                }

                if (typables) {
                    typables.forEach((typable) => {
                        typable.onclick = (e) => this.doFocus(e, id, typable);
                    });
                }
            }, 10);

            window.getSelection().removeAllRanges();

            let newSpan = document.createElement('span');
            let textNode = document.createTextNode(' ');
            newSpan.appendChild(textNode);

            span.parentNode.insertBefore(newSpan, span.nextSibling);
        }
    }

    rightClickFunction = (id, e) => {
        // 
        let span = document.querySelector('#' + id);
        // 
        // 

        let bound = span?.getBoundingClientRect();
        // 

        this.setState({ showRmenu: true, rId: id }, () => {
            let rmenu = document.querySelector('#rmenu');
            rmenu.style.top = bound.top + 10 + 'px';
            rmenu.style.left = bound.left + 10 + 'px';
            setTimeout(() => {
                
                document.addEventListener('mousedown', this.mouseRDone, true);
            }, 100);
        });
        // 
        // if (span) {
        //     span.remove()
        // }
    };

    mouseRDone = () => {
        
        setTimeout(() => {
            this.setState({ showRmenu: false });
            

            document.removeEventListener('mousedown', this.mouseRDone, true);
        }, 100);
    };

    copyClicked = () => {
        let id = this.state.rId;
        
        let copyText = document.querySelector('#' + id);
        
        

        // copyText.select();
        // copyText.setSelectionRange(0, 99999);

        navigator.clipboard.writeText(copyText.outerHTML);

        // alert("Copied the text: " + String(copyText));
    };

    doFocus = (e, id, typable) => {
        // let pid = placeholder.id
        // 
        // pid = pid.replace('PH-', '')
        // let input = document.querySelector('#' + pid)
        // if (input) {
        typable.focus();
        e.stopPropagation();

        // }
    };

    removeFunction = (id) => {
        
        let span = document.querySelector('#' + id);
        
        if (span) {
            span.remove();
        }
    };

    createSmartVariable(text, id) {
        var span = document.createElement('span');
        span.classList.add('smart-variable');
        span.setAttribute('id', id);
        span.innerHTML = text;

        // TODO: SAVE VARIABLE
        return span;
    }
    smartInputMouseMove = (e) => {

        const textContent = e.target.textContent;
        const { clientX, clientY } = e;
        this.DescriptionsBox.wrapperRef.style.left = `${clientX + 20}px`;
        this.DescriptionsBox.wrapperRef.style.top = `${clientY}px`;
        const foundVariable = this.props.properVariables.find(variable => variable.name == textContent);
        if (foundVariable && foundVariable.description) {
            this.DescriptionsBox.updateDescription(foundVariable.description)
            this.DescriptionsBox.wrapperRef.style.display = "block";
        } else {
            this.DescriptionsBox.wrapperRef.style.display = "none";
        }
    }
    smartInputMouseLeave = (e) => {
        this.DescriptionsBox.wrapperRef.style.display = "none";
    }

    render() {
        
        return (
            <div
                onMouseMove={this.smartInputMouseMove}
                onMouseLeave={this.smartInputMouseLeave}
                className=""
                ref={(el) => (this.wrapperRef = el)}
                role="presentation"
                style={{
                    display: 'flex',
                    overflow: 'auto',
                    position: 'relative',
                    flex: 1,
                    alignSelf: 'stretch',

                }}
            >
                <div
                    role={'textbox'}
                    onCopy={this.onCopy}
                    onPaste={this.onPaste}
                    onCut={this.onCopy}
                    onBlur={() => this.props.detectLinesProperVariables()}
                    onInput={this.onInput}
                    // onKeyDown={this.onKeyDown}
                    ref={(el) => (this.editor = el)}
                    className="editor w-100 h-100"
                    id="editor"
                    data-placeholder={'@ for variables'}
                    contentEditable={true}
                    spellCheck={false}
                    style={{
                        lineHeight: 2.3,
                        backgroundColor: '#fff',
                        color: 'rgb(00, 05, 10)',
                        padding: '10px 10px',
                        outline: 'none',
                        height: '100%',
                    }}
                ></div>
                {this.state.showList && (
                    <div
                        className="w-100 blur-back py-2"
                        style={{
                            top: this.state.listPostion?.top,
                            overflow: 'auto',
                            height: 200,
                            backgroundColor: '#ffffffdd',
                            borderRadius: '0px 0px 4px 0px',
                            position: 'absolute',
                            zIndex: 10,
                            boxShadow: '0px 0px 30px #10101020',
                        }}
                    >
                        {this.state.optionsList.map((prop, index) => {
                            return (
                                <div className="w-100 text-start" key={index}>
                                    <button
                                        onClick={() =>
                                            this.optionSelected(prop)
                                        }
                                        className="px-3 py-2 w-100 text-start"
                                        style={{
                                            borderBottom: '1px solid #f2f6f8',
                                        }}
                                    >
                                        <p>{prop.label}</p>
                                    </button>
                                </div>
                            );
                        })}
                    </div>
                )}

                {this.state.showRmenu && (
                    <div
                        id="rmenu"
                        className="blur-back"
                        style={{
                            backgroundColor: '#ffffffdd',
                            borderRadius: 8,
                            position: 'fixed',
                            boxShadow: '0px 0px 30px #10101030',
                        }}
                    >
                        <button
                            onClick={(e) => this.copyClicked()}
                            className="px-5 py-1"
                        >
                            <p style={{ color: '#000', fontSize: 14 }}>Copy</p>
                        </button>
                    </div>
                )}
                <DescriptionsBox ref={el => this.DescriptionsBox = el} styles={
                    {
                        maxWidth: 150,
                        backgroundColor: " rgb(226, 234, 242)",
                        padding: "5px 10px",
                        borderRadius: 5,
                        fontSize: 12

                    }
                } />
                <WordSuggestion ref={el => this.WordSuggestion = el} style={{}} suggestionWords={["word1", "word2"]} styles={
                    {
                        width: 200,
                        backgroundColor: " rgb(226, 234, 242)",
                        padding: "5px 10px",
                        borderRadius: 3,
                        fontSize: 14,
                        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1), 0 -2px 4px rgba(0, 0, 0, 0.1), 2px 0 4px rgba(0, 0, 0, 0.1), -2px 0 4px rgba(0, 0, 0, 0.1)',
                        // border: "1px solid #396fb4"

                    }
                }
                    selectSuggestionWord={this.selectSuggestionWord}
                    wordBoxStyle={
                        {
                            marginTop: 5,
                            boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1) inset',
                            padding: "5px"
                        }
                    }
                    activeWordStyle={
                        {
                            color: "#000"
                        }
                    }
                />
            </div>
        );
    }
}

export default SmartInput;
