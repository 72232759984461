import React from "react";
import HttpService from '../utils/Http.services';
import { siteConfig, siteTheme } from "../variables/config";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actions from '../stores/actionsList';
// import TabBar from "../views/TabBar";
import Configurer from "../components/Configurer";
import ProfileImage from "../components/partials/ProfileImage";
import TableViewer from "../components/TableViewer";
import { v4 as uuidv4 } from 'uuid';
import DataGrid from "../components/DataGrid";
import ServiceBox from "../components/boxes/ServiceBox";
import SearchBar from "../components/SearchBar";
import FilterViewer from "../components/FilterViewer";
import TLink from "../components/TLink";
import Loader from "react-loader-spinner";
import Collapsible from "react-collapsible";
import { imageAddress, pathMaker } from "../utils/useful";


class Library extends React.Component {


    state = {
        categories: [],
        collapses: {}
        //     { name: 'Sensors', key: '', dontShowInDashboard: true },
        //     { name: 'Temprature', key: 'edit' },
        //     { name: 'Humidity', key: 'type' },
        //     { name: 'Actions', key: 'password' },
        // ],


    }


    componentWillMount() {
        this.fetch()
        this.fetchLibraryCategories()
    }

    componentWillReceiveProps(newProps) {
        if (this.props.location != newProps.location) {
            this.setState({ isLoadingPage: true, data: {}, similarData: [] }, () => {
                this.fetch()
            })
        }
    }



    fetchLibraryCategories() {

        HttpService.request('getAllWithData', { applet: 'libraryCategories' }, (fetchResult, fetchError) => {
            
            

            if (fetchError) { return }
            if (fetchResult.info) {
                fetchResult.info.sort((a, b) => { if (b.values.priority) { return (a.values.priority - b.values.priority) } else { return false } })
                // 
                this.setState({ categories: fetchResult.info })
            }
        })
    }

    // fetchLibraryCategories() {

    //     HttpService.request('getAllWithData', { applet: 'libraryCategories' }, (fetchResult, fetchError) => {
    //         
    //         

    //         if (fetchError) { return }
    //         if (fetchResult.info) {
    //             this.setState({ categories: fetchResult.info })
    //         }
    //     })
    // }


    fetch() {

        // let search = this.props.location.search;
        // let params = new URLSearchParams(search);
        // let id = params.get('id');
        // let slug = params.get('slug');
        let id = this.props.match.params.id

        // let filter = { 'values.category': id }
        let filter = {}

        // if (slug) {
        //     filter = { 'values.slug': slug }
        // }
        this.setState({ isLoading: true })

        HttpService.request("getAllWithData", { applet: 'library', filter }, (fetchResult, fetchError) => {
            this.setState({ isLoading: false })

            
            

            if (fetchError) { return }
            // 
            if (id) {
                fetchResult.info.forEach(element => {
                    if (element._id == id) {
                        this.setArticle(element)
                    }
                });
            }
            // 
            // if (fetchResult.info) {
            this.setState({ articles: fetchResult.info })
            // }
        })
    }



    countSubCategories(id) {
        let count = 0
        for (let i = 0; i < this.state.categories.length; i++) {
            const element = this.state.categories[i];
            let parent = typeof element.values.parent == 'object' ? element.values.parent._id : element.values.parent

            if (parent == id) {
                count = count + 1
            }
        }

        return count
    }

    changeCollapse(id) {
        let collapses = this.state.collapses
        collapses[id] = !collapses[id]
        this.setState({ collapses })
    }




    renderCategory(parent) {
        return (
            <div>
                {this.state.categories?.map((prop, index) => {
                    let cparent = typeof prop.values.parent == 'object' ? prop.values.parent._id : prop.values.parent

                    if ((cparent == parent) || (parent == null && cparent == '')) {
                        return (
                            <div className={(parent == null ? 'mb-2' : 'mb-1')} key={index} >
                                <div key={index} className={"px-0 mt-1 flexc"} style={{ cursor: 'pointer' }} onClick={() => this.changeCollapse(prop._id)}>
                                    {/* <img src="/images/nexts.png" width="12px" className="mrd-2 opacity-2" /> */}
                                    {this.countSubCategories(prop._id) != 0 && (
                                        <button className="flexcc p-0 mrd-2" style={{ backgroundColor: '#fff', border: '0px solid #eee', borderRadius: 30, height: 20, width: 20, color: '#789' }}><p className="text-small">{this.state.collapses[prop._id] ? '+' : '-'}</p></button>
                                    )}
                                    <p className={" " + (parent == null ? "text-semibig text-bold" : 'text-small ')}>{prop?.values?.name}</p>
                                </div>

                                <Collapsible open={!this.state.collapses[prop._id]}>
                                    <div className="mld-5">
                                        {this.renderCategory(prop._id)}
                                    </div>
                                </Collapsible>

                                <div className="mld-0">
                                    {this.renderArticles(prop._id)}
                                </div>
                            </div>
                        )
                    }
                })}
            </div>
        )
    }




    renderArticles(parent) {
        return (
            <div>
                {this.state.articles?.map((prop, index) => {
                    if (prop.values.category?._id == parent) {
                        return (
                            <div className={'mb-2'} key={index} onClick={() => this.setArticle(prop)} >
                                <div key={index} className={"px-0 mt-1 flexc"} style={{ cursor: 'pointer' }} onClick={() => this.changeCollapse(prop._id)}>
                                    <img src="/images/nexts.png" width="10px" className="mrd-2 " />
                                    <p className="text-small" style={{ color: this.state.currentArticle?._id == prop._id ? 'rgb(103, 125, 196)' : '#101010', transition: 'all 0.5s' }}>{prop?.values?.title}</p>
                                </div>

                            </div>
                        )
                    }
                })}
            </div>
        )
    }


    setArticle = (article) => {
        window.history.pushState(null, null, pathMaker('/library/') + article._id);

        this.setState({ currentArticle: article })
    }


    openEditor = () => {
        this.props.history.push({
            pathname: '/editor/' + (this.props.initData?.name ?? ''),
            state: { compiled: this.state.currentArticle?.values?.editor, nickname: this.state.currentArticle.values.title }
        })
    }

    render() {
        return (

            <Configurer
                settingsInfo={{ showFooter: true, showTabBar: true, showHeader: true, headerTitle: "Explore", button: {} }}
                title={"Explore"}
                description={"Explore Description"}
                className=""
            >


                <div className="row m-0 p-0">
                    <div className="col-12 col-sm-12 col-md-3 col-lg-3 pageSide p-0 d-md-block d-none" style={{ backgroundColor: '#fff' }}>
                        <div className="" style={{ position: 'sticky', top: 45, backgroundColor: '#f2f6f8', borderRadius: 0, height: '100vh', overflow: 'auto' }}>

                            {/* <div className="text-center pt-2 pb-3" style={{ borderBottom: '1px solid #eee' }}>
                                <SearchBar />
                            </div> */}
                            <div className="pb-3 text-center p-3 " style={{ borderBottom: '0px solid #ddd', backgroundColor: 'rgb(103, 125, 196)', borderRadius: '0px 0px 30px 0px' }}>
                                <p className="m-0 p-0 text-bold" style={{ color: '#ffffffaa' }}>RASHAC</p>
                                <h1 className="mt-0" style={{ lineHeight: 1, color: '#fff' }}>LIBRARY</h1>
                            </div>


                            <div className="p-4 " >
                                <div className=" " >

                                    <div className="d-flex text-start flex-column align-items-start px-0 mt-1">
                                        {this.renderCategory()}
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>



                    <div className="col-12 col-md-9 col-lg-9 pt-4 pb-4 px-5" style={{}}>

                        <div className="p-2 ">
                            <div>
                                <h1 className="text-ultra-big mb-0" >{this.state.currentArticle?.values?.title}</h1>
                            </div>

                            <div className="col-12 mt-0 page-loader mb-5 p-0">

                                {this.state.isLoading && (
                                    <div className="mt-2 pt-5 flexcc">
                                        <Loader
                                            type={"Oval"}
                                            color={"#007aff"}
                                            height={50}
                                            width={50}
                                        />
                                    </div>
                                )}

                                {!this.state.isLoading && !this.state.currentArticle && (
                                    <div className="mt-2 pt-5 flexcc flex-column">
                                        <p className="text-bold text-semibig">Found Nothing</p>
                                        <p>Please select an article to read it's content</p>

                                    </div>
                                )}
                                <div style={{ color: '#202020', fontSize: 16 }} dangerouslySetInnerHTML={{ __html: this.state.currentArticle?.values?.body }}></div>

                                {this.state.currentArticle?.values?.editor && (
                                    <div>
                                        <div className="flexcb">
                                            <p className="mb-2 text-bold">THE CODE</p>
                                            <button onClick={() => this.openEditor()} className="text-small pb-3" style={{ color: '#007aff' }}>Open in editor</button>
                                        </div>
                                        <div className="px-3 py-4 text-small" style={{ overflow: 'auto', whiteSpace: 'pre-wrap', backgroundColor: '#f2f6f8', borderRadius: 4 }}>
                                            {this.state.currentArticle?.values?.editor}
                                        </div>
                                    </div>
                                )}



                            </div>

                            {this.state.currentArticle?.values?.attachments && (
                                <div>
                                    <div className="flexcb mt-2">
                                        <p className="mb-2 text-bold">ATTACHMENTS</p>
                                    </div>
                                    <div className="d-flex text-center">
                                        {this.state.currentArticle?.values?.attachments.map((prop, index) => {
                                            return (
                                                <a className="m-2" href={imageAddress(prop)} target="_blank">
                                                    <img src="/images/icons/file.png" width="30px" style={{ width: '60px' }} />
                                                    <p className="text-small">{prop.extension} File</p>
                                                    <p className="text-smaller" style={{ lineHeight: 1, color: '#007aff' }}>Click to download</p>

                                                </a>
                                            )
                                        })}
                                    </div>
                                </div>
                            )}

                        </div>




                    </div>
                </div>
            </Configurer>
        )
    }


}



const mapStateToProps = state => ({ settings: state.settings, user: state.user })
const mapDispatchToProps = dispatch => ({ actions: bindActionCreators(actions, dispatch) })

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Library);
