const STORAGE_KEY = 'RASHAC';


const callMiddlewares = state => {
    // MIDDLEWARES
}


export const saveState = (state) => {
    try {
        const dataToPersist = {
            settings: state.settings,
            user: state.user,
            cart: state.cart,
            resource: state.resource,
            notifs: state.notifs,
        }
        const stateFilter = JSON.parse(JSON.stringify(dataToPersist))
        const rawState = JSON.stringify(stateFilter);
        localStorage.setItem(STORAGE_KEY, rawState);

    } catch (err) {
        
    }
};


export const persistedState = (() => {
    try {
        const rawState = localStorage.getItem(STORAGE_KEY);
        if (rawState === null) return undefined;
        const state = JSON.parse(rawState);
        delete state.resource
        delete state.notifs

        callMiddlewares(state);
        return state;
    } catch (err) {
        return undefined;
    }
})()