import React from "react";
import Configurer from "../../components/Configurer";
import StagesManager from "../../components/StagesManager";
import TLink from "../../components/TLink";
import ThingHistoryChart from "./ThingHistory/ThingHistoryChart";
import ThingHistoryLocation from "./ThingHistory/ThingHistoryLocation";
import ThingHistoryMain from "./ThingHistory/ThingHistoryMain";
import ThingHistoryTable from "./ThingHistory/ThingHistoryTable";


class ThingHistory extends React.Component {

    state = {
        
    }


    componentDidMount(){
        // this.stageManager.changeStage('chart')
    }

    render() {
        return (



            <section className="thingInfoPage">
                <div className="w-100 pt-4 px-5 mb-4 pb-5">


                    <StagesManager ref={el => this.stageManager = el}>
                        <ThingHistoryMain stage={0} stageName={'main'} />
                        <ThingHistoryTable stage={1} stageName={'table'} />
                        <ThingHistoryChart stage={1} stageName={'chart'} />
                        <ThingHistoryLocation stage={1} stageName={'location'} />

                    </StagesManager>




                </div>




            </section>

        )
    }


}


export default ThingHistory
