export const INIT_SOCKET = 'INIT_SOCKET';
export const GENERATED_MODEL = '/generatedModel';
export const SOLVED_MODEL = '/solvedModel';
export const APP_ERROR = '/app_error';



export const initSocket = () => {
    return {
        type: INIT_SOCKET
    }
}