import { API_ROOT } from "./envVariables"

const siteConfig = {
    languages: ["en", "fa"],
    // languages: ["fa"],

    defaultLng: 'en',
    dontShowUrlForDefaultLng: true,
    forceUrl: true,


    showCart: false,
    showProfile: true,
    showLanguage: true,
    showDeliveryCalcText: false,

    // rootDomain: 'http://localhost:27017',  // Use the service name ""
    // domain: 'http://localhost:27017/haj/',  // Use the service name ""
    // assetsDomain: 'http://localhost:27017/assets/',  // Use the service name ""
    // http://localhost:7624
    rootDomain: `${API_ROOT}`,  // Use the service name ""
    domain: `${API_ROOT}/api/`,  // Use the service name ""
    assetsDomain: `${API_ROOT}/assets/`,  // Use the service name ""

    // internal docker
    // rootDomain: '',  // Use the service name ""
    // domain: '/haj/',  // Use the service name ""
    // assetsDomain: '/assets/',  // Use the service name ""


    name: 'Rashac Platform',
    siteName: 'Rashac Platform',
    phone: '09029433311',
    instagram: 'https://instagram.com/',
    email: 'test@gmail.com',
    facebook: '',


}
const siteTheme = {


    logo: '/images/RashacLogo.png',
    logo_s: '/images/RashacLogo.png',
    // invertLogo: true,


    themeColors: { headerBack: '#f2f7f8', headerFont: '#000000' },
    // themeColors: { headerBack: '#46196f', headerFont: '#ffffff' },
    // themeColors: { headerBack: '#000000', headerFont: '#ffffff' },



    backgroundColors: { normal: '#e2e8ea', plus: '#ee5050', luxury: '#101010', custom: '#d3b564' },
    colors: { normal: '#202020', plus: '#fff', luxury: '#fff', custom: '#101010' },
}

const languagesInfo = {
    en: {
        shortName: 'en',
        direction: 'ltr',
        name: 'English',
        icon: ''
        // icon: 'https://image.flaticon.com/icons/svg/197/197484.svg'
    },
    fa: {
        shortName: 'fa',
        direction: 'rtl',
        name: 'فارسی',
        icon: 'https://image.flaticon.com/icons/svg/197/197574.svg'
    }
}


const apisList = {

    // USER
    postUserIndicator: { route: 'users/signup/postUserIndicator', server: 'yc', hashMethod: '', params: [{ key: "userIndicator", type: 'string', required: true }, { key: "indicatorType", type: 'string', required: true }] },
    signupActicationCode: { route: 'users/signup/postActicationCode', server: 'yc', hashMethod: '', params: [{ key: "userIndicator", type: 'string', required: true }, { key: "code", type: 'number', required: true }, { key: "indicatorType", type: 'string', required: true }] },
    signinActicationCode: { route: 'users/signin/postActicationCode', server: 'yc', hashMethod: '', params: [{ key: "userIndicator", type: 'string', required: true }, { key: "code", type: 'number', required: true }, { key: "indicatorType", type: 'string', required: true }] },
    postUserInfo: { route: 'users/signup/postUserInfo', server: 'yc', hashMethod: '', flexibleParams: true, params: [{ key: "userIndicator", type: 'string', required: true }, { key: "code", type: 'number', required: true }, { key: "indicatorType", type: 'string', required: true }] },
    postForgotPassword: { route: 'users/signup/postForgotPassword', server: 'yc', hashMethod: '', params: [{ key: "userIndicator", type: 'string', required: true }, { key: "indicatorType", type: 'string', required: true }] },
    postPassword: { route: 'users/signup/postPassword', server: 'yc', hashMethod: '', params: [{ key: "userIndicator", type: 'string', required: true }, { key: "code", type: 'number', required: false }, { key: "password", type: 'string', required: true }, { key: "indicatorType", type: 'string', required: true }] },
    postNewPassword: { route: 'users/signin/postNewPassword', server: 'yc', hashMethod: '', params: [{ key: "userIndicator", type: 'string', required: true }, { key: "code", type: 'number', required: false }, { key: "password", type: 'string', required: true }, { key: "indicatorType", type: 'string', required: true }] },
    getUserInfo: { route: 'users/getUserInfo', server: 'yc', hashMethod: '', auth: 'token', params: [] },
    updateUserInfo: { route: 'users/updateUserInfo', server: 'yc', hashMethod: '', flexibleParams: true, auth: 'token', params: [] },
    updateUserPassword: { route: 'users/updateUserPassword', server: 'yc', hashMethod: '', flexibleParams: true, auth: 'token', params: [{ key: "currentPassword", type: 'string', required: true }, { key: "newPassword", type: 'string', required: true }] },


    //THINGS


    //Values
    getValuesWithData: { route: 'values/getValuesWithData', server: 'yc', hashMethod: '', params: [], auth: 'token', flexibleParams: true },
    postValue: { route: 'values/postValue', server: 'yc', hashMethod: '', params: [], auth: 'token', flexibleParams: true },
    getAllWithData: { route: 'values/getAllWithData', server: 'yc', hashMethod: '', params: [], auth: 'token', flexibleParams: true },
    getOneWithData: { route: 'values/getOneWithData', server: 'yc', hashMethod: '', params: [], auth: 'token', flexibleParams: true },
    getHistory: { route: 'or/getHistory', server: 'yc', hashMethod: '', params: [], auth: 'token', flexibleParams: true },
    createNewModel: { route: 'or/createNewModel', server: 'yc', hashMethod: '', params: [], auth: 'token', flexibleParams: true },
    getOneEquation: { route: 'or/getOneEquation', server: 'yc', hashMethod: '', params: [], auth: 'token', flexibleParams: true },
    readExcelDefinedName: { route: 'or/readExcelDefinedName', server: 'yc', hashMethod: '', params: [], auth: 'token', flexibleParams: true },
    downloadModelFiles: { route: 'or/models/download', server: 'yc', hashMethod: '', params: [], auth: 'token', flexibleParams: true, method: "POST" },
    exportModelFile: { route: 'or/models/exportModelFile', server: 'yc', hashMethod: '', params: [], auth: 'token', flexibleParams: true, method: "POST" },
    generateExtendedModel: { route: 'or/generateExtendedModel', server: 'yc', hashMethod: '', params: [], auth: 'token', flexibleParams: true },
    postEquationToSolvingEngine: { route: 'or/postEquationToSolvingEngine', server: 'yc', hashMethod: '', params: [], auth: 'token', flexibleParams: true },
}


export { siteConfig, siteTheme, languagesInfo, apisList }