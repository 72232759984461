import React from "react";
import FormViewer from "../components/FormViewer";
import LoaderButton from "../components/LoaderButton";
import Modal from '../components/Modal'
import HttpServices from "../utils/Http.services";
import { checkTranslation } from "../utils/useful";
import { countriesCode } from '../variables/countries'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actions from '../stores/actionsList';

class ItemModal extends React.Component {

    submitForm = () => {
        if (this.props.submit) {
            let data = this.form.getForm();
            if (data) {
                this.props.submit(data)
            }
        } else {
            this.postValue()
        }
    }
    hideModal = () => {
        const hideModalCb = this.props?.hideModalCb || null;

        this.modal.hideModal(hideModalCb);
    }
    state = {
        countries: [],
        actions: {
            submit: { key: "submit", label: "{{lang}}submit", bgColor: '#677dc4', color: '#fff', function: this.submitForm, loader: "isPostingData" },
            cancel: { key: "cancel", label: "{{lang}}cancel", bgColor: '#9ab', color: '#fff', function: this.hideModal },
        }
    }


    postValue = () => {
        if (this.form) {
            let data = this.form.getForm()
            this.setState({ errors: null })
            if (data) {
                let isEditing = data._id ? true : false
                let page = this.props.page
                data.page = this.props.page?.key
                this.setState({ isPostingData: true })
                HttpServices.request(page.addUrl, data, (fetchResult, fetchError) => {
                    this.setState({ isPostingData: false })

                    if (fetchError) {
                        this.setState({ errors: fetchError.errors })
                        this.props.actions.addNotif({ type: 'error', title: '{{lang}}errors.postDataFailed', description: fetchError.message })
                        return
                    }
                    this.modal.hideModal(() => {
                        this.props.actions.addNotif({ type: 'success', title: '{{lang}}info.postDataSuccesfully', description: '{{lang}}info.dataUpdatedSuccessfully' })
                        if (!isEditing && this.props.itemAdded) {
                            this.props.itemAdded(fetchResult.info)
                        }
                        if (isEditing && this.props.itemEdited) {
                            this.props.itemEdited(data)
                        }
                    })
                })
            }
        }
    }



    render() {
        return (
            <Modal
                ref={(el) => (this.modal = el)}
                maxWidth={"max-content"}
                hideModalExternalCb={this.props?.hideModalCb}
            >
                <div className="pb-5 w-100 h-100">
                    <div
                        className="w-100 h-100"
                        style={{
                            backgroundColor: "#fff",
                            overflow: "auto",
                            borderRadius: 8,
                        }}
                    >
                        <div
                            className="w-100"
                            style={{
                                zIndex: 1,
                                padding: "12px 22px",
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                position: "sticky",
                                top: 0,
                                borderBottom: "0px #eee solid",
                                backgroundColor: "#f2f6f8ee",
                                background:
                                    "linear-gradient(to right,#d7e2f7dd,#dad6e4dd)",
                                backdropFilter: "blur(10px) saturate(180%)",
                                WebkitBackdropFilter:
                                    "blur(10px) saturate(180%)",
                                borderRadius: "8px 8px 0px 0px",
                            }}
                        >
                            <p>Edit Item</p>

                            <div
                                className="cursor-pointer"
                                onClick={() => this.modal.hideModal()}
                                style={{
                                    width: 30,
                                    height: 30,
                                    borderRadius: "50%",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                }}
                            >
                                <img
                                    style={{ width: 14, height: 14 }}
                                    src=""
                                    alt=""
                                />
                                {/* <img style={{ width: 14, height: 14 }} src="https://image.flaticon.com/icons/svg/1828/1828747.svg" alt="" /> */}
                            </div>
                        </div>
                        <div className="py-3 px-4">
                            <FormViewer
                                ref={(el) => (this.form = el)}
                                headers={this.props.headers}
                                initData={this.props.initData}
                                errors={this.state.errors}
                                inputClass={"modern-input"}
                            />
                        </div>

                        <div className="flexc px-3 pb-3">
                            {Object.values(this.state.actions).map(
                                (prop, index) => {
                                    return (
                                        <div key={index} className="w-100 mx-1">
                                            <LoaderButton
                                                onClick={prop.function}
                                                isLoading={
                                                    this.state[prop.loader]
                                                }
                                                text={checkTranslation(
                                                    prop.label
                                                )}
                                                type={"Oval"}
                                                className="text-bold text-small text-uppercase w-100"
                                                buttonStyle={{
                                                    outline: "none",
                                                    width: "100%",
                                                    backgroundColor:
                                                        prop.bgColor,
                                                    cursor: "pointer",
                                                    padding: "13px 20px",
                                                    borderRadius: 8,
                                                    textTransform: "uppercase",
                                                    fontSize: 15,
                                                    fontWeight: "bold",
                                                    color: prop.color,
                                                    border: "none",
                                                }}
                                                width={40}
                                                height={40}
                                                color={prop.bgColor}
                                            />
                                        </div>
                                    );
                                }
                            )}
                        </div>
                    </div>
                </div>
            </Modal>
        );

    }
}



const mapStateToProps = state => ({ settings: state.settings, user: state.user })
const mapDispatchToProps = dispatch => ({ actions: bindActionCreators(actions, dispatch) })

export default connect(
    mapStateToProps,
    mapDispatchToProps,
    null, { forwardRef: true }
)(ItemModal);
