import React from "react";
import Configurer from "../../components/Configurer";
import TLink from "../../components/TLink";


class ThingConnectedThings extends React.Component {

    state = {
        data: [
            1, 1, 1, 1
        ]
    }

    render() {
        return (



            <section className="thingInfoPage">
                <div className="w-100 pt-4 px-5 mb-4 pb-5">

                    <div className={'thingInfoList mt-3'}>

                        <div className="row">

                            {this.state.data.map((prop, index) => {
                                return (
                                    <div className="col-6 col-md-6 mb-4" key={index}>

                                        <TLink to={'/thing/id'} className="flexc p-3" key={index} style={{ backgroundColor: '#f2f6f8', border: '1px solid #f0f2f4', boxShadow: '0px 0px 15px #00000000', borderRadius: 15 }}>

                                            <div className="d-flex">
                                                <div className="mrd-4 mt-1">
                                                    <img src="https://images.unsplash.com/photo-1577771487836-435449c81bce?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=738&q=80" style={{ width: 70, height: 70, borderRadius: 50, objectFit: 'cover' }} alt="" />
                                                </div>
                                                <div>
                                                    <span className="font-bold text-normal">Thing name</span>
                                                    <p className={'text-smaller '} style={{ color: '#789' }}>Part number: DC141</p>
                                                    <p className={'text-smaller '} style={{ color: '#789' }}>Brand: Brand name</p>
                                                    <p className={'text-smaller '} style={{ color: '#789' }}>Brand: Model name</p>

                                                </div>

                                            </div>
                                        </TLink>
                                    </div>
                                )
                            })}
                        </div>



                    </div>


                </div>




            </section>

        )
    }


}


export default ThingConnectedThings
